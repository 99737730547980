import React, { useState } from 'react';

const BusinessInfoForm = ({ onSubmit, loading, isDarkMode }) => {
  const [formData, setFormData] = useState({
    business_type: '',
    business_name: '',
    purpose: 'assistenza_clienti',
    description: '',
    contacts: '',
    address: '',
    opening_hours: '',
    num_qa_pairs: 20
  });

  const validateForm = (data) => {
    if (!data.business_type.trim()) {
      throw new Error('Il tipo di attività è obbligatorio');
    }
    if (!data.business_name.trim()) {
      throw new Error('Il nome dell\'attività è obbligatorio');
    }
    // Puoi aggiungere altre validazioni se necessario (es. contatti, indirizzo, orari)
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      validateForm(formData);
      const processedFormData = {
        ...formData,
        num_qa_pairs: parseInt(formData.num_qa_pairs, 10) || 20
      };
      onSubmit(processedFormData);
    } catch (error) {
      console.error(error);
      // Qui potresti mostrare un alert oppure notificare l'errore all'utente
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
      <div className="space-y-6">
        <div>
          <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
            Tipo di Attività
          </label>
          <input
            type="text"
            name="business_type"
            value={formData.business_type}
            onChange={handleChange}
            required
            className={`w-full px-4 py-2 border rounded-md ${
              isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-700'
            }`}
            placeholder="es. Negozio di fiori"
          />
        </div>

        <div>
          <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
            Nome Attività
          </label>
          <input
            type="text"
            name="business_name"
            value={formData.business_name}
            onChange={handleChange}
            required
            className={`w-full px-4 py-2 border rounded-md ${
              isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-700'
            }`}
            placeholder="es. Fioreria Bella Flora"
          />
        </div>

        <div>
          <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
            Finalità del Dataset
          </label>
          <select
            name="purpose"
            value={formData.purpose}
            onChange={handleChange}
            className={`w-full px-4 py-2 border rounded-md ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white' 
                : 'bg-white border-gray-300 text-gray-700'
            }`}
          >
            <option value="assistenza_clienti" className={isDarkMode ? 'bg-gray-700 text-white' : ''}>Assistenza Clienti</option>
            <option value="training_personale" className={isDarkMode ? 'bg-gray-700 text-white' : ''}>Training Personale</option>
            <option value="faq" className={isDarkMode ? 'bg-gray-700 text-white' : ''}>FAQ</option>
          </select>
        </div>

        <div>
          <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
            Contatti
          </label>
          <input
            type="text"
            name="contacts"
            value={formData.contacts}
            onChange={handleChange}
            className={`w-full px-4 py-2 border rounded-md ${
              isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-700'
            }`}
            placeholder="es. Telefono, Email, Social Media"
          />
        </div>

        <div>
          <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
            Indirizzo
          </label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className={`w-full px-4 py-2 border rounded-md ${
              isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-700'
            }`}
            placeholder="es. Via Roma, 123 - 00100 Roma"
          />
        </div>

        <div>
          <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
            Orari di Apertura
          </label>
          <input
            type="text"
            name="opening_hours"
            value={formData.opening_hours}
            onChange={handleChange}
            className={`w-full px-4 py-2 border rounded-md ${
              isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-700'
            }`}
            placeholder="es. 9:00-18:00"
          />
        </div>

        <div className="mb-4">
          <label className={`block text-sm font-bold mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
            Numero di coppie domanda-risposta
          </label>
          <input
            type="number"
            name="num_qa_pairs"
            value={formData.num_qa_pairs}
            onChange={handleChange}
            min={5}
            max={100}
            className={`w-full px-4 py-2 border rounded-md ${
              isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-700'
            }`}
          />
          <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            Scegli quante coppie di domande e risposte generare (min: 5, max: 100)
          </p>
        </div>

        <button
          type="submit"
          disabled={loading}
          className={`w-full py-2 px-4 rounded-md text-white 
            ${loading ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'}`}
        >
          {loading ? 'Generazione in corso...' : 'Genera Dataset'}
        </button>
      </div>
    </form>
  );
};

export default BusinessInfoForm;