import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

// Importa la configurazione dell'API
import { API_BASE_URL } from '../config/api.js';

/**
 * Hook per gestire il processo di fine-tuning
 * 
 * @param {function} appendMessage - Funzione per aggiungere messaggi al log
 * @returns {Object} Oggetto contenente stati e funzioni per il fine-tuning
 */
const useFinetuning = (appendMessage) => {
  // Stati per il fine-tuning
  const [isFineTuning, setIsFineTuning] = useState(false);
  const [fineTuneStatus, setFineTuneStatus] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [progress, setProgress] = useState(0);
  
  // Timer per il tempo trascorso
  useEffect(() => {
    let timer;
    if (isFineTuning && startTime) {
      timer = setInterval(() => {
        const currentTime = Date.now();
        const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
        setElapsedTime(elapsedSeconds);
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isFineTuning, startTime]);
  
  /**
   * Formatta il tempo trascorso in formato HH:MM:SS
   */
  const formatElapsedTime = useCallback((seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }, []);
  
  /**
   * Avvia il processo di fine-tuning
   */
  const startFineTuning = async (jsonFilePath) => {
    try {
      appendMessage('Invio del file per fine-tuning...', false);
      
      const formData = new FormData();
      formData.append('json_file_path', jsonFilePath);
      formData.append('purpose', 'assistenza_clienti_primo_livello');

      const response = await axios.post(`${API_BASE_URL}/process_finetuning`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        },
        timeout: 60000
      });
      
      if (response.data && response.data.temporary_job_id) {
        setIsFineTuning(true);
        setStartTime(Date.now());
        setProgress(0);
        setFineTuneStatus('Inizializzazione...');
        
        appendMessage('Fine-tuning avviato. Questo processo può richiedere diverse ore.', false);
        appendMessage('Puoi chiudere questa pagina e tornare più tardi. Il processo continuerà in background.', false);
        
        return response.data.temporary_job_id;
      } else {
        throw new Error('Risposta dal server non valida');
      }
    } catch (error) {
      console.error('Errore durante l\'avvio del fine-tuning:', error);
      appendMessage(`Errore durante l'avvio del fine-tuning: ${error.response?.data?.detail || error.message}`, false);
      return null;
    }
  };
  
  /**
   * Monitora lo stato del job di fine-tuning temporaneo
   */
  const pollRealJobId = useCallback(async (temporaryJobId) => {
    const token = localStorage.getItem('token');
    
    const checkStatus = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/fine_tune_status/${temporaryJobId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.data.status === 'completed' && response.data.job_id) {
          appendMessage(`Fine-tuning job creato con ID: ${response.data.job_id}`, false);
          return response.data.job_id;
        } else if (response.data.status === 'failed') {
          setIsFineTuning(false);
          appendMessage(`Errore durante la creazione del job di fine-tuning: ${response.data.error || 'Errore sconosciuto'}`, false);
          return null;
        } else {
          // Aggiorna lo stato e controlla di nuovo
          setFineTuneStatus(response.data.status || 'Preparazione del job...');
          setProgress(10); // Fase iniziale
          
          // Aspetta 5 secondi prima di controllare di nuovo
          await new Promise(resolve => setTimeout(resolve, 5000));
          return await checkStatus();
        }
      } catch (error) {
        console.error('Errore durante il controllo dello stato del job temporaneo:', error);
        appendMessage(`Errore durante il controllo dello stato: ${error.message}`, false);
        setIsFineTuning(false);
        return null;
      }
    };
    
    return await checkStatus();
  }, [appendMessage]);
  
  /**
   * Monitora lo stato del job di fine-tuning
   */
  const monitorFineTuneStatus = useCallback(async (jobId) => {
    const token = localStorage.getItem('token');
    
    const checkStatus = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/fine_tune_job/${jobId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        // Aggiorna lo stato
        setFineTuneStatus(response.data.status);
        
        // Calcola il progresso in base allo stato
        let progressValue = 10; // Default
        
        switch (response.data.status) {
          case 'validating_files':
            progressValue = 20;
            break;
          case 'queued':
            progressValue = 30;
            break;
          case 'running':
            progressValue = 50;
            break;
          case 'succeeded':
            progressValue = 100;
            setIsFineTuning(false);
            appendMessage(`Fine-tuning completato con successo! Modello: ${response.data.fine_tuned_model}`, false);
            return response.data;
          case 'failed':
            setIsFineTuning(false);
            appendMessage(`Fine-tuning fallito: ${response.data.error || 'Errore sconosciuto'}`, false);
            return null;
          case 'cancelled':
            setIsFineTuning(false);
            appendMessage('Fine-tuning annullato.', false);
            return null;
          default:
            progressValue = 40;
        }
        
        setProgress(progressValue);
        
        // Aggiorna i messaggi di log con gli step
        if (response.data.steps && response.data.steps.length > 0) {
          const lastStep = response.data.steps[response.data.steps.length - 1];
          appendMessage(`Stato fine-tuning: ${lastStep.message}`, false);
        }
        
        // Aspetta 30 secondi prima di controllare di nuovo
        await new Promise(resolve => setTimeout(resolve, 30000));
        return await checkStatus();
      } catch (error) {
        console.error('Errore durante il controllo dello stato del fine-tuning:', error);
        appendMessage(`Errore durante il controllo dello stato: ${error.message}`, false);
        
        // Continua a controllare anche in caso di errore temporaneo
        await new Promise(resolve => setTimeout(resolve, 60000));
        return await checkStatus();
      }
    };
    
    return await checkStatus();
  }, [appendMessage]);
  
  return {
    // Stati
    isFineTuning,
    fineTuneStatus,
    startTime,
    elapsedTime,
    progress,
    
    // Setters
    setIsFineTuning,
    setFineTuneStatus,
    setStartTime,
    setElapsedTime,
    setProgress,
    
    // Funzioni
    formatElapsedTime,
    startFineTuning,
    pollRealJobId,
    monitorFineTuneStatus
  };
};

export default useFinetuning; 