import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '../components/ui/Tabs';
import ProfileSection from '../components/user-dashboard/ProfileSection';
import ResourcesSummary from '../components/user-dashboard/ResourcesSummary';
import PaymentMethodsSection from '../components/user-dashboard/PaymentMethodsSection';
import InvoicesSection from '../components/user-dashboard/InvoicesSection';
import userDashboardService from '../services/userDashboardService';
import { toast } from 'react-hot-toast';

const UserProfilePage = ({ token, isDarkMode = false }) => {
  const [activeTab, setActiveTab] = useState('profile');
  const [profileData, setProfileData] = useState(null);
  const [resourcesSummary, setResourcesSummary] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState({
    profile: true,
    resources: true,
    invoices: true,
    payments: true
  });

  // Carica i dati del profilo
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const data = await userDashboardService.getProfile(token);
        setProfileData(data);
      } catch (error) {
        toast.error('Errore nel caricamento del profilo');
        console.error('Errore nel caricamento del profilo:', error);
      } finally {
        setLoading(prev => ({ ...prev, profile: false }));
      }
    };

    fetchProfileData();
  }, [token]);

  // Carica il riepilogo delle risorse
  useEffect(() => {
    const fetchResourcesSummary = async () => {
      try {
        const data = await userDashboardService.getResourcesSummary(token);
        setResourcesSummary(data);
      } catch (error) {
        toast.error('Errore nel caricamento del riepilogo risorse');
        console.error('Errore nel caricamento del riepilogo risorse:', error);
      } finally {
        setLoading(prev => ({ ...prev, resources: false }));
      }
    };

    fetchResourcesSummary();
  }, [token]);

  // Carica i dati pertinenti quando si cambia tab
  useEffect(() => {
    const fetchTabData = async () => {
      if (activeTab === 'invoices' && invoices.length === 0) {
        setLoading(prev => ({ ...prev, invoices: true }));
        try {
          const data = await userDashboardService.getInvoices(token);
          setInvoices(data);
        } catch (error) {
          toast.error('Errore nel caricamento delle fatture');
          console.error('Errore nel caricamento delle fatture:', error);
        } finally {
          setLoading(prev => ({ ...prev, invoices: false }));
        }
      } else if (activeTab === 'payment' && paymentMethods.length === 0) {
        setLoading(prev => ({ ...prev, payments: true }));
        try {
          const data = await userDashboardService.getPaymentMethods(token);
          setPaymentMethods(data);
        } catch (error) {
          toast.error('Errore nel caricamento dei metodi di pagamento');
          console.error('Errore nel caricamento dei metodi di pagamento:', error);
        } finally {
          setLoading(prev => ({ ...prev, payments: false }));
        }
      }
    };

    fetchTabData();
  }, [activeTab, token, invoices.length, paymentMethods.length]);

  // Gestisce l'aggiornamento del profilo
  const handleProfileUpdate = async (profileData) => {
    try {
      await userDashboardService.updateProfile(token, profileData);
      toast.success('Profilo aggiornato con successo');
      // Aggiorna i dati del profilo
      const updatedProfile = await userDashboardService.getProfile(token);
      setProfileData(updatedProfile);
    } catch (error) {
      toast.error('Errore nell\'aggiornamento del profilo');
      console.error('Errore nell\'aggiornamento del profilo:', error);
    }
  };

  // Gestisce il cambio password
  const handlePasswordChange = async (passwordData) => {
    try {
      await userDashboardService.changePassword(token, passwordData);
      toast.success('Password aggiornata con successo');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail || 'Password attuale non corretta');
      } else {
        toast.error('Errore nel cambio password');
      }
      console.error('Errore nel cambio password:', error);
    }
  };

  // Gestisce l'eliminazione di un metodo di pagamento
  const handleDeletePaymentMethod = async (id) => {
    try {
      await userDashboardService.deletePaymentMethod(token, id);
      toast.success('Metodo di pagamento eliminato con successo');
      // Aggiorna la lista dei metodi di pagamento
      const updatedMethods = await userDashboardService.getPaymentMethods(token);
      setPaymentMethods(updatedMethods);
    } catch (error) {
      toast.error('Errore nell\'eliminazione del metodo di pagamento');
      console.error('Errore nell\'eliminazione del metodo di pagamento:', error);
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} py-8`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className={`${isDarkMode ? 'bg-gray-800 shadow-dark' : 'bg-white shadow'} overflow-hidden sm:rounded-lg mb-8`}>
          <div className={`px-4 py-5 sm:px-6 ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
            <h1 className={`text-2xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Pannello Utente</h1>
            <p className={`mt-1 max-w-2xl text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              Gestisci il tuo profilo, visualizza le fatture e gestisci i metodi di pagamento
            </p>
          </div>

          {/* Riepilogo Risorse */}
          {!loading.resources && resourcesSummary && (
            <div className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} px-4 py-5 sm:px-6`}>
              <ResourcesSummary data={resourcesSummary} isDarkMode={isDarkMode} />
            </div>
          )}
        </div>

        <div className={`${isDarkMode ? 'bg-gray-800 shadow-dark' : 'bg-white shadow'} overflow-hidden sm:rounded-lg`}>
          <Tabs activeTab={activeTab} onChange={setActiveTab} isDarkMode={isDarkMode}>
            <Tab id="profile" label="Profilo">
              {!loading.profile && profileData && (
                <ProfileSection 
                  profileData={profileData} 
                  onProfileUpdate={handleProfileUpdate}
                  onPasswordChange={handlePasswordChange}
                  isDarkMode={isDarkMode}
                />
              )}
            </Tab>
            <Tab id="invoices" label="Fatture">
              <InvoicesSection 
                invoices={invoices}
                loading={loading.invoices}
                isDarkMode={isDarkMode}
              />
            </Tab>
            <Tab id="payment" label="Metodi di Pagamento">
              <PaymentMethodsSection 
                paymentMethods={paymentMethods}
                loading={loading.payments}
                onDelete={handleDeletePaymentMethod}
                isDarkMode={isDarkMode}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage; 