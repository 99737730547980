import React, { useState, useEffect } from 'react';
import { ArrowRight, CheckCircle, Star, Lightbulb, Package, Search, ChevronRight, Check } from 'lucide-react';

const LandingPage = () => {
  // Stato per gestire il caricamento delle animazioni e la modalità dark
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  
  // Effetto per attivare le animazioni dopo il montaggio del componente
  useEffect(() => {
    setIsLoaded(true);
    
    // Controllo se l'utente preferisce il tema scuro a livello di sistema
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setIsDarkMode(true);
    }
    
    // Per le testimonianze, ruotarle automaticamente ogni 5 secondi
    const testimonialInterval = setInterval(() => {
      setActiveTestimonial(prev => (prev + 1) % testimonials.length);
    }, 5000);
    
    return () => clearInterval(testimonialInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  // Benefici del servizio
  const benefits = [
    'Riduzione del 60% del tempo di preparazione dei dataset',
    'Costi di fine-tuning ridotti fino al 40%',
    'Integrazione immediata nei flussi di lavoro aziendali',
    'Supporto tecnico specializzato 24/7',
    'Completa personalizzazione dei modelli AI'
  ];

  // Testimonianze migliorate con più dettagli
  const testimonials = [
    {
      quote: "FineTuningMax ha trasformato il nostro approccio all'AI. Ora possiamo creare modelli personalizzati in poche ore anziché settimane. L'interfaccia è intuitiva e il supporto tecnico eccezionale.",
      author: "Marco Rossi",
      title: "CTO",
      company: "TechSolutions Italia",
      image: "/api/placeholder/100/100",
      rating: 5
    },
    {
      quote: "La possibilità di addestrare modelli specifici per il nostro settore ha aumentato la precisione delle risposte del 78%. I nostri clienti notano la differenza e il nostro team è più produttivo.",
      author: "Laura Bianchi",
      title: "Head of AI",
      company: "DataInnovation",
      image: "/api/placeholder/100/100",
      rating: 5
    },
    {
      quote: "Il ROI è stato immediato. In appena due mesi abbiamo recuperato l'investimento grazie all'efficienza dei modelli personalizzati e alla riduzione dei costi operativi.",
      author: "Giuseppe Verdi",
      title: "CEO",
      company: "AIXpert Solutions",
      image: "/api/placeholder/100/100",
      rating: 5
    }
  ];
  
  // Caratteristiche principali
  const features = [
    {
      title: "Generazione Dataset Professionale",
      description: "Crea dataset strutturati per il fine-tuning con la nostra interfaccia guidata. Parti da zero o importa dati esistenti.",
      icon: <Package className="h-7 w-7" />,
      color: "from-blue-500 to-indigo-600",
      benefits: ["Auto-completamento con AI", "Verifica della qualità integrata", "Pulizia automatica dei dati"]
    },
    {
      title: "Model Playground Avanzato",
      description: "Testa, affina e confronta i tuoi modelli personalizzati in tempo reale. Ottimizza parametri e analizza performance.",
      icon: <Lightbulb className="h-7 w-7" />,
      color: "from-green-400 to-emerald-600",
      benefits: ["Confronto diretto tra modelli", "Metriche di performance in tempo reale", "Ottimizzazione guidata dei parametri"]
    },
    {
      title: "RAG Search Intelligente",
      description: "Implementa sistemi di Retrieval-Augmented Generation che arricchiscono le risposte con dati specifici della tua azienda.",
      icon: <Search className="h-7 w-7" />,
      color: "from-purple-500 to-violet-600",
      benefits: ["Integrazione con database aziendali", "Indicizzazione automatica dei documenti", "Analisi semantica avanzata"]
    }
  ];

  // Sezione FAQ
  const faqs = [
    {
      question: "Cosa rende FineTuningMax diverso dalle altre soluzioni di fine-tuning?",
      answer: "FineTuningMax è progettato specificamente per le PMI, offrendo un'interfaccia semplificata che non richiede competenze tecniche avanzate. La nostra piattaforma automatizza i processi più complessi di pulizia e preparazione dei dati, riducendo drasticamente il tempo necessario per implementare modelli AI personalizzati."
    },
    {
      question: "È necessario avere conoscenze di programmazione?",
      answer: "No! Abbiamo progettato FineTuningMax per essere utilizzato anche da chi non ha competenze di programmazione. L'interfaccia guidata ti accompagna in ogni fase del processo, dalla preparazione dei dati fino all'implementazione del modello finale."
    },
    {
      question: "Con quali modelli AI è compatibile FineTuningMax?",
      answer: "FineTuningMax è compatibile con i principali provider di modelli AI come OpenAI, Google AI e Mistral. La nostra piattaforma si aggiorna costantemente per supportare i modelli più recenti e performanti sul mercato."
    },
    {
      question: "Quanto tempo richiede mediamente l'implementazione?",
      answer: "La maggior parte dei nostri clienti riesce a completare il setup iniziale in circa 10 minuti. Per quanto riguarda il fine-tuning completo di un modello, dipende dalla complessità e dimensione del dataset, ma generalmente richiede da 1 a 3 ore, contro i giorni o settimane necessari con approcci tradizionali."
    }
  ];

  return (
    <div className={`min-h-screen flex flex-col transition-colors duration-200 ${
      isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'
    }`}>
      {/* Header con miglioramenti di accessibilità */}
      <header className={`transition-colors duration-200 w-full ${
        isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'
      } shadow-md border-b ${isDarkMode ? 'border-gray-800' : 'border-gray-200'} sticky top-0 z-50`}
      role="banner">
        <div className="max-w-7xl mx-auto px-4 h-16">
          <div className="grid grid-cols-3 h-full">
            {/* Logo con effetto hover */}
            <div className="flex items-center justify-start">
              <div 
                className="flex items-center hover:scale-105 transition duration-200"
                role="img" 
                aria-label="FineTuningMax logo"
              >
                <span className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">FineTuningMax</span>
                <Star className="h-5 w-5 ml-1 text-yellow-400 fill-yellow-400" />
              </div>
            </div>
            
            {/* Menu centrale con miglioramenti di accessibilità */}
            <nav className="hidden md:flex items-center justify-center space-x-6" aria-label="Menu principale">
              <a href="#features" className="font-medium hover:text-blue-500 transition-colors">Funzionalità</a>
              <a href="#benefits" className="font-medium hover:text-blue-500 transition-colors">Vantaggi</a>
              <a href="#testimonials" className="font-medium hover:text-blue-500 transition-colors">Testimonianze</a>
              <a href="#faq" className="font-medium hover:text-blue-500 transition-colors">FAQ</a>
            </nav>
            
            {/* Bottoni a destra */}
            <div className="flex items-center justify-end space-x-4">
              <button
                onClick={toggleDarkMode}
                className={`p-2 rounded-full transition-colors duration-200 ${
                  isDarkMode 
                    ? 'bg-gray-800 text-yellow-400 hover:bg-gray-700' 
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
                aria-label={isDarkMode ? 'Attiva tema chiaro' : 'Attiva tema scuro'}
              >
                {isDarkMode ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                  </svg>
                )}
              </button>
              <a 
                href="/login" 
                className={`px-4 py-2 rounded-md font-medium transition-all duration-200 ${
                  isDarkMode 
                    ? 'bg-indigo-600 text-white hover:bg-indigo-700' 
                    : 'bg-indigo-600 text-white hover:bg-indigo-700'
                }`}
              >
                Accedi
              </a>
            </div>
          </div>
        </div>
      </header>

      {/* Hero Section Migliorata con semantica e accessibilità */}
      <section 
        className={`transition-colors duration-200 py-16 md:py-24 ${
          isDarkMode ? 'bg-gradient-to-b from-gray-900 to-indigo-900 text-white' : 'bg-gradient-to-b from-white to-indigo-50 text-gray-800'
        }`}
        aria-labelledby="hero-heading"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div 
            className={`grid grid-cols-1 md:grid-cols-2 gap-12 items-center ${
              isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
            } transition-all duration-700`}
          >
            <div>
              <h1 
                id="hero-heading"
                className="text-4xl sm:text-5xl lg:text-6xl font-extrabold tracking-tight mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600"
              >
                Fine-tuning AI per la tua azienda
              </h1>
              <p className="text-xl mb-8 max-w-lg">
                <strong>FineTuningMax</strong> rivoluziona il modo in cui le PMI creano modelli di AI personalizzati. 
                Prepara dataset, configura modelli e integra soluzioni AI precise per il tuo business - il tutto con pochi clic.
              </p>
              <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <a 
                  href="/register"
                  className={`inline-flex items-center justify-center w-full sm:w-auto px-8 py-4 rounded-lg text-lg font-medium shadow-lg hover:scale-105 transition duration-200 ${
                    isDarkMode 
                      ? 'bg-blue-600 text-white hover:bg-blue-700 shadow-blue-500/30' 
                      : 'bg-indigo-600 text-white hover:bg-indigo-700 shadow-indigo-500/30'
                  }`}
                >
                  Inizia ora
                  <ArrowRight className="ml-2 h-5 w-5" />
                </a>
                <a 
                  href="#features" 
                  className={`inline-flex items-center justify-center w-full sm:w-auto px-8 py-4 rounded-lg text-lg font-medium hover:scale-105 transition duration-200 ${
                    isDarkMode 
                      ? 'bg-gray-800 text-white hover:bg-gray-700 border border-gray-700' 
                      : 'bg-white text-indigo-600 hover:bg-gray-50 border border-indigo-200 shadow-md'
                  }`}
                >
                  Scopri di più
                </a>
              </div>
              
              {/* Badge per migliorare la credibilità */}
              <div className="mt-10 flex flex-wrap items-center gap-6">
                <div className={`px-4 py-2 rounded-full text-sm font-medium ${isDarkMode ? 'bg-indigo-800' : 'bg-indigo-100'}`}>
                  <span className="flex items-center">
                    <Check className="h-4 w-4 mr-1 text-green-400" />
                    Setup in 10 minuti
                  </span>
                </div>
                <div className={`px-4 py-2 rounded-full text-sm font-medium ${isDarkMode ? 'bg-indigo-800' : 'bg-indigo-100'}`}>
                  <span className="flex items-center">
                    <Check className="h-4 w-4 mr-1 text-green-400" />
                    Compatibile con OpenAI, Google AI & Mistral
                  </span>
                </div>
              </div>
            </div>
            
            {/* Dashboard preview animata */}
            <div 
              className={`hidden md:block transform ${
                isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
              } transition-all duration-1000 delay-300`}
            >
              <div className="relative mb-8 md:mb-0">
                <div className={`rounded-xl p-8 ${isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'} shadow-xl`}>
                  <div className={`h-64 rounded-lg ${isDarkMode ? 'bg-gray-900' : 'bg-indigo-50'} flex flex-col items-center justify-center mb-6 overflow-hidden relative`}>
                    {/* UI mockup più dettagliato */}
                    <div className={`absolute top-0 w-full h-10 ${isDarkMode ? 'bg-gray-800' : 'bg-indigo-100'} flex items-center px-4`}>
                      <div className="flex space-x-2">
                        <div className="w-3 h-3 rounded-full bg-red-500"></div>
                        <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                        <div className="w-3 h-3 rounded-full bg-green-500"></div>
                      </div>
                      <div className="mx-auto">
                        <p className="text-sm font-medium">Dashboard FineTuningMax</p>
                      </div>
                    </div>
                    
                    <div className="flex flex-col items-center justify-center h-full">
                      <p className={`text-2xl font-bold ${isDarkMode ? 'text-indigo-400' : 'text-indigo-600'}`}>
                        Console di Fine-Tuning
                      </p>
                      <p className={`text-sm mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                        Addestra modelli AI personalizzati in pochi passaggi
                      </p>
                      
                      <div className={`mt-4 w-48 h-2 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-indigo-200'}`}>
                        <div className="h-full w-2/3 rounded-full bg-indigo-600"></div>
                      </div>
                      <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-500' : 'text-gray-500'}`}>
                        Addestramento in corso: 67%
                      </p>
                    </div>
                  </div>
                  
                  {/* Statistiche in linea, non fluttuanti */}
                  <div className="grid grid-cols-2 gap-4">
                    <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-indigo-900 border border-indigo-800' : 'bg-indigo-100 border border-indigo-200'}`}>
                      <p className={`text-sm font-semibold ${isDarkMode ? 'text-indigo-300' : 'text-gray-600'}`}>Tempo risparmiato</p>
                      <p className="text-2xl font-bold text-indigo-500">84%</p>
                    </div>
                    <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-indigo-900 border border-indigo-800' : 'bg-indigo-100 border border-indigo-200'}`}>
                      <p className={`text-sm font-semibold ${isDarkMode ? 'text-indigo-300' : 'text-gray-600'}`}>Aumento precisione</p>
                      <p className="text-2xl font-bold text-indigo-500">+67%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Partner logos section */}
      <section 
        className={`py-10 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} border-t ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}`}
        aria-labelledby="partners-heading"
      >
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center">
            <h2 
              id="partners-heading"
              className={`text-lg font-semibold mb-6 ${isDarkMode ? 'text-indigo-400' : 'text-indigo-600'}`}
            >
              UTILIZZATO E APPROVATO DA
            </h2>
            <div className="flex flex-wrap justify-center gap-6">
              {['TechItalia', 'DataSolutions', 'AInnova', 'FutureTech'].map((company) => (
                <div 
                  key={company} 
                  className={`w-48 h-16 flex items-center justify-center rounded-lg shadow-md border transition-colors duration-300 ${
                    isDarkMode 
                      ? 'bg-indigo-900 border-indigo-700 text-white hover:bg-indigo-800' 
                      : 'bg-white border-gray-200 text-gray-800 hover:bg-gray-50'
                  }`}
                >
                  <span className="font-bold text-lg">{company}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section 
        id="features" 
        className={`py-20 transition-colors duration-200 ${
          isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
        }`}
        aria-labelledby="features-heading"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 
              id="features-heading"
              className="text-3xl font-extrabold tracking-tight sm:text-4xl mb-4"
            >
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">
                Funzionalità principali
              </span>
            </h2>
            <p className="mt-4 text-xl max-w-3xl mx-auto text-gray-500 dark:text-gray-300">
              Tutto ciò di cui hai bisogno per creare e implementare modelli AI personalizzati, senza bisogno di competenze tecniche specializzate
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            {features.map((feature, index) => (
              <div 
                key={index}
                className={`p-8 rounded-2xl transition-all duration-200 h-full flex flex-col ${
                  isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-indigo-50 hover:bg-indigo-100'
                }`}
              >
                <div className={`w-14 h-14 rounded-xl flex items-center justify-center bg-gradient-to-r ${feature.color} text-white mb-6 shadow-lg`}>
                  {feature.icon}
                </div>
                <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-6 flex-grow`}>
                  {feature.description}
                </p>
                <ul className="space-y-2 mb-6">
                  {feature.benefits.map((benefit, idx) => (
                    <li key={idx} className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 mt-0.5" />
                      <span className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>{benefit}</span>
                    </li>
                  ))}
                </ul>
                <a 
                  href="#"
                  className={`text-${isDarkMode ? 'blue-400' : 'indigo-600'} font-medium hover:underline inline-flex items-center`}
                >
                  Scopri di più 
                  <ChevronRight className="ml-1 h-5 w-5" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section 
        id="benefits" 
        className={`py-20 transition-colors duration-200 ${
          isDarkMode ? 'bg-gradient-to-b from-gray-800 to-gray-900 text-white' : 'bg-gradient-to-b from-indigo-50 to-white text-gray-800'
        }`}
        aria-labelledby="benefits-heading"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 
              id="benefits-heading"
              className="text-3xl font-extrabold tracking-tight sm:text-4xl mb-4"
            >
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">
                Perché scegliere FineTuningMax?
              </span>
            </h2>
            <p className="mt-4 text-xl max-w-3xl mx-auto text-gray-500 dark:text-gray-300">
              Trasforma la tua azienda con modelli AI specifici per il tuo settore, senza bisogno di competenze tecniche avanzate
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <ul className="space-y-6">
                {benefits.map((benefit, index) => (
                  <li 
                    key={index}
                    className="flex items-start"
                  >
                    <div className={`flex-shrink-0 h-8 w-8 rounded-full flex items-center justify-center ${
                      isDarkMode ? 'bg-indigo-800' : 'bg-indigo-100'
                    } mr-4`}>
                      <CheckCircle className="h-5 w-5 text-indigo-600" />
                    </div>
                    <p className="text-lg font-medium">{benefit}</p>
                  </li>
                ))}
              </ul>
              
              <div className="mt-10">
                <a 
                  href="#"
                  className={`inline-flex items-center justify-center px-8 py-4 rounded-lg text-lg font-medium shadow-lg ${
                    isDarkMode 
                      ? 'bg-blue-600 text-white hover:bg-blue-700 shadow-blue-500/30' 
                      : 'bg-indigo-600 text-white hover:bg-indigo-700 shadow-indigo-500/30'
                  } transition-all duration-200`}
                >
                  Prova gratuitamente
                  <ArrowRight className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>
            
            {/* Grafico/Statistiche */}
            <div className="relative">
              <div className={`rounded-2xl overflow-hidden shadow-xl ${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6`}>
                <h3 className="text-xl font-semibold mb-6">Risparmio sul tempo di implementazione</h3>
                
                {/* Grafico comparativo migliorato */}
                <div className="h-80 relative mt-8 mb-8 px-4 flex items-center justify-center">
                  <div className="flex items-end justify-center gap-24 h-full relative">
                    {/* Etichette in alto con maggiore spazio */}
                    <div className="absolute top-0 left-0 w-full flex justify-center gap-28">
                      <div className="text-center">
                        <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Soluzioni tradizionali</span>
                      </div>
                      <div className="text-center">
                        <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>FineTuningMax</span>
                      </div>
                    </div>
                    
                    {/* Barre del grafico */}
                    <div className="w-24 h-1/4 bg-gray-400 rounded-t-lg flex flex-col justify-end items-center relative mt-20">
                      <div className="absolute -bottom-8 text-center w-full">Tradizionale</div>
                    </div>
                    
                    <div className="w-24 h-3/4 bg-indigo-500 rounded-t-lg flex flex-col justify-end items-center relative mt-20">
                      {/* Badge posizionato direttamente sopra la barra di FineTuningMax */}
                      <div className="absolute -top-14 transform translate-x-0 bg-green-100 text-green-800 font-bold py-2 px-6 rounded-full shadow-md whitespace-nowrap border border-green-200">
                        -78% tempo
                      </div>
                      <div className="absolute -bottom-8 text-center w-full">FineTuningMax</div>
                    </div>
                  </div>
                </div>
                
                {/* Statistiche in griglia */}
                <div className="grid grid-cols-3 gap-4 mt-12 border-t pt-6 border-gray-200 dark:border-gray-700">
                  <div className="text-center">
                    <p className="text-3xl font-bold text-indigo-600">85%</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Riduzione costi</p>
                  </div>
                  <div className="text-center">
                    <p className="text-3xl font-bold text-indigo-600">12x</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Velocità</p>
                  </div>
                  <div className="text-center">
                    <p className="text-3xl font-bold text-indigo-600">94%</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Soddisfazione</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section - Completamente ridisegnata per maggiore impatto */}
      <section 
        id="testimonials" 
        className={`py-20 transition-colors duration-200 ${
          isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'
        }`}
        aria-labelledby="testimonials-heading"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 
              id="testimonials-heading"
              className="text-3xl font-extrabold tracking-tight sm:text-4xl mb-4"
            >
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">
                Cosa dicono i nostri clienti
              </span>
            </h2>
            <p className="mt-4 text-xl max-w-3xl mx-auto text-gray-500 dark:text-gray-300">
              Scopri come FineTuningMax ha trasformato i processi AI di aziende come la tua
            </p>
          </div>

          {/* Testimoniale principale */}
          <div className={`mb-12 p-8 rounded-2xl transition-all duration-500 border relative overflow-hidden ${
            isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200 shadow-lg'
          }`}>
            {/* Quote mark decorativo */}
            <div className="absolute -top-6 -left-6 text-9xl opacity-10 text-indigo-500">"</div>
            
            {/* Indicatori */}
            <div className="flex justify-center mb-8 relative z-10">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setActiveTestimonial(index)}
                  className={`w-3 h-3 mx-1 rounded-full ${
                    activeTestimonial === index 
                      ? 'bg-indigo-600' 
                      : isDarkMode ? 'bg-gray-700' : 'bg-gray-300'
                  }`}
                  aria-label={`Visualizza testimonianza ${index + 1}`}
                />
              ))}
            </div>
            
            {/* Contenuto testimoniale */}
            <div className="max-w-3xl mx-auto text-center relative z-10">
              <div className="mb-6">
                {[...Array(testimonials[activeTestimonial].rating)].map((_, i) => (
                  <Star key={i} className="h-6 w-6 inline-block text-yellow-400 fill-yellow-400 mr-1" />
                ))}
              </div>
              
              <blockquote>
                <p className={`text-xl lg:text-2xl font-medium italic mb-8 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  "{testimonials[activeTestimonial].quote}"
                </p>
                
                <div className="flex items-center justify-center">
                  <div className="flex-shrink-0 mr-4">
                    <img 
                      src={testimonials[activeTestimonial].image} 
                      alt={testimonials[activeTestimonial].author} 
                      className="h-16 w-16 rounded-full object-cover"
                    />
                  </div>
                  <div className="text-left">
                    <p className="font-bold text-lg">{testimonials[activeTestimonial].author}</p>
                    <p className="text-sm">{testimonials[activeTestimonial].title}</p>
                    <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>{testimonials[activeTestimonial].company}</p>
                  </div>
                </div>
              </blockquote>
            </div>
          </div>
          
          {/* Statistiche di soddisfazione */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            {[
              { number: '98%', label: 'Clienti soddisfatti' },
              { number: '32min', label: 'Tempo medio di setup' },
              { number: '78%', label: 'Incremento precisione' },
              { number: '24/7', label: 'Supporto tecnico' }
            ].map((stat, index) => (
              <div 
                key={index} 
                className={`p-6 rounded-lg text-center ${
                  isDarkMode ? 'bg-gray-800' : 'bg-indigo-50'
                }`}
              >
                <p className="text-3xl font-bold text-indigo-600 mb-2">{stat.number}</p>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      
      {/* FAQ Section - Nuova sezione per aumentare la conversione */}
      <section 
        id="faq" 
        className={`py-20 transition-colors duration-200 ${
          isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-50 text-gray-800'
        }`}
        aria-labelledby="faq-heading"
      >
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 
              id="faq-heading"
              className="text-3xl font-extrabold tracking-tight sm:text-4xl mb-4"
            >
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">
                Domande frequenti
              </span>
            </h2>
            <p className="mt-4 text-xl max-w-3xl mx-auto text-gray-500 dark:text-gray-300">
              Tutto ciò che devi sapere su FineTuningMax
            </p>
          </div>
          
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <div 
                key={index} 
                className={`rounded-lg p-6 transition-all duration-200 ${
                  isDarkMode ? 'bg-gray-900' : 'bg-white shadow-md'
                }`}
              >
                <h3 className="text-xl font-semibold mb-3">{faq.question}</h3>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{faq.answer}</p>
              </div>
            ))}
          </div>
          
          <div className="text-center mt-12">
            <p className="text-lg mb-4">Hai altre domande?</p>
                          <button 
                onClick={() => window.scrollTo({top: document.getElementById('faq').offsetTop, behavior: 'smooth'})}
                className={`inline-flex items-center justify-center px-8 py-3 rounded-lg font-medium ${
                  isDarkMode 
                    ? 'bg-gray-700 text-white hover:bg-gray-600' 
                    : 'bg-white text-indigo-600 hover:bg-gray-50 border border-gray-200 shadow-sm'
                } transition-all duration-200 cursor-pointer`}
              >
                Contattaci
              </button>
          </div>
        </div>
      </section>

      {/* CTA Section - Riprogettata per maggiore impatto visivo e conversione */}
      <section 
        className={`py-24 transition-colors duration-200 relative overflow-hidden ${
          isDarkMode ? 'bg-indigo-900 text-white' : 'bg-indigo-600 text-white'
        }`}
      >
        {/* Background pattern decorativo */}
        <div className="absolute inset-0 opacity-10">
          <svg className="h-full w-full" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
                <path d="M 80 0 L 0 0 0 80" fill="none" stroke="white" strokeWidth="1"/>
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#grid)" />
          </svg>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="lg:w-3/5">
              <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
                Pronto a rivoluzionare la tua AI?
              </h2>
              <p className="text-xl max-w-2xl mb-10 text-indigo-100">
                Inizia oggi stesso a creare modelli AI personalizzati e specifici per il tuo business.
                Registrati gratuitamente e scopri tutto il potenziale di FineTuningMax.
              </p>
              
              <div className="flex flex-col sm:flex-row gap-4">
                <a 
                  href="#"
                  className="inline-flex items-center justify-center px-8 py-4 rounded-lg text-lg font-medium bg-white text-indigo-600 hover:bg-indigo-50 shadow-lg transition-all duration-200 hover:scale-105"
                >
                  Inizia gratis 
                  <ArrowRight className="ml-2 h-5 w-5" />
                </a>
                <a 
                  href="#"
                  className="inline-flex items-center justify-center px-8 py-4 rounded-lg text-lg font-medium border border-white hover:bg-indigo-800 transition-all duration-200"
                >
                  Richiedi demo
                </a>
              </div>
              
              <p className="mt-6 text-indigo-200 flex items-center">
                <Check className="h-5 w-5 mr-2" />
                Nessuna carta di credito richiesta. Piano base gratuito per sempre.
              </p>
            </div>
            
            {/* Box visivo per aumentare l'impatto */}
            <div className="mt-12 lg:mt-0 lg:w-2/5">
              <div className={`rounded-2xl p-8 ${isDarkMode ? 'bg-indigo-800' : 'bg-indigo-700'} shadow-lg border border-indigo-500`}>
                <h3 className="text-xl font-bold mb-4">Piano Free include:</h3>
                <ul className="space-y-3">
                  {[
                    '1 progetto di fine-tuning',
                    '10,000 interazioni al mese',
                    'Dataset fino a 5MB',
                    'Supporto email',
                    'Interfaccia drag-and-drop'
                  ].map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <CheckCircle className="h-6 w-6 text-indigo-300 mr-2 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                
                <div className="mt-8 text-center">
                  <span className="inline-block px-4 py-2 rounded-full text-sm font-semibold bg-indigo-500">
                    Aggiornamento a Piano Pro disponibile in qualsiasi momento
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Migliorato con accessibilità */}
      <footer 
        className={`py-12 transition-colors duration-200 ${
          isDarkMode ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-600'
        }`}
        role="contentinfo"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="mb-8 md:mb-0 col-span-1 md:col-span-2">
              <div className="flex items-center mb-4">
                <span className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">FineTuningMax</span>
                <Star className="h-5 w-5 ml-1 text-yellow-400 fill-yellow-400" />
              </div>
              <p className="max-w-xs">
                Semplifichiamo il fine-tuning dell'AI per le piccole e medie imprese, rendendo accessibile la personalizzazione dei modelli AI.
              </p>
              <div className="mt-4 flex space-x-4">
                <a href="https://facebook.com/finetuningmax" className={`${isDarkMode ? 'hover:text-white' : 'hover:text-gray-900'}`} aria-label="Facebook">
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                  </svg>
                </a>
                <a href="https://twitter.com/finetuningmax" className={`${isDarkMode ? 'hover:text-white' : 'hover:text-gray-900'}`} aria-label="Twitter">
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
                <a href="https://github.com/finetuningmax" className={`${isDarkMode ? 'hover:text-white' : 'hover:text-gray-900'}`} aria-label="GitHub">
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
                  </svg>
                </a>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider">Prodotto</h3>
              <ul className="mt-4 space-y-3">
                <li><a href="#features" className="hover:text-indigo-500">Funzionalità</a></li>
                <li><a href="/prezzi" className="hover:text-indigo-500">Prezzi</a></li>
                <li><a href="/casi-studio" className="hover:text-indigo-500">Casi studio</a></li>
                <li><a href="/roadmap" className="hover:text-indigo-500">Roadmap</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider">Azienda</h3>
              <ul className="mt-4 space-y-3">
                <li><a href="/chi-siamo" className="hover:text-indigo-500">Chi siamo</a></li>
                <li><a href="/blog" className="hover:text-indigo-500">Blog</a></li>
                <li><a href="/carriere" className="hover:text-indigo-500">Carriere</a></li>
                <li><a href="/contatti" className="hover:text-indigo-500">Contatti</a></li>
              </ul>
            </div>
          </div>
          
          <div className="mt-12 pt-8 border-t border-gray-700 flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <p>&copy; {new Date().getFullYear()} FineTuningMax. Tutti i diritti riservati.</p>
            </div>
            <div className="flex space-x-6">
              <a href="/privacy" className="hover:text-indigo-500">Privacy</a>
              <a href="/termini" className="hover:text-indigo-500">Termini</a>
              <a href="/cookie" className="hover:text-indigo-500">Cookie</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;