import { useState, useCallback } from 'react';
import axios from 'axios';

// Importa la configurazione dell'API
import { API_BASE_URL } from '../config/api.js';

/**
 * Funzione di utilità per escapare i campi CSV
 * @param {string} field - Campo da escapare
 * @returns {string} - Campo escapato
 */
const escapeCSVField = (field) => {
    if (!field) return '';
    // Sostituisce le virgole con spazi e rimuove caratteri problematici
    return field.replace(/,/g, ' ')
               .replace(/\r?\n|\r/g, ' ')
               .replace(/"/g, '""')
               .trim();
};

/**
 * Hook per gestire le operazioni di caricamento, download e gestione dei file
 * 
 * @param {function} appendMessage - Funzione per aggiungere messaggi al log
 * @returns {Object} Oggetto contenente stati e funzioni per la gestione dei file
 */
const useFileOperations = (appendMessage) => {
  // Stati per i file
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [templateFormat, setTemplateFormat] = useState('csv');
  const [jsonFilePath, setJsonFilePath] = useState(null);
  const [jsonPreview, setJsonPreview] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  
  // Stati per l'espansione del dataset
  // Importante: impostato a false per default perché è una funzionalità a pagamento
  // che l'utente deve scegliere esplicitamente di attivare
  const [useExpansion, setUseExpansion] = useState(false);
  const [numVariants, setNumVariants] = useState(10);
  
  // Stato per il monitoraggio dei task
  const [taskMonitoring, setTaskMonitoring] = useState(false);

  /**
   * Gestisce il download del template
   */
  const handleDownloadTemplate = useCallback(async (purpose) => {
    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      return;
    }
    
    try {
      // Mostra il messaggio di inizio download
      appendMessage(`Download del template ${templateFormat.toUpperCase()} in corso...`, false);
      console.log(`Inizio download template: formato=${templateFormat}, purpose=${purpose}`);
      
      const response = await axios.get(`${API_BASE_URL}/download_template/${purpose}/${templateFormat}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob'
      });
      
      console.log('Risposta download template ricevuta:', {
        size: response.data.size,
        type: response.data.type,
        status: response.status
      });
      
      // Determina l'estensione corretta in base al formato
      let fileExtension;
      switch(templateFormat) {
        case 'csv':
          fileExtension = 'csv';
          break;
        case 'word':
          fileExtension = 'docx';
          break;
        case 'pdf':
          fileExtension = 'pdf';
          break;
        default:
          fileExtension = templateFormat;
      }
      
      // Crea un URL per il blob e avvia il download
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `template_${purpose}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      
      // Rimuovi il link e revoca l'URL per liberare memoria
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      }, 100);
      
      // Mostra il messaggio di completamento
      appendMessage(`Template ${templateFormat.toUpperCase()} scaricato con successo!`, false);
    } catch (error) {
      console.error('Errore durante il download del template:', error);
      appendMessage(`Errore durante il download del template: ${error.message}`, false);
    }
  }, [templateFormat, appendMessage]);

  /**
   * Gestisce la selezione dei file
   */
  const handleFileChange = useCallback((e) => {
    if (e.target.files.length > 0) {
      setSelectedFiles(Array.from(e.target.files));
      appendMessage(`File selezionato: ${e.target.files[0].name}`, true);
    }
  }, [appendMessage]);

  /**
   * Carica e analizza il file selezionato
   */
  const handleFileUpload = useCallback(async () => {
    if (selectedFiles.length === 0) {
      appendMessage('Per favore, seleziona almeno un file da caricare.', false);
      return null;
    }
    
    setIsProcessing(true);
    setProgress(0);
    
    const formData = new FormData();
    // Aggiungi i file con la chiave 'files'
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    
    // Passa solo il parametro purpose all'endpoint upload_preview
    formData.append('purpose', 'assistenza_clienti_primo_livello');
    
    // I parametri use_expansion e num_variants verranno inviati solo a convert_to_json
    // NON li aggiungiamo qui per upload_preview
    
    // Log dettagliato per debug
    const formDataEntries = {};
    for (let [key, value] of formData.entries()) {
      if (key !== 'files') { // Escludiamo i file per non appesantire il log
        formDataEntries[key] = value;
      } else {
        formDataEntries[key] = `File: ${value.name}, size: ${value.size}`;
      }
    }
    console.log('FormData completo inviato a upload_preview:', formDataEntries);
    
    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      setIsProcessing(false);
      return null;
    }
    
    try {
      appendMessage(`Caricamento del file ${selectedFiles[0].name} in corso...`, false);
      
      // Determina l'endpoint in base al numero di file
      const endpoint = selectedFiles.length > 1 ? 
        `${API_BASE_URL}/merge/upload` : 
        `${API_BASE_URL}/upload_preview`;
      
      console.log('Inviando richiesta a:', endpoint, {
        useExpansion,
        useExpansionType: typeof useExpansion,
        useExpansionString: useExpansion ? 'true' : 'false',
        numVariants
      });
      
      const response = await axios.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      });
      
      // Completiamo il caricamento
      setProgress(100);
      
      // DEBUG: Logga la struttura completa della risposta per debug
      console.log('Risposta API completa:', JSON.stringify(response.data, null, 2));
      
      // Verifica se la risposta è valida e contiene i dati necessari
      if (response.data) {
        appendMessage('File caricato con successo. Elaborazione completata.', false);
        
        // DEBUG: Logga i campi importanti
        console.log('Chiavi nella risposta:', Object.keys(response.data));
        console.log('Risposta contiene preview?', !!response.data.preview);
        console.log('Risposta contiene results?', !!response.data.results);
        
        if (response.data.results) {
          console.log('Numero di risultati:', response.data.results.length);
          console.log('Chiavi nel primo result:', Object.keys(response.data.results[0] || {}));
          console.log('Tipo di preview nel primo result:', typeof response.data.results[0]?.preview);
        }
        
        // Se la risposta contiene un task_id, lo restituiamo per il monitoraggio
        if (response.data.task_id) {
          return response.data.task_id;
        }
        
        // Altrimenti, restituiamo direttamente tutti i dati che sono già pronti
        return response.data;
      } else {
        throw new Error('Risposta dal server non valida');
      }
    } catch (error) {
      console.error('Errore durante il caricamento del file:', error);
      appendMessage(`Errore durante il caricamento: ${error.response?.data?.detail || error.message}`, false);
      setIsProcessing(false);
      return null;
    }
  }, [selectedFiles, useExpansion, numVariants, appendMessage]);

  /**
   * Normalizza le chiavi del risultato per garantire compatibilità
   * @param {Object} result - Il risultato da normalizzare
   * @returns {Object} - Il risultato con chiavi normalizzate
   */
  const normalizeResultKeys = useCallback((result) => {
    if (!result) return result;
    
    const normalizedResult = { ...result };
    
    // Log dettagliato dell'input
    console.log('Normalizzazione risultato:', {
        inputKeys: Object.keys(result),
        hasJsonFilePath: !!result.json_file_path,
        hasJsonlPath: !!result.jsonl_path,
        hasJsonPreview: !!result.json_preview,
        hasPreview: !!result.preview,
        jsonPreviewType: typeof result.json_preview,
        previewType: typeof result.preview
    });
    
    // Normalizza il path del file
    if (normalizedResult.json_file_path && !normalizedResult.jsonl_path) {
        console.log('Trovato json_file_path, normalizzando a jsonl_path');
        normalizedResult.jsonl_path = normalizedResult.json_file_path;
        delete normalizedResult.json_file_path;
    }
    
    // Normalizza la preview
    if (normalizedResult.json_preview && !normalizedResult.preview) {
        console.log('Trovato json_preview, normalizzando a preview');
        normalizedResult.preview = normalizedResult.json_preview;
        delete normalizedResult.json_preview;
    }
    
    // Assicurati che la preview sia un array
    if (normalizedResult.preview && !Array.isArray(normalizedResult.preview)) {
        console.log('Preview non è un array, converto');
        normalizedResult.preview = [normalizedResult.preview];
    }
    
    // Aggiungi campi mancanti se necessario
    if (normalizedResult.records_processed && !normalizedResult.original_records) {
        console.log('Calcolando original_records da records_processed');
        normalizedResult.original_records = normalizedResult.records_processed / (normalizedResult.num_variants || 1);
    }
    
    // Log dettagliato dell'output
    console.log('Risultato normalizzato:', {
        outputKeys: Object.keys(normalizedResult),
        hasJsonlPath: !!normalizedResult.jsonl_path,
        hasPreview: !!normalizedResult.preview,
        previewLength: normalizedResult.preview?.length,
        previewType: typeof normalizedResult.preview,
        isPreviewArray: Array.isArray(normalizedResult.preview)
    });
    
    return normalizedResult;
  }, []);

  /**
   * Monitora lo stato di un'attività in background
   */
  const monitorTaskStatus = useCallback(async (taskId) => {
    console.log('Inizio monitoraggio task:', taskId);
    
    // Imposta lo stato di monitoraggio
    setTaskMonitoring(true);
    
    // Se invece di un task_id riceviamo già i dati completi, li restituiamo direttamente
    if (typeof taskId === 'object' && taskId !== null) {
      console.log('monitorTaskStatus: ricevuto oggetto invece di task_id, restituisco direttamente', taskId);
      setTaskMonitoring(false);
      return normalizeResultKeys(taskId);
    }
    
    // Variabile per tenere traccia dell'ultimo aggiornamento di progresso
    let lastProgressUpdate = Date.now();
    let simulatedProgress = 5; // Inizia con il 5%
    
    // Funzione per simulare l'avanzamento del progresso quando il server non invia aggiornamenti
    const simulateProgress = () => {
      const now = Date.now();
      // Se sono passati più di 3 secondi dall'ultimo aggiornamento reale
      if (now - lastProgressUpdate > 3000) {
        // Incrementa il progresso simulato, ma non superare il 95%
        if (simulatedProgress < 95) {
          // Incremento più lento man mano che ci avviciniamo al 95%
          const increment = simulatedProgress < 50 ? 10 : simulatedProgress < 80 ? 5 : 2;
          simulatedProgress = Math.min(95, simulatedProgress + increment);
          console.log(`Simulazione progresso: ${simulatedProgress}%`);
          setProgress(simulatedProgress);
          
          // Aggiorna messaggi di stato simulati in base al progresso
          if (simulatedProgress === 30) {
            appendMessage('Generazione varianti in corso...', false);
          } else if (simulatedProgress === 60) {
            appendMessage('Ottimizzazione dataset espanso...', false);
          } else if (simulatedProgress === 85) {
            appendMessage('Finalizzazione e preparazione file JSONL...', false);
          }
        }
        lastProgressUpdate = now;
      }
    };
    
    const checkStatus = async () => {
      if (!taskId) {
        console.error('Task ID non valido per il monitoraggio');
        setTaskMonitoring(false);
        return null;
      }
      
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Token mancante durante il monitoraggio');
          appendMessage('Token mancante. Effettua nuovamente il login.', false);
          setIsProcessing(false);
          setTaskMonitoring(false);
          return null;
        }
        
        const response = await axios.get(`${API_BASE_URL}/task_status/${taskId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        
        console.log(`Stato task ${taskId}:`, response.data);
        
        // Aggiorna il progresso se disponibile
        if (response.data.progress !== undefined) {
          const newProgress = Math.min(Math.max(0, response.data.progress), 100);
          console.log(`Aggiornamento progresso reale: ${newProgress}%`);
          setProgress(newProgress);
          simulatedProgress = newProgress; // Aggiorna il progresso simulato
          lastProgressUpdate = Date.now(); // Aggiorna il timestamp dell'ultimo aggiornamento
          
          // Aggiorna anche il messaggio se disponibile
          if (response.data.message) {
            appendMessage(`Progresso: ${newProgress}% - ${response.data.message}`, false);
          }
        } else {
          console.log('Nessun progresso disponibile nella risposta, utilizzo simulazione');
          // Simula l'avanzamento del progresso
          simulateProgress();
        }
        
        // Verifica lo stato del task (supporta sia 'completed' che 'SUCCESS')
        if (response.data.status === 'completed' || response.data.state === 'SUCCESS') {
          console.log('Task completato:', response.data);
          
          // Imposta il progresso al 100% quando il task è completato
          setProgress(100);
          
          // Normalizza i nomi dei campi nella risposta
          const normalizedResponse = normalizeResultKeys(response.data);
          console.log('Risposta normalizzata:', normalizedResponse);
          
          // Verifica se abbiamo un path valido (controlla entrambi i possibili nomi)
          const filePath = normalizedResponse.jsonl_path || normalizedResponse.json_file_path;
          
          if (filePath) {
            setJsonFilePath(filePath);
            console.log('Path del file impostato:', filePath);
            
            // Se disponibile, aggiorna anche l'anteprima
            if (normalizedResponse.preview && Array.isArray(normalizedResponse.preview)) {
              console.log('Anteprima JSONL ricevuta:', {
                items: normalizedResponse.preview.length,
                firstItem: normalizedResponse.preview[0]
              });
              setJsonPreview(normalizedResponse.preview);
            } else if (normalizedResponse.json_preview) {
              console.log('Usando json_preview come preview');
              setJsonPreview(Array.isArray(normalizedResponse.json_preview) ? 
                normalizedResponse.json_preview : [normalizedResponse.json_preview]);
            } else {
              console.warn('Nessuna anteprima disponibile nella risposta');
            }
            
            // Aggiungi un messaggio con le informazioni sull'espansione
            if (normalizedResponse.records_processed !== undefined) {
              const originalRecords = normalizedResponse.original_records || 
                                    (normalizedResponse.records_processed / (numVariants || 1));
              const expansion = normalizedResponse.records_processed - originalRecords;
              const expansionMsg = expansion > 0 
                ? `Dataset espanso da ${originalRecords} a ${normalizedResponse.records_processed} record (+${expansion} varianti).`
                : `Dataset convertito con ${normalizedResponse.records_processed} record senza espansione.`;
              
              appendMessage(expansionMsg, false);
            }
            
            appendMessage('Conversione completata con successo!', false);
            setIsProcessing(false);
            setTaskMonitoring(false);
            return normalizedResponse;
          }
          
          // Se arriviamo qui, non abbiamo trovato un path valido
          console.error('Path del file mancante nella risposta:', normalizedResponse);
          throw new Error('Path del file JSONL mancante nella risposta');
        } else if (response.data.status === 'failed' || response.data.state === 'FAILURE') {
          console.error('Task fallito:', response.data);
          const errorMsg = response.data.error 
            ? `Errore durante l'elaborazione: ${response.data.error}` 
            : 'Elaborazione fallita. Riprova o contatta l\'amministratore.';
          
          appendMessage(errorMsg, false);
          setIsProcessing(false);
          setTaskMonitoring(false);
          return false;
        } else {
          // Il task è ancora in esecuzione
          console.log('Task in corso...');
          
          // Aggiorna il messaggio se c'è un aggiornamento di stato
          if (response.data.message) {
            appendMessage(response.data.message, false);
          }
          
          // Controlla nuovamente dopo 2 secondi
          await new Promise(resolve => setTimeout(resolve, 2000));
          return await checkStatus(); // Ritorna il risultato della chiamata ricorsiva
        }
      } catch (error) {
        console.error('Errore durante il monitoraggio del task:', error);
        
        if (error.response) {
          console.error('Dettagli errore:', {
            status: error.response.status,
            statusText: error.response.statusText,
            data: error.response.data
          });
        }
        
        // Anche in caso di errore, continua a simulare il progresso
        simulateProgress();
        
        appendMessage(`Errore durante il monitoraggio: ${error.message}`, false);
        
        // In caso di errore di rete, riprova dopo un po'
        await new Promise(resolve => setTimeout(resolve, 5000));
        return await checkStatus(); // Riprova
      }
    };
    
    try {
      // Imposta il progresso iniziale
      setProgress(5); // Inizia con un piccolo progresso per mostrare che qualcosa sta accadendo
      appendMessage('Avvio elaborazione del dataset...', false);
      
      // Esegui il controllo dello stato e restituisci il risultato
      const result = await checkStatus();
      console.log('Risultato finale monitoraggio task:', result);
      return result;
    } catch (error) {
      console.error('Errore durante il monitoraggio del task:', error);
      setTaskMonitoring(false);
      setIsProcessing(false);
      return false;
    }
  }, [appendMessage, API_BASE_URL, setIsProcessing, setProgress, setJsonFilePath, setJsonPreview, useExpansion, normalizeResultKeys, setTaskMonitoring, numVariants]);

  /**
   * Genera il file JSONL
   */
  const handleGenerateJson = useCallback(async (fromProceedAnyway = false, previewData = null) => {
    console.log('handleGenerateJson chiamato', { 
        fromProceedAnyway, 
        hasPreviewData: !!previewData,
        previewDataLength: previewData?.length,
        isProcessing,
        useExpansion,
        useExpansionType: typeof useExpansion,
        numVariants
    });
    
    // Se c'è già un'elaborazione in corso, non procedere
    if (isProcessing) {
        console.log('Operazione in corso, non posso procedere con handleGenerateJson');
        return null;
    }
    
    setIsProcessing(true);
    setProgress(0);
    
    try {
        // Verifica che ci siano dati da convertire
        if (!previewData || previewData.length === 0) {
            throw new Error('Nessun dato disponibile per la generazione JSONL');
        }

        // Ottieni il token
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('Token mancante. Effettua nuovamente il login.');
        }
        
        // Genera la preview JSONL dai dati esistenti
        const jsonlPreview = previewData.map(record => ({
            messages: [
                { role: "user", content: record.domanda },
                { role: "assistant", content: record.risposta }
            ]
        })).map(item => JSON.stringify(item));
        
        // Imposta la preview generata localmente
        setJsonPreview(jsonlPreview);
        
        // Invia i dati al server per la generazione del file JSONL
        const formData = new FormData();
        const csvContent = [
            'domanda,risposta',
            ...previewData.map(row => 
                `${escapeCSVField(row.domanda || '')},${escapeCSVField(row.risposta || '')}`
            )
        ].join('\n');
        
        const csvFile = new File([csvContent], 'dataset.csv', { type: 'text/csv' });
        formData.append('files', csvFile);
        formData.append('purpose', 'assistenza_clienti_primo_livello');
        
        // IMPORTANTE: Invia use_expansion come stringa "true" o "false" per compatibilità con il backend
        formData.append('use_expansion', useExpansion ? 'true' : 'false');
        formData.append('num_variants', numVariants.toString());
        
        console.log('Parametri inviati a convert_to_json:', {
            use_expansion: useExpansion ? 'true' : 'false',
            num_variants: numVariants.toString(),
            useExpansionType: typeof useExpansion
        });
        
        if (fromProceedAnyway) {
            formData.append('force', 'true');
        }
        
        // Aggiorna il messaggio per informare l'utente
        if (useExpansion) {
            appendMessage(`Avvio espansione dataset con ${numVariants} varianti per ogni domanda/risposta...`, false);
            // Imposta un progresso iniziale per mostrare che qualcosa sta accadendo
            setProgress(5);
        } else {
            appendMessage('Avvio conversione dataset in formato JSONL...', false);
        }
        
        const response = await axios.post(
            `${API_BASE_URL}/convert_to_json`,
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        
        console.log('Risposta da convert_to_json:', response.data);
        
        if (response.data.task_id) {
            appendMessage(`Task di ${useExpansion ? 'espansione' : 'conversione'} avviato. Monitoraggio in corso...`, false);
            return response.data.task_id;
        } else if (response.data.jsonl_path) {
            setIsProcessing(false); // Imposta isProcessing a false solo se abbiamo già il risultato
            return {
                jsonl_path: response.data.jsonl_path,
                original_records: response.data.original_records,
                records_processed: response.data.records_processed
            };
        } else {
            throw new Error('Risposta dal server non valida');
        }
    } catch (error) {
        console.error('Errore durante la generazione del JSONL:', error);
        appendMessage(`Errore durante la generazione: ${error.response?.data?.detail || error.message}`, false);
        setIsProcessing(false); // Assicurati di reimpostare isProcessing in caso di errore
        return null;
    }
}, [appendMessage, API_BASE_URL, useExpansion, numVariants, isProcessing, setIsProcessing, setProgress, setJsonPreview]);

  /**
   * Procede con la generazione JSONL anche in presenza di errori
   */
  const handleProceedAnyway = useCallback(async (previewData = null) => {
    return handleGenerateJson(true, previewData);
  }, [handleGenerateJson]);

  /**
   * Resetta lo stato dell'upload
   */
  const resetUploadState = useCallback(() => {
    setSelectedFiles([]);
    setJsonFilePath(null);
    setJsonPreview(null);
    setIsProcessing(false);
    setProgress(0);
  }, []);

  return {
    // Stati
    selectedFiles,
    templateFormat,
    jsonFilePath,
    jsonPreview,
    isProcessing,
    progress,
    useExpansion,
    numVariants,
    taskMonitoring,
    
    // Setters
    setSelectedFiles,
    setTemplateFormat,
    setJsonFilePath,
    setJsonPreview,
    setIsProcessing,
    setProgress,
    setUseExpansion,
    setNumVariants,
    
    // Handlers
    handleDownloadTemplate,
    handleFileChange,
    handleFileUpload,
    monitorTaskStatus,
    handleGenerateJson,
    handleProceedAnyway,
    resetUploadState
  };
};

export default useFileOperations; 