import { useState, useCallback, useMemo } from 'react';

// Importa la configurazione dell'API se necessario
import { API_BASE_URL } from '../config/api.js';

/**
 * Hook personalizzato per gestire i dati della tabella, includendo paginazione,
 * filtraggio, modifica e validazione
 * 
 * @param {function} validateCell - Funzione per validare il contenuto di una cella
 * @returns {Object} Oggetto contenente stati e funzioni per la gestione della tabella
 */
const useTableData = (validateCell) => {
  // Stati per i dati
  const [previewData, setPreviewData] = useState([]);
  const [editedData, setEditedData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  
  // Stati per la paginazione
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  // Stati per il menu azioni
  const [showActions, setShowActions] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [menuPosition, setMenuPosition] = useState('bottom');
  
  // Stati per il filtraggio
  const [showErrorsOnly, setShowErrorsOnly] = useState(false);
  
  // Statistiche sugli errori
  const [stats, setStats] = useState({
    total: 0,
    valid: 0,
    invalid: 0
  });
  
  // Calcola i dati paginati
  const paginatedData = useMemo(() => {
    // Filtra i dati se necessario
    let dataToUse = isEditMode ? editedData : previewData;
    if (!dataToUse) return [];
    
    if (showErrorsOnly) {
      dataToUse = dataToUse.filter(item => item.hasError);
    }
    
    // Calcola il range per la pagina corrente
    const startIndex = (currentPage - 1) * rowsPerPage;
    return dataToUse.slice(startIndex, startIndex + rowsPerPage);
  }, [currentPage, rowsPerPage, previewData, editedData, isEditMode, showErrorsOnly]);
  
  // Calcola il numero totale di pagine
  const totalPages = useMemo(() => {
    const dataToUse = isEditMode ? editedData : previewData;
    if (!dataToUse || dataToUse.length === 0) return 1;
    
    const filteredDataLength = showErrorsOnly 
      ? dataToUse.filter(item => item.hasError).length 
      : dataToUse.length;
      
    return Math.ceil(filteredDataLength / rowsPerPage);
  }, [rowsPerPage, previewData, editedData, isEditMode, showErrorsOnly]);
  
  // Funzione per cambiare pagina
  const handlePageChange = useCallback((newPage) => {
    if (newPage < 1) newPage = 1;
    if (newPage > totalPages) newPage = totalPages;
    setCurrentPage(newPage);
  }, [totalPages]);
  
  // Funzione per cambiare il numero di righe per pagina
  const handleRowsPerPageChange = useCallback((newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Resetta alla prima pagina quando cambia il numero di righe
  }, []);
  
  // Funzione per chiudere il menu
  const handleCloseMenu = useCallback(() => {
    setShowActions(null);
  }, []);
  
  // Funzione per mostrare il menu
  const handleShowActions = useCallback((index, totalRows) => {
    // Cambia la posizione del menu in base alla posizione nella tabella
    // Per evitare che il menu vada fuori dallo schermo
    let position = 'bottom';
    if (index >= totalRows - 2) {
      position = 'top';
    }
    setMenuPosition(position);
    setShowActions(index);
  }, []);
  
  // Funzione per modificare una cella
  const handleCellEdit = useCallback((index, field, value) => {
    if (!activeRow) return;
    
    // Crea una copia dell'elemento attivo aggiornato
    const updatedRow = {
      ...activeRow,
      [field]: value
    };
    
    // Aggiorna lo stato dell'elemento attivo
    setActiveRow(updatedRow);
    
    // Aggiorna l'array dei dati modificati
    setEditedData(prevData => {
      const newData = [...prevData];
      
      // Trova l'indice reale nella lista completa
      const pageStartIndex = (currentPage - 1) * rowsPerPage;
      const realIndex = pageStartIndex + index;
      
      // Sostituisci il record
      newData[realIndex] = {
        ...newData[realIndex],
        [field]: value
      };
      
      return newData;
    });
  }, [activeRow, currentPage, rowsPerPage]);
  
  /**
   * Aggiorna le statistiche del dataset
   */
  const updateStats = useCallback(() => {
    const data = isEditMode && editedData ? editedData : previewData;
  
    if (!data || !Array.isArray(data)) {
      console.warn('Impossibile aggiornare le statistiche: dati non disponibili');
      return;
    }

    console.log('Inizio aggiornamento statistiche:', {
      isEditMode,
      usandoEditedData: isEditMode && !!editedData,
      dataLength: data.length
    });
    
    // Esegui un controllo completo per ogni record per assicurarsi che lo stato sia aggiornato
    const updatedData = data.map(item => {
      // Se il record non ha i campi di validazione, saltalo
      if (!item) return item;
      
      // Verifica la validità di domanda e risposta
      const domanda = item.domanda || '';
      const risposta = item.risposta || '';
      
      // Rivalidazione completa
      const domandaValidation = (() => {
        if (!domanda || !domanda.trim()) {
          return { isValid: false, message: 'Il campo domanda non può essere vuoto' };
        }
        if (domanda.length > 150) {
          return { isValid: false, message: `La domanda supera il limite di 150 caratteri (${domanda.length})` };
        }
        if (domanda.length < 5) {
          return { isValid: false, message: `La domanda è troppo breve. Minimo 5 caratteri (${domanda.length})` };
        }
        return { isValid: true, message: `Domanda valida (${domanda.length} caratteri)` };
      })();
      
      const rispostaValidation = (() => {
        if (!risposta || !risposta.trim()) {
          return { isValid: false, message: 'Il campo risposta non può essere vuoto' };
        }
        if (risposta.length > 500) {
          return { isValid: false, message: `La risposta supera il limite di 500 caratteri (${risposta.length})` };
        }
        if (risposta.length < 10) {
          return { isValid: false, message: `La risposta è troppo breve. Minimo 10 caratteri (${risposta.length})` };
        }
        return { isValid: true, message: `Risposta valida (${risposta.length} caratteri)` };
      })();
      
      // Aggiorna hasError e status solo se necessario
      const hasError = !domandaValidation.isValid || !rispostaValidation.isValid;
      const status = hasError ? 'invalid' : 'valid';
      
      // Se lo stato è cambiato, registra il cambiamento
      if (item.hasError !== hasError || item.status !== status) {
        console.log('Aggiornamento stato record:', {
          domanda: domanda.substring(0, 20) + '...',
          risposta: risposta.substring(0, 20) + '...',
          prima: { hasError: item.hasError, status: item.status },
          dopo: { hasError, status },
          domandaValida: domandaValidation.isValid,
          rispostaValida: rispostaValidation.isValid
        });
        
        return {
          ...item,
          domandaValidation,
          rispostaValidation,
          hasError,
          status
        };
      }
      
      return item;
    });
    
    // Aggiorna i dati se necessario
    let dataUpdated = false;
    if (JSON.stringify(updatedData) !== JSON.stringify(data)) {
      if (isEditMode) {
        console.log('Aggiornamento editedData con stati corretti');
        setEditedData(updatedData);
      } else {
        console.log('Aggiornamento previewData con stati corretti');
        setPreviewData(updatedData);
      }
      dataUpdated = true;
    }
    
    // Conta gli errori nei dati aggiornati
    const total = updatedData.length;
    const invalid = updatedData.filter(item => item.hasError === true || item.status === 'invalid').length;
    const valid = total - invalid;
    
    console.log('Conteggio errori:', {
      total,
      invalid,
      valid,
      dataUpdated
    });
    
    // Aggiorna le statistiche solo se sono cambiate
    if (stats.total !== total || stats.invalid !== invalid || stats.valid !== valid) {
      console.log('Aggiornamento statistiche:', {
        prima: stats,
        dopo: { total, valid, invalid }
      });
      
      // Aggiorna le statistiche
      setStats({
        total,
        valid,
        invalid
      });
      
      // Emetti un evento personalizzato per notificare i cambiamenti
      try {
        const event = new CustomEvent('statsUpdated', { 
          detail: { total, valid, invalid } 
         });
         document.dispatchEvent(event);
       } catch (error) {
        console.error('Errore durante l\'emissione dell\'evento statsUpdated:', error);
      }
      
      // Emetti un evento quando tutte le righe diventano valide
      if (stats.invalid > 0 && invalid === 0) {
        try {
          console.log('Emetto evento datasetValidated - tutte le righe sono ora valide');
          const event = new CustomEvent('datasetValidated', { 
            detail: { 
              previousInvalid: stats.invalid,
              total
            } 
          });
          document.dispatchEvent(event);
        } catch (error) {
          console.error('Errore durante l\'invio dell\'evento datasetValidated:', error);
        }
      }
    } else {
      console.log('Statistiche invariate, nessun aggiornamento necessario');
    }
  }, [isEditMode, editedData, previewData, setEditedData, setPreviewData, stats]);
  
  // Funzione per salvare le modifiche a una cella
  const handleSaveEdit = useCallback((index, field, value) => {
    // Valida il valore
    const validation = validateCell(field, value);
    
    // Log per debug
    console.log('Salvando modifica:', {
      index,
      field,
      value,
      validation,
      currentPage,
      rowsPerPage,
      hasActiveRow: !!activeRow,
      hasEditedData: !!editedData,
      editedDataLength: editedData?.length
    });
    
    // Verifica che editedData esista
    if (!editedData || !Array.isArray(editedData)) {
      console.error('editedData non disponibile durante il salvataggio:', editedData);
      return;
    }
    
    // Trova l'indice reale nella lista completa
    const pageStartIndex = (currentPage - 1) * rowsPerPage;
    const realIndex = pageStartIndex + index;
    
    // Assicurati che l'indice sia valido
    if (realIndex < 0 || realIndex >= editedData.length) {
      console.error('Indice non valido durante il salvataggio:', {
        realIndex,
        arrayLength: editedData.length
      });
      return;
    }
    
    // Aggiorna i dati con la modifica
    setEditedData(prevData => {
      // Se prevData non è un array valido, crea una copia di previewData
      if (!prevData || !Array.isArray(prevData)) {
        console.warn('prevData non valido in handleSaveEdit, creazione copia da previewData');
        prevData = JSON.parse(JSON.stringify(previewData));
      }
      
      const newData = [...prevData];
      
      // Imposta il nuovo valore e aggiorna lo stato dell'errore
      const existingDomanda = field === 'domanda' ? value : (newData[realIndex]?.domanda || '');
      const existingRisposta = field === 'risposta' ? value : (newData[realIndex]?.risposta || '');
      
      // Verifica la validità di entrambi i campi
      const domandaValidation = validateCell('domanda', existingDomanda);
      const rispostaValidation = validateCell('risposta', existingRisposta);
      const hasError = !domandaValidation.isValid || !rispostaValidation.isValid;
      
      newData[realIndex] = {
        ...newData[realIndex],
        [field]: value,
        domandaValidation,
        rispostaValidation,
        hasError: hasError,
        status: hasError ? 'invalid' : 'valid',
        // Imposta il flag di editing su false solo per il campo corrente
        [`is${field.charAt(0).toUpperCase() + field.slice(1)}Editing`]: false
      };
      
      console.log('Dati aggiornati dopo salvataggio:', {
        realIndex,
        updatedValue: value,
        hasError,
        domandaValid: domandaValidation.isValid,
        rispostaValid: rispostaValidation.isValid,
        newData: newData[realIndex]
      });
      
      return newData;
    });
    
    // Resetta lo stato di editing per il campo specifico nell'activeRow
    if (activeRow) {
      setActiveRow(prevActiveRow => {
        if (!prevActiveRow) return null;
        
        // Aggiorna il valore nel campo attivo e disattiva l'editing
        return {
          ...prevActiveRow,
          [field]: value,
          [`is${field.charAt(0).toUpperCase() + field.slice(1)}Editing`]: false
        };
      });
    }
    
    // Aggiorna anche i dati originali per mantenere la coerenza
    setPreviewData(prevData => {
      const newData = [...prevData];
      
      if (realIndex >= 0 && realIndex < newData.length) {
        // Verifica la validità di entrambi i campi
        const existingDomanda = field === 'domanda' ? value : (newData[realIndex]?.domanda || '');
        const existingRisposta = field === 'risposta' ? value : (newData[realIndex]?.risposta || '');
        const isDomandaValid = validateCell('domanda', existingDomanda);
        const isRispostaValid = validateCell('risposta', existingRisposta);
        const hasError = !isDomandaValid || !isRispostaValid;
        
        newData[realIndex] = {
          ...newData[realIndex],
          [field]: value,
          hasError: hasError
        };
      }
      
      return newData;
    });
    
    // Timeout per garantire che editedData sia aggiornato
    setTimeout(() => {
      try {
        if (typeof updateStats === 'function') {
          updateStats();
        }
      } catch (error) {
        console.error('Errore durante updateStats:', error);
      }
    }, 50);
  }, [activeRow, currentPage, rowsPerPage, validateCell, updateStats, editedData, previewData]);
  
  // Funzione per annullare la modifica
  const handleCancelEdit = useCallback((index) => {
    console.log('Annullando modifica per riga:', index, {
      hasActiveRow: !!activeRow,
      currentPage,
      rowsPerPage
    });
    
    if (!editedData || !Array.isArray(editedData)) {
      console.error('editedData non disponibile durante l\'annullamento:', editedData);
      return;
    }
    
    // Trova l'indice reale nella lista completa
    const pageStartIndex = (currentPage - 1) * rowsPerPage;
    const realIndex = pageStartIndex + index;
    
    // Assicurati che l'indice sia valido
    if (realIndex < 0 || realIndex >= editedData.length) {
      console.error('Indice non valido durante l\'annullamento:', {
        realIndex,
        editedDataLength: editedData.length
      });
      return;
    }
    
    // Resetta lo stato di editing e ripristina i dati originali
    if (activeRow) {
      // Disattiva l'editing per entrambi i campi
      setActiveRow(prevActiveRow => {
        if (!prevActiveRow) return null;
        
        return {
          ...prevActiveRow,
          isDomandaEditing: false,
          isRispostaEditing: false
        };
      });
    }
    
    // Aggiorna l'array di dati modificati per disattivare l'editing su questa riga
    // e ripristinare i dati originali
    setEditedData(prevData => {
      // Se prevData non è un array valido, esci
      if (!prevData || !Array.isArray(prevData)) {
        console.warn('prevData non valido in handleCancelEdit');
        return editedData;
      }
      
      const newData = [...prevData];
      
      // Ripristina i dati originali dalla previewData
      if (previewData && previewData.length > realIndex) {
        // Crea una copia profonda per evitare riferimenti condivisi
        const originalData = JSON.parse(JSON.stringify(previewData[realIndex]));
        
        // Combina i dati originali con i flag di editing resettati
        newData[realIndex] = {
          ...originalData,
          isDomandaEditing: false,
          isRispostaEditing: false
        };
        
        console.log('Dati ripristinati dopo annullamento:', {
          realIndex,
          originalData: originalData,
          ripristinato: newData[realIndex]
        });
      } else {
        // Se per qualche motivo non abbiamo dati originali, disattiva solo l'editing
        console.warn('Impossibile ripristinare dati originali: previewData non disponibile');
        newData[realIndex] = {
          ...newData[realIndex],
          isDomandaEditing: false,
          isRispostaEditing: false
        };
      }
      
      return newData;
    });
    
    // Aggiorna le statistiche dopo un breve delay per assicurarsi che editedData sia aggiornato
    setTimeout(() => {
      updateStats();
    }, 50);
  }, [activeRow, currentPage, rowsPerPage, editedData, previewData, updateStats]);
  
  // Funzione per eliminare un record
  const handleDeleteRecord = useCallback((index) => {
    // Calcola l'indice reale nella lista completa
    const pageStartIndex = (currentPage - 1) * rowsPerPage;
    const realIndex = pageStartIndex + index;

    // Elimina il record da editedData se siamo in modalità modifica
    if (isEditMode && editedData) {
      setEditedData(prevData => {
        if (!prevData || !Array.isArray(prevData)) return prevData;
        const newData = [...prevData];
        newData.splice(realIndex, 1);
        return newData;
      });
    }

    // Elimina sempre il record da previewData
    setPreviewData(prevData => {
      if (!prevData || !Array.isArray(prevData)) return prevData;
      const newData = [...prevData];
      newData.splice(realIndex, 1);
      return newData;
    });

    // Aggiorna le statistiche
    setTimeout(() => {
      updateStats();
    }, 50);

    // Chiudi il menu
    setShowActions(null);

    // Se dopo l'eliminazione la pagina corrente è vuota, torna alla pagina precedente
    const remainingItems = (isEditMode && editedData ? editedData.length : previewData.length) - 1;
    const newTotalPages = Math.ceil(remainingItems / rowsPerPage);
    if (currentPage > newTotalPages) {
      setCurrentPage(Math.max(1, newTotalPages));
    }
  }, [currentPage, rowsPerPage, updateStats, isEditMode, editedData, previewData]);
  
  // Funzione per mostrare solo gli errori
  const handleShowErrorsOnly = useCallback(() => {
    setShowErrorsOnly(true);
    setCurrentPage(1); // Torna alla prima pagina
  }, []);
  
  // Funzione per mostrare tutti i record
  const handleShowAll = useCallback(() => {
    setShowErrorsOnly(false);
    setCurrentPage(1); // Torna alla prima pagina
  }, []);
  
  // Funzione per modificare un record
  const handleEditRecord = useCallback((index) => {
    console.log('Attivazione modalità editing per riga:', index);
    
    // Se è la prima volta che entriamo in modalità modifica, copia i dati originali
    if (!isEditMode) {
      console.log('Prima attivazione della modalità modifica, copiando i dati originali');
      // Usa una deep copy per evitare riferimenti condivisi
      try {
        const deepCopy = JSON.parse(JSON.stringify(previewData));
        setEditedData(deepCopy);
      } catch (error) {
        console.error('Errore durante la deep copy dei dati:', error);
        setEditedData([...previewData]); // Fallback a shallow copy
      }
      setIsEditMode(true);
    }
    
    // Assicurati che l'indice sia valido
    if (index < 0 || !paginatedData || index >= paginatedData.length) {
      console.error('Indice non valido per l\'editing:', {
        index,
        hasPaginatedData: !!paginatedData,
        paginatedDataLength: paginatedData?.length
      });
      return;
    }
    
    // Prendi il record attuale
    const currentRow = paginatedData[index];
    console.log('Record corrente da modificare:', currentRow);
    
    // Trova l'indice reale nella lista completa
    const pageStartIndex = (currentPage - 1) * rowsPerPage;
    const realIndex = pageStartIndex + index;
    
    // Verifica che l'indice reale sia valido
    if (!editedData || !Array.isArray(editedData)) {
      console.error('editedData non disponibile per l\'editing:', editedData);
      return;
    }
    
    if (realIndex < 0 || realIndex >= editedData.length) {
      console.error('Indice reale non valido per l\'editing:', {
        realIndex,
        editedDataLength: editedData.length
      });
      return;
    }
    
    // Aggiorna l'array di dati modificati per marcare questa riga come in editing
    setEditedData(prevData => {
      const newData = [...prevData];
      
      // Assicurati che tutte le righe abbiano i flag di editing impostati a false
      newData.forEach(row => {
        if (row) {
          row.isDomandaEditing = false;
          row.isRispostaEditing = false;
        }
      });
      
      // Crea una copia sicura del record corrente
      const safeCopy = {...newData[realIndex]};
      
      // Imposta i flag di editing per questa riga specifica
      newData[realIndex] = {
        ...safeCopy,
        isDomandaEditing: true,
        isRispostaEditing: true
      };
      
      console.log('Record preparato per editing:', {
        realIndex,
        originalData: safeCopy,
        editingData: newData[realIndex]
      });
      
      return newData;
    });
    
    // Imposta la riga attiva con i flag di editing
    setActiveRow({
      ...currentRow,
      isDomandaEditing: true,
      isRispostaEditing: true
    });
    
    // Chiudi il menu azioni
    setShowActions(null);
  }, [isEditMode, previewData, paginatedData, currentPage, rowsPerPage, editedData]);
  
  // Inizializza i dati della tabella
  const initializeData = useCallback((data) => {
    if (!data || !Array.isArray(data)) {
      console.error('Impossibile inizializzare i dati: dati non validi');
      return;
    }
    
    // Aggiungi controlli di validazione dettagliati
    const validatedData = data.map(item => {
      // Valida domanda
      const domandaValidation = (() => {
        if (!item.domanda || !item.domanda.trim()) {
          return { isValid: false, message: 'Il campo domanda non può essere vuoto' };
        }
        if (item.domanda.length > 150) {
          return { isValid: false, message: `La domanda supera il limite di 150 caratteri (${item.domanda.length})` };
        }
        if (item.domanda.length < 5) {
          return { isValid: false, message: `La domanda è troppo breve. Minimo 5 caratteri (${item.domanda.length})` };
        }
        return { isValid: true, message: `Domanda valida (${item.domanda.length} caratteri)` };
      })();
      
      // Valida risposta
      const rispostaValidation = (() => {
        if (!item.risposta || !item.risposta.trim()) {
          return { isValid: false, message: 'Il campo risposta non può essere vuoto' };
        }
        if (item.risposta.length > 500) {
          return { isValid: false, message: `La risposta supera il limite di 500 caratteri (${item.risposta.length})` };
        }
        if (item.risposta.length < 10) {
          return { isValid: false, message: `La risposta è troppo breve. Minimo 10 caratteri (${item.risposta.length})` };
        }
        return { isValid: true, message: `Risposta valida (${item.risposta.length} caratteri)` };
      })();
      
      return {
        ...item,
        domandaValidation,
        rispostaValidation,
        hasError: !domandaValidation.isValid || !rispostaValidation.isValid,
        status: !domandaValidation.isValid || !rispostaValidation.isValid ? 'invalid' : 'valid'
      };
    });
    
    setPreviewData(validatedData);
    setEditedData(null);
    setIsEditMode(false);
    setCurrentPage(1);
    setShowErrorsOnly(false);
    setActiveRow(null);
    setShowActions(null);
    
    // Calcola le statistiche iniziali
    const total = validatedData.length;
    const invalid = validatedData.filter(item => item.hasError || item.status === 'invalid').length;
    
    setStats({
      total,
      valid: total - invalid,
      invalid
    });
    
    console.log('Dati tabella inizializzati con successo:', {
      total,
      invalid,
      valid: total - invalid,
      dettagliPrimoRecord: validatedData.length > 0 ? {
        domanda: validatedData[0].domanda,
        risposta: validatedData[0].risposta,
        domandaValida: validatedData[0].domandaValidation.isValid,
        rispostaValida: validatedData[0].rispostaValidation.isValid,
        messaggioDomanda: validatedData[0].domandaValidation.message,
        messaggioRisposta: validatedData[0].rispostaValidation.message
      } : null
    });
  }, []);
  
  return {
    // Stati
    previewData,
    editedData,
    isEditMode,
    currentPage,
    rowsPerPage,
    showActions,
    activeRow,
    menuPosition,
    showErrorsOnly,
    stats,
    paginatedData,
    totalPages,
    
    // Setters
    setPreviewData,
    setEditedData,
    setIsEditMode,
    
    // Funzioni
    handlePageChange,
    handleRowsPerPageChange,
    handleCloseMenu,
    handleShowActions,
    handleEditRecord,
    handleCellEdit,
    handleSaveEdit,
    handleCancelEdit,
    handleDeleteRecord,
    handleShowErrorsOnly,
    handleShowAll,
    updateStats,
    initializeData
  };
};

export default useTableData; 