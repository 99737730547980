import React, { useState, useEffect, useRef } from 'react';
import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

/**
 * Componente cella della tabella con supporto per modalità modifica
 * @param {string} content - Contenuto della cella
 * @param {boolean} isEditing - Flag per indicare se la tabella è in modalità modifica
 * @param {function} onChange - Funzione chiamata quando il contenuto viene modificato
 * @param {boolean} isDarkMode - Flag per indicare se la UI è in modalità scura
 * @param {string} type - Tipo di cella ('domanda' o 'risposta')
 * @param {number} index - Indice della riga
 * @param {function} onSave - Funzione per salvare le modifiche
 * @param {function} onCancel - Funzione per annullare le modifiche
 * @param {object} row - Oggetto riga per determinare se specifica cella è in modifica
 */
const TableCell = ({ content, isEditing, onChange, isDarkMode, type, index, onSave, onCancel, row }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [localContent, setLocalContent] = useState(content);
  const contentRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  // Determina se questa specifica cella è in modifica
  const isCellEditing = isEditing && 
    ((type === 'domanda' && row?.isDomandaEditing) || 
     (type === 'risposta' && row?.isRispostaEditing));
  
  // Log per debug
  useEffect(() => {
    console.log(`[TableCell] Stato editing per cella ${type} alla riga ${index}:`, {
      isEditing,
      isCellEditing,
      rowDomandaEditing: row?.isDomandaEditing,
      rowRispostaEditing: row?.isRispostaEditing
    });
  }, [isEditing, isCellEditing, row, type, index]);

  useEffect(() => {
    setLocalContent(content);
  }, [content]);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > 128); // 8rem = 128px
    }
  }, [content]);

  // Calcola il limite di caratteri in base al tipo di campo
  const charLimit = type === 'domanda' ? 150 : 500;
  
  // Calcola se il contenuto supera il limite
  const isOverLimit = localContent.length > charLimit;
  
  // Calcola la percentuale di utilizzo
  const usagePercentage = Math.min((localContent.length / charLimit) * 100, 100);
  
  // Determina il colore della barra di progresso
  const progressColor = isOverLimit 
    ? 'bg-red-500' 
    : usagePercentage > 80 
      ? 'bg-yellow-500' 
      : 'bg-green-500';
  
  // Verifica se il campo è vuoto
  const isEmpty = !localContent || localContent.trim() === '';
  
  // Verifica se il campo è troppo corto
  const isTooShort = type === 'domanda' 
    ? localContent.length < 5 
    : localContent.length < 10;
  
  // Determina se c'è un errore
  const hasError = isOverLimit || isEmpty || isTooShort;

  // Ottieni il messaggio di validazione
  const getValidationMessage = () => {
    if (type === 'domanda') {
      return row?.domandaValidation?.message || (
        isEmpty ? 'Il campo domanda non può essere vuoto' :
        isOverLimit ? `La domanda supera il limite di 150 caratteri (${localContent.length})` :
        isTooShort ? `La domanda è troppo breve. Minimo 5 caratteri (${localContent.length})` :
        `Domanda valida (${localContent.length} caratteri)`
      );
    } else {
      return row?.rispostaValidation?.message || (
        isEmpty ? 'Il campo risposta non può essere vuoto' :
        isOverLimit ? `La risposta supera il limite di 500 caratteri (${localContent.length})` :
        isTooShort ? `La risposta è troppo breve. Minimo 10 caratteri (${localContent.length})` :
        `Risposta valida (${localContent.length} caratteri)`
      );
    }
  };

  const validationMessage = getValidationMessage();
  
  // Determina la classe di sfondo in base allo stato della cella
  const getBgClass = () => {
    // Se siamo in modalità editing, usa sempre il colore di sfondo normale
    if (isEditing) {
      return isDarkMode ? 'bg-gray-800' : 'bg-white';
    }
    
    // Ottieni i dettagli di validazione e stato
    const isValid = (type === 'domanda' && row.domandaValidation?.isValid !== false) || 
                    (type === 'risposta' && row.rispostaValidation?.isValid !== false);
    
    // Log per debug sui cambiamenti di stato
    if (!isValid) {
      console.log(`Cella non valida (${type}):`, {
        type,
        validationResult: type === 'domanda' ? row.domandaValidation : row.rispostaValidation,
        hasError: row.hasError,
        status: row.status
      });
    }
    
    // Usa il colore appropriato in base alla validità
    if (!isValid) {
      return isDarkMode ? 'bg-red-900/30' : 'bg-red-100';
    } else {
      return isDarkMode ? 'bg-green-900/30' : 'bg-green-100';
    }
  };

  // Ottieni la classe corretta in base allo stato e alla validità
  const getCellClassName = () => {
    let baseClass = `group relative overflow-hidden p-3 border-b transition-colors duration-200 ${getBgClass()} ${
      isDarkMode ? 'border-gray-700' : 'border-gray-200'
    }`;
    
    // Aggiungi classe di validazione solo se non è in modifica
    if (!isCellEditing) {
      baseClass += ' ' + (isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50');
    }
    
    return baseClass;
  };

  // Funzione per cambiare il contenuto
  const handleContentChange = (event) => {
    setLocalContent(event.target.value);
    if (onChange) {
      onChange(index, type, event.target.value);
    }
  };

  return (
    <div className={getCellClassName()}>
      {/* Contenuto della cella */}
      {isCellEditing ? (
        <div className="flex flex-col space-y-2">
          <textarea
            className={`w-full p-2 rounded-md focus:outline-none focus:ring-2 focus:border-transparent ${
              isDarkMode 
                ? 'bg-gray-600 text-white focus:ring-blue-500' 
                : 'bg-white text-gray-900 focus:ring-blue-500'
            } ${hasError ? isDarkMode ? 'border-red-500' : 'border-red-500' : ''}`}
            value={localContent}
            onChange={handleContentChange}
            rows={6}
          />
          
          {/* Contatore caratteri */}
          <div className="flex flex-col space-y-1">
            <div className="flex justify-between items-center text-xs">
              <span className={`font-medium ${
                hasError
                  ? 'text-red-500'
                  : isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>
                {localContent.length}/{charLimit} caratteri
              </span>
              {hasError && (
                <span className="text-red-500 flex items-center">
                  <ExclamationCircleIcon className="h-4 w-4 mr-1" />
                  {isEmpty ? 'Campo vuoto' : isOverLimit ? 'Troppo lungo' : 'Troppo breve'}
                </span>
              )}
            </div>
            
            {/* Barra di progresso */}
            <div className={`w-full h-1 rounded-full overflow-hidden ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>
              <div 
                className={`h-full ${progressColor} transition-all duration-300`}
                style={{ width: `${usagePercentage}%` }}
              />
            </div>
          </div>
          
          {/* Pulsanti per salvare/annullare */}
          <div className="flex justify-end space-x-2 mt-2">
            <button
              onClick={() => {
                console.log(`Annullamento modifiche per cella ${type} alla riga ${index}`);
                setLocalContent(content || '');
                if (onCancel) onCancel(index, type);
              }}
              className={`px-3 py-1 rounded-md text-sm font-medium ${
                isDarkMode
                  ? 'bg-gray-600 text-white hover:bg-gray-500'
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }`}
            >
              Annulla
            </button>
            <button
              onClick={() => {
                console.log(`Salvataggio modifiche per cella ${type} alla riga ${index}, valore: ${localContent}`);
                if (onSave) onSave(index, type, localContent);
              }}
              className={`px-3 py-1 rounded-md text-sm font-medium ${
                isDarkMode
                  ? 'bg-blue-600 text-white hover:bg-blue-500'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              } ${hasError ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={hasError}
            >
              Salva
            </button>
          </div>
        </div>
      ) : (
        <div className="relative">
          <div 
            ref={contentRef}
            className={`${isExpanded ? '' : 'max-h-32'} overflow-hidden transition-all duration-300 ${
              isDarkMode ? 'text-gray-200' : 'text-gray-800'
            }`}
          >
            {content || (
              <span className="italic text-gray-400">
                {type === 'domanda' ? 'Nessuna domanda' : 'Nessuna risposta'}
              </span>
            )}
          </div>
          
          {/* Indicatore di errore */}
          {(row?.hasError || hasError) && (
            <div 
              className="absolute top-0 right-0 cursor-pointer"
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
            >
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
              
              {tooltipVisible && (
                <div className={`absolute z-10 right-0 mt-1 w-64 p-2 rounded-md shadow-lg text-xs ${
                  isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'
                }`}>
                  {validationMessage}
                </div>
              )}
            </div>
          )}
          
          {/* Pulsante di espansione se il contenuto è troppo lungo */}
          {isOverflowing && (
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className={`mt-2 text-xs font-medium ${
                isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'
              }`}
            >
              {isExpanded ? 'Mostra meno' : 'Mostra tutto'}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default TableCell; 