// /react-app/src/components/rag/RAGAnalyticsInterface
import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Upload, AlertCircle, Send } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const Message = ({ message, isDarkMode }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  
  // Verifichiamo che il plot sia una stringa base64 valida
  const isValidBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  // Gestiamo il click sull'immagine
  const handleImageClick = () => {
    setIsExpanded(!isExpanded);
  };

  // Gestione del caricamento immagine
  const handleImageLoad = () => {
    setIsLoading(false);
    setImageError(false);
  };

  // Gestione errori immagine
  const handleImageError = (e) => {
    setIsLoading(false);
    setImageError(true);
    console.error('Errore caricamento immagine:', e);
    e.target.style.display = 'none';
  };

  return (
    <div
      className={`max-w-[80%] rounded-lg p-4 ${
        message.type === 'user'
          ? isDarkMode 
            ? 'bg-blue-600' 
            : 'bg-blue-500 text-white'
          : isDarkMode
            ? 'bg-gray-700'
            : 'bg-gray-100'
      }`}
    >
      <p className="whitespace-pre-wrap">
        {typeof message.content === 'string' ? message.content : message.content?.content}
      </p>
      {message.plot && isValidBase64(message.plot) && !imageError && (
        <>
          {/* Modal Overlay per l'immagine espansa */}
          {isExpanded && (
            <div 
              className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
              onClick={handleImageClick}
            >
              <div className="relative max-w-[90vw] max-h-[90vh]">
                <button
                  className="absolute -top-10 right-0 text-white bg-red-500 rounded-full p-2 hover:bg-red-600 transition-colors"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(false);
                  }}
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-6 w-6" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M6 18L18 6M6 6l12 12" 
                    />
                  </svg>
                </button>
                <img 
                  src={`data:image/png;base64,${message.plot}`}
                  alt="Data Visualization Expanded"
                  className="max-w-full max-h-[90vh] object-contain rounded-lg"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            </div>
          )}
          
          {/* Immagine thumbnail */}
          <div className="mt-4 relative">
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              </div>
            )}
            <img 
              src={`data:image/png;base64,${message.plot}`}
              alt="Data Visualization"
              className={`
                max-w-full rounded-lg shadow-lg 
                cursor-pointer hover:opacity-90 transition-opacity
                ${isLoading ? 'opacity-0' : 'opacity-100'}
              `}
              onClick={handleImageClick}
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
            <div className="text-xs text-gray-500 mt-1 text-center italic">
              Clicca per {isExpanded ? 'ridurre' : 'ingrandire'} il grafico
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const RAGAnalyticsInterface = ({ isDarkMode }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const messagesEndRef = useRef(null);
  const { token } = useAuth();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const onDrop = async (acceptedFiles) => {
    try {
      setIsUploading(true);
      setError(null);

      for (const file of acceptedFiles) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(
          `${API_BASE_URL}/rag/upload`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data?.document_id) {
          setUploadedDocuments(prev => [...prev, { id: response.data.document_id, name: file.name }]);
        }
      }

      await loadDocuments();
    } catch (error) {
      console.error('Error uploading:', error);
      setError('Errore nel caricamento dei file');
    } finally {
      setIsUploading(false);
    }
  };

  const loadDocuments = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/rag/documents`,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      
      if (response.data) {
        setDocuments(response.data);
        if (response.data.length === 0) {
          addBotMessage("Benvenuto! Puoi caricare i tuoi documenti trascinandoli qui o cliccando sull'area di upload. Una volta caricati, potrò aiutarti ad analizzarli.");
        }
      }
    } catch (error) {
      console.error('Error loading documents:', error);
      setError('Errore nel caricamento dei documenti');
    }
  };

  useEffect(() => {
    loadDocuments();
  }, [token]);

  const addUserMessage = (text) => {
    setMessages(prev => [...prev, { type: 'user', content: text }]);
  };

  const addBotMessage = (response) => {
    setMessages(prev => [...prev, { 
      type: 'bot', 
      content: typeof response === 'string' ? response : response.content,
      plot: typeof response === 'object' && response.plot ? response.plot : null
    }]);
  };

  const handleAnalysisRequest = async (message) => {
    if (!message.trim() || uploadedDocuments.length === 0) return;

    try {
      setIsLoading(true);
      setError(null);

      addUserMessage(message);

      const requestData = {
        query: message,
        document_ids: uploadedDocuments.map(doc => doc.id)
      };

      const response = await axios.post(
        `${API_BASE_URL}/rag/analyze`,
        requestData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.success) {
        addBotMessage({
          content: response.data.analysis,
          plot: response.data.plot
        });
      } else {
        addBotMessage("Mi dispiace, non sono riuscito ad analizzare i dati. Riprova con una domanda diversa.");
      }

    } catch (error) {
      console.error('[RAG] Errore analisi:', error);
      if (error.response?.status === 401) {
        setError('Sessione scaduta. Effettua nuovamente il login.');
      } else {
        setError('Errore durante l\'analisi dei dati');
      }
      addBotMessage("Si è verificato un errore durante l'analisi. Riprova più tardi.");
    } finally {
      setIsLoading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt'],
      'text/csv': ['.csv']
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isLoading && inputMessage.trim()) {
      handleAnalysisRequest(inputMessage);
      setInputMessage('');
    }
  };

  // Aggiungiamo un componente per mostrare i documenti caricati
  const DocumentList = () => {
    if (uploadedDocuments.length === 0) return null;

    return (
      <div className={`mt-4 p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
        <h3 className="text-sm font-semibold mb-2">Documenti Caricati:</h3>
        <ul className="space-y-2">
          {uploadedDocuments.map((doc, index) => (
            <li 
              key={doc.id} 
              className={`flex items-center justify-between p-2 rounded ${
                isDarkMode ? 'bg-gray-700' : 'bg-white'
              }`}
            >
              <span className="text-sm truncate">{doc.name}</span>
              <button
                onClick={() => handleRemoveDocument(doc.id)}
                className="text-red-500 hover:text-red-600"
                title="Rimuovi documento"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  // Aggiungiamo la funzione per rimuovere un documento
  const handleRemoveDocument = async (documentId) => {
    try {
      await axios.delete(
        `${API_BASE_URL}/rag/documents/${documentId}`,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      
      setUploadedDocuments(prev => prev.filter(doc => doc.id !== documentId));
      addBotMessage("Documento rimosso con successo.");
    } catch (error) {
      console.error('Error removing document:', error);
      setError('Errore nella rimozione del documento');
    }
  };

  return (
    <div className={`flex flex-col h-[80vh] ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      <div
        {...getRootProps()}
        className={`
          border-2 border-dashed rounded-lg p-4 mb-4 text-center
          ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}
          ${isDragActive ? 'border-blue-500 bg-blue-50' : ''}
          ${isUploading ? 'opacity-50' : ''}
          transition-all duration-200 ease-in-out
          hover:border-blue-500 hover:bg-blue-50/10
        `}
      >
        <input {...getInputProps()} />
        <Upload className={`mx-auto h-6 w-6 mb-2 ${isUploading ? 'animate-bounce' : ''} ${
          isDarkMode ? 'text-gray-400' : 'text-gray-500'
        }`} />
        <p className="text-sm">
          {isUploading 
            ? "Caricamento in corso..."
            : isDragActive
              ? "Rilascia i file qui..."
              : "Trascina i file qui o clicca per selezionarli"}
        </p>
        <p className="text-xs text-gray-500 mt-1">
          Supporta PDF, DOCX, TXT, CSV
        </p>
      </div>

      <DocumentList />

      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <Message message={message} isDarkMode={isDarkMode} />
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {error && (
        <div className="flex items-center space-x-2 text-red-500 p-2">
          <AlertCircle className="h-5 w-5" />
          <span>{error}</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="p-4 border-t">
        <div className="flex space-x-2">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Fai una domanda sui tuoi documenti..."
            className={`flex-1 p-2 rounded-lg border ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white' 
                : 'bg-white border-gray-300'
            }`}
            disabled={isLoading || uploadedDocuments.length === 0}
          />
          <button
            type="submit"
            disabled={isLoading || !inputMessage.trim() || uploadedDocuments.length === 0}
            className={`p-2 rounded-lg ${
              isLoading || !inputMessage.trim() || uploadedDocuments.length === 0
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
          >
            <Send className="h-5 w-5" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default RAGAnalyticsInterface; 