import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrainingProvider } from '../contexts/TrainingContext';
import ProgressGraph from '../components/training-dashboard/ProgressGraph';

const TrainingDashboardPage = ({ token, isDarkMode }) => {
  const [progressData, setProgressData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const navigate = useNavigate();
  
  // Dati di esempio per il grafico
  const mockProgressData = [
    { date: '01/03', overall: 62, communication: 70, objection_handling: 55, closing: 45, product_knowledge: 75 },
    { date: '05/03', overall: 65, communication: 72, objection_handling: 58, closing: 50, product_knowledge: 75 },
    { date: '10/03', overall: 68, communication: 75, objection_handling: 62, closing: 54, product_knowledge: 78 },
    { date: '15/03', overall: 72, communication: 78, objection_handling: 65, closing: 60, product_knowledge: 80 },
    { date: '20/03', overall: 76, communication: 80, objection_handling: 70, closing: 65, product_knowledge: 82 },
    { date: '25/03', overall: 78, communication: 82, objection_handling: 73, closing: 68, product_knowledge: 85 },
    { date: '30/03', overall: 82, communication: 85, objection_handling: 76, closing: 73, product_knowledge: 87 },
  ];
  
  // Dati di esempio per il radar delle competenze
  const skillsData = {
    communication: 85,
    objection_handling: 76,
    closing: 73,
    product_knowledge: 87,
    customer_rapport: 82,
    persuasion: 79,
    storytelling: 81
  };
  
  // Mock delle sessioni recenti
  const recentSessions = [
    { id: 1, agent_type: 'coach', date: '30/03/2025', duration: 45, focus: 'Comunicazione', score: 85 },
    { id: 2, agent_type: 'simulator', date: '28/03/2025', duration: 30, focus: 'Obiezioni', score: 76 },
    { id: 3, agent_type: 'analyzer', date: '25/03/2025', duration: 60, focus: 'Script Review', score: 82 },
    { id: 4, agent_type: 'coach', date: '22/03/2025', duration: 40, focus: 'Chiusura Vendita', score: 73 },
    { id: 5, agent_type: 'simulator', date: '18/03/2025', duration: 25, focus: 'Rapporto Cliente', score: 79 },
  ];
  
  // Aree di miglioramento suggerite
  const improvementAreas = [
    { 
      id: 1, 
      title: 'Gestione delle Obiezioni', 
      score: 76, 
      suggestion: 'Pratica più scenari di obiezioni specifiche del settore attraverso le simulazioni.' 
    },
    { 
      id: 2, 
      title: 'Tecniche di Chiusura', 
      score: 73, 
      suggestion: 'Rivedi le tecniche di chiusura alternative e pratica con il simulatore.' 
    },
    { 
      id: 3, 
      title: 'Storytelling', 
      score: 81, 
      suggestion: 'Migliora le tue storie di successo con clienti con più dettagli e risultati concreti.' 
    },
  ];
  
  useEffect(() => {
    // In un'implementazione reale, qui chiameremmo un servizio API
    const fetchProgressData = async () => {
      try {
        setIsLoading(true);
        // Simuliamo una chiamata API con un ritardo
        await new Promise(resolve => setTimeout(resolve, 800));
        setProgressData(mockProgressData);
        setError(null);
      } catch (err) {
        setError('Errore nel caricamento dei dati di progresso');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchProgressData();
  }, []);
  
  const handleStartSession = (agentType) => {
    navigate('/training', { state: { initialAgentType: agentType } });
  };
  
  return (
    <TrainingProvider>
      <div className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Dashboard Formazione</h1>
          <button
            onClick={() => navigate('/training')}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Torna agli Agenti
          </button>
        </div>
        
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 p-4 rounded-lg">
            {error}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Punteggio Globale */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 lg:col-span-3">
              <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                Progresso Formativo
              </h2>
              <ProgressGraph 
                progressData={progressData} 
                metrics={['overall', 'communication', 'objection_handling', 'closing', 'product_knowledge']} 
                isDarkMode={isDarkMode} 
              />
            </div>
            
            {/* Sessioni Recenti */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 lg:col-span-2">
              <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                Sessioni Recenti
              </h2>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                        Agente
                      </th>
                      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                        Data
                      </th>
                      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                        Durata
                      </th>
                      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                        Focus
                      </th>
                      <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                        Score
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                    {recentSessions.map((session) => (
                      <tr key={session.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className={`
                              p-2 rounded-full mr-2
                              ${session.agent_type === 'coach' ? 'bg-green-100 text-green-600 dark:bg-green-900 dark:text-green-300' : 
                                session.agent_type === 'simulator' ? 'bg-yellow-100 text-yellow-600 dark:bg-yellow-900 dark:text-yellow-300' :
                                'bg-purple-100 text-purple-600 dark:bg-purple-900 dark:text-purple-300'}
                            `}>
                              {session.agent_type.charAt(0).toUpperCase()}
                            </div>
                            <span className="text-sm text-gray-900 dark:text-white">
                              {session.agent_type === 'coach' ? 'Coach' : 
                               session.agent_type === 'simulator' ? 'Simulatore' : 'Analizzatore'}
                            </span>
                          </div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                          {session.date}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                          {session.duration} min
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                          {session.focus}
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="w-16 bg-gray-200 dark:bg-gray-700 rounded-full h-2.5 mr-2">
                              <div 
                                className={`h-2.5 rounded-full ${
                                  session.score >= 80 ? 'bg-green-600' : 
                                  session.score >= 70 ? 'bg-yellow-600' : 
                                  'bg-red-600'
                                }`}
                                style={{ width: `${session.score}%` }}
                              ></div>
                            </div>
                            <span className="text-sm text-gray-700 dark:text-gray-300">{session.score}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            
            {/* Aree di Miglioramento */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
              <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                Aree di Miglioramento
              </h2>
              <div className="space-y-4">
                {improvementAreas.map((area) => (
                  <div key={area.id} className="p-4 border rounded-lg border-gray-200 dark:border-gray-700">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="font-medium text-gray-900 dark:text-white">{area.title}</h3>
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                        area.score >= 80 ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200' : 
                        area.score >= 70 ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200' : 
                        'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                      }`}>
                        {area.score}/100
                      </span>
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-300">{area.suggestion}</p>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Azioni Rapide */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 lg:col-span-3">
              <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
                Azioni Rapide
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <button
                  onClick={() => handleStartSession('coach')}
                  className="flex items-center justify-center p-4 bg-blue-50 dark:bg-blue-900 text-blue-700 dark:text-blue-300 rounded-lg hover:bg-blue-100 dark:hover:bg-blue-800 transition-colors"
                >
                  <div className="bg-blue-100 dark:bg-blue-800 p-3 rounded-full mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="text-left">
                    <h3 className="font-medium">Coach Personale</h3>
                    <p className="text-sm text-blue-600 dark:text-blue-400">Ricevi consigli e formazione</p>
                  </div>
                </button>
                
                <button
                  onClick={() => handleStartSession('simulator')}
                  className="flex items-center justify-center p-4 bg-yellow-50 dark:bg-yellow-900 text-yellow-700 dark:text-yellow-300 rounded-lg hover:bg-yellow-100 dark:hover:bg-yellow-800 transition-colors"
                >
                  <div className="bg-yellow-100 dark:bg-yellow-800 p-3 rounded-full mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="text-left">
                    <h3 className="font-medium">Simulatore Cliente</h3>
                    <p className="text-sm text-yellow-600 dark:text-yellow-400">Pratica scenari di vendita</p>
                  </div>
                </button>
                
                <button
                  onClick={() => handleStartSession('analyzer')}
                  className="flex items-center justify-center p-4 bg-purple-50 dark:bg-purple-900 text-purple-700 dark:text-purple-300 rounded-lg hover:bg-purple-100 dark:hover:bg-purple-800 transition-colors"
                >
                  <div className="bg-purple-100 dark:bg-purple-800 p-3 rounded-full mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                      <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="text-left">
                    <h3 className="font-medium">Analizzatore</h3>
                    <p className="text-sm text-purple-600 dark:text-purple-400">Analizza le tue performance</p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </TrainingProvider>
  );
};

export default TrainingDashboardPage; 