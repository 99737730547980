import React, { useState, useEffect, useRef } from 'react';
import { useTraining } from '../../contexts/TrainingContext';
import { sendMessageToAgent, requestAnalysis, resetSessionMessages, deleteSession } from '../../services/agentService';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { getAuthHeader } from '../../utils/auth';

// Aggiungiamo uno stile interno per personalizzare meglio l'aspetto del markdown
const markdownStyles = `
  .markdown-content h1, .markdown-content h2, .markdown-content h3, .markdown-content h4 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-weight: 600;
  }
  
  .markdown-content h1 {
    font-size: 1.5em;
  }
  
  .markdown-content h2 {
    font-size: 1.3em;
  }
  
  .markdown-content h3 {
    font-size: 1.1em;
  }
  
  .markdown-content ul, .markdown-content ol {
    margin-left: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .markdown-content li {
    margin-bottom: 0.25em;
  }
  
  .markdown-content p {
    margin-bottom: 0.75em;
  }
  
  .markdown-content code {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.1em 0.3em;
    border-radius: 3px;
    font-family: monospace;
  }
  
  .markdown-content strong {
    font-weight: 600;
  }
  
  .markdown-content blockquote {
    border-left: 3px solid #cbd5e0;
    padding-left: 1em;
    margin-left: 0;
    color: #4a5568;
  }
  
  .dark .markdown-content blockquote {
    border-left-color: #4a5568;
    color: #cbd5e0;
  }
`;

const AgentChat = ({ sessionId, agentType }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  
  // Aggiungo un log per debug
  useEffect(() => {
    console.log('AgentType:', agentType);
  }, [agentType]);
  
  const { currentSession, loadSessionState, isLoading, error } = useTraining();

  // Converti sessionId in intero per confronti coerenti
  const numericSessionId = sessionId ? parseInt(sessionId, 10) : null;

  useEffect(() => {
    // Carica i dati solo se c'è un ID sessione e solo al montaggio del componente o al cambio di ID
    if (sessionId) {
      // Evita di ricaricare se abbiamo già una sessione con lo stesso ID
      if (!currentSession || currentSession.id !== numericSessionId) {
        loadSessionState(sessionId);
        
        // Verifica se questa sessione è già nelle sessioni note in localStorage
        try {
          const savedSessions = localStorage.getItem('known_sessions');
          if (savedSessions) {
            const parsedSessions = JSON.parse(savedSessions);
            const sessionExists = parsedSessions.some(s => s.id === numericSessionId);
            
            if (!sessionExists) {
              console.log("AgentChat: aggiungo sessione corrente a known_sessions", numericSessionId);
              
              // Se la sessione non esiste, la aggiungiamo (versione semplificata)
              const sessionData = {
                id: numericSessionId,
                agent_type: agentType || 'coach',
                created_at: new Date().toISOString(),
                status: 'active',
                metadata: {
                  agent_name: agentType === 'coach' ? 'Coach Formativo' : 
                              agentType === 'simulator' ? 'Simulatore Cliente' : 
                              agentType === 'analyzer' ? 'Analizzatore Performance' : 'Agente'
                }
              };
              
              // Aggiungi la nuova sessione all'inizio dell'array
              const updatedSessions = [sessionData, ...parsedSessions];
              localStorage.setItem('known_sessions', JSON.stringify(updatedSessions));
            }
          } else {
            // Se non ci sono sessioni salvate, inizializziamo l'array
            const initialSession = {
              id: numericSessionId,
              agent_type: agentType || 'coach',
              created_at: new Date().toISOString(),
              status: 'active',
              metadata: {
                agent_name: agentType === 'coach' ? 'Coach Formativo' : 
                            agentType === 'simulator' ? 'Simulatore Cliente' : 
                            agentType === 'analyzer' ? 'Analizzatore Performance' : 'Agente'
              }
            };
            
            localStorage.setItem('known_sessions', JSON.stringify([initialSession]));
          }
        } catch (error) {
          console.error("AgentChat: errore nel gestire known_sessions", error);
        }
      }
    }
  }, [sessionId, loadSessionState, currentSession, numericSessionId, agentType]);

  useEffect(() => {
    // Assicurati che messages sia sempre un array valido
    if (currentSession) {
      // Doppio controllo per sicurezza
      const safeMessages = Array.isArray(currentSession.messages) 
        ? currentSession.messages 
        : [];
      
      setMessages(safeMessages);
    }
  }, [currentSession]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    if (!inputMessage.trim()) return;
    
    // Verifica che sessionId sia definito
    if (!sessionId) {
      const errorMessage = {
        role: 'system',
        content: 'Errore: Impossibile inviare il messaggio, ID sessione non definito.',
        timestamp: new Date().toISOString(),
        isError: true,
      };
      setMessages(prev => [...prev, errorMessage]);
      return;
    }
    
    const userMessage = {
      role: 'user',
      content: inputMessage,
      timestamp: new Date().toISOString(),
    };
    
    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsTyping(true);
    
    try {
      console.log(`Invio messaggio alla sessione ${sessionId}: "${inputMessage}"`);
      const response = await sendMessageToAgent(sessionId, inputMessage);
      
      const agentMessage = {
        role: 'assistant',
        content: response.message,
        timestamp: new Date().toISOString(),
        feedback: response.feedback || null,
        metrics: response.metrics || null,
      };
      
      setMessages(prev => [...prev, agentMessage]);
      
      // Se l'agente è un analizzatore e ha fornito un'analisi
      if (agentType === 'analyzer' && response.analysis) {
        // Gestire visualizzazione specifiche per l'analisi
      }
    } catch (error) {
      const errorMessage = {
        role: 'system',
        content: `Errore: ${error.message}`,
        timestamp: new Date().toISOString(),
        isError: true,
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
      inputRef.current?.focus();
    }
  };

  const requestSpecificAnalysis = async (analysisType, data = {}) => {
    // Verifica che sessionId sia definito
    if (!sessionId) {
      const errorMessage = {
        role: 'system',
        content: 'Errore: Impossibile richiedere analisi, ID sessione non definito.',
        timestamp: new Date().toISOString(),
        isError: true,
      };
      setMessages(prev => [...prev, errorMessage]);
      return;
    }
    
    setIsTyping(true);
    
    try {
      const response = await requestAnalysis(sessionId, analysisType, data);
      
      const analysisMessage = {
        role: 'assistant',
        content: response.message,
        timestamp: new Date().toISOString(),
        analysisType: analysisType,
        analysisData: response.data || null,
      };
      
      setMessages(prev => [...prev, analysisMessage]);
    } catch (error) {
      const errorMessage = {
        role: 'system',
        content: `Errore nell'analisi: ${error.message}`,
        timestamp: new Date().toISOString(),
        isError: true,
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  // Modifichiamo la funzione di reset della sessione per pulire completamente la chat
  const handleResetSession = async () => {
    if (!sessionId) return;
    
    // Chiediamo conferma all'utente
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Vuoi davvero resettare questa conversazione? I messaggi verranno rimossi dall'interfaccia ma la sessione rimarrà nella cronologia.")) {
      return;
    }
    
    try {
      setIsTyping(true);
      
      // Manteniamo l'approccio di chiamare resetSessionMessages per compatibilità backend
      const response = await resetSessionMessages(sessionId);
      
      if (response.status === "success") {
        // Resetta completamente i messaggi locali (più pulito rispetto alla versione precedente)
        setMessages([]);
        
        // Aggiungi un messaggio di benvenuto iniziale come se fosse una nuova sessione
        const welcomeMessage = {
          role: 'assistant',
          content: `Ciao! Sono il tuo ${
            agentType === 'coach' ? 'Coach Formativo' : 
            agentType === 'simulator' ? 'Simulatore Cliente' : 
            agentType === 'analyzer' ? 'Analizzatore Performance' : 'Assistente'
          }. Come posso aiutarti oggi?`,
          timestamp: new Date().toISOString(),
        };
        
        setMessages([welcomeMessage]);
        
        // Notifica visivamente l'utente
        toast?.success?.('Conversazione resettata con successo');
      }
    } catch (error) {
      const errorMessage = {
        role: 'system',
        content: `Errore nel reset della sessione: ${error.message}`,
        timestamp: new Date().toISOString(),
        isError: true,
      };
      
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  // Funzione per eliminare una sessione dalla cronologia
  const handleDeleteSession = async () => {
    if (!numericSessionId) return;
    
    // Chiediamo una conferma più esplicita per l'eliminazione
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("ATTENZIONE: Questa operazione rimuoverà completamente questa sessione dalla cronologia. L'operazione non può essere annullata. Procedere?")) {
      return;
    }
    
    try {
      setIsTyping(true);
      
      // Utilizziamo la funzione deleteSession dal servizio
      const response = await deleteSession(numericSessionId);
      
      if (response.status === "success") {
        // Resetta i messaggi locali
        setMessages([]);
        
        // Aggiungi un messaggio di sistema che conferma l'eliminazione
        const systemMessage = {
          role: 'system',
          content: 'Sessione eliminata con successo. Verrai reindirizzato alla lista sessioni.',
          timestamp: new Date().toISOString(),
        };
        
        setMessages([systemMessage]);
        
        // Reindirizza alla lista delle sessioni dopo 2 secondi
        setTimeout(() => {
          window.location.href = '/training';
        }, 2000);
      }
    } catch (error) {
      const errorMessage = {
        role: 'system',
        content: `Errore nell'eliminazione della sessione: ${error.message}`,
        timestamp: new Date().toISOString(),
        isError: true,
      };
      
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  // Aggiungi questa funzione per gestire l'upload dello script
  const handleScriptUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;
      
      // Inviare un messaggio di sistema che indica che è stato caricato uno script
      const systemMessage = {
        role: 'system',
        content: `Script caricato: "${file.name}"`,
        timestamp: new Date().toISOString(),
      };
      
      setMessages(prev => [...prev, systemMessage]);
      
      // Inviare il contenuto dello script come messaggio dell'utente
      setInputMessage(content);
      await handleSendMessage({
        preventDefault: () => {}
      });
      
      // Reset del file input
      event.target.value = '';
    };
    
    reader.readAsText(file);
  };

  // Funzione per aggiungere emoji ai messaggi in base al contenuto
  const addEmojisToMessage = (content) => {
    if (typeof content !== 'string') return content;

    // Sostituzione per titoli numerati (formato più evidente)
    content = content.replace(/(\d+)\.\s+([\w\s]+)/g, '## $1. $2');
    
    // Aggiungiamo emoji per i feedback degli script
    content = content.replace(/Punti Positivi:/gi, '✅ Punti Positivi:');
    content = content.replace(/Aree di Miglioramento:/gi, '🔍 Aree di Miglioramento:');
    content = content.replace(/Esempi? di Script/gi, '📝 Esempio di Script');
    content = content.replace(/Aree di Miglioramento/gi, '🔍 Aree di Miglioramento');
    content = content.replace(/Esempio di Script/gi, '📝 Esempio di Script');
    content = content.replace(/Continua così!/gi, '🚀 Continua così!');
    content = content.replace(/Personalizzazione/gi, '🎯 Personalizzazione');
    content = content.replace(/Gestione delle Obiezioni/gi, '🛡️ Gestione delle Obiezioni');
    content = content.replace(/Coinvolgimento Emotivo/gi, '❤️ Coinvolgimento Emotivo');
    content = content.replace(/Introduzione Cordiale/gi, '👋 Introduzione Cordiale');
    content = content.replace(/Chiarezza/gi, '💎 Chiarezza');
    content = content.replace(/Domande Coinvolgenti/gi, '🤔 Domande Coinvolgenti');
    content = content.replace(/Offerta di Prova/gi, '🎁 Offerta di Prova');
    content = content.replace(/Chiusura con Opzioni/gi, '🎯 Chiusura con Opzioni');
    
    // Aggiungiamo emoji in base al tipo di contenuto
    content = content.replace(/Conoscere il Prodotto/gi, '🔍 Conoscere il Prodotto');
    content = content.replace(/Identificare i Bisogni/gi, '👂 Identificare i Bisogni');
    content = content.replace(/Creare un Rapporto/gi, '🤝 Creare un Rapporto');
    content = content.replace(/Presentare il Prodotto/gi, '🎯 Presentare il Prodotto');
    content = content.replace(/Gestire le Obiezioni/gi, '🛡️ Gestire le Obiezioni');
    content = content.replace(/Chiudere la Vendita/gi, '✅ Chiudere la Vendita');
    content = content.replace(/Follow-up/gi, '📅 Follow-up');
    
    // Valorizza parole chiave come "Esempio" con emoji
    content = content.replace(/\*\*Esempio:\*\*/g, '**💡 Esempio:**');
    content = content.replace(/Esempio:/g, '💡 Esempio:');
    content = content.replace(/\*\*Cosa significa\?\*\*/g, '**❓ Cosa significa?**');
    content = content.replace(/Cosa significa\?/g, '❓ Cosa significa?');
    
    // Migliora le domande
    content = content.replace(/Cosa significa\?/gi, '❓ Cosa significa?');
    
    return content;
  };

  // Funzione per convertire il testo in formato Markdown avanzato
  const formatMessageContent = (content) => {
    if (typeof content !== 'string') return 'Contenuto non disponibile';
    
    // Migliora la formattazione dell'elenco numerato e dei titoli
    let formattedContent = content;
    
    // Sostituisci titoli con formato ### 1. Titolo
    formattedContent = formattedContent.replace(/###\s*(\d+)\.\s*([\w\s]+)/g, '## $1. $2');
    
    // Sostituisci anche il formato 1. Titolo all'inizio di una riga
    formattedContent = formattedContent.replace(/^(\d+)\.\s+([\w\s]+)$/gm, '## $1. $2');
    
    // Aggiungi emoji e migliora la formattazione
    formattedContent = addEmojisToMessage(formattedContent);
    
    // Usiamo le classi di Tailwind Typography
    return (
      <div className="prose prose-invert prose-sm max-w-none
                      prose-headings:font-semibold prose-headings:mt-2 prose-headings:mb-2
                      prose-h1:text-xl prose-h2:text-lg prose-h3:text-base
                      prose-p:mb-3 prose-p:leading-relaxed
                      prose-ul:ml-6 prose-ul:mb-2 prose-li:mb-1
                      prose-code:bg-gray-800 prose-code:px-1 prose-code:py-0.5 prose-code:rounded
                      prose-strong:font-semibold prose-strong:text-white
                      prose-blockquote:border-l-2 prose-blockquote:border-gray-500 prose-blockquote:pl-4 prose-blockquote:italic">
        <ReactMarkdown>
          {formattedContent}
        </ReactMarkdown>
      </div>
    );
  };

  // Renderizzazione condizionale dello stato della sessione
  const renderChatContent = () => {
    if (!numericSessionId) {
      return (
        <div className="flex justify-center items-center h-full text-gray-500 dark:text-gray-400">
          Nessuna sessione selezionata. Tornare alla lista agenti e selezionarne uno.
        </div>
      );
    }
    
    if (error) {
      return (
        <div className="flex flex-col justify-center items-center h-full p-4">
          <div className="text-red-500 dark:text-red-400 mb-4">
            {error}
          </div>
          <button
            onClick={() => window.location.href = '/training'}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Torna alla Selezione Agenti
          </button>
        </div>
      );
    }
    
    return (
      <div className="flex flex-col h-full">
        {/* Header fisso con info sull'agente */}
        <div className="sticky top-0 z-10">
          {/* Intestazione agente */}
          <div className="bg-gray-800 p-4 border-b border-gray-700">
            <h2 className="text-xl font-bold text-white">
              Agente {agentType === 'coach' ? 'coach' : 
                    agentType === 'simulator' ? 'simulatore' : 
                    agentType === 'analyzer' ? 'analizzatore' : 'chatbot'}
            </h2>
            <div className="flex items-center mt-1">
              <span className="inline-block w-2 h-2 bg-green-500 rounded-full mr-2"></span>
              <span className="text-sm text-gray-300">
                Attivo • {
                  agentType === 'coach' ? 'Coach' : 
                  agentType === 'simulator' ? 'Simulatore' : 
                  agentType === 'analyzer' ? 'Analizzatore' : 'Assistente'
                } • Livello: intermediate
              </span>
            </div>
          </div>
          
          {/* Sottointestazione con info sulla sessione */}
          <div className="bg-gray-900 p-3 border-b border-gray-700">
            <h3 className="text-lg font-semibold text-white">
            {agentType === 'coach' ? 'Coach Formativo' : 
             agentType === 'simulator' ? 'Simulatore Cliente' : 
              agentType === 'analyzer' ? 'Analizzatore Performance' : 'Assistente'}
          </h3>
            <p className="text-sm text-gray-400">
              Sessione #{numericSessionId} • {isTyping ? 'Sta scrivendo...' : 'Online'}
          </p>
          </div>
        </div>
        
        {/* Area messaggi scorrevole */}
        <div className="flex-1 overflow-y-auto py-4 px-4 bg-gray-900">
          {messages.length === 0 ? (
            <div className="flex flex-col justify-center items-center h-full text-gray-500 space-y-4">
              <p>Non ci sono ancora messaggi. Inizia la conversazione!</p>
              {agentType === 'coach' && (
                <div className="relative">
                  <label 
                    htmlFor="script-upload"
                    className="px-5 py-2.5 bg-indigo-600 text-white rounded-md text-sm cursor-pointer flex items-center hover:bg-indigo-700 transition-colors"
                  >
                    <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                    Carica uno Script per Iniziare
                  </label>
                  <input 
                    id="script-upload" 
                    type="file" 
                    accept=".txt,.doc,.docx,.pdf"
                    className="sr-only"
                    onChange={handleScriptUpload}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="space-y-6 pb-24">
              {messages.map((message, index) => renderMessage(message, index))}
              {isTyping && (
                <div className="flex items-center space-x-2 p-2">
                  <div className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce"></div>
                  <div className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce delay-100"></div>
                  <div className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce delay-200"></div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          )}
        </div>
        
        {/* Footer fisso con pulsanti e form */}
        <div className="sticky bottom-0 z-20 bg-gray-800 border-t border-gray-700">
          {/* Barra dei pulsanti */}
          <div className="flex justify-between items-center p-4 border-b border-gray-700">
            <div className="flex space-x-4">
              <div className="relative">
                <label 
                  htmlFor="script-upload-main"
                  className="px-5 py-2.5 bg-green-600 text-white rounded-md text-sm cursor-pointer flex items-center hover:bg-green-700 transition-colors"
                >
                  <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                  Carica Script
                </label>
                <input 
                  id="script-upload-main" 
                  type="file" 
                  accept=".txt,.doc,.docx,.pdf"
                  className="sr-only"
                  onChange={handleScriptUpload}
                />
              </div>
            </div>
            
            <div className="flex space-x-4">
              <button 
                onClick={handleResetSession}
                className="px-4 py-2 bg-gray-600 text-white rounded-md text-sm flex items-center hover:bg-gray-700 transition-colors"
              >
                Reset
              </button>
              <button 
                onClick={handleDeleteSession}
                className="px-4 py-2 bg-red-600 text-white rounded-md text-sm flex items-center hover:bg-red-700 transition-colors"
              >
                Elimina
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  // Funzione di rendering dei messaggi
  const renderMessage = (message, index) => {
    // Validazione che message sia un oggetto valido
    if (!message || typeof message !== 'object') {
      console.warn('Messaggio non valido:', message);
      return null;
    }
    
    const isUser = message.role === 'user';
    const isSystem = message.role === 'system';
    const isError = message.isError;
    
    return (
      <div key={index} className={`flex ${isUser ? 'justify-end' : 'justify-start'} ${isSystem ? 'justify-center' : ''}`}>
        <div className={`max-w-[85%] ${isSystem ? 'max-w-md' : ''}`}>
          <div 
            className={`rounded-lg px-4 py-3 ${
              isUser 
                ? 'bg-indigo-600 text-white rounded-tr-none' 
                : isSystem 
                  ? 'bg-gray-700 text-gray-200' 
                  : 'bg-gray-700 text-white rounded-tl-none'
            } ${
              isError ? 'border-red-500 border-2' : ''
            } shadow-md`}
          >
            {/* Contenuto del messaggio */}
            {isUser || isSystem || isError ? (
              <div className="prose dark:prose-invert prose-sm max-w-none">
                {typeof message.content === 'string' ? message.content : 'Contenuto non disponibile'}
              </div>
            ) : (
              formatMessageContent(message.content)
            )}
            
            {/* Visualizzazione feedback se presente e valido */}
            {message.feedback && typeof message.feedback === 'string' && (
              <div className="mt-2 p-2 bg-yellow-100 dark:bg-yellow-900 rounded text-sm">
                <h4 className="font-semibold">📝 Feedback:</h4>
                <p>{message.feedback}</p>
              </div>
            )}
            
            {/* Visualizzazione metriche se presenti e valide */}
            {message.metrics && typeof message.metrics === 'object' && !Array.isArray(message.metrics) && (
              <div className="mt-2 p-2 bg-green-100 dark:bg-green-900 rounded text-sm">
                <h4 className="font-semibold">📊 Metriche:</h4>
                <ul>
                  {Object.entries(message.metrics).map(([key, value]) => (
                    <li key={key}>{key}: {typeof value === 'object' ? JSON.stringify(value) : value}</li>
                  ))}
                </ul>
              </div>
            )}
            
            {/* Visualizzazione dati di analisi se presenti e validi */}
            {message.analysisData && (
              <div className="mt-2 p-2 bg-purple-100 dark:bg-purple-900 rounded text-sm">
                <h4 className="font-semibold">🔍 Analisi {message.analysisType || 'dati'}:</h4>
                <pre className="whitespace-pre-wrap text-xs">
                  {typeof message.analysisData === 'object' 
                    ? JSON.stringify(message.analysisData, null, 2) 
                    : message.analysisData}
                </pre>
              </div>
            )}
          </div>
          
          {/* Timestamp sotto il messaggio */}
          <div className="text-xs text-gray-500 mt-1 px-1">
            {message.timestamp ? new Date(message.timestamp).toLocaleTimeString() : ''}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full bg-gray-900">
      <style>{markdownStyles}</style>
      {renderChatContent()}
      
      {/* Form di input sempre visibile */}
      <div className="sticky bottom-0 z-30 bg-gray-800 border-t border-gray-700 p-4">
        <form onSubmit={handleSendMessage} className="w-full">
          <div className="flex space-x-2">
            <input
              type="text"
              className="flex-1 px-4 py-3 border border-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-gray-900 text-gray-200"
              placeholder="Scrivi un messaggio..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              disabled={isTyping}
              ref={inputRef}
            />
            <button
              type="submit"
              className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:opacity-50 transition-colors"
              disabled={!inputMessage.trim() || isTyping}
            >
              Invia
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgentChat; 