import React, { useState } from 'react';
import { CreditCardIcon, TrashIcon } from '@heroicons/react/24/outline';

/**
 * Componente per la visualizzazione e gestione dei metodi di pagamento
 */
const PaymentMethodsSection = ({ paymentMethods = [], loading = false, onDelete, isDarkMode = false }) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const getCardIcon = (brand) => {
    // Qui puoi aggiungere le immagini per i diversi brand di carte
    switch (brand?.toLowerCase()) {
      case 'visa':
        return <span className="font-bold text-blue-600">VISA</span>;
      case 'mastercard':
        return <span className="font-bold text-red-600">MC</span>;
      case 'amex':
        return <span className="font-bold text-blue-800">AMEX</span>;
      default:
        return <CreditCardIcon className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />;
    }
  };

  const handleDeleteClick = (paymentMethodId) => {
    setDeleteConfirmation(paymentMethodId);
  };

  const confirmDelete = (id) => {
    onDelete(id);
    setDeleteConfirmation(null);
  };

  const cancelDelete = () => {
    setDeleteConfirmation(null);
  };

  if (loading) {
    return (
      <div className="p-4 flex justify-center">
        <div className="animate-pulse flex space-x-4">
          <div className={`h-12 w-12 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-full`}></div>
          <div className="flex-1 space-y-4 py-1">
            <div className={`h-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded w-3/4`}></div>
            <div className="space-y-2">
              <div className={`h-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded`}></div>
              <div className={`h-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded w-5/6`}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!paymentMethods.length) {
    return (
      <div className="p-8 text-center">
        <CreditCardIcon className={`mx-auto h-12 w-12 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
        <h3 className={`mt-2 text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Nessun metodo di pagamento
        </h3>
        <p className={`mt-1 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          Non hai ancora metodi di pagamento nel tuo account.
        </p>
        <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Aggiungi metodo di pagamento
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h2 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Metodi di Pagamento
          </h2>
          <p className={`mt-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-700'}`}>
            Gestisci i tuoi metodi di pagamento
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Aggiungi
          </button>
        </div>
      </div>
      <div className="mt-8 space-y-4">
        {paymentMethods.map((method) => (
          <div
            key={method.id}
            className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow sm:rounded-lg overflow-hidden ${isDarkMode ? 'border border-gray-700' : ''}`}
          >
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {getCardIcon(method.card_brand)}
                  </div>
                  <div className="ml-4">
                    <h3 className={`text-lg font-medium leading-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {method.card_brand || 'Carta'} **** {method.card_last4}
                    </h3>
                    <p className={`mt-1 max-w-2xl text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      Scadenza: {method.exp_month}/{method.exp_year}
                      {method.is_default && (
                        <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          Predefinito
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div>
                  {deleteConfirmation === method.id ? (
                    <div className="flex items-center space-x-2">
                      <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Confermi?</span>
                      <button
                        type="button"
                        onClick={() => confirmDelete(method.id)}
                        className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        <TrashIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        onClick={cancelDelete}
                        className={`inline-flex items-center p-1.5 border ${
                          isDarkMode
                            ? 'border-gray-600 text-gray-300 bg-gray-700 hover:bg-gray-600'
                            : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
                        } rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                      >
                        ✕
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleDeleteClick(method.id)}
                      className={`inline-flex items-center p-1.5 border ${
                        isDarkMode
                          ? 'border-gray-600 text-gray-300 bg-gray-700 hover:bg-gray-600'
                          : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
                      } rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                    >
                      <TrashIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethodsSection; 