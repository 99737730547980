import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

/**
 * Componente per la visualizzazione e modifica del profilo utente
 */
const ProfileSection = ({ profileData, onProfileUpdate, onPasswordChange, isDarkMode = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    full_name: profileData.full_name || '',
    email: profileData.email || '',
  });
  
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [passwordData, setPasswordData] = useState({
    current_password: '',
    new_password: '',
    confirm_password: '',
  });

  // Gestisce i cambiamenti nei campi del form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Gestisce i cambiamenti nei campi del form password
  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Invia il form di aggiornamento profilo
  const handleSubmit = (e) => {
    e.preventDefault();
    onProfileUpdate(formData);
    setIsEditing(false);
  };

  // Invia il form di cambio password
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    
    // Validazione password
    if (passwordData.new_password !== passwordData.confirm_password) {
      toast.error('Le password non coincidono');
      return;
    }
    
    if (passwordData.new_password.length < 8) {
      toast.error('La nuova password deve essere di almeno 8 caratteri');
      return;
    }
    
    onPasswordChange({
      current_password: passwordData.current_password,
      new_password: passwordData.new_password
    });
    
    // Reset del form
    setPasswordData({
      current_password: '',
      new_password: '',
      confirm_password: '',
    });
    setShowPasswordForm(false);
  };

  return (
    <div className="space-y-8">
      {/* Informazioni Profilo */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h2 className={`text-xl font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Informazioni Personali
          </h2>
          {!isEditing && (
            <button
              type="button"
              onClick={() => setIsEditing(true)}
              className={`inline-flex items-center px-3 py-2 border ${
                isDarkMode 
                  ? 'border-gray-600 text-gray-300 bg-gray-700 hover:bg-gray-600'
                  : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
              } shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            >
              Modifica
            </button>
          )}
        </div>

        {isEditing ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="full_name" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Nome Completo
              </label>
              <input
                type="text"
                name="full_name"
                id="full_name"
                value={formData.full_name}
                onChange={handleInputChange}
                className={`mt-1 block w-full border ${
                  isDarkMode 
                    ? 'border-gray-600 bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500'
                    : 'border-gray-300 bg-white text-gray-900 focus:ring-blue-500 focus:border-blue-500'
                } rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm`}
              />
            </div>
            
            <div>
              <label htmlFor="email" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                className={`mt-1 block w-full border ${
                  isDarkMode 
                    ? 'border-gray-600 bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500'
                    : 'border-gray-300 bg-white text-gray-900 focus:ring-blue-500 focus:border-blue-500'
                } rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm`}
              />
            </div>
            
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => {
                  setFormData({
                    full_name: profileData.full_name || '',
                    email: profileData.email || '',
                  });
                  setIsEditing(false);
                }}
                className={`inline-flex items-center px-4 py-2 border ${
                  isDarkMode 
                    ? 'border-gray-600 text-gray-300 bg-gray-700 hover:bg-gray-600'
                    : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
                } shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
              >
                Annulla
              </button>
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Salva
              </button>
            </div>
          </form>
        ) : (
          <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
            <dt className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>Nome Completo</dt>
            <dd className={`mt-1 text-sm ${isDarkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
              {profileData.full_name || '-'}
            </dd>
          </div>
        )}

        {!isEditing && (
          <div className={`mt-1 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
            <dt className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>Email</dt>
            <dd className={`mt-1 text-sm ${isDarkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
              {profileData.email}
            </dd>
          </div>
        )}

        {!isEditing && (
          <div className={`mt-1 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
            <dt className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>Data di registrazione</dt>
            <dd className={`mt-1 text-sm ${isDarkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
              {new Date(profileData.created_at).toLocaleDateString('it-IT', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </dd>
          </div>
        )}

        {!isEditing && profileData.last_login && (
          <div className={`mt-1 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
            <dt className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>Ultimo accesso</dt>
            <dd className={`mt-1 text-sm ${isDarkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
              {new Date(profileData.last_login).toLocaleDateString('it-IT', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </dd>
          </div>
        )}

        {/* Visualizza i profili aziendali se presenti */}
        {!isEditing && profileData.business_profiles && profileData.business_profiles.length > 0 && (
          <div className={`mt-1 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
            <dt className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>Profili aziendali</dt>
            <dd className={`mt-1 text-sm ${isDarkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
              <ul className="divide-y divide-gray-200">
                {profileData.business_profiles.map(profile => (
                  <li key={profile.id} className="py-2">
                    <div className="font-medium">{profile.business_name}</div>
                    <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      {profile.business_description}
                    </div>
                    <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mt-1`}>
                      Categoria: {profile.category || 'Non specificata'}
                      {profile.subcategory && ` > ${profile.subcategory}`}
                    </div>
                  </li>
                ))}
              </ul>
            </dd>
          </div>
        )}

        {/* Visualizza informazioni sull'abbonamento se presente */}
        {!isEditing && profileData.current_subscription && (
          <div className={`mt-1 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
            <dt className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>Abbonamento</dt>
            <dd className={`mt-1 text-sm ${isDarkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
              <div className="font-medium">{profileData.current_subscription.plan_name}</div>
              <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                Stato: <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                  profileData.current_subscription.status === 'active' 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {profileData.current_subscription.status === 'active' ? 'Attivo' : 'Non attivo'}
                </span>
              </div>
              <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mt-1`}>
                Scadenza: {new Date(profileData.current_subscription.current_period_end).toLocaleDateString('it-IT')}
              </div>
              {profileData.current_subscription.cancel_at_period_end && (
                <div className={`text-sm text-red-500 mt-1`}>
                  L'abbonamento non si rinnoverà automaticamente alla scadenza
                </div>
              )}
            </dd>
          </div>
        )}
      </div>

      {/* Cambio Password */}
      <div className="pt-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className={`text-xl font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Sicurezza</h2>
          {!showPasswordForm && !isEditing && (
            <button
              type="button"
              onClick={() => setShowPasswordForm(true)}
              className={`inline-flex items-center px-3 py-2 border ${
                isDarkMode 
                  ? 'border-gray-600 text-gray-300 bg-gray-700 hover:bg-gray-600'
                  : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
              } shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            >
              Cambia Password
            </button>
          )}
        </div>

        {showPasswordForm && (
          <form onSubmit={handlePasswordSubmit} className={`space-y-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} p-4 rounded-md`}>
            <div>
              <label htmlFor="current_password" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Password Attuale
              </label>
              <input
                type="password"
                name="current_password"
                id="current_password"
                value={passwordData.current_password}
                onChange={handlePasswordInputChange}
                required
                className={`mt-1 block w-full border ${
                  isDarkMode 
                    ? 'border-gray-600 bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500'
                    : 'border-gray-300 bg-white text-gray-900 focus:ring-blue-500 focus:border-blue-500'
                } rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm`}
              />
            </div>
            
            <div>
              <label htmlFor="new_password" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Nuova Password
              </label>
              <input
                type="password"
                name="new_password"
                id="new_password"
                value={passwordData.new_password}
                onChange={handlePasswordInputChange}
                required
                minLength={8}
                className={`mt-1 block w-full border ${
                  isDarkMode 
                    ? 'border-gray-600 bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500'
                    : 'border-gray-300 bg-white text-gray-900 focus:ring-blue-500 focus:border-blue-500'
                } rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm`}
              />
              <p className={`mt-1 text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>La password deve essere di almeno 8 caratteri</p>
            </div>
            
            <div>
              <label htmlFor="confirm_password" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                Conferma Nuova Password
              </label>
              <input
                type="password"
                name="confirm_password"
                id="confirm_password"
                value={passwordData.confirm_password}
                onChange={handlePasswordInputChange}
                required
                className={`mt-1 block w-full border ${
                  isDarkMode 
                    ? 'border-gray-600 bg-gray-700 text-white focus:ring-blue-500 focus:border-blue-500'
                    : 'border-gray-300 bg-white text-gray-900 focus:ring-blue-500 focus:border-blue-500'
                } rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm`}
              />
            </div>
            
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => {
                  setPasswordData({
                    current_password: '',
                    new_password: '',
                    confirm_password: '',
                  });
                  setShowPasswordForm(false);
                }}
                className={`inline-flex items-center px-4 py-2 border ${
                  isDarkMode 
                    ? 'border-gray-600 text-gray-300 bg-gray-700 hover:bg-gray-600'
                    : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
                } shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
              >
                Annulla
              </button>
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Aggiorna Password
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ProfileSection; 