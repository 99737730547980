import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { getSessionState, getSessionHistory } from '../services/agentService';
import axios from 'axios';

const TrainingContext = createContext();

export const TrainingProvider = ({ children }) => {
  const [currentSession, setCurrentSession] = useState(null);
  const [sessionHistory, setSessionHistory] = useState([]);
  const [trainingProgress, setTrainingProgress] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sessionIdLoaded, setSessionIdLoaded] = useState(null);
  const [historyLoadAttempted, setHistoryLoadAttempted] = useState(false);
  
  // Aggiungiamo uno stato per tenere traccia delle sessioni accessibili
  const [knownSessions, setKnownSessions] = useState(() => {
    // Tenta di recuperare sessioni note dal localStorage
    const saved = localStorage.getItem('known_sessions');
    try {
      return saved ? JSON.parse(saved) : [];
    } catch (e) {
      console.error('Errore nel parsing delle sessioni salvate:', e);
      return [];
    }
  });

  // Funzione per caricare lo stato della sessione
  const loadSessionState = useCallback(async (sessionId) => {
    if (!sessionId) {
      setError("ID sessione non valido");
      return;
    }
    
    // Evita chiamate duplicate per lo stesso sessionId
    if (isLoading || (sessionIdLoaded === sessionId && currentSession)) {
      console.log(`Caricamento sessione ${sessionId} già in corso o completato, salto la richiesta`);
      return;
    }
    
    setIsLoading(true);
    try {
      console.log(`TrainingContext: caricamento stato sessione ${sessionId}`);
      const sessionState = await getSessionState(sessionId);
      console.log('TrainingContext: stato sessione caricato', sessionState);
      
      // Normalizza i dati per assicurarsi che tutti i campi necessari siano presenti
      const normalizedState = {
        ...sessionState,
        // Assicura che messages sia sempre un array
        messages: Array.isArray(sessionState.messages) ? sessionState.messages : [],
        // Assicura che altri campi che potrebbero essere undefined siano inizializzati
        agent_type: sessionState.agent_type || 'coach',
        level: sessionState.level || 'base',
        status: sessionState.status || 'active'
      };
      
      setCurrentSession(normalizedState);
      setSessionIdLoaded(sessionId); // Salva l'ID della sessione caricata
      setError(null);
    } catch (err) {
      console.error('TrainingContext: errore nel caricamento della sessione', err);
      setError(err.message || "Errore nel caricamento della sessione");
      
      // Resetta la sessione corrente in caso di errore
      setCurrentSession(null);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, sessionIdLoaded, currentSession]);

  // Funzione per aggiornare il progresso formativo
  const updateTrainingProgress = (progressData) => {
    setTrainingProgress(current => ({
      ...current,
      ...progressData
    }));
  };

  // Funzione per caricare lo storico delle sessioni
  const loadSessionHistory = useCallback(async (userId) => {
    // Evitiamo di eseguire più tentativi se stiamo già caricando
    if (isLoading) {
      console.log('TrainingContext: caricamento già in corso, ignoro la richiesta');
      return;
    }
    
    // In ambiente di sviluppo, limitiamo ad un solo tentativo
    if (historyLoadAttempted && (process.env.NODE_ENV === 'development' || window.location.hostname.includes('localhost'))) {
      console.log('TrainingContext: già tentato in precedenza, uso dati simulati');
      if (sessionHistory.length === 0) {
        const mockData = [
          { 
            id: 1, 
            agent_type: 'coach', 
            created_at: new Date().toISOString(),
            status: 'active',
            metadata: { agent_name: 'Coach Formativo' }
          },
          { 
            id: 2, 
            agent_type: 'simulator', 
            created_at: new Date().toISOString(),
            status: 'active',
            metadata: { agent_name: 'Simulatore Cliente' }
          }
        ];
        setSessionHistory(mockData);
      }
      return;
    }
    
    setHistoryLoadAttempted(true);
    setIsLoading(true);
    try {
      console.log('TrainingContext: caricamento storico sessioni per utente', userId || 'corrente');
      
      // Ottieni il token direttamente per controllare la validità dell'autenticazione
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('TrainingContext: token di autenticazione mancante');
        setSessionHistory([]);
        setError('Token di autenticazione mancante');
        return;
      }
      
      // Utilizziamo direttamente il servizio, che ora ha una gestione migliorata per i percorsi
      try {
        console.log('TrainingContext: tentativo con getSessionHistory');
        const history = await getSessionHistory(userId);
        
        if (Array.isArray(history)) {
          console.log('TrainingContext: storico sessioni caricato con successo', history.length, 'sessioni');
          setSessionHistory(history);
          setError(null);
        } else {
          console.error('TrainingContext: risposta non è un array', history);
          throw new Error('Formato risposta non valido');
        }
      } catch (err) {
        console.error('TrainingContext: errore nel caricamento dello storico', err);
        
        // Dati simulati in caso di errore in ambiente di sviluppo
        if (process.env.NODE_ENV === 'development' || window.location.hostname.includes('localhost')) {
          console.log('TrainingContext: utilizzo dati simulati in ambiente di sviluppo');
          const mockData = [
            { 
              id: 1, 
              agent_type: 'coach', 
              created_at: new Date().toISOString(),
              status: 'active',
              metadata: { agent_name: 'Coach Formativo' }
            },
            { 
              id: 2, 
              agent_type: 'simulator', 
              created_at: new Date().toISOString(),
              status: 'active',
              metadata: { agent_name: 'Simulatore Cliente' }
            }
          ];
          setSessionHistory(mockData);
          setError(null);
        } else {
          setError(err.message || 'Errore nel caricamento dello storico sessioni');
          setSessionHistory([]);
        }
      }
    } catch (err) {
      console.error('TrainingContext: errore globale nel caricamento dello storico', err);
      setError(err.message || 'Errore nel caricamento dello storico sessioni');
      setSessionHistory([]);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, historyLoadAttempted]);

  // Dopo aver caricato una sessione, la aggiungiamo alle sessioni note
  useEffect(() => {
    if (currentSession && currentSession.id) {
      console.log('TrainingContext: aggiungo sessione alle note', currentSession.id);
      
      // Verifica se la sessione è già nelle sessioni note
      setKnownSessions(prev => {
        const exists = prev.some(s => s.id === currentSession.id);
        if (!exists) {
          // Crea una versione semplificata della sessione per l'elenco
          const sessionData = {
            id: currentSession.id,
            agent_type: currentSession.agent_type,
            created_at: currentSession.created_at || new Date().toISOString(),
            status: currentSession.status || 'active',
            metadata: {
              agent_name: currentSession.agent_name || currentSession.agent_type
            }
          };
          
          // Salva l'array aggiornato in localStorage
          const updated = [sessionData, ...prev.filter(s => s.id !== currentSession.id)];
          localStorage.setItem('known_sessions', JSON.stringify(updated));
          
          return updated;
        }
        
        return prev;
      });
    }
  }, [currentSession]);

  // Aggiungiamo un effect per combinare le sessioni note con quelle dal backend
  useEffect(() => {
    // Se il backend non ha restituito sessioni, ma abbiamo sessioni note, le usiamo
    if (sessionHistory.length === 0 && knownSessions.length > 0) {
      console.log('TrainingContext: uso sessioni note', knownSessions.length);
      setSessionHistory(knownSessions);
    } else if (sessionHistory.length > 0) {
      // Combina e deduplicizza le sessioni note con quelle dal backend
      console.log('TrainingContext: combino sessioni', sessionHistory.length, knownSessions.length);
      
      const combinedSessions = [...sessionHistory];
      let changed = false;
      
      // Aggiungi le sessioni note che non sono in sessionHistory
      knownSessions.forEach(knownSession => {
        if (!combinedSessions.some(s => s.id === knownSession.id)) {
          combinedSessions.push(knownSession);
          changed = true;
        }
      });
      
      // Aggiorna solo se abbiamo effettivamente aggiunto sessioni
      if (changed) {
        setSessionHistory(combinedSessions);
      }
    }
  }, [sessionHistory, knownSessions]);

  const contextValue = {
    currentSession,
    sessionHistory,
    trainingProgress,
    isLoading,
    error,
    loadSessionState,
    updateTrainingProgress,
    loadSessionHistory
  };

  return (
    <TrainingContext.Provider value={contextValue}>
      {children}
    </TrainingContext.Provider>
  );
};

export const useTraining = () => useContext(TrainingContext); 