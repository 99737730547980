import axios from 'axios';

// Definisci una base URL specifica in modo più robusto
const getBaseUrl = () => {
  // Ottieni l'hostname corrente
  const hostname = window.location.hostname;
  
  // In produzione usa sempre HTTPS esplicitamente
  if (hostname === 'finetuningmax.com' || hostname.includes('finetuningmax')) {
    const baseUrl = `https://${hostname}/api`;
    console.log("Ambiente produzione - Base URL impostata a:", baseUrl);
    return baseUrl;
  }
  
  // In sviluppo locale usa il percorso relativo
  console.log("Ambiente sviluppo - Base URL impostata a: /api");
  return '/api';
};

const API_BASE_URL = getBaseUrl();
console.log("API_BASE_URL configurata definitivamente come:", API_BASE_URL);

// IMPORTANTE: Crea una funzione per ottenere il token aggiornato
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Creazione di un'istanza axios configurata con autenticazione
const createApiClient = () => {
  // Log per debug - verifica l'URL base ogni volta che viene creato un client
  console.log("createApiClient - Utilizzo BASE_URL:", API_BASE_URL);
  
  const instance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeader()
    }
  });

  // Aggiungi un interceptor per loggare tutte le richieste
  instance.interceptors.request.use(config => {
    // Costruzione corretta dell'URL completo senza compromettere il protocollo
    let fullUrl = '';
    if (config.baseURL && config.url) {
      // Assicurati che il protocollo rimanga intatto
      if (config.baseURL.startsWith('http')) {
        // Per URL assoluti, concatena attentamente
        const baseEndWithSlash = config.baseURL.endsWith('/');
        const urlStartWithSlash = config.url.startsWith('/');
        
        if (baseEndWithSlash && urlStartWithSlash) {
          // Evita doppi slash rimuovendo quello all'inizio dell'URL
          fullUrl = `${config.baseURL}${config.url.substring(1)}`;
        } else if (!baseEndWithSlash && !urlStartWithSlash) {
          // Aggiungi uno slash se manca in entrambi
          fullUrl = `${config.baseURL}/${config.url}`;
        } else {
          // Caso normale: uno dei due ha già lo slash
          fullUrl = `${config.baseURL}${config.url}`;
        }
      } else {
        // Per URL relativi, concatena normalmente
        fullUrl = `${config.baseURL}${config.url}`;
      }
    } else {
      fullUrl = config.url || config.baseURL || '';
    }
    
    console.log(`Invio richiesta API: ${config.method.toUpperCase()} ${fullUrl}`);
    console.log("Headers:", JSON.stringify(config.headers));
    
    // Assicurati che l'URL completo contenga il protocollo https:// per le richieste in produzione
    if (fullUrl.includes('finetuningmax.com') && !fullUrl.startsWith('https://')) {
      console.error("ERRORE: URL non sicuro rilevato:", fullUrl);
      // Modifica l'URL per forzare HTTPS
      config.url = config.url.replace('http://', 'https://');
      config.baseURL = config.baseURL.replace('http://', 'https://');
      
      // Log aggiuntivo dopo la correzione
      console.log("URL corretto a:", `${config.baseURL}${config.url}`);
    }
    
    return config;
  });

  return instance;
};

// Funzione speciale per gestire gli URL di training che non hanno il prefisso /api
const getTrainingUrl = (path) => {
  const hostname = window.location.hostname;
  
  // Prima puliamo il path per evitare duplicazioni di /api/
  // Se il path contiene già /api/ in posizioni che potrebbero generare duplicati
  const cleanPath = path.replace(/\/api\/api\//, '/api/');
  
  // In produzione
  if (hostname === 'finetuningmax.com' || hostname.includes('finetuningmax')) {
    // Aggiungiamo il prefisso /api se non è già presente
    const apiPath = cleanPath.startsWith('/api/') ? cleanPath : `/api${cleanPath}`;
    return `https://${hostname}${apiPath}`;
  }
  
  // In sviluppo locale, assicurati che il percorso abbia il prefisso /api
  return cleanPath.startsWith('/api/') ? cleanPath : `/api${cleanPath}`;
};

// Funzione per costruire URL per API di training senza passare per getTrainingUrl
const getTrainingUrlDirect = (path) => {
  const hostname = window.location.hostname;
  
  // In produzione
  if (hostname === 'finetuningmax.com' || hostname.includes('finetuningmax')) {
    return `https://${hostname}${path}`;
  }
  
  // In sviluppo locale, assicurati che il percorso sia corretto
  return path;
};

// Recupera i tipi di agente disponibili
export const getAgentTypes = async () => {
  try {
    // Correggiamo il percorso usando il prefisso /api
    const fullUrl = getTrainingUrl('/api/training/agents/sessions');
    console.log("Chiamata API getAgentTypes a:", fullUrl);
    
    const response = await axios.get(fullUrl, {
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      }
    });
    
    console.log("Risposta da getAgentTypes:", response.data);
    
    // Trasforma i dati in un array nel formato atteso da AgentSelector
    // Se response.data è già un array, lo usiamo, altrimenti usiamo il fallback
    const agentTypes = Array.isArray(response.data) ? 
      response.data.map(agent => ({
        id: agent.agent_type || agent.id || 'unknown',
        name: agent.agent_name || (agent.agent_type ? 
          agent.agent_type.charAt(0).toUpperCase() + agent.agent_type.slice(1) : 'Agente'),
        description: agent.description || `Un agente di tipo ${agent.agent_type || 'sconosciuto'}`
      })) : 
      // Fallback con valori predefiniti
      [
        { 
          id: 'coach', 
          name: 'Coach Formativo', 
          description: 'Un coach che fornisce consigli, formazione e feedback sulle tecniche di vendita.' 
        },
        { 
          id: 'simulator', 
          name: 'Simulatore Cliente', 
          description: 'Simula diversi tipi di clienti per far praticare le tecniche di vendita in scenari realistici.' 
        },
        { 
          id: 'analyzer', 
          name: 'Analizzatore Performance', 
          description: 'Analizza le performance e fornisce feedback dettagliati sulle aree di miglioramento.' 
        }
      ];
    
    // Assicuriamoci che ci siano valori univoci
    const uniqueAgentTypes = Array.from(new Set(agentTypes.map(a => a.id)))
      .map(id => agentTypes.find(a => a.id === id));
    
    console.log("AgentTypes trasformati:", uniqueAgentTypes);
    return uniqueAgentTypes;
  } catch (error) {
    console.error("Errore nella chiamata API /training/agents/sessions:", error);
    // Fallback in caso di errore: restituisci tipi di agenti predefiniti
    return [
      { 
        id: 'coach', 
        name: 'Coach Formativo', 
        description: 'Un coach che fornisce consigli, formazione e feedback sulle tecniche di vendita.' 
      },
      { 
        id: 'simulator', 
        name: 'Simulatore Cliente', 
        description: 'Simula diversi tipi di clienti per far praticare le tecniche di vendita in scenari realistici.' 
      },
      { 
        id: 'analyzer', 
        name: 'Analizzatore Performance', 
        description: 'Analizza le performance e fornisce feedback dettagliati sulle aree di miglioramento.' 
      }
    ];
  }
};

// Crea un nuovo agente
export const createAgent = async (agentConfig) => {
  try {
    // Log dettagliato sulla configurazione dell'agente che stiamo inviando
    console.log("Configurazione agente da creare:", agentConfig);
    
    // Standardizza il formato della richiesta in base alla documentazione del backend
    const standardizedConfig = {
      agent_type: agentConfig.type || agentConfig.agent_type,
      domain: agentConfig.domain || "vendita", // default
      level: agentConfig.level || "base", // default
      client_id: agentConfig.client_id || 1, // default
      extra_params: agentConfig.extra_params || {}
    };
    
    console.log("Configurazione standardizzata:", standardizedConfig);
    
    // Correggiamo il percorso usando il prefisso /api
    const fullUrl = getTrainingUrl('/api/training/agents/create');
    console.log("Chiamata API createAgent a:", fullUrl);
    
    // Tentativo con POST standard
    try {
      const response = await axios.post(fullUrl, standardizedConfig, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...getAuthHeader(),
          // Aggiungi header CORS espliciti
          'Access-Control-Allow-Origin': window.location.origin,
          'Access-Control-Allow-Methods': 'POST, OPTIONS'
        }
      });
      
      console.log("Risposta createAgent:", response.data);
      return response.data;
    } catch (firstError) {
      console.error("Primo tentativo fallito:", firstError);
      
      if (firstError.response && firstError.response.status === 405) {
        console.log("Metodo non consentito, provo con GET come fallback...");
        
        // Fallback: Alcuni endpoint potrebbero richiedere GET invece di POST
        const getResponse = await axios.get(fullUrl, { 
          params: standardizedConfig,
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...getAuthHeader()
          }
        });
        
        console.log("Risposta fallback GET:", getResponse.data);
        return getResponse.data;
      }
      
      // Ripropaga l'errore se il fallback non è possibile o fallisce
      throw firstError;
    }
  } catch (error) {
    console.error("Errore nella creazione dell'agente:", error);
    
    // Log dettagliato per diagnosi
    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Headers:", error.response.headers);
      console.error("Data:", error.response.data);
    }
    
    // Mock response per debugging e sviluppo
    if (process.env.NODE_ENV === 'development' || window.location.hostname.includes('localhost')) {
      console.log("Fornisco risposta mock per ambiente di sviluppo");
      return {
        status: "success",
        message: "Agente creato con successo (mock)",
        training_session_id: Math.floor(Math.random() * 1000) + 1,
        thread_id: "thread_mock_" + Date.now()
      };
    }
    
    throw new Error(error.response?.data?.detail || 'Errore nella creazione agente');
  }
};

// Invia un messaggio all'agente
export const sendMessageToAgent = async (sessionId, message) => {
  try {
    console.log(`Tentativo di inviare messaggio alla sessione ${sessionId}: "${message}"`);
    
    // Prima verifichiamo se dobbiamo recuperare l'agente
    let needToRecover = false;
    
    try {
      // Primo tentativo - invio diretto del messaggio
      const fullUrl = getTrainingUrl('/api/training/agents/send-message');
      console.log("Chiamata API sendMessageToAgent a:", fullUrl);
      
      const response = await axios.post(fullUrl, { 
        training_session_id: sessionId,
        message 
      }, {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader()
        }
      });
      
      console.log("Risposta invio messaggio:", response.data);
      return response.data;
    } catch (error) {
      // Controlla per errore specifico di previous_response_id
      if (error.response && 
          error.response.status === 400 && 
          error.response.data?.error?.code === 'previous_response_not_found') {
        console.log("Errore di previous_response_id non trovato, tentativo di recupero con reset...");
        needToRecover = true;
      } 
      // Se riceviamo un 404, significa che l'agente non è attivo e dobbiamo recuperarlo
      else if (error.response && error.response.status === 404) {
        console.log("Agente non trovato, tentativo di recupero...");
        needToRecover = true;
      } else {
        // Altri errori, rilanciamo l'eccezione
        throw error;
      }
    }
    
    if (needToRecover) {
      // Recupera le informazioni sulla sessione
      const sessionUrl = getTrainingUrl(`/api/training/sessions/${sessionId}/state`);
      console.log("Recupero informazioni sessione:", sessionUrl);
      
      const sessionResponse = await axios.get(sessionUrl, {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader()
        }
      });
      
      const sessionData = sessionResponse.data;
      console.log("Informazioni sessione recuperate:", sessionData);
      
      // Recupera l'agente con forced_reset=true per creare un nuovo response_id
      const recoverUrl = getTrainingUrl('/api/training/agents/recover');
      console.log("Tentativo di recupero agente con reset:", recoverUrl);
      
      const recoverResponse = await axios.post(recoverUrl, {
        agent_type: sessionData.agent_type || 'coach',
        domain: sessionData.domain || 'vendita',
        level: sessionData.level || 'base',
        client_id: 1, // Valore di default
        extra_params: {
          session_id: sessionId,
          forced_reset: true // Forza un reset per creare un nuovo response_id
        }
      }, {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader()
        }
      });
      
      console.log("Agente recuperato:", recoverResponse.data);
      
      // Ora proviamo a inviare il messaggio di nuovo
      const retryUrl = getTrainingUrl('/api/training/agents/send-message');
      console.log("Secondo tentativo invio messaggio:", retryUrl);
      
      const retryResponse = await axios.post(retryUrl, { 
        training_session_id: sessionId,
        message 
      }, {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader()
        }
      });
      
      console.log("Risposta secondo tentativo:", retryResponse.data);
      return retryResponse.data;
    }
  } catch (error) {
    console.error("Errore nell'invio del messaggio:", error);
    throw new Error(error.response?.data?.detail || error.message || 'Errore nell\'invio messaggio');
  }
};

// Recupera lo stato della sessione
export const getSessionState = async (sessionId) => {
  try {
    // Correggiamo il percorso usando il prefisso /api
    const fullUrl = getTrainingUrl(`/api/training/sessions/${sessionId}/state`);
    console.log("Chiamata API getSessionState a:", fullUrl);
    
    const response = await axios.get(fullUrl, {
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      }
    });
    
    // Normalizza qui la risposta per evitare errori di rendering
    const sessionData = response.data;
    
    // Assicura che tutti i campi siano nel formato corretto
    const normalizedData = {
      ...sessionData,
      messages: Array.isArray(sessionData.messages) ? sessionData.messages : [],
      agent_type: sessionData.agent_type || 'coach',
      level: sessionData.level || 'base',
      status: sessionData.status || 'active'
    };
    
    return normalizedData;
  } catch (error) {
    console.error("Errore nel recupero dello stato della sessione:", error);
    throw new Error(error.response?.data?.detail || 'Errore nel recupero stato sessione');
  }
};

// Richiede un'analisi specifica
export const requestAnalysis = async (sessionId, analysisType, data) => {
  try {
    // Correggiamo il percorso usando il prefisso /api
    const fullUrl = getTrainingUrl('/api/training/agents/evaluate-script');
    console.log("Chiamata API requestAnalysis a:", fullUrl);
    
    const response = await axios.post(fullUrl, {
      training_session_id: sessionId,
      script_text: data.text || "",
      script_title: analysisType
    }, {
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      }
    });
    
    return response.data;
  } catch (error) {
    console.error("Errore nella richiesta di analisi:", error);
    throw new Error(error.response?.data?.detail || 'Errore nella richiesta di analisi');
  }
};

// Richiede una valutazione del progresso
export const evaluateProgress = async (sessionId) => {
  try {
    // Correggiamo il percorso usando il prefisso /api
    const fullUrl = getTrainingUrl('/api/training/agents/update-progress');
    console.log("Chiamata API evaluateProgress a:", fullUrl);
    
    const response = await axios.post(fullUrl, {
      training_session_id: sessionId,
      skill_area: "overall",
      score: 0,
      feedback: "Auto-evaluazione richiesta"
    }, {
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      }
    });
    
    return response.data;
  } catch (error) {
    console.error("Errore nella valutazione del progresso:", error);
    throw new Error(error.response?.data?.detail || 'Errore nella valutazione progresso');
  }
};

// Recupera lo storico delle sessioni
export const getSessionHistory = async (userId, options = {}) => {
  // Limitiamo a un solo percorso per evitare loop infiniti
  const path = '/api/training/sessions';
  
  try {
    console.log(`getSessionHistory: Tentativo di recupero da ${path}`);
    
    // Ottieni il token di autenticazione direttamente
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.error("getSessionHistory: Token di autenticazione mancante");
      return []; // Restituisci un array vuoto se non c'è token
    }
    
    // Chiamata API semplificata
    const response = await axios.get(path, { 
      params: { ...options },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Cache-Control': 'no-cache'
      }
    });
    
    console.log(`getSessionHistory: Risposta da ${path}:`, response.status);
    
    // Verifica che la risposta sia un array
    if (Array.isArray(response.data)) {
      console.log(`getSessionHistory: Ricevuto array di ${response.data.length} elementi`);
      return response.data;
    } 
    
    // Se la risposta è un oggetto con una proprietà che contiene l'array
    if (response.data && typeof response.data === 'object') {
      // Possibili proprietà che potrebbero contenere l'array
      const possibleArrayProps = ['data', 'sessions', 'results', 'items', 'content'];
      
      for (const prop of possibleArrayProps) {
        if (Array.isArray(response.data[prop])) {
          console.log(`getSessionHistory: Trovato array in ${prop} con ${response.data[prop].length} elementi`);
          return response.data[prop];
        }
      }
    }
    
    // Dati simulati solo in ambiente di sviluppo
    if (process.env.NODE_ENV === 'development' || window.location.hostname.includes('localhost')) {
      console.log("getSessionHistory: Ambiente di sviluppo, restituisco dati di fallback");
      return [
        { id: 1, agent_type: 'coach', created_at: new Date().toISOString() },
        { id: 2, agent_type: 'simulator', created_at: new Date().toISOString() }
      ];
    }
    
    return []; // Restituisci un array vuoto come fallback
  } catch (error) {
    console.error("getSessionHistory: Errore", error.message);
    
    // Dati simulati in ambiente di sviluppo
    if (process.env.NODE_ENV === 'development' || window.location.hostname.includes('localhost')) {
      console.log("getSessionHistory: Restituisco dati simulati dopo errore");
      return [
        { id: 1, agent_type: 'coach', created_at: new Date().toISOString() },
        { id: 2, agent_type: 'simulator', created_at: new Date().toISOString() }
      ];
    }
    
    return []; // Restituisci un array vuoto in caso di errore
  }
};

// Recupera un agente esistente
export const recoverAgent = async (agentConfig) => {
  try {
    console.log("Configurazione recupero agente:", agentConfig);
    
    const standardizedConfig = {
      agent_type: agentConfig.type || agentConfig.agent_type,
      domain: agentConfig.domain || "vendita",
      level: agentConfig.level || "base",
      client_id: agentConfig.client_id || 1,
      extra_params: agentConfig.extra_params || {}
    };
    
    console.log("Configurazione standardizzata per recupero:", standardizedConfig);
    
    const fullUrl = getTrainingUrl('/api/training/agents/recover');
    console.log("Chiamata API recoverAgent a:", fullUrl);
    
    try {
      const response = await axios.post(fullUrl, standardizedConfig, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...getAuthHeader()
        }
      });
      
      console.log("Risposta recupero agente:", response.data);
      return response.data;
    } catch (recoverError) {
      // Se l'errore è un 404, significa che l'endpoint non esiste o non è disponibile
      // invece di generare un errore, procediamo con la creazione di un nuovo agente
      if (recoverError.response && recoverError.response.status === 404) {
        console.log("Endpoint di recupero non disponibile o agente non esistente, procedo con la creazione");
        return createAgent(agentConfig);
      }
      
      throw recoverError;
    }
  } catch (error) {
    // Se siamo in ambiente di sviluppo, logghiamo l'errore ma in maniera meno invasiva
    if (process.env.NODE_ENV === 'development' || window.location.hostname.includes('localhost')) {
      console.log("Nota: problema nel recupero dell'agente, tento di creare un nuovo agente");
    } else {
      console.error("Errore nel recupero dell'agente:", error);
    }
    
    // Tentiamo comunque di creare un nuovo agente
    try {
      return await createAgent(agentConfig);
    } catch (createError) {
      console.error("Errore anche nella creazione dell'agente:", createError);
      throw createError;
    }
  }
};

// Resetta la sessione - cancella i messaggi ma mantiene la sessione
export const resetSessionMessages = async (sessionId) => {
  try {
    // Approccio 1: Ottieni lo stato attuale e reimpostalo con messaggi vuoti
    const fullUrl = getTrainingUrl(`/api/training/sessions/${sessionId}/state`);
    console.log("Chiamata API getSessionState per reset a:", fullUrl);
    
    // Prima prendiamo lo stato attuale
    const currentState = await getSessionState(sessionId);
    
    // Poi creiamo un nuovo stato con messaggi vuoti mantenendo tutti gli altri dati
    const resetState = {
      ...currentState,
      messages: []
    };
    
    // Possiamo anche tentare di chiamare direttamente l'endpoint update se esiste
    // Il codice seguente è commentato perché potrebbe non esserci l'endpoint
    /*
    const updateUrl = getTrainingUrl(`/api/training/sessions/${sessionId}/update`);
    const response = await axios.put(updateUrl, {
      metadata: {
        ...currentState.metadata,
        reset_timestamp: new Date().toISOString()
      }
    }, {
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      }
    });
    */
    
    // Per ora, restituiamo semplicemente lo stato resettato
    // e lasciamo che il frontend si occupi di reimpostare i messaggi
    return {
      status: "success",
      message: "Sessione resettata correttamente",
      reset_state: resetState
    };
  } catch (error) {
    console.error("Errore nel reset della sessione:", error);
    throw new Error(error.response?.data?.detail || 'Errore nel reset della sessione');
  }
};

// Elimina completamente una sessione
export const deleteSession = async (sessionId) => {
  try {
    // Prepariamo l'URL corretto
    const hostname = window.location.hostname;
    const isProduction = hostname === 'finetuningmax.com' || hostname.includes('finetuningmax');
    const baseUrl = isProduction ? `https://${hostname}` : '';
    const deleteUrl = `${baseUrl}/api/training/sessions/${sessionId}`;
    
    // Ottieni il token di autenticazione direttamente
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.error("Token di autenticazione mancante");
      throw new Error("Autenticazione richiesta per eliminare la sessione");
    }
    
    console.log("Chiamata API deleteSession a:", deleteUrl);
    console.log("Token di autenticazione presente:", !!token);
    
    // Prima di chiamare l'API, rimuoviamo la sessione dal localStorage
    try {
      const savedSessions = localStorage.getItem('known_sessions');
      if (savedSessions) {
        const parsedSessions = JSON.parse(savedSessions);
        const filteredSessions = parsedSessions.filter(s => s.id !== parseInt(sessionId));
        localStorage.setItem('known_sessions', JSON.stringify(filteredSessions));
        console.log("Sessione rimossa dal localStorage:", sessionId);
      }
    } catch (localStorageError) {
      console.error("Errore nella rimozione della sessione dal localStorage:", localStorageError);
      // Continuiamo comunque con la chiamata API
    }
    
    const response = await axios.delete(deleteUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    
    console.log("Risposta eliminazione:", response);
    
    return {
      status: response.status === 200 || response.status === 204 ? "success" : "error",
      message: "Sessione eliminata con successo"
    };
  } catch (error) {
    console.error("Errore nell'eliminazione della sessione:", error);
    
    // Log dettagliato per diagnosi
    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Headers:", error.response.headers);
      console.error("Data:", error.response.data);
    }
    
    // Se siamo in ambiente di sviluppo e l'API fallisce, simuliamo successo e rimuoviamo solo dal localStorage
    if (process.env.NODE_ENV === 'development' || window.location.hostname.includes('localhost')) {
      console.log("Ambiente di sviluppo: simulo successo eliminazione");
      
      try {
        const savedSessions = localStorage.getItem('known_sessions');
        if (savedSessions) {
          const parsedSessions = JSON.parse(savedSessions);
          const filteredSessions = parsedSessions.filter(s => s.id !== parseInt(sessionId));
          localStorage.setItem('known_sessions', JSON.stringify(filteredSessions));
          console.log("Sessione rimossa dal localStorage (fallback):", sessionId);
        }
      } catch (localStorageError) {
        console.error("Errore nella rimozione di fallback:", localStorageError);
      }
      
      return {
        status: "success",
        message: "Sessione eliminata con successo (locale)"
      };
    }
    
    throw new Error(error.response?.data?.detail || 'Errore nell\'eliminazione della sessione');
  }
}; 