import React, { useState, useEffect } from 'react';
import { PencilSquareIcon, TrashIcon, SparklesIcon, XMarkIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';

// Componente ActionMenu riutilizzabile
const ActionMenu = ({ onEdit, onDelete, onAIFix, onCancel, isDarkMode, position = 'bottom' }) => (
  <div className={`absolute z-50 w-48 rounded-md shadow-lg ${
    position === 'top' 
      ? 'bottom-full right-0 mb-2' 
      : position === 'bottom-shifted'
        ? 'top-0 left-0 ml-[-200px]' 
        : 'top-0 right-0 mt-2'
  } ${
    isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
  }`}>
    <div className="py-1">
      <button
        onClick={onEdit}
        className={`flex items-center w-full px-4 py-2 text-sm ${
          isDarkMode 
            ? 'text-gray-200 hover:bg-gray-700' 
            : 'text-gray-700 hover:bg-gray-50'
        }`}
      >
        <PencilSquareIcon className="h-4 w-4 mr-2" />
        Modifica
      </button>
      
      <button
        onClick={onAIFix}
        className={`flex items-center w-full px-4 py-2 text-sm ${
          isDarkMode 
            ? 'text-purple-300 hover:bg-gray-700' 
            : 'text-purple-600 hover:bg-gray-50'
        }`}
      >
        <SparklesIcon className="h-4 w-4 mr-2" />
        Fix AI
      </button>
      
      <button
        onClick={onDelete}
        className={`flex items-center w-full px-4 py-2 text-sm ${
          isDarkMode 
            ? 'text-red-300 hover:bg-gray-700' 
            : 'text-red-600 hover:bg-gray-50'
        }`}
      >
        <TrashIcon className="h-4 w-4 mr-2" />
        Elimina
      </button>

      <button
        onClick={onCancel}
        className={`flex items-center w-full px-4 py-2 text-sm border-t ${
          isDarkMode 
            ? 'text-gray-300 hover:bg-gray-700 border-gray-700' 
            : 'text-gray-500 hover:bg-gray-50 border-gray-200'
        }`}
      >
        <XMarkIcon className="h-4 w-4 mr-2" />
        Chiudi
      </button>
    </div>
  </div>
);

const DatasetEditor = ({ dataset = [], onUpdateQA, onDeleteQA, onAIFixQA, fixingRecordId, isDarkMode }) => {
  // Stato per tenere traccia di quale elemento è in fase di modifica
  const [editingId, setEditingId] = useState(null);
  
  // Stato per memorizzare i dati modificati temporaneamente
  const [editFormData, setEditFormData] = useState({
    domanda: '',
    risposta: '',
    qa_type: '',
    quality_score: 0
  });
  
  // Stato per la ricerca e il filtro
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  
  // Stato per il menu delle azioni
  const [activeMenuId, setActiveMenuId] = useState(null);
  const [menuPosition, setMenuPosition] = useState('bottom');
  
  // Stato per il dataset con ID generati
  const [datasetWithIds, setDatasetWithIds] = useState([]);
  
  // Genera ID per ciascun elemento al caricamento del dataset
  useEffect(() => {
    if (Array.isArray(dataset) && dataset.length > 0) {
      // Genera ID per ciascun elemento se non ne ha già uno
      const newDataset = dataset.map((item, index) => {
        // Usa il campo id esistente o genera un nuovo id basato sull'indice
        return { ...item, clientId: item.id || `temp-id-${index}` };
      });
      
      console.log("Dataset con ID generati:", newDataset);
      setDatasetWithIds(newDataset);
    }
  }, [dataset]);

  // Debug per verificare lo stato
  useEffect(() => {
    console.log("Stato attuale:", { 
      editingId, 
      activeMenuId, 
      datasetLength: datasetWithIds?.length || 0 
    });
  }, [editingId, activeMenuId, datasetWithIds]);

  // Verifica che dataset sia un array
  if (!Array.isArray(datasetWithIds)) {
    return <div className="p-4 text-red-500">Errore: dataset non valido</div>;
  }

  // Filtra il dataset in base ai criteri di ricerca e tipo
  const filteredDataset = datasetWithIds.filter(qa => {
    const matchesSearch = qa.domanda?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         qa.risposta?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = filterType === 'all' || qa.qa_type === filterType;
    return matchesSearch && matchesType;
  });

  // Gestisce l'apertura del menu delle azioni
  const handleToggleMenu = (qaId, index, totalItems) => {
    // Determina la posizione del menu (sopra o sotto)
    const isLastRows = index >= totalItems - 3;
    const pos = isLastRows ? 'top' : 'bottom';
    setMenuPosition(pos);
    
    // Attiva/disattiva il menu
    console.log("Toggle menu for ID:", qaId);
    setActiveMenuId(prevId => prevId === qaId ? null : qaId);
  };

  // Attiva la modalità di modifica per un elemento
  const handleEdit = (qa) => {
    console.log("Editing QA:", qa);
    const qaId = qa.clientId;
    
    if (!qaId) {
      console.error("ID mancante nell'elemento da modificare:", qa);
      alert("Errore: impossibile modificare l'elemento - ID mancante");
      return;
    }
    
    setEditingId(qaId);
    setEditFormData({
      domanda: qa.domanda || '',
      risposta: qa.risposta || '',
      qa_type: qa.qa_type || '',
      quality_score: qa.quality_score || 0,
      // Mantieni l'ID originale se esiste, altrimenti usa clientId
      originalIndex: qa.originalIndex || qa.index || null
    });
    setActiveMenuId(null); // Chiude il menu
  };

  // Gestisce i cambiamenti nei campi del form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Salva le modifiche
  const handleSave = async () => {
    try {
      console.log("Saving edit for ID:", editingId, "with data:", editFormData);
      
      // Validazione
      if (!editFormData.domanda || editFormData.domanda.trim().length < 5) {
        alert('La domanda deve contenere almeno 5 caratteri');
        return;
      }
      if (!editFormData.risposta || editFormData.risposta.trim().length < 10) {
        alert('La risposta deve contenere almeno 10 caratteri');
        return;
      }
      
      if (!editingId) {
        console.error("ID mancante durante il salvataggio");
        alert("Errore: ID mancante");
        return;
      }
      
      // Trova l'indice originale dell'elemento nel dataset
      const editedQA = datasetWithIds.find(qa => qa.clientId === editingId);
      if (!editedQA) {
        console.error("Elemento non trovato nel dataset");
        alert("Errore: elemento non trovato");
        return;
      }
      
      // Indice per l'API: usa l'indice originale o quello corrente
      const apiIndex = editedQA.originalIndex || datasetWithIds.indexOf(editedQA);
      
      console.log("Aggiornamento con indice:", apiIndex);
      
      // Chiama la funzione di aggiornamento con l'ID/indice
      await onUpdateQA(apiIndex, editFormData);
      
      // Aggiorna il dataset locale senza aspettare la risposta dell'API
      setDatasetWithIds(prevDataset => 
        prevDataset.map(qa => 
          qa.clientId === editingId 
            ? { ...qa, ...editFormData } 
            : qa
        )
      );
      
      // Resetta lo stato di modifica
      setEditingId(null);
      setEditFormData({
        domanda: '',
        risposta: '',
        qa_type: '',
        quality_score: 0
      });
    } catch (error) {
      console.error('Errore durante il salvataggio:', error);
      alert(`Errore durante il salvataggio: ${error.message}`);
    }
  };

  // Annulla la modifica
  const handleCancel = () => {
    setEditingId(null);
    setEditFormData({
      domanda: '',
      risposta: '',
      qa_type: '',
      quality_score: 0
    });
  };

  // Gestisce l'eliminazione
  const handleDelete = (qaId) => {
    if (!qaId) {
      console.error("ID mancante per l'eliminazione");
      alert("Errore: ID mancante");
      return;
    }
    
    const qaToDelete = datasetWithIds.find(qa => qa.clientId === qaId);
    if (!qaToDelete) {
      console.error("Elemento non trovato nel dataset");
      alert("Errore: elemento non trovato");
      return;
    }
    
    // Indice per l'API
    const apiIndex = qaToDelete.originalIndex || datasetWithIds.indexOf(qaToDelete);
    
    if (window.confirm('Sei sicuro di voler eliminare questa coppia domanda/risposta?')) {
      console.log("Eliminazione elemento con indice:", apiIndex);
      onDeleteQA(apiIndex);
      
      // Aggiorna il dataset locale
      setDatasetWithIds(prev => prev.filter(qa => qa.clientId !== qaId));
    }
    
    setActiveMenuId(null); // Chiude il menu
  };

  // Gestisce la correzione AI
  const handleAIFix = (qaId, domanda, risposta) => {
    if (!qaId) {
      console.error("ID mancante per la correzione AI");
      alert("Errore: ID mancante");
      return;
    }
    
    const qaToFix = datasetWithIds.find(qa => qa.clientId === qaId);
    if (!qaToFix) {
      console.error("Elemento non trovato nel dataset");
      alert("Errore: elemento non trovato");
      return;
    }
    
    // Indice per l'API
    const apiIndex = qaToFix.originalIndex || datasetWithIds.indexOf(qaToFix);
    
    console.log("AI Fix per elemento con indice:", apiIndex, "e dati:", { domanda, risposta });
    onAIFixQA(apiIndex, domanda, risposta);
    setActiveMenuId(null); // Chiude il menu
  };

  return (
    <div className={`bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-8 transition-colors duration-200`}>
      <h2 className="text-2xl font-semibold mb-4 dark:text-white">Editor Dataset</h2>

      {/* Filtri e Ricerca */}
      <div className="flex gap-4 mb-6">
        <input
          type="text"
          placeholder="Cerca nelle domande e risposte..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-1 px-4 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          className="px-4 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        >
          <option value="all">Tutti i tipi</option>
          <option value="web">Web</option>
          <option value="synthetic">Sintetici</option>
          <option value="handler">Handler</option>
        </select>
      </div>

      {/* Lista Q&A */}
      <div className="space-y-4">
        {filteredDataset.map((qa, index) => {
          // Controlla se questo record è in fase di correzione AI
          const isFixingThisRecord = fixingRecordId === 
            (qa.originalIndex || datasetWithIds.indexOf(qa));
          
          return (
          <div key={qa.clientId} className="border p-4 rounded-lg dark:border-gray-700 relative">
            {editingId === qa.clientId ? (
              // Modalità modifica
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-2 dark:text-gray-300">Domanda</label>
                  <input
                    type="text"
                    name="domanda"
                    value={editFormData.domanda}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-2 dark:text-gray-300">Risposta</label>
                  <textarea
                    name="risposta"
                    value={editFormData.risposta}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    rows={3}
                  />
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={handleSave}
                    className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                  >
                    Salva
                  </button>
                  <button
                    onClick={handleCancel}
                    className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                  >
                    Annulla
                  </button>
                </div>
              </div>
            ) : (
              // Modalità visualizzazione
              <>
                <p className="font-medium mb-2 dark:text-white">D: {qa.domanda}</p>
                
                {/* Mostra loading solo per il record in correzione */}
                {isFixingThisRecord ? (
                  <div className="flex items-center space-x-2 my-4">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
                    <p className="text-sm text-blue-500">Ottimizzazione risposta in corso...</p>
                  </div>
                ) : (
                  <p className="text-gray-600 mb-4 dark:text-gray-300">R: {qa.risposta}</p>
                )}
                
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    Tipo: {qa.qa_type} | Qualità: {Math.round((qa.quality_score || 0) * 100)}%
                  </span>
                  <div className="relative">
                    <button
                      onClick={() => handleToggleMenu(qa.clientId, index, filteredDataset.length)}
                      className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                      disabled={isFixingThisRecord} // Disabilita il menu quando si sta correggendo
                    >
                      <EllipsisVerticalIcon className="h-5 w-5" />
                    </button>
                    
                    {activeMenuId === qa.clientId && !isFixingThisRecord && (
                      <ActionMenu
                        onEdit={() => handleEdit(qa)}
                        onDelete={() => handleDelete(qa.clientId)}
                        onAIFix={() => handleAIFix(qa.clientId, qa.domanda, qa.risposta)}
                        onCancel={() => setActiveMenuId(null)}
                        isDarkMode={isDarkMode}
                        position={menuPosition}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          );
        })}
      </div>
      
      {filteredDataset.length === 0 && (
        <div className="text-center py-10">
          <p className="text-gray-500 dark:text-gray-400">Nessun record trovato con i filtri applicati.</p>
        </div>
      )}
    </div>
  );
};

export default DatasetEditor; 