import React from 'react';
import { SparklesIcon, LightBulbIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

/**
 * Componente per le opzioni di espansione del dataset
 * @param {boolean} useExpansion - Flag per attivare l'espansione del dataset
 * @param {function} setUseExpansion - Funzione per impostare l'uso dell'espansione del dataset
 * @param {number} numVariants - Numero di varianti per l'espansione
 * @param {function} handleNumVariantsChange - Funzione per modificare il numero di varianti
 * @param {boolean} isDarkMode - Flag per indicare se la UI è in modalità scura
 * @param {function} handleProceed - Funzione per procedere con la generazione del JSONL
 * @param {boolean} isProcessing - Flag per indicare se è in corso un'elaborazione
 * @param {boolean} hasErrors - Flag per indicare se ci sono errori nel dataset
 */
const DatasetExpansionOptions = ({
  useExpansion,
  setUseExpansion,
  numVariants,
  handleNumVariantsChange,
  isDarkMode,
  handleProceed,
  isProcessing,
  hasErrors
}) => {
  return (
    <div className="mb-8">
      <div className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className="flex items-center mb-4">
          <SparklesIcon className={`h-6 w-6 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Espansione Dataset con AI
          </h2>
        </div>
        
        <div className="mb-6">
          <div className="flex items-center">
            <input
              id="use-expansion"
              type="checkbox"
              checked={useExpansion}
              onChange={(e) => setUseExpansion(e.target.checked)}
              className={`h-5 w-5 rounded border-gray-300 ${
                isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-white'
              } focus:ring-blue-500 text-blue-600`}
            />
            <label
              htmlFor="use-expansion"
              className={`ml-2 block text-base font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}
            >
              Attiva Espansione Dataset con Varianti AI
            </label>
          </div>
          
          <div className={`mt-3 p-3 rounded-md flex items-start ${
            isDarkMode ? 'bg-blue-900/20 border border-blue-800' : 'bg-blue-50 border border-blue-100'
          }`}>
            <LightBulbIcon className={`h-5 w-5 mr-2 flex-shrink-0 ${isDarkMode ? 'text-yellow-400' : 'text-yellow-500'}`} />
            <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              <span className="font-medium">Consiglio:</span> L'espansione del dataset migliora significativamente la qualità del fine-tuning, 
              generando varianti delle domande e risposte che mantengono lo stesso significato ma con formulazioni diverse. 
              Questo aiuta il modello a comprendere meglio il contesto e a rispondere in modo più naturale.
            </p>
          </div>
          
          {useExpansion && (
            <div className="mt-5 p-5 rounded-lg border-2 border-dashed border-blue-300 bg-blue-50/30 dark:bg-blue-900/20 dark:border-blue-800">
              <div className="flex items-start">
                <div className="bg-blue-100 dark:bg-blue-800 p-2 rounded-full mr-3">
                  <SparklesIcon className={`h-7 w-7 ${isDarkMode ? 'text-blue-300' : 'text-blue-600'}`} />
                </div>
                <div>
                  <h3 className={`text-md font-medium ${isDarkMode ? 'text-blue-300' : 'text-blue-700'}`}>
                    Generazione Varianti AI
                  </h3>
                  <p className={`mt-1 text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                    Per ogni domanda/risposta nel dataset, verranno generate varianti mantenendo lo stesso significato ma con formulazioni diverse.
                    Questo processo arricchisce il dataset e migliora la capacità del modello di comprendere diverse formulazioni delle stesse domande.
                  </p>
                </div>
              </div>
              
              <div className="mt-5 bg-white/50 dark:bg-gray-800/50 p-4 rounded-lg">
                <label
                  htmlFor="num-variants"
                  className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}
                >
                  Numero di varianti per domanda/risposta
                </label>
                <div className="mt-2 flex items-center">
                  <input
                    type="range"
                    id="num-variants-range"
                    min="10"
                    max="50"
                    step="5"
                    value={numVariants}
                    onChange={handleNumVariantsChange}
                    className={`w-full h-2 rounded-lg appearance-none cursor-pointer ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                    }`}
                  />
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <input
                    type="number"
                    id="num-variants"
                    min="10"
                    max="100"
                    value={numVariants}
                    onChange={handleNumVariantsChange}
                    className={`block w-20 rounded-md py-2 px-3 border text-center ${
                      isDarkMode 
                        ? 'bg-gray-800 border-gray-600 text-white' 
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500`}
                  />
                  <span className={`text-sm font-medium ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                    {numVariants} varianti
                  </span>
                </div>
                <div className="mt-2 flex justify-between text-xs">
                  <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>Minimo (10)</span>
                  <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>Consigliato (20-30)</span>
                  <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>Massimo (50)</span>
                </div>
                <p className={`mt-3 text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  <span className="font-medium">Nota:</span> Più varianti = dataset più ricco, ma tempi di elaborazione più lunghi.
                  Per dataset di piccole dimensioni (meno di 50 domande), consigliamo 30-50 varianti.
                  Per dataset più grandi, 10-20 varianti sono generalmente sufficienti.
                </p>
              </div>
            </div>
          )}
        </div>
        
        <div className="mt-6 flex justify-end">
          <button
            onClick={handleProceed}
            disabled={isProcessing || hasErrors}
            className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
              isProcessing || hasErrors
                ? 'bg-gray-400 text-white cursor-not-allowed'
                : isDarkMode
                  ? 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500'
                  : 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500'
            }`}
          >
            {isProcessing ? (
              <>
                <ArrowPathIcon className="animate-spin mr-2 h-4 w-4" />
                Elaborazione in corso...
              </>
            ) : (
              <>
                <SparklesIcon className="mr-2 h-4 w-4" />
                Genera JSONL
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DatasetExpansionOptions; 