import { useState, useCallback } from 'react';
import axios from 'axios';

// Importa la configurazione dell'API
import { API_BASE_URL } from '../config/api.js';

/**
 * Hook per gestire le correzioni AI del dataset
 * 
 * @param {function} appendMessage - Funzione per aggiungere messaggi al log
 * @returns {Object} Oggetto contenente stati e funzioni per la correzione AI
 */
const useAICorrection = (appendMessage) => {
  // Indice della riga in fase di correzione AI
  const [AIFixLoadingIndex, setAIFixLoadingIndex] = useState(null);
  // Stato per indicare se l'AI sta elaborando
  const [isProcessingAI, setIsProcessingAI] = useState(false);
  // ID della cella attualmente in elaborazione AI
  const [currentAIProcessingCellId, setCurrentAIProcessingCellId] = useState(null);
  
  // Funzione per mostrare toast (simulata qui dato che non è disponibile direttamente)
  const showToast = useCallback((message, type) => {
    console.log(`Toast (${type}): ${message}`);
    appendMessage(message, false);
  }, [appendMessage]);

  /**
   * Effettua una correzione AI su una risposta
   * 
   * @param {number} index - Indice della riga nella tabella
   * @param {Array} editedData - Dati correnti in modifica
   * @param {Array} paginatedData - Dati paginati correnti
   * @param {number} currentPage - Pagina corrente
   * @param {number} rowsPerPage - Righe per pagina
   * @param {function} setEditedData - Funzione per aggiornare i dati modificati
   * @param {function} updateStats - Funzione per aggiornare le statistiche
   * @param {function} validateCell - Funzione per validare il contenuto delle celle
   * @param {Array} previewData - Dati di preview correnti (opzionale)
   * @param {function} setIsEditMode - Funzione per attivare la modalità di modifica (opzionale)
   */
  const handleAICorrection = useCallback(async (
    index, 
    editedData, 
    paginatedData, 
    currentPage, 
    rowsPerPage, 
    setEditedData,
    updateStats,
    validateCell,
    previewData = null,
    setIsEditMode = null
  ) => {
    // Verifica completa dei parametri
    if (!editedData || !Array.isArray(editedData)) {
      console.error("Errore: editedData non è un array valido:", editedData);
      
      // Se previewData è disponibile, inizializza editedData con una copia di previewData
      if (previewData && Array.isArray(previewData) && previewData.length > 0) {
        console.log("Inizializzazione automatica di editedData con previewData");
        
        try {
          // Crea una deep copy di previewData
          const deepCopy = JSON.parse(JSON.stringify(previewData));
          setEditedData(deepCopy);
          
          // Attiva la modalità di modifica se è disponibile la funzione setIsEditMode
          if (typeof setIsEditMode === 'function') {
            console.log("Attivazione automatica modalità di modifica");
            setIsEditMode(true);
          }
          
          // Aspetta un momento per consentire a React di aggiornare lo stato
          await new Promise(resolve => setTimeout(resolve, 100));
          
          // Usa previewData come editedData per questa chiamata
          editedData = deepCopy;
          console.log("editedData inizializzato con successo:", {
            lunghezza: editedData.length
          });
        } catch (error) {
          console.error("Errore durante l'inizializzazione di editedData:", error);
          appendMessage('Errore: impossibile inizializzare i dati di modifica', false);
          return;
        }
      } else {
        appendMessage('Errore: dati di modifica non validi o mancanti', false);
        return;
      }
    }

    if (!paginatedData || !Array.isArray(paginatedData)) {
      console.error("Errore: paginatedData non è un array valido:", paginatedData);
      appendMessage('Errore: dati paginati non validi o mancanti', false);
      return;
    }
    
    if (!validateCell || typeof validateCell !== 'function') {
      console.error("Errore: validateCell non è una funzione valida:", validateCell);
      appendMessage('Errore: funzione di validazione non disponibile', false);
      return;
    }

    // Se non ci sono dati o l'indice non è valido, esci
    if (index < 0 || index >= paginatedData.length) {
      console.error("Indice non valido o dati mancanti:", { 
        index, 
        hasPaginatedData: !!paginatedData,
        paginatedDataLength: paginatedData.length,
        editedDataLength: editedData.length
      });
      return;
    }

    // Imposta l'indice di caricamento
    setAIFixLoadingIndex(index);
    
    // Prendi la domanda e la risposta dalla riga
    const { domanda, risposta } = paginatedData[index];
    
    // Log per debug
    console.log("Dati inviati per correzione AI:", { 
      index, 
      domanda, 
      risposta,
      paginatedData: paginatedData[index]
    });
    
    // Se non abbiamo una domanda o risposta, esci
    if (!domanda || !risposta) {
      appendMessage('Errore: impossibile correggere - dati mancanti (domanda o risposta non presenti)', false);
      setAIFixLoadingIndex(null);
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      setAIFixLoadingIndex(null);
      return;
    }
    
    try {
      appendMessage(`Richiesta di correzione AI per la riga ${index + 1}...`, false);
      
      // Prepara il payload secondo il formato originale usato in Dashboard.backup.js
      const payload = {
        text: risposta.trim(),            // Usa 'text' invece di 'answer'
        context: domanda.trim(),          // Usa 'context' invece di 'question'
        error_type: 'length',             // Aggiungi il campo error_type
        purpose: 'assistenza_clienti_primo_livello'  // Aggiungi il campo purpose
      };
      
      console.log("Invio richiesta al server:", {
        endpoint: `${API_BASE_URL}/ai_correction`,
        payload
      });
      
      const response = await axios.post(`${API_BASE_URL}/ai_correction`, payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      console.log("Risposta ricevuta:", response.data);
      
      if (response.data && (response.data.corrected_answer || response.data.corrected_text)) {
        // Supporta entrambi i formati di risposta: corrected_answer (nuovo) e corrected_text (originale)
        const correctedAnswer = response.data.corrected_answer || response.data.corrected_text;
        
        // Trova l'indice reale nella lista completa
        const pageStartIndex = (currentPage - 1) * rowsPerPage;
        const realIndex = pageStartIndex + index;
        
        if (realIndex < 0 || realIndex >= editedData.length) {
          console.error("Indice reale fuori dai limiti:", {
            realIndex,
            editedDataLength: editedData.length
          });
          appendMessage("Errore: l'indice calcolato è fuori dai limiti dell'array", false);
          setAIFixLoadingIndex(null);
          return;
        }

        // Aggiorna i dati editati
        setEditedData(prevData => {
          console.log("Aggiornamento dati dopo correzione AI:", {
            realIndex,
            prevDataLength: prevData.length,
            correctedAnswerLength: correctedAnswer.length
          });
          
          const newData = [...prevData];
          
          // Effettua validazione della risposta corretta
          const domandaValidation = validateCell('domanda', newData[realIndex].domanda);
          const rispostaValidation = validateCell('risposta', correctedAnswer);
          
          // Determina se ora è valido o ha ancora errori
          const hasError = !domandaValidation.isValid || !rispostaValidation.isValid;
          
          // Sostituisci la risposta e aggiorna i flag di validazione
          newData[realIndex] = {
            ...newData[realIndex],
            risposta: correctedAnswer,
            hasError: hasError,
            domandaValidation: domandaValidation,
            rispostaValidation: rispostaValidation,
            status: hasError ? 'invalid' : 'valid'
          };
          
          console.log("Record aggiornato dopo correzione AI:", {
            domanda: newData[realIndex].domanda.substring(0, 20) + '...',
            risposta: correctedAnswer.substring(0, 20) + '...',
            domandaValida: domandaValidation.isValid,
            rispostaValida: rispostaValidation.isValid,
            hasError: hasError,
            status: hasError ? 'invalid' : 'valid'
          });
          
          return newData;
        });
      
        // Aggiorna le statistiche 
        if (typeof updateStats === 'function') {
          // Aggiungi un timeout per assicurarti che i dati siano aggiornati prima
          setTimeout(() => {
            updateStats();
            console.log('Statistiche aggiornate dopo correzione AI');
            
            // Forza un secondo aggiornamento per assicurarsi che tutti i componenti riflettano i cambiamenti
            setTimeout(() => {
              updateStats();
              console.log('Aggiornamento statistiche forzato dopo correzione AI');
              
              // Invia un evento personalizzato per notificare l'aggiornamento AI
              try {
                const event = new CustomEvent('aiCorrectionComplete', { 
                  detail: { 
                    index, 
                    status: 'success',
                    fieldName: 'risposta', // Assume sempre che stiamo correggendo la risposta
                    success: true
                  } 
                });
                document.dispatchEvent(event);
              } catch (error) {
                console.error('Errore durante l\'invio dell\'evento personalizzato:', error);
              }
            }, 300);
          }, 100);
        } else {
          console.warn("La funzione updateStats non è disponibile");
        }
        
        appendMessage(`Correzione AI completata per la riga ${index + 1}`, false);
      } else {
        throw new Error('Risposta dal server non valida o mancante corrected_answer/corrected_text');
      }
    } catch (error) {
      console.error('Errore durante la correzione AI:', error);
      console.error('Dettagli errore:', {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        message: error.message
      });
      appendMessage(`Errore durante la correzione AI: ${error.response?.data?.detail || error.message}`, false);
    } finally {
      // Resetta l'indice di caricamento
      setAIFixLoadingIndex(null);
    }
  }, [appendMessage]);

  /**
   * Corregge un file intero tramite AI
   * 
   * @param {string} filename - Nome del file da correggere
   */
  const handleCorrectFile = useCallback(async (filename) => {
    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      return null;
    }
    
    try {
      appendMessage(`Richiesta di correzione AI per l'intero file...`, false);
      
      const response = await axios.post(`${API_BASE_URL}/ai_correction_file`, {
        filename
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data && response.data.task_id) {
        appendMessage('Correzione AI avviata. Elaborazione in corso...', false);
        return response.data.task_id;
      } else {
        throw new Error('Risposta dal server non valida');
      }
    } catch (error) {
      console.error('Errore durante la correzione AI del file:', error);
      appendMessage(`Errore durante la correzione AI: ${error.response?.data?.detail || error.message}`, false);
      return null;
    }
  }, [appendMessage]);

  return {
    // Stati
    AIFixLoadingIndex,
    isProcessingAI,
    currentAIProcessingCellId,
    
    // Setters
    setAIFixLoadingIndex,
    setIsProcessingAI,
    setCurrentAIProcessingCellId,
    
    // Funzioni
    handleAICorrection,
    handleCorrectFile,
    showToast
  };
};

export default useAICorrection; 