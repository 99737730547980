import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

// Manteniamo la definizione originale di API_BASE_URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

// Log per debug
console.log('API Base URL:', API_BASE_URL);

const Login = ({ setIsLoggedIn, setToken, isDarkMode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('login'); // 'login' o 'register'
  const [loginMethod, setLoginMethod] = useState('magic'); // 'magic' o 'password'

  // Verifica il token appena il componente viene montato
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    
    if (token) {
      console.log('Token trovato, avvio verifica...');
      verifyMagicLink(token);
    }
  }, [location]);

  const verifyMagicLink = async (token) => {
    try {
      setLoading(true);
      setError('');
      
      // Log per debug
      console.log('Verifica magic link:', `${API_BASE_URL}/auth/magic-link/verify`);
      
      const response = await axios.get(`${API_BASE_URL}/auth/magic-link/verify`, {
        params: { token }
      });

      if (response.data?.access_token) {
        setToken(response.data.access_token);
        setIsLoggedIn(true);
        localStorage.setItem('token', response.data.access_token);
        // Utilizziamo il metodo originale per il reindirizzamento
        window.history.replaceState({}, document.title, '/');
      } else {
        throw new Error('Token di accesso non trovato nella risposta');
      }
    } catch (error) {
      console.error('Errore verifica magic link:', error);
      
      if (error.response?.status === 403) {
        setError(
          <div>
            <p className="font-medium">Account non ancora attivo</p>
            <p className="mt-2">
              Il tuo account è in attesa di attivazione. 
              Ti contatteremo presto per completare la procedura di registrazione.
            </p>
            <p className="mt-2">
              Per qualsiasi domanda, contatta il nostro supporto.
            </p>
          </div>
        );
      } else {
        setError(
          error.response?.data?.detail || 
          error.message ||
          'Errore durante la verifica del link. Richiedi un nuovo link di accesso.'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMagicLinkRequest = async () => {
    try {
      setLoading(true);
      setError('');
      
      if (!email) {
        setError('Inserisci il tuo indirizzo email');
        return;
      }
      
      // Log per debug
      console.log('Richiesta magic link:', `${API_BASE_URL}/auth/magic-link/request`);
      
      // Utilizziamo l'endpoint originale
      await axios.post(`${API_BASE_URL}/auth/magic-link/request`, {
        email: email,
        full_name: activeTab === 'register' ? fullName : undefined
      });
      
      setMagicLinkSent(true);
    } catch (error) {
      console.error('Errore richiesta magic link:', error);
      
      // Gestione speciale per ambiente di sviluppo
      if (process.env.NODE_ENV === 'development') {
        console.log('Simulazione di invio magic link in ambiente di sviluppo');
        setMagicLinkSent(true);
        return;
      }
      
      setError(error.response?.data?.detail || 'Errore nell\'invio del magic link. Riprova.');
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordLogin = async () => {
    try {
      setLoading(true);
      setError('');
      
      if (!email || !password) {
        setError('Inserisci email e password');
        return;
      }
      
      // Log per debug
      console.log('Login con password:', `${API_BASE_URL}/auth/token`);
      
      // Utilizziamo FormData come nella versione originale
      const formData = new FormData();
      formData.append('username', email);
      formData.append('password', password);
      
      // In ambiente di sviluppo, possiamo simulare il login per test
      if (process.env.NODE_ENV === 'development' && email === 'test@example.com') {
        console.log('Simulazione login in ambiente di sviluppo');
        const mockToken = 'mock-token-for-development';
        setToken(mockToken);
        setIsLoggedIn(true);
        localStorage.setItem('token', mockToken);
        return;
      }
      
      // Utilizziamo l'endpoint originale
      const response = await axios.post(`${API_BASE_URL}/auth/token`, formData);
      
      if (response.data?.access_token) {
        setToken(response.data.access_token);
        setIsLoggedIn(true);
        localStorage.setItem('token', response.data.access_token);
      } else {
        throw new Error('Token di accesso non trovato nella risposta');
      }
    } catch (error) {
      console.error('Errore login con password:', error);
      
      // Gestione speciale per ambiente di sviluppo
      if (process.env.NODE_ENV === 'development') {
        // Simuliamo un login di successo per test
        if (email && password) {
          console.log('Simulazione login in ambiente di sviluppo');
          const mockToken = 'mock-token-for-development';
          setToken(mockToken);
          setIsLoggedIn(true);
          localStorage.setItem('token', mockToken);
          return;
        }
      }
      
      setError(error.response?.data?.detail || 'Credenziali non valide');
    } finally {
      setLoading(false);
    }
  };

  if (loading && !magicLinkSent) {
    return (
      <div className="flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500 mx-auto mb-4"></div>
          <p className="text-gray-600 dark:text-gray-300">Verifica in corso...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-8 text-center text-gray-800 dark:text-white">
        {activeTab === 'register' ? 'Crea un account' : 'Accedi al tuo account'}
      </h2>
      
      {/* Tabs principali: Accedi/Registrati */}
      <div className="bg-gray-100 dark:bg-gray-700 rounded-lg p-1 mb-8 grid grid-cols-2 gap-1">
        <button
          onClick={() => setActiveTab('login')}
          className={`py-3 rounded-md font-medium text-center transition-all duration-200 ${
            activeTab === 'login' 
              ? isDarkMode
                ? 'bg-gray-800 text-white shadow-md'
                : 'bg-white text-gray-800 shadow-md'
              : isDarkMode
                ? 'text-gray-400 hover:text-white'
                : 'text-gray-600 hover:text-gray-800'
          }`}
        >
          Accedi
        </button>
        <button
          onClick={() => setActiveTab('register')}
          className={`py-3 rounded-md font-medium text-center transition-all duration-200 ${
            activeTab === 'register' 
              ? isDarkMode
                ? 'bg-gray-800 text-white shadow-md'
                : 'bg-white text-gray-800 shadow-md'
              : isDarkMode
                ? 'text-gray-400 hover:text-white'
                : 'text-gray-600 hover:text-gray-800'
          }`}
        >
          Registrati
        </button>
      </div>

      {/* Contenuto principale */}
      {!magicLinkSent ? (
        <div className="space-y-6">
          {/* Tabs secondari: Magic Link/Password (solo per login) */}
          {activeTab === 'login' && (
            <div className="bg-gray-100 dark:bg-gray-700 rounded-lg p-1 grid grid-cols-2 gap-1 mb-6">
              <button
                onClick={() => setLoginMethod('magic')}
                className={`py-3 rounded-md font-medium text-center transition-all duration-200 ${
                  loginMethod === 'magic' 
                    ? isDarkMode
                      ? 'bg-gray-800 text-white shadow-md'
                      : 'bg-white text-gray-800 shadow-md'
                    : isDarkMode
                      ? 'text-gray-400 hover:text-white'
                      : 'text-gray-600 hover:text-gray-800'
                }`}
              >
                Magic Link
              </button>
              <button
                onClick={() => setLoginMethod('password')}
                className={`py-3 rounded-md font-medium text-center transition-all duration-200 ${
                  loginMethod === 'password' 
                    ? isDarkMode
                      ? 'bg-gray-800 text-white shadow-md'
                      : 'bg-white text-gray-800 shadow-md'
                    : isDarkMode
                      ? 'text-gray-400 hover:text-white'
                      : 'text-gray-600 hover:text-gray-800'
                }`}
              >
                Password
              </button>
            </div>
          )}

          {/* Form con altezza fissa */}
          <div className="min-h-[240px]">
            {/* Campo Nome e Cognome (solo per registrazione) */}
            {activeTab === 'register' && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Nome e Cognome
                </label>
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  placeholder="Mario Rossi"
                  disabled={loading}
                />
              </div>
            )}

            {/* Campo Email (sempre presente) */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                placeholder="email@esempio.com"
                disabled={loading}
              />
            </div>

            {/* Campo Password (solo per login con password) */}
            {activeTab === 'login' && loginMethod === 'password' && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                  placeholder="••••••••••••"
                  disabled={loading}
                />
              </div>
            )}

            {/* Placeholder per mantenere l'altezza quando non c'è il campo password */}
            {activeTab === 'login' && loginMethod === 'magic' && (
              <div className="mb-4 py-[52px]"></div>
            )}
          </div>

          {/* Pulsante di azione */}
          <button
            onClick={
              activeTab === 'login' && loginMethod === 'password'
                ? handlePasswordLogin
                : handleMagicLinkRequest
            }
            disabled={loading}
            className="w-full py-3 px-4 bg-indigo-600 hover:bg-indigo-700 text-white font-medium rounded-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Elaborazione...
              </span>
            ) : (
              activeTab === 'login' && loginMethod === 'password'
                ? 'Accedi'
                : activeTab === 'register'
                  ? 'Registrati'
                  : 'Invia link di accesso'
            )}
          </button>

          {/* Messaggio informativo */}
          <p className="text-sm text-gray-500 dark:text-gray-400 text-center mt-4">
            {activeTab === 'register' 
              ? 'Riceverai un link di accesso via email per completare la registrazione.'
              : loginMethod === 'magic'
                ? 'Riceverai un link di accesso via email per accedere al tuo account.'
                : 'Inserisci le tue credenziali per accedere.'}
          </p>
        </div>
      ) : (
        <div className="text-center p-6 bg-green-50 dark:bg-green-900/20 rounded-lg">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto text-green-500 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
          <h3 className="text-xl font-semibold text-green-700 dark:text-green-400 mb-2">
            Link di accesso inviato!
          </h3>
          <p className="text-green-600 dark:text-green-300">
            Controlla la tua email per accedere al tuo account.
          </p>
        </div>
      )}

      {/* Messaggio di errore */}
      {error && (
        <div className="mt-6 p-4 bg-red-50 dark:bg-red-900/20 rounded-lg">
          <div className="text-sm text-red-600 dark:text-red-400">
            {typeof error === 'string' ? (
              <p className="text-center">{error}</p>
            ) : (
              error
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;




