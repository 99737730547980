import React from 'react';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';

const ChatbotHeader = ({ isDarkMode }) => {
  return (
    <div className={`transition-colors duration-200 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-sm border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <ChatBubbleBottomCenterTextIcon className="h-6 w-6 text-indigo-600" />
              <span className={`ml-2 text-lg font-semibold ${
                isDarkMode ? 'text-white' : 'text-gray-800'
              }`}>
                Chatbot AI
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <span className={`text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              Assistente per la creazione di dataset personalizzati
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatbotHeader;