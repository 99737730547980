import React, { useState, useEffect } from 'react';
import { getDocuments, linkDocumentsToAgent, getAgentDocuments } from '../../services/ragService';

const RagDocumentConnector = ({ agentId, agentType }) => {
  const [availableDocuments, setAvailableDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documentPriorities, setDocumentPriorities] = useState({});
  const [documentCategories, setDocumentCategories] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  // Opzioni per le categorie di documenti
  const categoryOptions = [
    { id: 'script', name: 'Script di Vendita' },
    { id: 'product', name: 'Informazioni Prodotto' },
    { id: 'training', name: 'Materiale Formativo' },
    { id: 'objection', name: 'Gestione Obiezioni' },
    { id: 'reference', name: 'Riferimento Generale' },
  ];
  
  // Opzioni per le priorità
  const priorityOptions = [
    { id: 'high', name: 'Alta' },
    { id: 'medium', name: 'Media' },
    { id: 'low', name: 'Bassa' },
  ];

  useEffect(() => {
    const fetchDocuments = async () => {
      setIsLoading(true);
      try {
        // Per semplicità usiamo null come parametro, 
        // in un'implementazione reale useremmo l'ID utente attuale o il modelId
        const docs = await getDocuments(null);
        setAvailableDocuments(docs);
        setError(null);
      } catch (err) {
        setError(`Errore nel caricamento dei documenti: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchAgentDocuments = async () => {
      if (!agentId) return;
      
      setIsLoading(true);
      try {
        const agentDocs = await getAgentDocuments(agentId);
        
        // Aggiorna i documenti selezionati
        const selectedIds = agentDocs.map(doc => doc.id);
        setSelectedDocuments(selectedIds);
        
        // Aggiorna priorità e categorie
        const priorities = {};
        const categories = {};
        
        agentDocs.forEach(doc => {
          if (doc.priority) priorities[doc.id] = doc.priority;
          if (doc.category) categories[doc.id] = doc.category;
        });
        
        setDocumentPriorities(priorities);
        setDocumentCategories(categories);
        
        setError(null);
      } catch (err) {
        setError(`Errore nel caricamento dei documenti dell'agente: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocuments();
    if (agentId) fetchAgentDocuments();
  }, [agentId]);

  const handleDocumentToggle = (docId) => {
    setSelectedDocuments(prev => {
      if (prev.includes(docId)) {
        return prev.filter(id => id !== docId);
      } else {
        return [...prev, docId];
      }
    });
  };

  const handlePriorityChange = (docId, priority) => {
    setDocumentPriorities(prev => ({
      ...prev,
      [docId]: priority
    }));
  };

  const handleCategoryChange = (docId, category) => {
    setDocumentCategories(prev => ({
      ...prev,
      [docId]: category
    }));
  };

  const handleSaveDocuments = async () => {
    if (!agentId) {
      setError('ID agente non valido');
      return;
    }
    
    setIsLoading(true);
    try {
      await linkDocumentsToAgent(agentId, selectedDocuments, {
        priorities: documentPriorities,
        categories: documentCategories
      });
      
      setSuccess('Documenti collegati con successo all\'agente');
      setError(null);
      
      // Pulizia dopo 3 secondi
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(`Errore nel collegamento dei documenti: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Funzione per ottenere una descrizione adatta al tipo di agente
  const getAgentSpecificDescription = () => {
    switch (agentType) {
      case 'coach':
        return 'Seleziona i documenti che il coach utilizzerà per fornirti consigli e strategie di vendita personalizzate.';
      case 'simulator':
        return 'Seleziona i documenti che il simulatore utilizzerà per creare scenari realistici e generare risposte dei clienti.';
      case 'analyzer':
        return 'Seleziona i documenti di riferimento che l\'analizzatore utilizzerà per valutare le tue performance e fornire feedback.';
      default:
        return 'Seleziona i documenti da utilizzare con questo agente.';
    }
  };

  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6">
      <h2 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white">
        Connessione Documenti RAG
      </h2>
      
      <p className="text-gray-600 dark:text-gray-300 mb-6">
        {getAgentSpecificDescription()}
      </p>
      
      {error && (
        <div className="mb-4 p-3 bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 rounded">
          {error}
        </div>
      )}
      
      {success && (
        <div className="mb-4 p-3 bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200 rounded">
          {success}
        </div>
      )}
      
      {isLoading ? (
        <div className="flex justify-center py-8">
          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
        </div>
      ) : availableDocuments.length === 0 ? (
        <div className="text-center py-8 bg-gray-50 dark:bg-gray-800 rounded-lg">
          <p className="text-gray-500 dark:text-gray-400">
            Nessun documento disponibile. Carica documenti nella sezione RAG.
          </p>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Seleziona
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Nome Documento
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Tipo
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Priorità
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                    Categoria
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-800">
                {availableDocuments.map((doc) => (
                  <tr key={doc.id} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800"
                        checked={selectedDocuments.includes(doc.id)}
                        onChange={() => handleDocumentToggle(doc.id)}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {doc.file_name || `Documento ${doc.id}`}
                      </div>
                      <div className="text-xs text-gray-500 dark:text-gray-400">
                        Caricato: {new Date(doc.created_at).toLocaleDateString()}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                        {doc.file_type || 'Documento'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <select
                        className="block w-full text-sm bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm"
                        value={documentPriorities[doc.id] || 'medium'}
                        onChange={(e) => handlePriorityChange(doc.id, e.target.value)}
                        disabled={!selectedDocuments.includes(doc.id)}
                      >
                        {priorityOptions.map(option => (
                          <option key={option.id} value={option.id}>{option.name}</option>
                        ))}
                      </select>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <select
                        className="block w-full text-sm bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm"
                        value={documentCategories[doc.id] || 'reference'}
                        onChange={(e) => handleCategoryChange(doc.id, e.target.value)}
                        disabled={!selectedDocuments.includes(doc.id)}
                      >
                        {categoryOptions.map(option => (
                          <option key={option.id} value={option.id}>{option.name}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
              onClick={handleSaveDocuments}
              disabled={isLoading}
            >
              {isLoading ? 'Salvataggio...' : 'Salva Collegamenti'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RagDocumentConnector; 