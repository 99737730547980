import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

/**
 * Componente per visualizzare i messaggi di log e lo stato del fine-tuning
 * @param {Array} messages - Array di messaggi da visualizzare
 * @param {Object} messagesEndRef - Ref per lo scroll automatico
 * @param {boolean} isDarkMode - Flag per indicare se la UI è in modalità scura
 * @param {boolean} isFineTuning - Flag per indicare se è in corso un fine-tuning
 * @param {string} fineTuneStatus - Stato del fine-tuning
 * @param {number} progress - Percentuale di progresso
 * @param {string} elapsedTimeFormatted - Tempo trascorso formattato
 * @param {boolean} showInfoMessage - Flag per mostrare il messaggio informativo
 */
const MessageLog = ({
  messages,
  messagesEndRef,
  isDarkMode,
  isFineTuning,
  fineTuneStatus,
  progress,
  elapsedTimeFormatted,
  showInfoMessage
}) => {
  return (
    <div className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        Log di Sistema
      </h2>
      
      {/* Messaggi del sistema */}
      {messages.length > 0 && (
        <div className={`border rounded-md divide-y max-h-60 overflow-y-auto custom-scrollbar ${
          isDarkMode 
            ? 'border-gray-700 divide-gray-700' 
            : 'border-gray-200 divide-gray-200'
        }`}>
          {messages.map((message, index) => (
            <div key={index} className={`p-3 ${
              message.isUser 
                ? isDarkMode ? 'bg-blue-900 bg-opacity-50' : 'bg-blue-50'
                : isDarkMode ? 'bg-gray-800' : 'bg-white'
            }`}>
              <p className={`text-sm flex items-start ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                {message.isUser ? (
                  <ExclamationCircleIcon className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                ) : (
                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                )}
                {message.content}
              </p>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      )}
      
      {/* Stato del fine-tuning */}
      {fineTuneStatus && (
        <div className="mt-4">
          <h3 className={`text-lg font-medium ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Stato del Fine-Tuning</h3>
          <div className="mt-2">
            {fineTuneStatus === 'running' && (
              <div className="flex items-center">
                <div className="w-5 h-5 mr-2 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                <p className={`${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}>Fine-tuning in corso...</p>
              </div>
            )}
            {fineTuneStatus === 'succeeded' && (
              <div className="flex items-center">
                <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2" />
                <p className={`${
                  isDarkMode ? 'text-green-400' : 'text-green-700'
                }`}>Fine-tuning completato con successo!</p>
              </div>
            )}
            {fineTuneStatus === 'failed' && (
              <div className="flex items-center">
                <ExclamationCircleIcon className="h-6 w-6 text-red-500 mr-2" />
                <p className={`${
                  isDarkMode ? 'text-red-400' : 'text-red-700'
                }`}>Fine-tuning fallito. Riprova più tardi.</p>
              </div>
            )}
          </div>
        </div>
      )}
      
      {/* Progresso del fine-tuning */}
      {isFineTuning && (
        <div className="mt-4">
          <h3 className={`text-lg font-medium ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Progresso del Fine-Tuning</h3>
          <div className="mt-2 relative pt-1">
            <div className={`overflow-hidden h-2 mb-4 text-xs flex rounded ${
              isDarkMode ? 'bg-blue-900' : 'bg-blue-200'
            }`}>
              <div 
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500 ease-in-out"
              ></div>
            </div>
            <div className="text-right">
              <span className={`text-sm font-semibold inline-block ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`}>
                {progress}%
              </span>
            </div>
          </div>
          <p className={`text-sm mt-2 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`}>
            Tempo trascorso: {elapsedTimeFormatted}
          </p>
          {showInfoMessage && (
            <div className="mt-2 p-3 rounded-md flex items-start bg-opacity-50 bg-blue-100 dark:bg-blue-900 dark:bg-opacity-30">
              <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
              <p className={`text-sm ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}>
                Il processo di fine-tuning può richiedere molto tempo, specialmente durante la fase 'running'. Si prega di pazientare.
              </p>
            </div>
          )}
        </div>
      )}
      
      {/* Indicatore di elaborazione */}
      {!isFineTuning && progress > 0 && progress < 100 && (
        <div className="mt-4">
          <h3 className={`text-lg font-medium ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>Elaborazione in corso</h3>
          <div className="mt-2 relative pt-1">
            <div className={`overflow-hidden h-2 mb-4 text-xs flex rounded ${
              isDarkMode ? 'bg-blue-900' : 'bg-blue-200'
            }`}>
              <div 
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500 ease-in-out"
              ></div>
            </div>
            <div className="text-right">
              <span className={`text-sm font-semibold inline-block ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`}>
                {progress}%
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageLog; 