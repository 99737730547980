import React from 'react';

const LoadingIndicator = ({ message }) => (
  <div className="flex flex-col items-center justify-center p-4">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-4"></div>
    <p className="text-lg text-gray-600">
      {message || 'Generazione dataset in corso...'}
    </p>
    <p className="text-sm text-gray-500 mt-2">
      Questo processo potrebbe richiedere alcuni minuti
    </p>
  </div>
);

LoadingIndicator.defaultProps = {
  message: 'Generazione dataset in corso...'
};

export default LoadingIndicator; 