import React from 'react';

const DatasetPreview = ({ dataset, statistics, businessInfo, isDarkMode }) => {
  return (
    <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-lg shadow-md p-6 mb-6`}>
      <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Anteprima Dataset</h2>
      
      {/* Informazioni Attività */}
      <div className="mb-6">
        <h3 className={`text-xl font-semibold mb-3 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Informazioni Attività</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>Tipo: {businessInfo?.tipo}</p>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>Nome: {businessInfo?.nome}</p>
          </div>
          <div>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>Finalità: {businessInfo?.finalita}</p>
          </div>
        </div>
      </div>

      {/* Statistiche Dataset */}
      <div className="mb-6">
        <h3 className={`text-xl font-semibold mb-3 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Statistiche Dataset</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} p-4 rounded-lg`}>
            <h4 className={`font-medium mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>Totale Q&A</h4>
            <p className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>{statistics?.totale_qa || 0}</p>
          </div>
          <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} p-4 rounded-lg`}>
            <h4 className={`font-medium mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>Qualità Media</h4>
            <p className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>{statistics?.qualita_media || 0}%</p>
          </div>
          <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} p-4 rounded-lg`}>
            <h4 className={`font-medium mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>Tipi di Q&A</h4>
            <div>
              {statistics?.tipi_qa && Object.entries(statistics.tipi_qa).map(([tipo, count]) => (
                <p key={tipo} className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  {tipo}: <span className="font-medium">{count}</span>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Esempi Q&A */}
      <div>
        <h3 className={`text-xl font-semibold mb-3 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Esempi Q&A</h3>
        <div className="space-y-4">
          {dataset?.slice(0, 3).map((qa, index) => (
            <div key={index} className={`border p-4 rounded-lg ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
              <p className={`font-medium mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>D: {qa.domanda}</p>
              <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>R: {qa.risposta}</p>
              <div className="mt-2">
                <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  Qualità: {Math.round(qa.quality_score * 100)}%
                </span>
                <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} ml-4`}>
                  Tipo: {qa.qa_type}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DatasetPreview;