import axios from 'axios';

// Nota: assicurati che questo PATH corrisponda al prefisso nel router backend
// user_dashboard.py usa '/api/dashboard' quindi questo è il percorso completo
const BASE_URL = '/api/dashboard';

/**
 * Servizio per la gestione delle API del pannello utente
 */
const userDashboardService = {
  /**
   * Ottiene le informazioni del profilo utente
   * @param {string} token - Token di autenticazione
   * @returns {Promise} - Promessa con i dati del profilo
   */
  getProfile: async (token) => {
    try {
      const response = await axios.get(`${BASE_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Errore nel recupero del profilo:', error);
      throw error;
    }
  },

  /**
   * Aggiorna le informazioni del profilo utente
   * @param {string} token - Token di autenticazione
   * @param {Object} profileData - Dati da aggiornare
   * @returns {Promise} - Promessa con il risultato dell'operazione
   */
  updateProfile: async (token, profileData) => {
    try {
      const response = await axios.put(`${BASE_URL}/profile`, profileData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Errore nell\'aggiornamento del profilo:', error);
      throw error;
    }
  },

  /**
   * Cambia la password dell'utente
   * @param {string} token - Token di autenticazione
   * @param {Object} passwordData - Dati per il cambio password
   * @returns {Promise} - Promessa con il risultato dell'operazione
   */
  changePassword: async (token, passwordData) => {
    try {
      const response = await axios.put(`${BASE_URL}/change-password`, passwordData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Errore nel cambio password:', error);
      throw error;
    }
  },

  /**
   * Ottiene il riepilogo delle risorse dell'utente
   * @param {string} token - Token di autenticazione
   * @returns {Promise} - Promessa con i dati delle risorse
   */
  getResourcesSummary: async (token) => {
    try {
      const response = await axios.get(`${BASE_URL}/resources/summary`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Errore nel recupero del riepilogo risorse:', error);
      throw error;
    }
  },

  /**
   * Ottiene la lista delle fatture
   * @param {string} token - Token di autenticazione
   * @returns {Promise} - Promessa con i dati delle fatture
   */
  getInvoices: async (token) => {
    try {
      const response = await axios.get(`${BASE_URL}/invoices`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Errore nel recupero delle fatture:', error);
      throw error;
    }
  },

  /**
   * Ottiene la lista dei metodi di pagamento
   * @param {string} token - Token di autenticazione
   * @returns {Promise} - Promessa con i dati dei metodi di pagamento
   */
  getPaymentMethods: async (token) => {
    try {
      const response = await axios.get(`${BASE_URL}/payment-methods`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Errore nel recupero dei metodi di pagamento:', error);
      throw error;
    }
  },

  /**
   * Elimina un metodo di pagamento
   * @param {string} token - Token di autenticazione
   * @param {number} paymentMethodId - ID del metodo di pagamento
   * @returns {Promise} - Promessa con il risultato dell'operazione
   */
  deletePaymentMethod: async (token, paymentMethodId) => {
    try {
      const response = await axios.delete(`${BASE_URL}/payment-methods/${paymentMethodId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Errore nell\'eliminazione del metodo di pagamento:', error);
      throw error;
    }
  }
};

export default userDashboardService; 