import React from 'react';

const WelcomeMessage = ({ isDarkMode }) => {
  return (
    <div className="w-full max-w-full overflow-hidden">
      <div className={`p-4 rounded-md w-full ${
        isDarkMode 
          ? 'bg-gradient-to-br from-gray-900 to-gray-950' 
          : 'bg-gradient-to-br from-blue-50 to-indigo-100 border border-gray-200'
      }`}>
        <h2 className={`text-xl font-semibold mb-2 ${
          isDarkMode ? 'text-purple-400' : 'text-purple-700'
        }`}>
          Benvenuto all'Assistente Dataset
        </h2>
        <p className={`mb-4 ${
          isDarkMode ? 'text-white' : 'text-gray-800'
        }`}>
          Benvenuto! Ti guiderò nella creazione di un dataset personalizzato per il tuo servizio clienti.
        </p>
        
        <div className={`p-3 rounded border-l-4 border-purple-500 mb-4 ${
          isDarkMode ? 'bg-purple-900 bg-opacity-50' : 'bg-purple-100'
        }`}>
          <div className="flex items-start">
            <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 mr-2 mt-0.5 flex-shrink-0 ${
              isDarkMode ? 'text-yellow-400' : 'text-yellow-600'
            }`} viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
            <div>
              <h3 className={`font-semibold ${
                isDarkMode ? 'text-yellow-400' : 'text-yellow-600'
              }`}>IMPORTANTE:</h3>
              <p className={`text-sm ${
                isDarkMode ? 'text-white' : 'text-gray-800'
              }`}>Per ogni domanda fornisci una singola risposta chiara e completa.</p>
            </div>
          </div>
        </div>
        
        <div className="mb-4">
          <h3 className={`font-medium mb-2 ${
            isDarkMode ? 'text-blue-400' : 'text-blue-600'
          }`}>Il processo includerà:</h3>
          <ul className={`space-y-1 text-sm ${
            isDarkMode ? 'text-gray-300' : 'text-gray-700'
          }`}>
            <li className="flex items-start">
              <span className={`mr-2 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`}>•</span>
              <span className="break-words">Circa 50 domande mirate sulla tua attività</span>
            </li>
            <li className="flex items-start">
              <span className={`mr-2 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`}>•</span>
              <span className="break-words">Una sezione finale dedicata ai tuoi prodotti/servizi</span>
            </li>
          </ul>
        </div>
        
        <div className="mb-4">
          <h3 className={`font-medium mb-2 ${
            isDarkMode ? 'text-green-400' : 'text-green-600'
          }`}>Suggerimenti per risposte ottimali:</h3>
          <ul className={`space-y-1 text-sm ${
            isDarkMode ? 'text-gray-300' : 'text-gray-700'
          }`}>
            <li className="flex items-start">
              <span className={`mr-2 ${
                isDarkMode ? 'text-green-400' : 'text-green-600'
              }`}>•</span>
              <span className="break-words">Fornisci informazioni dettagliate ma concise</span>
            </li>
            <li className="flex items-start">
              <span className={`mr-2 ${
                isDarkMode ? 'text-green-400' : 'text-green-600'
              }`}>•</span>
              <span className="break-words">Usa risposte di lunghezza moderata</span>
            </li>
            <li className="flex items-start">
              <span className={`mr-2 ${
                isDarkMode ? 'text-green-400' : 'text-green-600'
              }`}>•</span>
              <span className="break-words">Evita risposte multiple o alternative</span>
            </li>
          </ul>
        </div>
        
        <p className={`text-sm ${
          isDarkMode ? 'text-white' : 'text-gray-800'
        }`}>Scrivi "Pronto" per iniziare!</p>
      </div>
    </div>
  );
};

export default WelcomeMessage;