import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronDownIcon, ChevronUpIcon, DocumentTextIcon, ChevronLeftIcon, ChevronRightIcon, InformationCircleIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import axios from '../../utils/axios-interceptors';

const JsonPreview = ({ preview, isDarkMode, jsonFilePath }) => {
    const [expandedItems, setExpandedItems] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPreview, setFilteredPreview] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fullPreview, setFullPreview] = useState(null);
    const [showFullPreviewInfo, setShowFullPreviewInfo] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const containerRef = useRef(null);
    const prevPreviewRef = useRef(preview);
    const [errorMessage, setErrorMessage] = useState('');
    
    // Configurazione della paginazione
    const itemsPerPage = 20;
    const totalPages = Math.ceil((filteredPreview?.length || 0) / itemsPerPage);
    
    // Log per debugging
    console.log('JsonPreview props:', {
        hasPreview: !!preview,
        previewType: typeof preview,
        isArray: Array.isArray(preview),
        length: preview?.length,
        hasJsonFilePath: !!jsonFilePath
    });

    // Effetto per gestire il cambio di preview
    useEffect(() => {
        // Se la preview è cambiata, aggiorna filteredPreview ma mantieni la pagina corrente
        if (preview !== prevPreviewRef.current) {
            if (preview && Array.isArray(preview)) {
                // Se abbiamo già dei dati e la nuova preview è l'anteprima JSONL (10 record),
                // NON sovrascrivere i dati esistenti
                if (preview.length === 10 && 
                    preview[0]?.messages && 
                    filteredPreview.length > 10) {
                    console.log('Mantengo i dati originali e la paginazione');
                    // Mantieni i dati esistenti e non aggiornare prevPreviewRef
                    return;
                }

                // Se è un nuovo dataset completo, aggiornalo
                console.log('Aggiorno i dati con la nuova preview:', preview.length, 'records');
                setFilteredPreview(preview);
                prevPreviewRef.current = preview;
                
                // Mantieni la pagina corrente se possibile
                const newTotalPages = Math.ceil(preview.length / itemsPerPage);
                if (currentPage > newTotalPages) {
                    setCurrentPage(newTotalPages || 1);
                }
                
                // Aggiorna il totale dei record solo se è maggiore di quello attuale
                if (!totalRecords || preview.length > totalRecords) {
                    setTotalRecords(preview.length);
                }
            }
        }
    }, [preview, itemsPerPage]);

    // Effetto per la ricerca
    useEffect(() => {
        // Se stiamo cercando in dati completi già caricati, usa quelli come fonte
        const source = fullPreview || preview;
        if (!source || !Array.isArray(source)) return;

        if (!searchTerm.trim()) {
            setFilteredPreview(source);
            return;
        }

        const lowercaseSearchTerm = searchTerm.toLowerCase();
        const filtered = source.filter(item => {
            // Se l'item ha la struttura JSONL (messages), cerca nel contenuto
            if (item.messages) {
                const messages = item.messages.map(m => m.content).join(' ');
                return messages.toLowerCase().includes(lowercaseSearchTerm);
            }
            // Altrimenti cerca nel formato originale
            const jsonString = typeof item === 'object' ? JSON.stringify(item) : item;
            return jsonString.toLowerCase().includes(lowercaseSearchTerm);
        });

        setFilteredPreview(filtered);
        setCurrentPage(1);
    }, [searchTerm, preview, fullPreview]);

    // Effetto per il logging della paginazione
    useEffect(() => {
        console.log('JsonPreview - Stato paginazione:', {
            currentPage,
            totalPages,
            itemsPerPage,
            filteredLength: filteredPreview?.length || 0,
            previewLength: preview?.length || 0
        });
    }, [currentPage, totalPages, itemsPerPage, filteredPreview?.length, preview?.length]);

    // Effetto per il reset dello stato quando cambia il file JSON
    useEffect(() => {
        // Se cambia il percorso del file, resetta lo stato per il nuovo file
        console.log('JsonFilePath cambiato:', jsonFilePath);
        setIsLoaded(false);
        setFullPreview(null);
        setShowFullPreviewInfo(false);
        setCurrentPage(1);
        
        // Se preview è presente, usala come punto di partenza
        if (preview && Array.isArray(preview)) {
            setFilteredPreview(preview);
            prevPreviewRef.current = preview;
            setTotalRecords(preview.length);
        }
    }, [jsonFilePath]);

    // Funzione per formattare il contenuto per la visualizzazione
    const formatContent = (item) => {
        // Se l'item ha la struttura JSONL (dopo la conversione)
        if (item.messages) {
            return {
                domanda: item.messages[0]?.content || '',
                risposta: item.messages[1]?.content || ''
            };
        }
        // Altrimenti restituisci l'item originale
        return item;
    };

    // Calcola gli indici per la paginazione
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredPreview.length);
    const currentItems = filteredPreview.slice(startIndex, endIndex).map(formatContent);

    // Determina se stiamo visualizzando solo un'anteprima parziale
    const isPartialPreview = preview && (
        (preview.length === 10 && !fullPreview) || // Se abbiamo solo 10 record iniziali e non il dataset completo
        (totalRecords && preview.length < totalRecords) || // Se abbiamo meno record del totale
        (filteredPreview.length > preview.length && !fullPreview) // Se filteredPreview ha più dati di preview
    );
    
    // Log per debugging stato anteprima
    console.log('JsonPreview - Stato anteprima:', {
        isPartialPreview,
        previewLength: preview?.length || 0,
        filteredPreviewLength: filteredPreview?.length || 0,
        totalRecords,
        hasFullPreview: !!fullPreview,
        fullPreviewLength: fullPreview?.length || 0
    });

    // Funzione migliorata per gestire errori di parsing JSON
    const safeParseJson = (line) => {
        if (typeof line === 'object') return line;
        
        try {
            return JSON.parse(line);
        } catch (e) {
            console.error('Errore nel parsing JSON:', e, 'Contenuto:', line.substring(0, 100));
            
            // Tentativo alternativo: se la linea contiene caratteri di escape non validi
            try {
                // Sostituisci sequenze di escape comuni che possono causare problemi
                const sanitized = line
                    .replace(/\\(?!["\\/bfnrt])/g, '\\\\') // Backslash non seguito da caratteri di escape validi
                    .replace(/\n/g, '\\n')                 // Newline letterali
                    .replace(/\r/g, '\\r')                 // Carriage return letterali
                    .replace(/\t/g, '\\t')                 // Tab letterali
                    .replace(/"/g, '\\"');                 // Doppi apici non escaped
                
                return JSON.parse(sanitized);
            } catch (innerError) {
                // Se anche il tentativo di correzione fallisce, restituisci un oggetto di errore
                return { 
                    error: 'Formato JSON non valido', 
                    raw: line.substring(0, 150) + (line.length > 150 ? '...' : '')
                };
            }
        }
    };

    // Funzione per caricare il file JSONL completo
    const loadFullJsonl = async () => {
        if (!jsonFilePath) return;
        
        setIsLoading(true);
        try {
            // Estrai solo il nome del file dal percorso
            const fileName = jsonFilePath.split('/').pop();
            
            const response = await axios.get(`/api/dataset/get_full_jsonl?file_path=${encodeURIComponent(fileName)}`);
            if (response.data && Array.isArray(response.data.preview)) {
                const fullData = response.data.preview;
                console.log('Dataset completo caricato:', fullData.length, 'record');
                
                if (fullData.length === 0) {
                    throw new Error('Il file JSONL non contiene dati validi');
                }
                
                // Aggiorna lo stato con i dati completi
                setFullPreview(fullData);
                setFilteredPreview(fullData);
                setTotalRecords(fullData.length);
                prevPreviewRef.current = fullData;
                setCurrentPage(1);
                setShowFullPreviewInfo(false);
                setIsLoaded(true);
                setErrorMessage('');
            }
        } catch (error) {
            console.error('Errore nel caricamento del file JSONL completo:', error);
            setErrorMessage('Errore nel caricamento del dataset completo: ' + (error.response?.data?.detail || error.message));
            setShowFullPreviewInfo(true);
            
            // Se abbiamo almeno i dati di anteprima, usiamo quelli
            if (preview && Array.isArray(preview) && preview.length > 0) {
                setFullPreview(preview);
                setFilteredPreview(preview);
                setTotalRecords(preview.length);
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Funzione migliorata per la navigazione delle pagine
    const goToPage = useCallback((page, event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (page >= 1 && page <= totalPages) {
            console.log(`Navigazione alla pagina ${page}`);
            setCurrentPage(page);
            
            // Scroll all'inizio del container
            if (containerRef.current) {
                containerRef.current.scrollTop = 0;
            }
        }
    }, [totalPages]);

    const handleViewAllRecords = async () => {
        if (!jsonFilePath) {
            console.error('jsonFilePath non definito, impossibile caricare i dati completi');
            return;
        }
        
        // Evita richieste duplice se i dati sono già stati caricati o se è in corso un caricamento
        if (isLoaded || isLoading) {
            console.log('Dataset già caricato o caricamento in corso, ignoro la richiesta');
            return;
        }
        
        await loadFullJsonl();
    };

    // Funzione per scaricare il file JSONL
    const handleDownloadJsonl = async () => {
        if (!jsonFilePath) return;
        
        try {
            // Estrai il nome del file dal percorso
            const fileName = jsonFilePath.split('/').pop();
            
            // Usa direttamente il metodo che funziona
            const jsonlResponse = await axios.get(`/api/dataset/get_full_jsonl?file_path=${encodeURIComponent(fileName)}`);
            
            if (jsonlResponse.data && Array.isArray(jsonlResponse.data.preview)) {
                // Converti i dati JSON in formato JSONL (una riga per record)
                const jsonlText = jsonlResponse.data.preview
                    .map(record => JSON.stringify(record))
                    .join('\n');
                
                // Crea un file da scaricare
                const blob = new Blob([jsonlText], { type: 'application/json' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName.replace('.enc', ''));
                
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                throw new Error('Formato risposta non valido dal server');
            }
        } catch (error) {
            console.error('Errore durante il download del file JSONL:', error);
            
            // Mostra un messaggio di errore all'utente
            setErrorMessage('Impossibile scaricare il file. Dettaglio: ' + (error.response?.data?.detail || error.message));
            setShowFullPreviewInfo(true);
        }
    };

    if (!preview || !Array.isArray(preview) || preview.length === 0) {
        return (
            <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-600'}`}>
                Nessuna anteprima JSONL disponibile
            </div>
        );
    }

    const toggleExpand = (index) => {
        setExpandedItems(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    // Funzione per espandere/comprimere tutti i record
    const toggleAllExpanded = (expand) => {
        const newState = {};
        currentItems.forEach((_, index) => {
            newState[startIndex + index] = expand;
        });
        setExpandedItems(prev => ({...prev, ...newState}));
    };

    // Funzione per colorare le chiavi e i valori JSON
    const formatJsonString = (jsonString) => {
        if (!jsonString) return '';
        
        // Sostituisci le chiavi con span colorati
        let formatted = jsonString.replace(/"([^"]+)":/g, '<span class="json-key">\"$1\"</span>:');
        
        // Sostituisci i valori stringa con span colorati
        formatted = formatted.replace(/: "([^"]+)"/g, ': <span class="json-string">\"$1\"</span>');
        
        // Sostituisci i valori numerici con span colorati
        formatted = formatted.replace(/: ([0-9]+)/g, ': <span class="json-number">$1</span>');
        
        // Sostituisci i valori booleani con span colorati
        formatted = formatted.replace(/: (true|false)/g, ': <span class="json-boolean">$1</span>');
        
        // Sostituisci null con span colorati
        formatted = formatted.replace(/: null/g, ': <span class="json-null">null</span>');
        
        return formatted;
    };

    // Determina il numero totale di record da visualizzare
    const displayTotalRecords = fullPreview 
        ? fullPreview.length 
        : Math.max(
            totalRecords || 0,
            preview?.length || 0,
            filteredPreview?.length || 0
        );
    
    // Determina il messaggio per l'anteprima parziale
    const partialPreviewMessage = isPartialPreview
        ? `Stai visualizzando ${preview.length} record su ${displayTotalRecords} totali. Puoi visualizzare il dataset completo o scaricarlo usando i pulsanti qui sotto.`
        : `Stai visualizzando ${filteredPreview.length} record del dataset${searchTerm ? ' filtrati in base alla ricerca' : ''}.`;

    return (
        <div className={`rounded-lg overflow-hidden shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'} mb-8`}>
            <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
                <h3 className={`text-lg font-semibold flex items-center ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                    <DocumentTextIcon className="w-5 h-5 mr-2" />
                    Anteprima JSONL ({displayTotalRecords} righe{isPartialPreview ? ', anteprima parziale' : ''})
                </h3>
                
                <div className="flex items-center space-x-2">
                    {jsonFilePath && (
                        <button 
                            onClick={handleDownloadJsonl}
                            className={`px-2 py-1 text-xs rounded flex items-center ${
                                isDarkMode ? 'bg-green-600 hover:bg-green-700 text-white' : 'bg-green-100 hover:bg-green-200 text-green-800'
                            }`}
                        >
                            <ArrowDownTrayIcon className="w-3 h-3 mr-1" />
                            Scarica JSONL
                        </button>
                    )}
                    <button 
                        onClick={() => toggleAllExpanded(true)}
                        className={`px-2 py-1 text-xs rounded ${
                            isDarkMode ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-blue-100 hover:bg-blue-200 text-blue-800'
                        }`}
                    >
                        Espandi tutti
                    </button>
                    <button 
                        onClick={() => toggleAllExpanded(false)}
                        className={`px-2 py-1 text-xs rounded ${
                            isDarkMode ? 'bg-gray-600 hover:bg-gray-700 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
                        }`}
                    >
                        Comprimi tutti
                    </button>
                </div>
            </div>
            
            {/* Paginazione sempre visibile */}
            <div className={`p-3 border-b ${isDarkMode ? 'bg-gray-750 border-gray-700' : 'bg-gray-50 border-gray-200'} flex justify-between items-center`}>
                <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-auto">
                    <input
                        type="text"
                        placeholder="Cerca nei record..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={`px-3 py-1 rounded text-sm w-full sm:w-64 ${
                            isDarkMode 
                                ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                                : 'bg-white border-gray-300 text-gray-700 placeholder-gray-500'
                        } border focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    />
                </div>
                
                {/* Paginazione */}
                <div className="flex items-center space-x-1">
                    <span className={`text-sm mr-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                        {filteredPreview.length === (fullPreview?.length || preview.length) 
                            ? `Pagina ${currentPage} di ${totalPages}` 
                            : `${filteredPreview.length} risultati trovati`}
                    </span>
                    <div className="flex items-center space-x-1">
                        <button
                            onClick={(e) => goToPage(1, e)}
                            disabled={currentPage === 1}
                            className={`p-1 rounded ${
                                currentPage === 1
                                    ? isDarkMode ? 'text-gray-500 cursor-not-allowed' : 'text-gray-400 cursor-not-allowed'
                                    : isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-200'
                            }`}
                            aria-label="Prima pagina"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                            </svg>
                        </button>
                        <button
                            onClick={(e) => goToPage(currentPage - 1, e)}
                            disabled={currentPage === 1}
                            className={`p-1 rounded ${
                                currentPage === 1
                                    ? isDarkMode ? 'text-gray-500 cursor-not-allowed' : 'text-gray-400 cursor-not-allowed'
                                    : isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-200'
                            }`}
                            aria-label="Pagina precedente"
                        >
                            <ChevronLeftIcon className="h-5 w-5" />
                        </button>
                        
                        {/* Paginazione numerica migliorata */}
                        <div className="hidden sm:flex space-x-1">
                            {(() => {
                                const pages = [];
                                let startPage = Math.max(1, currentPage - 2);
                                let endPage = Math.min(totalPages, startPage + 4);
                                
                                // Aggiusta l'intervallo se siamo vicini alla fine
                                if (endPage - startPage < 4) {
                                    startPage = Math.max(1, endPage - 4);
                                }
                                
                                for (let i = startPage; i <= endPage; i++) {
                                    pages.push(
                                        <button
                                            key={i}
                                            onClick={(e) => goToPage(i, e)}
                                            className={`w-8 h-8 flex items-center justify-center rounded ${
                                                currentPage === i
                                                    ? isDarkMode 
                                                        ? 'bg-blue-600 text-white' 
                                                        : 'bg-blue-500 text-white'
                                                    : isDarkMode 
                                                        ? 'text-gray-300 hover:bg-gray-700' 
                                                        : 'text-gray-700 hover:bg-gray-200'
                                            }`}
                                            aria-label={`Pagina ${i}`}
                                            aria-current={currentPage === i ? 'page' : undefined}
                                        >
                                            {i}
                                        </button>
                                    );
                                }
                                return pages;
                            })()}
                        </div>
                        
                        <button
                            onClick={(e) => goToPage(currentPage + 1, e)}
                            disabled={currentPage === totalPages}
                            className={`p-1 rounded ${
                                currentPage === totalPages
                                    ? isDarkMode ? 'text-gray-500 cursor-not-allowed' : 'text-gray-400 cursor-not-allowed'
                                    : isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-200'
                            }`}
                            aria-label="Pagina successiva"
                        >
                            <ChevronRightIcon className="h-5 w-5" />
                        </button>
                        <button
                            onClick={(e) => goToPage(totalPages, e)}
                            disabled={currentPage === totalPages}
                            className={`p-1 rounded ${
                                currentPage === totalPages
                                    ? isDarkMode ? 'text-gray-500 cursor-not-allowed' : 'text-gray-400 cursor-not-allowed'
                                    : isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-200'
                            }`}
                            aria-label="Ultima pagina"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            
            {/* Avviso anteprima parziale senza interferire con la paginazione */}
            {isPartialPreview && (
                <div className={`p-3 ${isDarkMode ? 'bg-blue-900/30 border-blue-800' : 'bg-blue-50 border-blue-100'} flex items-start`}>
                    <InformationCircleIcon className={`w-5 h-5 mr-2 flex-shrink-0 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
                    <div>
                        <p className={`text-sm ${isDarkMode ? 'text-blue-300' : 'text-blue-700'}`}>
                            {partialPreviewMessage}
                        </p>
                        <button
                            onClick={handleViewAllRecords}
                            disabled={isLoading}
                            className={`mt-2 px-3 py-1 text-xs rounded flex items-center ${
                                isDarkMode 
                                    ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                                    : 'bg-blue-500 hover:bg-blue-600 text-white'
                            } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? (
                                <>
                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Caricamento dataset completo...
                                </>
                            ) : (
                                'Visualizza tutti i record'
                            )}
                        </button>
                    </div>
                </div>
            )}
            
            {/* Messaggio informativo quando non è possibile caricare il file completo */}
            {showFullPreviewInfo && (
                <div className={`p-3 border-b ${isDarkMode ? 'bg-yellow-900/30 border-yellow-800' : 'bg-yellow-50 border-yellow-100'} flex items-start`}>
                    <InformationCircleIcon className={`w-5 h-5 mr-2 flex-shrink-0 ${isDarkMode ? 'text-yellow-400' : 'text-yellow-500'}`} />
                    <div>
                        <p className={`text-sm ${isDarkMode ? 'text-yellow-300' : 'text-yellow-700'}`}>
                            Si è verificato un problema nel caricamento di tutti i record. {errorMessage ? `Dettaglio: ${errorMessage}` : 'Potrebbero esserci problemi di decrittazione sul server.'}
                        </p>
                        <ol className={`list-decimal list-inside mt-2 ml-4 text-sm ${isDarkMode ? 'text-yellow-300' : 'text-yellow-700'}`}>
                            <li>Stai attualmente visualizzando {filteredPreview.length} record {filteredPreview.length <= 10 ? '(anteprima parziale)' : ''}</li>
                            <li>Puoi scaricare il file JSONL completo usando il pulsante "Scarica JSONL"</li>
                            <li>Se il download fallisce, contatta l'amministratore del sistema per verificare la decrittazione dei file</li>
                        </ol>
                    </div>
                </div>
            )}
            
            <style jsx="true">{`
                .json-key {
                    color: ${isDarkMode ? '#9cdcfe' : '#0451a5'};
                }
                .json-string {
                    color: ${isDarkMode ? '#ce9178' : '#a31515'};
                }
                .json-number {
                    color: ${isDarkMode ? '#b5cea8' : '#098658'};
                }
                .json-boolean {
                    color: ${isDarkMode ? '#569cd6' : '#0000ff'};
                }
                .json-null {
                    color: ${isDarkMode ? '#569cd6' : '#0000ff'};
                }
            `}</style>
            
            <div 
                ref={containerRef}
                className={`overflow-x-auto ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}
                style={{ maxHeight: '600px', overflowY: 'auto' }}
            >
                <div className="p-4">
                    {currentItems.map((line, index) => {
                        // Calcola l'indice globale
                        const globalIndex = startIndex + index;
                        
                        // Se la linea è un oggetto, convertila in stringa JSON
                        let jsonObj = safeParseJson(line);
                        
                        const isExpanded = expandedItems[globalIndex] || false;
                        
                        // Formatta il JSON in base allo stato di espansione
                        const formattedJson = isExpanded 
                            ? JSON.stringify(jsonObj, null, 2) 
                            : JSON.stringify(jsonObj);
                        
                        // Crea una versione compatta per la visualizzazione collassata
                        const compactPreview = !isExpanded ? (
                            <div className="flex items-center">
                                <span className="truncate max-w-md">
                                    {jsonObj.messages && jsonObj.messages[0] && jsonObj.messages[0].content 
                                        ? `"${jsonObj.messages[0].content.substring(0, 60)}${jsonObj.messages[0].content.length > 60 ? '...' : ''}"`
                                        : formattedJson.substring(0, 60) + (formattedJson.length > 60 ? '...' : '')}
                                </span>
                            </div>
                        ) : null;
                        
                        return (
                            <div 
                                key={globalIndex}
                                className={`mb-2 rounded-md overflow-hidden ${
                                    isDarkMode ? 'bg-gray-800 hover:bg-gray-750' : 'bg-white hover:bg-gray-50'
                                } border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} transition-all duration-200`}
                            >
                                <div 
                                    className={`px-4 py-3 flex justify-between items-center cursor-pointer ${
                                        isDarkMode ? 'bg-gray-750' : 'bg-gray-100'
                                    }`}
                                    onClick={() => toggleExpand(globalIndex)}
                                >
                                    <div className="flex items-center">
                                        <span className={`inline-flex items-center justify-center w-6 h-6 rounded-full mr-2 ${
                                            isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-700'
                                        }`}>
                                            {globalIndex + 1}
                                        </span>
                                        <span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                                            Record {globalIndex + 1}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        {compactPreview}
                                        {isExpanded ? (
                                            <ChevronUpIcon className="w-5 h-5 ml-2 flex-shrink-0" />
                                        ) : (
                                            <ChevronDownIcon className="w-5 h-5 ml-2 flex-shrink-0" />
                                        )}
                                    </div>
                                </div>
                                
                                {isExpanded && (
                                    <div className={`p-4 font-mono text-sm overflow-x-auto ${
                                        isDarkMode ? 'bg-gray-850 text-gray-300' : 'bg-white text-gray-700'
                                    }`}>
                                        <pre 
                                            dangerouslySetInnerHTML={{ 
                                                __html: formatJsonString(JSON.stringify(jsonObj, null, 2)) 
                                            }} 
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            
            {/* Paginazione inferiore per dataset grandi */}
            {totalPages > 1 && (
                <div className={`p-3 border-t ${isDarkMode ? 'bg-gray-750 border-gray-700' : 'bg-gray-50 border-gray-200'} flex justify-between items-center`}>
                    <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        Mostrando {startIndex + 1}-{endIndex} di {filteredPreview.length} record
                        {isPartialPreview && ' (anteprima parziale)'}
                    </span>
                    
                    <div className="flex items-center space-x-1">
                        <button
                            onClick={(e) => goToPage(currentPage - 1, e)}
                            disabled={currentPage === 1}
                            className={`px-3 py-1 rounded text-sm ${
                                currentPage === 1
                                    ? isDarkMode ? 'bg-gray-700 text-gray-500' : 'bg-gray-200 text-gray-400'
                                    : isDarkMode ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                            }`}
                        >
                            Precedente
                        </button>
                        
                        <button
                            onClick={(e) => goToPage(currentPage + 1, e)}
                            disabled={currentPage === totalPages}
                            className={`px-3 py-1 rounded text-sm ${
                                currentPage === totalPages
                                    ? isDarkMode ? 'bg-gray-700 text-gray-500' : 'bg-gray-200 text-gray-400'
                                    : isDarkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600'
                            }`}
                        >
                            Successiva
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default JsonPreview;