import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Search, Upload, AlertCircle, Trash2, CheckCircle } from 'lucide-react';

const API_URL = 'https://www.finetuningmax.com';

const RAGSearchInterface = ({ isDarkMode }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);

  // Carica i documenti all'avvio del componente
  useEffect(() => {
    loadDocuments();
  }, []);

  // Funzione per caricare i documenti
  const loadDocuments = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${API_URL}/api/rag/documents`,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.data) {
        setDocuments(response.data);
      }
    } catch (error) {
      console.error('Error loading RAG documents:', error);
      setError(error.response?.data?.detail || 'Errore nel caricamento dei documenti');
    } finally {
      setIsLoading(false);
    }
  };

  // Gestione upload file
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    setIsLoading(true);
    setError(null);
    setUploadProgress(0);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/api/rag/upload`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(Math.round(progress));
          }
        }
      );

      if (response.data.success) {
        setError(null);
        alert('Documento caricato con successo!');
        // Ricarica la lista dei documenti dopo il caricamento
        await loadDocuments();
      }
    } catch (error) {
      console.error('Error uploading RAG document:', error);
      setError(error.response?.data?.detail || 'Errore nel caricamento del documento');
    } finally {
      setIsLoading(false);
      setUploadProgress(0);
    }
  };

  // Gestione ricerca
  const handleSearch = async () => {
    if (!query.trim()) return;
  
    setIsLoading(true);
    setError(null);
  
    try {
      const token = localStorage.getItem('token');
      // Usa gli ID dei documenti caricati
      const documentIds = documents.map(doc => doc.id);
      
      const response = await axios.get(
        `${API_URL}/api/rag/search`,
        {
          params: { 
            query: query,
            document_ids: documentIds
          },
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
  
      if (response.data.success) {
        setResults(response.data.results);
      }
    } catch (error) {
      console.error('Error in RAG search:', error);
      setError(error.response?.data?.detail || 'Errore nella ricerca');
    } finally {
      setIsLoading(false);
    }
  };

  // Funzione per eliminare un documento
  const handleDeleteDocument = async (documentId) => {
    if (!window.confirm('Sei sicuro di voler eliminare questo documento?')) {
      return;
    }
    
    setIsLoading(true);
    setError(null);
    
    try {
      const token = localStorage.getItem('token');
      
      // Prova prima con una richiesta DELETE standard
      try {
        const response = await axios.delete(
          `${API_URL}/api/rag/documents/${documentId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        if (response.data && response.data.success) {
          // Rimuoviamo il documento dalla lista locale
          setDocuments(prevDocuments => 
            prevDocuments.filter(doc => doc.id !== documentId)
          );
          setError(null);
          return; // Usciamo dalla funzione se l'eliminazione è riuscita
        }
      } catch (deleteError) {
        console.error('DELETE request failed, trying alternative method:', deleteError);
        // Continua con il metodo alternativo se DELETE fallisce
      }
      
      // Metodo alternativo: usa POST con _method=DELETE come fallback
      const response = await axios.post(
        `${API_URL}/api/rag/documents/${documentId}/remove`,
        { _method: 'DELETE' },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data && response.data.success) {
        // Rimuoviamo il documento dalla lista locale
        setDocuments(prevDocuments => 
          prevDocuments.filter(doc => doc.id !== documentId)
        );
        setError(null);
      } else {
        throw new Error('Risposta non valida dal server');
      }
    } catch (error) {
      console.error('Error deleting RAG document:', error);
      
      // Rimuoviamo comunque il documento dall'interfaccia
      setDocuments(prevDocuments => 
        prevDocuments.filter(doc => doc.id !== documentId)
      );
      
      // Mostra un messaggio di errore più specifico
      if (error.message && error.message.includes('Network Error')) {
        setError('Il documento è stato rimosso d\'interfaccia, ma potrebbe essere ancora presente sul server. Errore di rete.');
      } else if (error.response && error.response.status === 404) {
        setError('Endpoint di eliminazione non trovato. Contatta l\'amministratore.');
      } else if (error.response && error.response.status === 403) {
        setError('Non hai i permessi per eliminare questo documento.');
      } else {
        setError(error.response?.data?.detail || 'Errore nell\'eliminazione del documento');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Funzione per eliminare più documenti
  const handleDeleteMultipleDocuments = async () => {
    if (!window.confirm(`Sei sicuro di voler eliminare ${selectedDocuments.length} documenti?`)) {
      return;
    }
    
    setIsLoading(true);
    setError(null);
    
    const deletePromises = selectedDocuments.map(async (docId) => {
      try {
        const token = localStorage.getItem('token');
        
        // Prova prima con una richiesta DELETE standard
        try {
          const response = await axios.delete(
            `${API_URL}/api/rag/documents/${docId}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            }
          );
          
          return { id: docId, success: response.data && response.data.success };
        } catch (deleteError) {
          // Metodo alternativo: usa POST con _method=DELETE come fallback
          const response = await axios.post(
            `${API_URL}/api/rag/documents/${docId}/remove`,
            { _method: 'DELETE' },
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            }
          );
          
          return { id: docId, success: response.data && response.data.success };
        }
      } catch (error) {
        console.error(`Error deleting document ${docId}:`, error);
        return { id: docId, success: false, error };
      }
    });
    
    try {
      const results = await Promise.all(deletePromises);
      
      // Rimuovi i documenti eliminati dalla lista locale
      const successfullyDeletedIds = results
        .filter(result => result.success)
        .map(result => result.id);
      
      setDocuments(prevDocuments => 
        prevDocuments.filter(doc => !successfullyDeletedIds.includes(doc.id))
      );
      
      // Verifica se ci sono stati errori
      const failedResults = results.filter(result => !result.success);
      if (failedResults.length > 0) {
        setError(`${failedResults.length} documenti non sono stati eliminati correttamente.`);
      } else {
        setError(null);
      }
      
      // Resetta la selezione
      setSelectedDocuments([]);
      setIsSelectionMode(false);
    } catch (error) {
      console.error('Error in batch deletion:', error);
      setError('Si è verificato un errore durante l\'eliminazione multipla.');
    } finally {
      setIsLoading(false);
    }
  };

  // Gestione selezione documento
  const toggleDocumentSelection = (docId) => {
    setSelectedDocuments(prevSelected => {
      if (prevSelected.includes(docId)) {
        return prevSelected.filter(id => id !== docId);
      } else {
        return [...prevSelected, docId];
      }
    });
  };

  // Seleziona/deseleziona tutti i documenti
  const toggleSelectAll = () => {
    if (selectedDocuments.length === documents.length) {
      setSelectedDocuments([]);
    } else {
      setSelectedDocuments(documents.map(doc => doc.id));
    }
  };

  return (
    <div className={`p-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      <h2 className="text-2xl font-bold mb-6">RAG Search Interface</h2>

      {/* Upload Section */}
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-4">Carica Documenti</h3>
        <div className="flex items-center space-x-4">
          <label className={`
            cursor-pointer px-4 py-2 rounded-lg
            ${isDarkMode 
              ? 'bg-blue-600 hover:bg-blue-700' 
              : 'bg-blue-500 hover:bg-blue-600'
            } text-white transition-colors duration-200
          `}>
            <Upload className="inline-block mr-2 h-5 w-5" />
            Carica File
            <input
              type="file"
              className="hidden"
              onChange={handleFileUpload}
              accept=".pdf,.txt,.doc,.docx"
            />
          </label>
          {uploadProgress > 0 && (
            <div className="flex items-center">
              <div className="w-48 h-2 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-green-500 transition-all duration-300"
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
              <span className="ml-2">{uploadProgress}%</span>
            </div>
          )}
        </div>
      </div>

      {/* Documents List Section */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Documenti Disponibili</h3>
          
          <div className="flex space-x-2">
            {documents.length > 0 && (
              <>
                <button
                  onClick={() => setIsSelectionMode(!isSelectionMode)}
                  className={`px-3 py-1 rounded text-sm ${
                    isDarkMode 
                      ? 'bg-gray-700 hover:bg-gray-600' 
                      : 'bg-gray-200 hover:bg-gray-300'
                  }`}
                >
                  {isSelectionMode ? 'Annulla' : 'Seleziona'}
                </button>
                
                {isSelectionMode && (
                  <>
                    <button
                      onClick={toggleSelectAll}
                      className={`px-3 py-1 rounded text-sm ${
                        isDarkMode 
                          ? 'bg-blue-600 hover:bg-blue-700' 
                          : 'bg-blue-500 hover:bg-blue-600'
                      } text-white`}
                    >
                      {selectedDocuments.length === documents.length 
                        ? 'Deseleziona tutti' 
                        : 'Seleziona tutti'}
                    </button>
                    
                    {selectedDocuments.length > 0 && (
                      <button
                        onClick={handleDeleteMultipleDocuments}
                        className={`px-3 py-1 rounded text-sm bg-red-500 hover:bg-red-600 text-white flex items-center`}
                        disabled={isLoading}
                      >
                        <Trash2 className="h-4 w-4 mr-1" />
                        Elimina ({selectedDocuments.length})
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        
        {documents.length === 0 ? (
          <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
            Nessun documento caricato
          </div>
        ) : (
          <div className="space-y-4">
            {documents.map((doc) => (
              <div
                key={doc.id}
                className={`p-4 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} 
                  ${isSelectionMode && selectedDocuments.includes(doc.id) 
                    ? isDarkMode ? 'border-2 border-blue-500' : 'border-2 border-blue-500' 
                    : ''
                  }`}
              >
                <div className="flex justify-between items-start">
                  <div className="flex items-start">
                    {isSelectionMode && (
                      <div 
                        className="mr-3 cursor-pointer"
                        onClick={() => toggleDocumentSelection(doc.id)}
                      >
                        {selectedDocuments.includes(doc.id) ? (
                          <CheckCircle className="h-5 w-5 text-blue-500" />
                        ) : (
                          <div className={`h-5 w-5 rounded-full border ${
                            isDarkMode ? 'border-gray-400' : 'border-gray-500'
                          }`} />
                        )}
                      </div>
                    )}
                    
                    <div>
                      <div className="font-medium">{doc.file_name || doc.id}</div>
                      {doc.metadata && (
                        <div className="text-sm text-gray-500">
                          Caricato il: {new Date(doc.metadata.timestamp).toLocaleString()}
                        </div>
                      )}
                      <div className={`text-sm mt-1 ${
                        doc.status === 'completed' ? 'text-green-500' : 
                        doc.status === 'failed' ? 'text-red-500' : 'text-yellow-500'
                      }`}>
                        {doc.status || 'Elaborato'}
                      </div>
                    </div>
                  </div>
                  
                  {/* Pulsante di eliminazione (solo se non in modalità selezione) */}
                  {!isSelectionMode && (
                    <button
                      onClick={() => handleDeleteDocument(doc.id)}
                      className={`p-2 rounded-full hover:bg-gray-200 ${
                        isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-200'
                      } transition-colors duration-200`}
                      title="Elimina documento"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Search Section */}
      <div className="mb-8">
        <div className="flex space-x-4 mb-4">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Inserisci la tua domanda..."
            className={`
              flex-1 p-2 rounded-lg border
              ${isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white' 
                : 'bg-white border-gray-300'
              } transition-colors duration-200
            `}
          />
          <button
            onClick={handleSearch}
            disabled={isLoading || documents.length === 0}
            className={`
              px-4 py-2 rounded-lg flex items-center
              ${isDarkMode 
                ? 'bg-green-600 hover:bg-green-700' 
                : 'bg-green-500 hover:bg-green-600'
              } text-white transition-colors duration-200
              disabled:opacity-50 disabled:cursor-not-allowed
            `}
          >
            <Search className="h-5 w-5 mr-2" />
            Cerca
          </button>
        </div>

        {/* Results Section */}
        {results.length > 0 && (
          <div className="space-y-4">
            {results.map((result, index) => (
              <div
                key={index}
                className={`p-4 rounded-lg ${
                  isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                }`}
              >
                <div className="mb-2">{result.text}</div>
                <div className="text-sm text-gray-500">
                  Similarità: {(result.similarity * 100).toFixed(1)}%
                </div>
                {result.metadata && (
                  <div className="text-sm text-gray-500">
                    Fonte: {result.metadata.source}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="flex items-center space-x-2 text-red-500 mt-4">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RAGSearchInterface; 