import React from 'react';
import { UserCircleIcon } from '@heroicons/react/24/solid';

const ChatMessage = ({ message, isUser, isDarkMode }) => {
  // Per determinare se il messaggio è un componente React o testo normale
  const isReactComponent = React.isValidElement(message);

  return (
    <div className="flex w-full mb-4">
      <div className={`flex ${isUser ? 'flex-row-reverse ml-auto' : 'flex-row'} ${
        isUser ? 'max-w-[80%]' : 'w-full'
      }`}>
        {/* Avatar */}
        <div className={`flex-shrink-0 ${isUser ? 'ml-3' : 'mr-3'} mt-1`}>
          {isUser ? (
            <div className="h-10 w-10 rounded-full bg-blue-600 flex items-center justify-center text-white">
              <UserCircleIcon className="h-10 w-10" />
            </div>
          ) : (
            <div className="h-10 w-10 rounded-full bg-purple-600 flex items-center justify-center text-white font-bold">
              AI
            </div>
          )}
        </div>
        
        {/* Messaggio - Con larghezza ottimizzata */}
        <div 
          className={`py-3 px-5 rounded-lg ${
            isUser 
              ? 'bg-blue-600 text-white rounded-tr-none shadow-md max-w-full' 
              : isDarkMode 
                ? 'bg-gray-600 text-white rounded-tl-none shadow-md w-full' 
                : 'bg-gray-200 text-gray-800 rounded-tl-none shadow-md w-full'
          }`}
        >
          {isReactComponent ? (
            // Se è un componente React, renderizzalo direttamente
            <div className="text-base w-full max-w-full overflow-hidden break-words">
              {message}
            </div>
          ) : (
            // Se è testo, formattalo normalmente
            <div className="text-base whitespace-pre-wrap break-words max-w-full overflow-hidden">
              {message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;