import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ProgressGraph = ({ progressData, metrics = ['overall'], isDarkMode }) => {
  // Se non ci sono dati, mostra un messaggio
  if (!progressData || progressData.length === 0) {
    return (
      <div className="flex justify-center items-center h-64 bg-gray-50 dark:bg-gray-800 rounded-lg">
        <p className="text-gray-500 dark:text-gray-400">Nessun dato di progresso disponibile</p>
      </div>
    );
  }

  // Mappatura dei colori per ciascuna metrica
  const metricColors = {
    overall: '#3b82f6', // blue
    communication: '#10b981', // green
    objection_handling: '#f59e0b', // amber
    closing: '#ef4444', // red
    product_knowledge: '#8b5cf6', // purple
    customer_rapport: '#ec4899', // pink
    persuasion: '#06b6d4', // cyan
    storytelling: '#f97316', // orange
  };

  return (
    <div className="w-full h-80">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={progressData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke={isDarkMode ? '#374151' : '#e5e7eb'} />
          <XAxis 
            dataKey="date" 
            stroke={isDarkMode ? '#9ca3af' : '#6b7280'} 
          />
          <YAxis 
            stroke={isDarkMode ? '#9ca3af' : '#6b7280'} 
          />
          <Tooltip 
            contentStyle={{ 
              backgroundColor: isDarkMode ? '#1f2937' : '#ffffff',
              borderColor: isDarkMode ? '#374151' : '#e5e7eb',
              color: isDarkMode ? '#f9fafb' : '#111827'
            }} 
          />
          <Legend />
          {metrics.map((metric) => (
            <Line 
              key={metric}
              type="monotone" 
              dataKey={metric} 
              name={getMetricDisplayName(metric)}
              stroke={metricColors[metric] || '#3b82f6'}
              activeDot={{ r: 8 }} 
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

// Funzione per ottenere il nome visualizzato di una metrica
function getMetricDisplayName(metric) {
  const metricNames = {
    overall: 'Punteggio Globale',
    communication: 'Comunicazione',
    objection_handling: 'Gestione Obiezioni',
    closing: 'Chiusura Vendita',
    product_knowledge: 'Conoscenza Prodotto',
    customer_rapport: 'Rapporto Cliente',
    persuasion: 'Capacità Persuasiva',
    storytelling: 'Storytelling'
  };

  return metricNames[metric] || metric;
}

export default ProgressGraph; 