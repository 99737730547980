import React from 'react';
import { 
  PencilSquareIcon, 
  TrashIcon, 
  XMarkIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';

/**
 * Menu di azioni per le righe della tabella
 * @param {function} onEdit - Funzione per modificare il record
 * @param {function} onDelete - Funzione per eliminare il record
 * @param {function} onAIFix - Funzione per correggere con AI
 * @param {function} onCancel - Funzione per chiudere il menu
 * @param {boolean} isDarkMode - Flag per indicare se la UI è in modalità scura
 * @param {string} position - Posizione del menu ('bottom', 'top', 'bottom-shifted')
 * @param {boolean} isFixNeeded - Flag per indicare se la correzione AI è consigliata
 * @param {string} cellType - Tipo di cella ('domanda' o 'risposta')
 * @param {boolean} isAIFixLoading - Flag per indicare se è in corso una correzione AI
 */
const ActionMenu = ({ 
  onEdit, 
  onDelete, 
  onAIFix, 
  onCancel, 
  isDarkMode, 
  position = 'bottom', 
  isFixNeeded = false, 
  cellType = '', 
  isAIFixLoading = false 
}) => {
  // Classe di animazione per il pulsante "Fix AI" quando è necessaria una correzione
  const fixButtonAnimation = isFixNeeded 
    ? 'animate-pulse shadow-md ring-2 ring-purple-500 ring-opacity-50' 
    : '';

  return (
    <div className={`absolute z-50 w-48 rounded-md shadow-lg ${
      position === 'top' 
        ? 'bottom-full right-0 mb-2' // Menu sopra il bottone
        : position === 'bottom-shifted'
          ? 'top-0 left-0 ml-[-200px]' // Menu a sinistra con offset fisso
          : 'top-0 right-0 mt-2'    // Menu sotto il bottone (default)
    } ${
      isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
    }`}>
      <div className="py-1">
        <button
          onClick={onEdit}
          className={`flex items-center w-full px-4 py-2 text-sm ${
            isDarkMode 
              ? 'text-gray-200 hover:bg-gray-700' 
              : 'text-gray-700 hover:bg-gray-50'
          }`}
        >
          <PencilSquareIcon className="h-4 w-4 mr-2" />
          Modifica
        </button>
        
        <button
          onClick={onAIFix}
          disabled={cellType === 'domanda' || isAIFixLoading} // Disabilita solo per domande o durante il caricamento
          className={`flex items-center w-full px-4 py-2 text-sm rounded ${
            cellType === 'domanda' || isAIFixLoading
              ? isDarkMode 
                ? 'text-gray-500 cursor-not-allowed' 
                : 'text-gray-400 cursor-not-allowed'
              : isDarkMode 
                ? `text-purple-300 hover:bg-gray-700 ${isFixNeeded ? 'bg-purple-900 bg-opacity-30' : ''}` 
                : `text-purple-600 hover:bg-gray-50 ${isFixNeeded ? 'bg-purple-100' : ''}`
          } ${fixButtonAnimation}`}
        >
          {isAIFixLoading ? (
            <>
              <svg className="animate-spin h-4 w-4 mr-2 text-purple-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Elaborazione...
            </>
          ) : (
            <>
              <SparklesIcon className={`h-4 w-4 mr-2 ${isFixNeeded ? 'text-yellow-400' : ''}`} />
              Fix AI
              {cellType === 'domanda' && ' (solo per risposte)'}
              {isFixNeeded && ' ✨'}
            </>
          )}
        </button>
        
        <button
          onClick={onDelete}
          className={`flex items-center w-full px-4 py-2 text-sm ${
            isDarkMode 
              ? 'text-red-300 hover:bg-gray-700' 
              : 'text-red-600 hover:bg-gray-50'
          }`}
        >
          <TrashIcon className="h-4 w-4 mr-2" />
          Elimina
        </button>
        
        <button
          onClick={onCancel}
          className={`flex items-center w-full px-4 py-2 text-sm ${
            isDarkMode 
              ? 'text-gray-200 hover:bg-gray-700' 
              : 'text-gray-700 hover:bg-gray-50'
          }`}
        >
          <XMarkIcon className="h-4 w-4 mr-2" />
          Annulla
        </button>
      </div>
    </div>
  );
};

export default ActionMenu; 