import React, { useState, useEffect } from 'react';
import { getAgentTypes, createAgent } from '../../services/agentService';

const AgentSelector = ({ onAgentSelected }) => {
  const [agentTypes, setAgentTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('base');
  const [selectedDomain, setSelectedDomain] = useState('vendita');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // Flag per tracciare se è stato già fatto un tentativo di recupero automatico
  const [hasAttemptedAutoRecover, setHasAttemptedAutoRecover] = useState(false);

  const levels = [
    { id: 'base', name: 'Base', description: 'Per principianti e nuovi venditori' },
    { id: 'intermedio', name: 'Intermedio', description: 'Per venditori con esperienza base' },
    { id: 'avanzato', name: 'Avanzato', description: 'Per venditori esperti' },
  ];

  const domains = [
    { id: 'vendita', name: 'Vendita', description: 'Tecniche di vendita generali' },
    { id: 'customer_service', name: 'Servizio Clienti', description: 'Gestione clienti e assistenza' },
    { id: 'obiezioni', name: 'Gestione Obiezioni', description: 'Superare le obiezioni dei clienti' },
    { id: 'closing', name: 'Chiusura Vendita', description: 'Tecniche per chiudere le vendite' },
  ];

  useEffect(() => {
    const fetchAgentTypes = async () => {
      setIsLoading(true);
      try {
        const types = await getAgentTypes();
        
        // Filtriamo e normalizziamo gli agenti per mostrare solo i 3 principali
        // Eliminiamo supervisor, supervision e normalizziamo i nomi
        const standardAgentTypes = [
          {
            id: 'coach',
            name: 'Coach Formativo',
            description: 'Un coach che fornisce consigli, formazione e feedback sulle tecniche di vendita.'
          },
          {
            id: 'simulator',
            name: 'Simulatore Cliente',
            description: 'Simula diversi tipi di clienti per far praticare le tecniche di vendita in scenari realistici.'
          },
          {
            id: 'analyzer',
            name: 'Analizzatore Performance',
            description: 'Analizza le performance e fornisce feedback dettagliati sulle aree di miglioramento.'
          }
        ];
        
        // Utilizziamo questi tipi standard anziché quelli recuperati dall'API
        // che potrebbero contenere duplicati o tipi non necessari
        setAgentTypes(standardAgentTypes);
        setSelectedType('coach'); // Impostiamo Coach come default
        setError(null);
      } catch (err) {
        console.error("Errore nel recupero dei tipi di agenti:", err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgentTypes();
    
    // Rimuoviamo qualsiasi tentativo di auto-recupero all'inizializzazione
    // che possa causare errori multipli in console
  }, []);

  const handleCreateAgent = async () => {
    if (!selectedType) return;

    setIsLoading(true);
    try {
      const agentConfig = {
        type: selectedType,
        level: selectedLevel,
        domain: selectedDomain,
      };

      console.log("Configurazione agente:", agentConfig);
      
      // Poiché l'endpoint di recupero dà sempre 404, procediamo direttamente alla creazione
      // senza tentare il recupero che fallirebbe comunque
      const agent = await createAgent(agentConfig);
      console.log("Agente creato:", agent);
      
      // Assicuriamoci che abbiamo un ID valido per la sessione
      if (!agent || !agent.training_session_id) {
        throw new Error("La creazione dell'agente non ha restituito un ID sessione valido");
      }
      
      onAgentSelected(agent);
      setError(null);
    } catch (err) {
      console.error("Errore nella creazione dell'agente:", err);
      setError(err.message || "Errore sconosciuto nella creazione dell'agente");
    } finally {
      setIsLoading(false);
    }
  };

  const getTypeDescription = (typeId) => {
    const type = agentTypes.find(t => t.id === typeId);
    return type ? type.description : '';
  };

  if (isLoading && agentTypes.length === 0) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">Scegli il tuo Agente Formativo</h2>
      
      {error && (
        <div className="mb-4 p-4 bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 rounded">
          Errore: {error}
        </div>
      )}
      
      <div className="space-y-6">
        {/* Selezione Tipo Agente */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Tipo di Agente
          </label>
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            {agentTypes.map((type) => (
              <div 
                key={type.id}
                className={`
                  border rounded-lg p-4 cursor-pointer transition-all
                  ${selectedType === type.id 
                    ? 'border-blue-500 bg-blue-50 dark:bg-blue-900 dark:border-blue-400' 
                    : 'border-gray-200 dark:border-gray-700 hover:border-blue-300 dark:hover:border-blue-700'}
                `}
                onClick={() => setSelectedType(type.id)}
              >
                <div className="flex items-start">
                  <div className={`
                    p-2 rounded-full mr-3
                    ${type.id === 'coach' ? 'bg-green-100 text-green-600 dark:bg-green-900 dark:text-green-300' : 
                      type.id === 'simulator' ? 'bg-yellow-100 text-yellow-600 dark:bg-yellow-900 dark:text-yellow-300' :
                      'bg-purple-100 text-purple-600 dark:bg-purple-900 dark:text-purple-300'}
                  `}>
                    {type.id === 'coach' ? (
                      <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd"></path>
                      </svg>
                    ) : type.id === 'simulator' ? (
                      <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd"></path>
                      </svg>
                    ) : (
                      <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                        <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"></path>
                      </svg>
                    )}
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white">{type.name}</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{type.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        {/* Selezione Livello */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Livello di Competenza
          </label>
          <div className="flex flex-wrap gap-2">
            {levels.map((level) => (
              <button
                key={level.id}
                type="button"
                className={`
                  px-4 py-2 rounded-md text-sm font-medium
                  ${selectedLevel === level.id 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700'}
                `}
                onClick={() => setSelectedLevel(level.id)}
              >
                {level.name}
              </button>
            ))}
          </div>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            {levels.find(l => l.id === selectedLevel)?.description}
          </p>
        </div>
        
        {/* Selezione Dominio */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Area di Formazione
          </label>
          <select
            value={selectedDomain}
            onChange={(e) => setSelectedDomain(e.target.value)}
            className="block w-full rounded-md border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            {domains.map((domain) => (
              <option key={domain.id} value={domain.id}>
                {domain.name}
              </option>
            ))}
          </select>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            {domains.find(d => d.id === selectedDomain)?.description}
          </p>
        </div>
        
        {/* Descrizione dell'Agente */}
        <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
          <h3 className="font-medium text-gray-900 dark:text-white mb-2">Il tuo agente formativo</h3>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            {selectedType && agentTypes.find(t => t.id === selectedType)?.name} di livello {selectedLevel && levels.find(l => l.id === selectedLevel)?.name} per {selectedDomain && domains.find(d => d.id === selectedDomain)?.name}.
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
            {getTypeDescription(selectedType)}
          </p>
        </div>
        
        {/* Button di creazione */}
        <div className="flex justify-end">
          <button
            type="button"
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
            onClick={handleCreateAgent}
            disabled={isLoading || !selectedType}
          >
            {isLoading ? (
              <div className="flex items-center">
                <div className="animate-spin mr-2 h-4 w-4 border-2 border-t-transparent border-white rounded-full"></div>
                <span>Creazione...</span>
              </div>
            ) : (
              'Inizia Sessione'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentSelector; 