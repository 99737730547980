import React from 'react';
import { ArrowUpOnSquareIcon, ArrowDownOnSquareIcon, DocumentPlusIcon } from '@heroicons/react/24/outline';

/**
 * Componente per la gestione dell'upload e download dei file
 * @param {Array} selectedFiles - Array di file selezionati
 * @param {function} handleFileChange - Funzione chiamata quando vengono selezionati nuovi file
 * @param {function} handleFileUpload - Funzione per caricare i file selezionati
 * @param {function} handleDownloadTemplate - Funzione per scaricare il template
 * @param {boolean} isDarkMode - Flag per indicare se la UI è in modalità scura
 * @param {string} templateFormat - Formato del template
 * @param {function} setTemplateFormat - Funzione per impostare il formato del template
 * @param {boolean} isProcessing - Flag per indicare se è in corso un'elaborazione
 */
const UploadSection = ({
  selectedFiles,
  handleFileChange,
  handleFileUpload,
  handleDownloadTemplate,
  isDarkMode,
  templateFormat,
  setTemplateFormat,
  isProcessing
}) => {
  // Riferimento all'input file nascosto
  const fileInputRef = React.useRef(null);
  
  // Funzione per attivare il click sull'input file
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  
  return (
    <div className="mb-8">
      <div className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Gestione File Dataset
        </h2>
        
        <div className="flex flex-wrap -mx-2">
          {/* Pannello di download template */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className={`h-full p-4 rounded-lg border ${isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-gray-50 border-gray-200'}`}>
              <h3 className={`text-md font-medium mb-3 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                Scarica Template
              </h3>
              <div className="mb-4">
                <label className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Formato Template
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <select
                    value={templateFormat}
                    onChange={(e) => setTemplateFormat(e.target.value)}
                    className={`block w-full rounded-md py-2 px-3 border ${
                      isDarkMode 
                        ? 'bg-gray-800 border-gray-600 text-white' 
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500`}
                  >
                    <option value="csv">CSV</option>
                    <option value="word">Word (DOCX)</option>
                    <option value="pdf">PDF</option>
                  </select>
                </div>
              </div>
              <button
                onClick={handleDownloadTemplate}
                disabled={isProcessing}
                className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                  isProcessing
                    ? 'bg-gray-400 text-white cursor-not-allowed'
                    : isDarkMode
                      ? 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500'
                      : 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500'
                }`}
              >
                <ArrowDownOnSquareIcon className="mr-2 h-5 w-5" />
                Scarica Template
              </button>
            </div>
          </div>
          
          {/* Pannello di upload */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <div className={`h-full p-4 rounded-lg border ${isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-gray-50 border-gray-200'}`}>
              <h3 className={`text-md font-medium mb-3 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                Carica File
              </h3>
              
              <div className="mb-4">
                <label className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  File Dataset
                </label>
                
                {/* Input file nascosto */}
                <input
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFileChange}
                  accept=".csv,.docx,.pdf,.doc"
                  disabled={isProcessing}
                  className="hidden"
                />
                
                {/* Pulsante personalizzato per selezionare file */}
                <div className="mt-2 flex flex-col sm:flex-row gap-3">
                  <button
                    onClick={triggerFileInput}
                    disabled={isProcessing}
                    className={`flex items-center justify-center px-4 py-2 border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                      isProcessing
                        ? 'bg-gray-400 text-white cursor-not-allowed border-gray-400'
                        : isDarkMode
                          ? 'bg-indigo-600 hover:bg-indigo-700 text-white border-transparent focus:ring-indigo-500'
                          : 'bg-indigo-600 hover:bg-indigo-700 text-white border-transparent focus:ring-indigo-500'
                    }`}
                  >
                    <DocumentPlusIcon className="mr-2 h-5 w-5" />
                    Seleziona File
                  </button>
                  
                  {/* Mostra il nome del file selezionato */}
                  <div className={`flex-1 flex items-center px-3 py-2 rounded-md ${
                    isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-700'
                  }`}>
                    <span className="truncate">
                      {selectedFiles.length > 0 
                        ? selectedFiles[0].name 
                        : 'Nessun file selezionato'}
                    </span>
                  </div>
                </div>
                
                <p className={`mt-1 text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  File CSV, Word (.docx/.doc) o PDF
                </p>
              </div>
              
              <button
                onClick={handleFileUpload}
                disabled={!selectedFiles.length || isProcessing}
                className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                  !selectedFiles.length || isProcessing
                    ? 'bg-gray-400 text-white cursor-not-allowed'
                    : isDarkMode
                      ? 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500'
                      : 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500'
                }`}
              >
                <ArrowUpOnSquareIcon className="mr-2 h-5 w-5" />
                {isProcessing ? 'Elaborazione in corso...' : 'Carica e Analizza'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadSection; 