import React, { useState, useEffect } from 'react';
import AgentChat from './AgentChat';
import { deleteSession } from '../../services/agentService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTraining } from '../../contexts/TrainingContext';

// Funzione di utilità per gestire l'autenticazione
const getToken = () => {
  return localStorage.getItem('token');
};

const AgentChatWithSidebar = ({ sessionId, agentType }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [sessionHistory, setSessionHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // Flag per evitare di caricare più volte lo stesso contenuto
  const [hasLoadedHistory, setHasLoadedHistory] = useState(false);
  const navigate = useNavigate();
  
  // Utilizziamo il contesto di training che gestisce già correttamente le sessioni
  const { sessionHistory: contextSessionHistory, loadSessionHistory, isLoading: contextLoading } = useTraining();
  
  // Converti sessionId in intero per confronti coerenti
  const numericSessionId = sessionId ? parseInt(sessionId, 10) : null;
  
  useEffect(() => {
    // Carica lo storico delle sessioni utilizzando il contesto, ma solo una volta
    // e solo se non abbiamo già sessioni
    if (sessionHistory.length === 0 && !isLoading && !hasLoadedHistory) {
      console.log('AgentChatWithSidebar: carico lo storico delle sessioni inizialmente');
      setHasLoadedHistory(true); // Segna che abbiamo già tentato di caricare
      loadSessionHistory();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Esegui solo al mount del componente
  
  // Quando lo storico viene aggiornato nel contesto, aggiorniamo lo stato locale
  useEffect(() => {
    if (Array.isArray(contextSessionHistory) && contextSessionHistory.length > 0) {
      console.log('AgentChatWithSidebar: Aggiorno sessioni dal contesto', contextSessionHistory.length);
      setSessionHistory(contextSessionHistory);
    } else if (Array.isArray(contextSessionHistory) && contextSessionHistory.length === 0) {
      // Se il backend ha restituito un array vuoto, controlliamo sessioni in localStorage
      console.log('AgentChatWithSidebar: Nessuna sessione dal contesto, controllo localStorage');
      
      try {
        const savedSessions = localStorage.getItem('known_sessions');
        if (savedSessions) {
          const parsedSessions = JSON.parse(savedSessions);
          if (Array.isArray(parsedSessions) && parsedSessions.length > 0) {
            console.log('AgentChatWithSidebar: Trovate sessioni in localStorage', parsedSessions.length);
            setSessionHistory(parsedSessions);
          }
        }
      } catch (error) {
        console.error('AgentChatWithSidebar: Errore nel recupero sessioni da localStorage', error);
      }
    }
  }, [contextSessionHistory]);
  
  // Aggiorniamo lo stato di caricamento basandoci sul contesto
  useEffect(() => {
    setIsLoading(contextLoading);
  }, [contextLoading]);
  
  const handleSessionClick = (id) => {
    navigate(`/training/chat/${id}`);
  };
  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  
  // Funzione per formattare il tipo di agente
  const getAgentTypeDisplay = (type) => {
    switch (type?.toLowerCase()) {
      case 'coach': return 'Coach Formativo';
      case 'simulator': return 'Simulatore Cliente';
      case 'analyzer': return 'Analizzatore Performance';
      default: return 'Agente';
    }
  };
  
  // Modifichiamo la funzione per accettare un ID sessione specifico
  const handleDeleteSession = async (sessionIdToDelete) => {
    if (!sessionIdToDelete) return;
    
    // Chiediamo una conferma più esplicita per l'eliminazione
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("ATTENZIONE: Questa operazione rimuoverà completamente questa sessione dalla cronologia. L'operazione non può essere annullata. Procedere?")) {
      return;
    }
    
    try {
      setIsLoading(true);
      
      console.log("Tentativo di eliminare la sessione:", sessionIdToDelete);
      
      // Utilizziamo la funzione deleteSession dal servizio
      const response = await deleteSession(sessionIdToDelete);
      console.log("Risposta eliminazione:", response);
      
      if (response.status === "success") {
        // Aggiorna la lista delle sessioni rimuovendo quella cancellata
        setSessionHistory(prevHistory => prevHistory.filter(session => session.id !== sessionIdToDelete));
        
        // Mostra un messaggio di successo
        alert('Sessione eliminata con successo');
        
        // Se abbiamo eliminato la sessione corrente, torniamo alla lista
        if (sessionIdToDelete === numericSessionId) {
          navigate('/training');
        }
      }
    } catch (error) {
      console.error("Errore nell'eliminazione della sessione:", error);
      
      // Messaggio nell'interfaccia utente
      alert(`Errore nell'eliminazione della sessione: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Renderizziamo un messaggio informativo se ci sono sessioni solo in localStorage
  const renderLocalStorageInfo = () => {
    if (Array.isArray(sessionHistory) && sessionHistory.length > 0 && 
        (!Array.isArray(contextSessionHistory) || contextSessionHistory.length === 0)) {
      return (
        <div className="px-4 py-2 text-xs bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-200 rounded-md mb-2">
          Visualizzazione sessioni da cache locale
        </div>
      );
    }
    return null;
  };
  
  return (
    <div className="flex h-full">
      {/* Sidebar con storico sessioni */}
      <div 
        className={`fixed inset-y-0 left-0 transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } w-64 bg-gray-900 text-white overflow-y-auto border-r border-gray-700 transition-transform duration-300 ease-in-out z-20`}
      >
        <div className="p-4 border-b border-gray-700 flex justify-between items-center">
          <h3 className="font-semibold">Storico Sessioni</h3>
          <button 
            onClick={toggleSidebar}
            className="text-gray-400 hover:text-white p-1"
          >
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        
        {renderLocalStorageInfo()}
        
        {isLoading || contextLoading ? (
          <div className="flex justify-center items-center h-16 py-4">
            <div className="animate-spin h-5 w-5 border-2 border-white rounded-full border-t-transparent"></div>
            <span className="ml-2">Caricamento...</span>
          </div>
        ) : (
          <ul className="p-2">
            <li className="mb-4">
              <a 
                href="/training"
                className="block p-2 bg-blue-600 text-white rounded text-center hover:bg-blue-700"
              >
                Nuova Sessione
              </a>
            </li>
            
            {Array.isArray(sessionHistory) && sessionHistory.length > 0 ? (
              sessionHistory.map(session => (
                <li 
                  key={session.id}
                  className={`mb-2 p-2 rounded hover:bg-gray-700 ${
                    session.id === numericSessionId ? 'bg-gray-700 border-l-4 border-blue-500' : ''
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div 
                      className="flex-1 cursor-pointer"
                      onClick={() => handleSessionClick(session.id)}
                    >
                      <div className="text-sm font-medium truncate">
                        {getAgentTypeDisplay(session.agent_type)}
                      </div>
                      <div className="text-xs text-gray-400 flex justify-between">
                        <span>#{session.id}</span>
                        <span>{new Date(session.created_at).toLocaleDateString()}</span>
                      </div>
                    </div>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation(); // Previene il click sulla sessione
                        handleDeleteSession(session.id);
                      }}
                      className="ml-2 p-1.5 text-gray-400 hover:text-red-500 hover:bg-gray-600 rounded-full transition-colors"
                      title="Elimina sessione"
                      aria-label="Elimina sessione"
                    >
                      <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </li>
              ))
            ) : (
              <li className="text-center text-gray-500 py-4">
                {process.env.NODE_ENV === 'development' ? 
                  'Dati simulati attivi in modalità sviluppo' : 
                  'Nessuna sessione disponibile'
                }
              </li>
            )}
          </ul>
        )}
      </div>
      
      {/* Bottone per mostrare/nascondere la sidebar - riposizionato in basso */}
      <div className={`fixed bottom-6 ${isSidebarOpen ? 'left-64' : 'left-4'} z-30 transition-all duration-300 ease-in-out`}>
        <button 
          onClick={toggleSidebar}
          className={`p-3 rounded-full bg-blue-600 text-white transition-all duration-300 ease-in-out shadow-lg hover:bg-blue-700 flex items-center justify-center`}
          title={isSidebarOpen ? "Nascondi storico" : "Mostra storico"}
          aria-label={isSidebarOpen ? "Nascondi storico" : "Mostra storico"}
        >
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d={isSidebarOpen 
              ? "M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414z" 
              : "M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"} 
              clipRule="evenodd" />
          </svg>
          {!isSidebarOpen && <span className="ml-1">Storico</span>}
        </button>
      </div>
      
      {/* Contenuto principale - AgentChat */}
      <div className={`flex-1 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
        <AgentChat sessionId={sessionId} agentType={agentType} />
      </div>
    </div>
  );
};

export default AgentChatWithSidebar; 