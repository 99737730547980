import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Dashboard from './Dashboard';
import Chatbot from './Chatbot';
import Login from './Login';
import ModelPlayground from './model-playground';
import EmbeddingsInterface from './EmbeddingsInterface';
import RAGSearchInterface from './components/rag/RAGSearchInterface';
import EnhancedRAGInterface from './components/rag/EnhancedRAGInterface';
import { Sun as SunIcon, Moon as MoonIcon, Star as StarIcon } from 'lucide-react';
import APISettingsPanel from './components/settings/APISettingsPanel';
import WidgetsPage from './components/widget-manager/WidgetsPage';
import Navbar from './components/Navbar';
import WebsiteAnalyzer from './components/WebsiteAnalyzer/WebsiteAnalyzer';
import { AuthProvider } from './contexts/AuthContext';
import RAGAnalyticsInterface from './components/rag/RAGAnalyticsInterface';
import NavigationMenu from './components/NavigationMenu';
import DatasetGenerator from './components/DatasetGenerator/DatasetGenerator';
import UserProfilePage from './pages/UserProfilePage';
import LandingPage from './pages/LandingPage';
import TrainingAgentsPage from './pages/TrainingAgentsPage';
import AgentChatPage from './pages/AgentChatPage';
import TrainingDashboardPage from './pages/TrainingDashboardPage';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [currentView, setCurrentView] = useState('dashboard');
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme') === 'dark' || 
    (!localStorage.getItem('theme') && window.matchMedia('(prefers-color-scheme: dark)').matches)
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);

  useEffect(() => {
    localStorage.setItem('darkMode', isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setCurrentView('dashboard');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Determina se la vista corrente è a schermo intero (senza padding)
  const isFullscreenView = currentView === 'chatbot';

  // Componente per le rotte protette che richiedono autenticazione
  const ProtectedRoute = ({ children }) => {
    if (!isLoggedIn) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  // Componente per il layout dell'applicazione autenticata
  const AuthenticatedLayout = ({ children }) => {
    return (
      <>
        <Navbar 
          isDarkMode={isDarkMode} 
          toggleDarkMode={toggleDarkMode}
          toggleSidebar={toggleSidebar}
          handleLogout={handleLogout}
        />
        <div className="flex flex-1 overflow-hidden">
          <div className={`${isSidebarOpen ? 'w-64' : 'w-0'} transition-all duration-300 ease-in-out overflow-hidden`}>
            <NavigationMenu
              isOpen={isSidebarOpen}
              isDarkMode={isDarkMode}
            />
          </div>
          <main className="flex-1 overflow-auto">
            {isFullscreenView ? (
              // Vista a schermo intero solo per chatbot
              <div className="h-full">
                {children}
              </div>
            ) : (
              // Viste standard con padding e bordi arrotondati
              <div className="max-w-7xl mx-auto p-6">
                <div className="transition-colors duration-200 dark:bg-gray-800 bg-white rounded-lg shadow-lg p-6">
                  {children}
                </div>
              </div>
            )}
          </main>
        </div>
      </>
    );
  };

  return (
    <AuthProvider>
      <BrowserRouter>
        <Toaster 
          position="top-right"
          toastOptions={{
            duration: 3000,
            style: {
              background: isDarkMode ? '#374151' : '#ffffff',
              color: isDarkMode ? '#f3f4f6' : '#1f2937',
            },
          }}
        />
        <div className="min-h-screen flex flex-col transition-colors duration-200 dark:bg-gray-900 bg-gray-100">
          <Routes>
            {/* Rotta pubblica per la landing page */}
            <Route 
              path="/" 
              element={
                isLoggedIn ? 
                <Navigate to="/dashboard" /> : 
                <LandingPage isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
              } 
            />
            
            {/* Rotta pubblica per il login */}
            <Route 
              path="/login" 
              element={
                isLoggedIn ? 
                <Navigate to="/dashboard" /> : 
                <>
                  <div className="bg-white dark:bg-gray-800 shadow-sm border-b border-gray-200 dark:border-gray-700 py-2 px-4">
                    <div className="w-full mx-auto h-16">
                      <div className="grid grid-cols-3 h-full">
                        <div className="flex items-center justify-start">
                          <div className="flex items-center">
                            <Link to="/" className="text-xl font-semibold text-gray-800 dark:text-white hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-200">
                              FineTuningMax
                            </Link>
                            <StarIcon className="h-5 w-5 ml-1 text-yellow-400 fill-current" />
                          </div>
                        </div>
                        
                        <div className="flex items-center justify-center">
                        </div>
                        
                        <div className="flex items-center justify-end">
                          <button
                            onClick={toggleDarkMode}
                            className={`p-2 rounded-full transition-colors duration-200 ${
                              isDarkMode 
                                ? 'bg-gray-700 text-yellow-400 hover:bg-gray-600' 
                                : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
                            }`}
                            aria-label={isDarkMode ? 'Attiva tema chiaro' : 'Attiva tema scuro'}
                          >
                            {isDarkMode ? (
                              <SunIcon className="h-5 w-5" />
                            ) : (
                              <MoonIcon className="h-5 w-5" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-md mx-auto p-6">
                    <div className="transition-colors duration-200 dark:bg-gray-800 bg-white rounded-lg shadow-lg p-6 mt-8">
                      <Login 
                        setIsLoggedIn={setIsLoggedIn} 
                        setToken={setToken} 
                        isDarkMode={isDarkMode} 
                      />
                    </div>
                  </div>
                </>
              } 
            />
            
            {/* Rotte protette che richiedono autenticazione */}
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <Dashboard 
                      isLoggedIn={isLoggedIn} 
                      token={token} 
                      onLogout={handleLogout}
                      isDarkMode={isDarkMode}
                    />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/chatbot" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <Chatbot token={token} isDarkMode={isDarkMode} />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/dataset-generator" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <DatasetGenerator 
                      isDarkMode={isDarkMode}
                      onDatasetGenerated={(data) => {
                        console.log('Dataset generato:', data);
                      }}
                    />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/playground" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <ModelPlayground token={token} isDarkMode={isDarkMode} />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/embeddings" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <EmbeddingsInterface 
                      isDarkMode={isDarkMode}
                      datasetId="your-dataset-id"
                      onComplete={() => console.log('Analisi completata')}
                    />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/rag-analytics" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <RAGAnalyticsInterface isDarkMode={isDarkMode} />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/rag-simple" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <RAGSearchInterface modelId={null} isDarkMode={isDarkMode} />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/website-analyzer" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <WebsiteAnalyzer 
                      isDarkMode={isDarkMode}
                      onDatasetGenerated={(data) => {
                        console.log('Dataset generato:', data);
                      }}
                    />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/widgets" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <WidgetsPage token={token} isDarkMode={isDarkMode} />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/settings" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <APISettingsPanel isDarkMode={isDarkMode} />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/user-profile" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <UserProfilePage token={token} isDarkMode={isDarkMode} />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/training" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <TrainingAgentsPage token={token} isDarkMode={isDarkMode} />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/training/chat/:sessionId" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <AgentChatPage token={token} isDarkMode={isDarkMode} />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            <Route 
              path="/training/dashboard" 
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <TrainingDashboardPage token={token} isDarkMode={isDarkMode} />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              } 
            />
            
            {/* Reindirizza qualsiasi altra rotta alla dashboard se autenticato, altrimenti alla landing page */}
            <Route 
              path="*" 
              element={isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/" />} 
            />
          </Routes>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;