import React from 'react';

/**
 * Componente Tabs per gestire schede di navigazione
 */
export const Tabs = ({ children, activeTab, onChange, isDarkMode = false }) => {
  // Filtra solo i componenti Tab come figli
  const validChildren = React.Children.toArray(children).filter(
    child => React.isValidElement(child) && child.type.displayName === 'Tab'
  );

  // Estrae gli id delle tab dai figli
  const tabIds = validChildren.map(child => child.props.id);

  // Verifica che activeTab sia valido, altrimenti usa il primo
  const currentTab = tabIds.includes(activeTab) ? activeTab : tabIds[0];

  // Trova il tab attivo
  const activeContent = validChildren.find(
    child => child.props.id === currentTab
  );

  return (
    <div className="tabs-container">
      <div className={`border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
        <nav className="flex -mb-px">
          {validChildren.map((child) => {
            const { id, label } = child.props;
            const isActive = id === currentTab;
            
            return (
              <button
                key={id}
                className={`py-4 px-6 text-center border-b-2 font-medium text-sm ${
                  isActive
                    ? `border-blue-500 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`
                    : `border-transparent ${
                        isDarkMode
                          ? 'text-gray-400 hover:text-gray-300 hover:border-gray-600'
                          : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      }`
                }`}
                onClick={() => onChange(id)}
                aria-current={isActive ? 'page' : undefined}
              >
                {label}
              </button>
            );
          })}
        </nav>
      </div>
      
      <div className="p-4 sm:p-6">
        {activeContent}
      </div>
    </div>
  );
};

/**
 * Componente Tab per rappresentare una singola scheda
 */
export const Tab = ({ children, id, label }) => {
  return (
    <div className="tab-content" id={`tab-${id}`}>
      {children}
    </div>
  );
};

// Aggiungi displayName per identificare i componenti Tab
Tab.displayName = 'Tab'; 