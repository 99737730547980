import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  SunIcon, 
  MoonIcon, 
  Bars3Icon, 
  SquaresPlusIcon,
  BellIcon,
  QuestionMarkCircleIcon,
  ArrowRightOnRectangleIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline';

const Navbar = ({ isDarkMode, toggleDarkMode, toggleSidebar, handleLogout }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname.substring(1) || 'dashboard'; // Rimuove lo slash iniziale

  // Utilizziamo la funzione handleLogout passata come prop
  const onLogout = () => {
    handleLogout();
    navigate('/');
  };

  // Ottieni il titolo della pagina corrente
  const getCurrentPageTitle = () => {
    switch (currentPath) {
      case 'dashboard': return 'Dashboard Fine-Tuning';
      case 'chatbot': return 'Chatbot AI';
      case 'dataset-generator': return 'Generatore Dataset';
      case 'playground': return 'Model Playground';
      case 'embeddings': return 'Embeddings';
      case 'rag-analytics': return 'RAG Analytics';
      case 'rag-simple': return 'RAG Search';
      case 'website-analyzer': return 'Website Analyzer';
      case 'widgets': return 'I miei Widget';
      case 'settings': return 'Impostazioni API';
      case 'user-profile': return 'Profilo Utente';
      default: return 'FineTuningMax';
    }
  };

  return (
    <nav className={`transition-colors duration-200 ${
      isDarkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-gray-800 border-gray-200'
    } shadow-sm border-b sticky top-0 z-10`}>
      <div className="max-w-full mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <button
              onClick={toggleSidebar}
              className={`p-2 rounded-md transition-colors duration-200 ${
                isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100'
              }`}
              aria-label="Toggle sidebar"
            >
              <Bars3Icon className="h-6 w-6" />
            </button>
            <div className="ml-4 font-medium">{getCurrentPageTitle()}</div>
          </div>
          
          <div className="flex items-center space-x-2">
            <Link
              to="/widgets"
              className={`p-2 rounded-md transition-colors duration-200 ${
                isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100'
              }`}
              aria-label="I miei Widget"
            >
              <SquaresPlusIcon className="h-5 w-5" />
            </Link>
            
            <button
              className={`p-2 rounded-md transition-colors duration-200 ${
                isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100'
              }`}
              aria-label="Notifiche"
            >
              <BellIcon className="h-5 w-5" />
            </button>
            
            <button
              className={`p-2 rounded-md transition-colors duration-200 ${
                isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100'
              }`}
              aria-label="Aiuto"
            >
              <QuestionMarkCircleIcon className="h-5 w-5" />
            </button>
            
            <button
              onClick={toggleDarkMode}
              className={`p-2 rounded-md transition-colors duration-200 ${
                isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100'
              }`}
              aria-label={isDarkMode ? 'Attiva tema chiaro' : 'Attiva tema scuro'}
            >
              {isDarkMode ? <SunIcon className="h-5 w-5" /> : <MoonIcon className="h-5 w-5" />}
            </button>
            
            <Link
              to="/user-profile"
              className={`p-2 rounded-md transition-colors duration-200 ${
                isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100'
              }`}
              aria-label="Profilo utente"
            >
              <UserCircleIcon className="h-5 w-5" />
            </Link>
            
            <button
              onClick={onLogout}
              className={`p-2 rounded-md transition-colors duration-200 ${
                isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-100'
              }`}
              aria-label="Logout"
            >
              <ArrowRightOnRectangleIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 