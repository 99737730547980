import axios from 'axios';



export const uploadDocuments = async (modelId, files, onProgress, priority = 'default') => {

    const formData = new FormData();

    files.forEach(file => formData.append('files', file));

    formData.append('priority', priority);



    try {

        const response = await axios.post(

            `/api/rag/upload/batch/${modelId}`,

            formData,

            {

                headers: {

                    'Content-Type': 'multipart/form-data',

                },

                onUploadProgress: (progressEvent) => {

                    const percentCompleted = Math.round(

                        (progressEvent.loaded * 100) / progressEvent.total

                    );

                    onProgress(percentCompleted);

                },

            }

        );

        return response.data;

    } catch (error) {

        throw new Error(error.response?.data?.detail || 'Errore durante l\'upload');

    }

};



export const getDocuments = async (modelId) => {

    try {

        const response = await axios.get(`/api/rag/documents/${modelId}`);

        return response.data;

    } catch (error) {

        throw new Error(error.response?.data?.detail || 'Errore nel recupero documenti');

    }

};



export const searchDocuments = async (modelId, query, options = {}) => {

    try {

        const response = await axios.get(`/api/rag/search/${modelId}`, {

            params: {

                query,

                n_results: options.n_results || 5,

                min_similarity: options.min_similarity || 0.7

            }

        });

        return response.data;

    } catch (error) {

        throw new Error(error.response?.data?.detail || 'Errore nella ricerca');

    }

};



export const deleteDocument = async (modelId, documentId) => {

    try {

        const response = await axios.delete(`/api/rag/documents/${modelId}/${documentId}`);

        return response.data;

    } catch (error) {

        throw new Error(error.response?.data?.detail || 'Errore nell\'eliminazione');

    }

};



export const getTaskStatus = async (taskId) => {

    try {

        const response = await axios.get(`/api/rag/tasks/${taskId}`);

        return response.data;

    } catch (error) {

        throw new Error(error.response?.data?.detail || 'Errore nel controllo stato');

    }

};



export const linkDocumentsToAgent = async (agentId, documentIds, options = {}) => {

    try {

        const response = await axios.post(

            `/api/training/agents/${agentId}/documents`,

            {

                document_ids: documentIds,

                priorities: options.priorities || {},

                categories: options.categories || {},

            },

            {

                headers: {

                    'Content-Type': 'application/json',

                },

            }

        );

        return response.data;

    } catch (error) {

        throw new Error(error.response?.data?.detail || 'Errore nell\'associazione documenti');

    }

};



export const getAgentDocuments = async (agentId) => {

    try {

        const response = await axios.get(`/api/training/agents/${agentId}/documents`);

        return response.data;

    } catch (error) {

        throw new Error(error.response?.data?.detail || 'Errore nel recupero documenti agente');

    }

};



export const categorizeDocuments = async (documentIds, category) => {

    try {

        const response = await axios.post(

            `/api/rag/documents/categorize`,

            {

                document_ids: documentIds,

                category: category,

            }

        );

        return response.data;

    } catch (error) {

        throw new Error(error.response?.data?.detail || 'Errore nella categorizzazione');

    }

}; 