import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrainingProvider } from '../contexts/TrainingContext';
import AgentSelector from '../components/training-agents/AgentSelector';
import AgentChat from '../components/training-agents/AgentChat';
import { getSessionHistory } from '../services/agentService';

const TrainingAgentsPage = () => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [sessionHistory, setSessionHistory] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [errorHistory, setErrorHistory] = useState(null);
  const [activeTab, setActiveTab] = useState('new-session');
  
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchSessionHistory = async () => {
      setIsLoadingHistory(true);
      try {
        // Assumiamo che l'ID utente sia disponibile in qualche modo (es. via context o local storage)
        const userId = localStorage.getItem('userId') || 1; // fallback a ID 1 per test
        const history = await getSessionHistory(userId);
        setSessionHistory(history);
        setErrorHistory(null);
      } catch (err) {
        setErrorHistory(err.message);
      } finally {
        setIsLoadingHistory(false);
      }
    };
    
    fetchSessionHistory();
  }, []);
  
  const handleAgentSelected = (agent) => {
    console.log("Agente selezionato:", agent);
    
    // Normalize agent type
    const normalizedType = normalizeAgentType(agent.agent_type);
    
    setSelectedAgent({
      ...agent,
      // Assicurati di usare il campo corretto per l'ID della sessione
      sessionId: agent.training_session_id,
      type: normalizedType
    });
    
    // Naviga direttamente alla pagina di chat con questo agente
    navigate(`/training/chat/${agent.training_session_id}`);
  };
  
  const handleSessionSelect = (sessionId) => {
    navigate(`/training/chat/${sessionId}`);
  };
  
  // Funzione per normalizzare i tipi di agenti
  const normalizeAgentType = (type) => {
    if (!type) return 'coach';
    
    // Converti a lowercase per confronto case-insensitive
    const lowerType = type.toLowerCase();
    
    // Mappa dei tipi di agente alle versioni standard
    if (lowerType.includes('coach') || lowerType.includes('coaching')) return 'coach';
    if (lowerType.includes('simul')) return 'simulator';
    if (lowerType.includes('analy') || lowerType === 'analysis') return 'analyzer';
    
    // Default è coach
    return 'coach';
  };

  // Funzione per ottenere il nome dell'agente
  const getAgentName = (type) => {
    const normalizedType = normalizeAgentType(type);
    
    switch (normalizedType) {
      case 'coach': return 'Coach Formativo';
      case 'simulator': return 'Simulatore Cliente';
      case 'analyzer': return 'Analizzatore Performance';
      default: return 'Agente Formativo';
    }
  };
  
  return (
    <TrainingProvider>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          Agenti di Formazione
        </h1>
        
        {/* Tabs di navigazione */}
        <div className="border-b border-gray-200 dark:border-gray-700 mb-6">
          <nav className="-mb-px flex space-x-8">
            <button
              className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === 'new-session'
                  ? 'border-blue-500 text-blue-600 dark:text-blue-400 dark:border-blue-400'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'}
              `}
              onClick={() => setActiveTab('new-session')}
            >
              Nuova Sessione
            </button>
            <button
              className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === 'history'
                  ? 'border-blue-500 text-blue-600 dark:text-blue-400 dark:border-blue-400'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'}
              `}
              onClick={() => setActiveTab('history')}
            >
              Storico Sessioni
            </button>
            <button
              className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === 'dashboard'
                  ? 'border-blue-500 text-blue-600 dark:text-blue-400 dark:border-blue-400'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'}
              `}
              onClick={() => navigate('/training/dashboard')}
            >
              Dashboard Progressi
            </button>
          </nav>
        </div>
        
        {/* Contenuto principale basato sul tab attivo */}
        {activeTab === 'new-session' && (
          <div className="grid grid-cols-1 gap-8">
            {!selectedAgent ? (
              <AgentSelector onAgentSelected={handleAgentSelected} />
            ) : (
              <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg overflow-hidden h-[70vh]">
                <AgentChat sessionId={selectedAgent.sessionId} agentType={selectedAgent.type} />
              </div>
            )}
          </div>
        )}
        
        {activeTab === 'history' && (
          <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">
              Sessioni di Formazione Recenti
            </h2>
            
            {errorHistory && (
              <div className="mb-4 p-4 bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 rounded">
                Errore nel caricamento dello storico: {errorHistory}
              </div>
            )}
            
            {isLoadingHistory ? (
              <div className="flex justify-center items-center p-8">
                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
              </div>
            ) : sessionHistory.length === 0 ? (
              <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                <p>Non hai ancora sessioni di formazione</p>
                <button
                  onClick={() => setActiveTab('new-session')}
                  className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Inizia la tua prima sessione
                </button>
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-800">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Tipo
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Livello
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Data
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Progresso
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                        Azioni
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-800">
                    {sessionHistory.map((session) => {
                      // Normalizza il tipo di agente
                      const normalizedType = normalizeAgentType(session.agent_type);
                      
                      return (
                        <tr key={session.id} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className={`
                                p-2 rounded-full mr-3
                                ${normalizedType === 'coach' ? 'bg-green-100 text-green-600 dark:bg-green-900 dark:text-green-300' : 
                                  normalizedType === 'simulator' ? 'bg-yellow-100 text-yellow-600 dark:bg-yellow-900 dark:text-yellow-300' :
                                  'bg-purple-100 text-purple-600 dark:bg-purple-900 dark:text-purple-300'}
                              `}>
                                {/* Icona basata sul tipo di agente */}
                                {normalizedType === 'coach' ? 'C' : normalizedType === 'simulator' ? 'S' : 'A'}
                              </div>
                              <div className="text-sm font-medium text-gray-900 dark:text-white">
                                {getAgentName(session.agent_type)}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                              {session.level}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                            {new Date(session.created_at).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
                              <div 
                                className="bg-blue-600 h-2.5 rounded-full" 
                                style={{ width: `${session.progress || 0}%` }}
                              ></div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button
                              onClick={() => handleSessionSelect(session.id)}
                              className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300"
                            >
                              Continua
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </TrainingProvider>
  );
};

export default TrainingAgentsPage; 