import React from 'react';
import { ExclamationTriangleIcon, CheckCircleIcon, ArrowPathIcon, CpuChipIcon } from '@heroicons/react/24/outline';

/**
 * Componente barra di stato fissa in fondo alla pagina
 * @param {boolean} hasErrors - Flag per indicare se ci sono errori nel dataset
 * @param {number} errorCount - Numero di errori nel dataset
 * @param {function} onShowErrorsOnly - Funzione per mostrare solo gli errori
 * @param {function} onShowAll - Funzione per mostrare tutti i record
 * @param {boolean} showErrorsOnly - Flag per indicare se sono mostrati solo gli errori
 * @param {function} onProceed - Funzione per procedere con la generazione JSONL
 * @param {function} onProceedAnyway - Funzione per procedere comunque ignorando gli errori
 * @param {function} onSendForFinetuning - Funzione per inviare il file per il fine-tuning
 * @param {string} jsonFilePath - Percorso del file JSONL generato
 * @param {boolean} isDarkMode - Flag per indicare se la UI è in modalità scura
 * @param {boolean} isProcessing - Flag per indicare se è in corso un'elaborazione
 */
const StatusBar = ({ 
  hasErrors, 
  errorCount, 
  onShowErrorsOnly, 
  onShowAll,
  showErrorsOnly,
  onProceed,
  onProceedAnyway,
  onSendForFinetuning,
  jsonFilePath,
  isDarkMode,
  isProcessing
}) => {
  // Debug per verificare lo stato di isProcessing
  console.log('StatusBar renderizzato', {
    hasErrors,
    errorCount,
    isProcessing,
    jsonFilePath,
    isProcessingType: typeof isProcessing,
    isButtonDisabled: isProcessing === true
  });
  
  return (
    <div className={`fixed bottom-0 left-0 right-0 p-4 shadow-lg z-10 dashboard-status-bar ${
      isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
    }`}>
      <div className="max-w-7xl mx-auto flex flex-wrap items-center justify-between">
        {/* Stato del dataset */}
        <div className="flex items-center mb-2 sm:mb-0">
          {hasErrors ? (
            <div className="flex items-center text-red-500">
              <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
              <span className="font-medium">
                {errorCount} {errorCount === 1 ? 'errore' : 'errori'} nel dataset
              </span>
            </div>
          ) : (
            <div className="flex items-center text-green-500">
              <CheckCircleIcon className="h-5 w-5 mr-2" />
              <span className="font-medium">Dataset valido</span>
            </div>
          )}
        </div>
        
        {/* Filtri e azioni */}
        <div className="flex flex-wrap items-center space-x-2">
          {/* Filtri */}
          {hasErrors && (
            <div className="flex items-center space-x-2 mr-4">
              <button
                onClick={showErrorsOnly ? onShowAll : onShowErrorsOnly}
                className={`px-3 py-1 rounded-md text-sm ${
                  isDarkMode 
                    ? 'bg-gray-700 hover:bg-gray-600 text-gray-200' 
                    : 'bg-gray-200 hover:bg-gray-300 text-gray-800'
                }`}
              >
                {showErrorsOnly ? 'Mostra tutti' : 'Mostra solo errori'}
              </button>
            </div>
          )}
          
          {/* Azioni */}
          <div className="flex items-center space-x-2">
            {/* Pulsanti per la correzione (visibili solo se ci sono errori) */}
            {hasErrors && (
              <>
                <button
                  onClick={onProceedAnyway}
                  disabled={isProcessing}
                  className={`px-3 py-1 rounded-md text-sm flex items-center ${
                    isProcessing
                      ? isDarkMode 
                        ? 'bg-gray-700 text-gray-500 cursor-not-allowed' 
                        : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                      : isDarkMode 
                        ? 'bg-yellow-600 hover:bg-yellow-700 text-white' 
                        : 'bg-yellow-500 hover:bg-yellow-600 text-white'
                  }`}
                >
                  <ArrowPathIcon className="h-4 w-4 mr-1" />
                  Procedi comunque
                </button>
                
                <button
                  onClick={onProceed}
                  disabled={isProcessing}
                  className={`px-3 py-1 rounded-md text-sm flex items-center ${
                    isProcessing
                      ? isDarkMode 
                        ? 'bg-gray-700 text-gray-500 cursor-not-allowed' 
                        : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                      : isDarkMode 
                        ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                        : 'bg-blue-500 hover:bg-blue-600 text-white'
                  }`}
                >
                  <ArrowPathIcon className="h-4 w-4 mr-1" />
                  Correggi e procedi
                </button>
              </>
            )}
            
            {/* Pulsante "Genera JSONL" - Sempre visibile e attivo quando non c'è in corso un'elaborazione */}
            <button
              onClick={() => {
                console.log('Bottone Genera JSONL cliccato', {
                  isProcessing,
                  hasErrors,
                  jsonFilePath
                });
                onProceed();
              }}
              disabled={isProcessing}
              className={`px-3 py-1 rounded-md text-sm flex items-center ${
                isProcessing
                  ? isDarkMode 
                    ? 'bg-gray-700 text-gray-500 cursor-not-allowed' 
                    : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                  : !hasErrors
                    ? isDarkMode 
                      ? 'bg-green-600 hover:bg-green-700 text-white transition-all duration-300 animate-pulse shadow-lg shadow-green-500/50' 
                      : 'bg-green-500 hover:bg-green-600 text-white transition-all duration-300 animate-pulse shadow-lg shadow-green-500/50'
                    : isDarkMode 
                      ? 'bg-green-600 hover:bg-green-700 text-white' 
                      : 'bg-green-500 hover:bg-green-600 text-white'
              }`}
            >
              <ArrowPathIcon className="h-4 w-4 mr-1" />
              {hasErrors 
                ? 'Genera JSONL' 
                : 'Genera JSONL ✓'}
            </button>
            
            {/* Pulsante "Avvia Fine-Tuning" (visibile solo se c'è un file JSONL) */}
            {jsonFilePath && (
              <button
                onClick={() => onSendForFinetuning(jsonFilePath)}
                disabled={isProcessing}
                className={`px-3 py-1 rounded-md text-sm flex items-center ${
                  isProcessing
                    ? isDarkMode 
                      ? 'bg-gray-700 text-gray-500 cursor-not-allowed' 
                      : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                    : isDarkMode 
                      ? 'bg-purple-600 hover:bg-purple-700 text-white' 
                      : 'bg-purple-500 hover:bg-purple-600 text-white'
                }`}
              >
                <CpuChipIcon className="h-4 w-4 mr-1" />
                Avvia Fine-Tuning
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusBar; 