import React from 'react';

/**
 * Componente di paginazione per la tabella
 * @param {number} currentPage - Pagina corrente
 * @param {number} totalPages - Numero totale di pagine
 * @param {function} onPageChange - Funzione chiamata quando la pagina cambia
 * @param {number} rowsPerPage - Numero di righe per pagina
 * @param {function} onRowsPerPageChange - Funzione chiamata quando il numero di righe per pagina cambia
 * @param {boolean} isDarkMode - Flag per indicare se la UI è in modalità scura
 */
const Pagination = ({ currentPage, totalPages, onPageChange, rowsPerPage, onRowsPerPageChange, isDarkMode }) => (
  <div className="mt-4 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 pt-4">
    <div className="flex items-center">
      <select
        value={rowsPerPage}
        onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
        className={`rounded-md border ${
          isDarkMode 
            ? 'bg-gray-700 border-gray-600 text-white' 
            : 'bg-white border-gray-300 text-gray-900'
        } py-1 pl-2 pr-8 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
      >
        {[10, 25, 50, 100].map(value => (
          <option key={value} value={value}>
            {value} righe
          </option>
        ))}
      </select>
    </div>

    <div className="flex items-center space-x-2">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-3 py-1 rounded-md ${
          isDarkMode 
            ? 'bg-gray-700 text-white disabled:text-gray-500' 
            : 'bg-white text-gray-700 disabled:text-gray-400'
        } border ${
          isDarkMode ? 'border-gray-600' : 'border-gray-300'
        } disabled:opacity-50`}
      >
        Precedente
      </button>

      <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
        Pagina {currentPage} di {totalPages}
      </span>

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-3 py-1 rounded-md ${
          isDarkMode 
            ? 'bg-gray-700 text-white disabled:text-gray-500' 
            : 'bg-white text-gray-700 disabled:text-gray-400'
        } border ${
          isDarkMode ? 'border-gray-600' : 'border-gray-300'
        } disabled:opacity-50`}
      >
        Successiva
      </button>
    </div>
  </div>
);

export default Pagination; 