import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import ChatbotHeader from './ChatbotHeader';
import ProductServiceForm from './ProductServiceForm';
import WelcomeMessage from './WelcomeMessage';
import { RefreshCw, ArrowUpCircle, Send } from 'lucide-react';
import ChatbotLayout from './components/ChatbotLayout';
import ChatMessage from './components/ChatMessage';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const PURPOSES = [
  { id: 'assistenza_clienti_primo_livello', label: 'Assistenza Clienti Primo Livello' },
  { id: 'training_personale', label: 'Training del Personale' },
  { id: 'analisi_vendite', label: 'Analisi delle Vendite' }
];

// Componente para la vista previa del conjunto de datos
const DatasetPreview = ({ data, isDarkMode }) => {
  if (!data || data.length === 0) return null;

  return (
    <div className={`mt-4 rounded-lg overflow-hidden border transition-colors duration-200 ${
      isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'
    }`}>
      <div className={`px-4 py-2 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
        <h3 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Anteprima Dataset ({data.length} domande e risposte)
        </h3>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
            <tr>
              <th className={`px-4 py-2 text-left text-sm font-medium ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>Domanda</th>
              <th className={`px-4 py-2 text-left text-sm font-medium ${
                isDarkMode ? 'text-gray-300' : 'text-gray-600'
              }`}>Risposta</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {data.map((pair, index) => (
              <tr key={index} className={`${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'}`}>
                <td className={`px-4 py-2 text-sm ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-900'
                }`}>{pair.domanda}</td>
                <td className={`px-4 py-2 text-sm ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-900'
                }`}>{pair.risposta}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Chatbot = ({ isDarkMode }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);
  const [preview, setPreview] = useState(null);
  const [showProductForm, setShowProductForm] = useState(false);
  const [existingQA, setExistingQA] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState('assistenza_clienti_primo_livello');
  const chatMessagesRef = useRef(null);
  const inputRef = useRef(null);
  const welcomeMessageAddedRef = useRef(false);
  const chatContainerRef = useRef(null);

  // Funzione migliorata per lo scroll
  const smoothScrollToBottom = useCallback(() => {
    if (chatMessagesRef.current) {
      const element = chatMessagesRef.current;
      element.scrollTop = element.scrollHeight;
      
      // Garantisci la visibilità dell'input con un approccio più robusto
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          
          // Assicura che l'intero container del chat sia visibile
          if (chatContainerRef.current) {
            chatContainerRef.current.scrollIntoView({ block: 'end' });
          }
        }
      }, 100);
    }
  }, []);

  // Aggiungiamo un effetto per gestire il resize della finestra
  useEffect(() => {
    const handleResize = () => {
      // Forza uno scrollToBottom quando la finestra viene ridimensionata
      smoothScrollToBottom();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [smoothScrollToBottom]);

  // Aggiungiamo un MutationObserver per monitorare le modifiche al DOM
  useEffect(() => {
    if (chatMessagesRef.current) {
      const observer = new MutationObserver(() => {
        smoothScrollToBottom();
      });
      
      observer.observe(chatMessagesRef.current, {
        childList: true,
        subtree: true
      });
      
      return () => observer.disconnect();
    }
  }, [smoothScrollToBottom]);
  
  useEffect(() => {
    smoothScrollToBottom();
  }, [messages, smoothScrollToBottom, preview]);

  useEffect(() => {
    // Verifica se il browser è Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    
    if (isSafari || isIOS) {
      // Fix specifico per Safari che ha problemi con 'position: sticky' e flex
      const fixSafariLayout = () => {
        if (chatMessagesRef.current) {
          chatMessagesRef.current.style.height = `calc(100vh - 18rem)`;
        }
        
        if (chatContainerRef.current) {
          chatContainerRef.current.style.height = '100%';
          setTimeout(() => {
            smoothScrollToBottom();
            window.scrollTo(0, document.body.scrollHeight);
          }, 500);
        }
      };
      
      fixSafariLayout();
      window.addEventListener('resize', fixSafariLayout);
      return () => window.removeEventListener('resize', fixSafariLayout);
    }
  }, [smoothScrollToBottom]);
  
  // Modifica anche all'area di input per Safari
  const isInputVisible = () => {
    if (!inputRef.current) return true;
    
    const rect = inputRef.current.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };
  
  useEffect(() => {
    const checkInputVisibility = () => {
      if (!isInputVisible() && inputRef.current) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    };
    
    // Controlla dopo ogni render e modifiche al DOM
    setTimeout(checkInputVisibility, 500);
  });

  const appendMessage = (message, isUser, preview = null, isWelcomeMessage = false) => {
    const newMessage = { content: message, isUser, preview, isWelcomeMessage };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    if (preview) {
      setPreview(preview);
    }
  };

  // Aggiungi il messaggio di benvenuto solo al primo caricamento
  useEffect(() => {
    if (!welcomeMessageAddedRef.current) {
      appendMessage(<WelcomeMessage isDarkMode={isDarkMode} />, false, null, true);
      welcomeMessageAddedRef.current = true;
    }
  }, []);

  const getAccessToken = () => {
    return localStorage.getItem('token');
  };

  const extractDownloadLink = (content) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const match = content.match(urlRegex);
    return match ? match[0] : null;
  };

  const handleProductSubmit = async (products) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/chatbot/chat`,
        { 
          type: 'products',
          products: products 
        },
        {
          headers: {
            'Authorization': `Bearer ${getAccessToken()}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const { 
        response: responseMessage, 
        phase, 
        qa_pairs, 
        allQaPairs,
        totalQuestions 
      } = response.data;
      
      setShowProductForm(false);
      
      // Primero muestra el mensaje de confirmación
      appendMessage(responseMessage, false);
      
      // Luego, en un nuevo mensaje, muestra la vista previa de las nuevas preguntas y respuestas generadas
      if (qa_pairs && qa_pairs.length > 0) {
        appendMessage(
          "Ecco le nuove domande e risposte generate per i tuoi prodotti:",
          false,
          qa_pairs
        );
        
        // Actualiza el estado general de las preguntas y respuestas
        setExistingQA(allQaPairs || []);
      }
      
    } catch (error) {
      console.error('Errore durante l\'invio dei prodotti:', error);
      appendMessage(
        'Si è verificato un errore durante l\'elaborazione dei prodotti. Riprova più tardi.',
        false
      );
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    if (!input.trim()) return;
    appendMessage(input, true);
    setInput('');
    setLoading(true);

    // Assicuriamoci che l'input sia visibile dopo l'invio con metodo più robusto
    if (window.innerWidth <= 768) { // Per dispositivi mobili
      setTimeout(() => {
        if (inputRef.current) {
          // Focus esplicito sull'input
          inputRef.current.focus();
          
          // Scroll forzato alla fine della pagina
          window.scrollTo(0, document.body.scrollHeight);
          
          smoothScrollToBottom();
        }
      }, 300);
    } else {
      setTimeout(() => {
        smoothScrollToBottom();
      }, 100);
    }

    const authToken = getAccessToken();
    if (!authToken) {
      appendMessage('Sessione scaduta. Effettua nuovamente il login.', false);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/chatbot/chat`, 
        { 
          message: input,
          purpose: selectedPurpose
        },
        { 
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const { 
        response: responseMessage, 
        phase, 
        preview, 
        downloadLink: newDownloadLink,
        showProductForm: shouldShowForm,
        existingQA: newExistingQA,
        totalQuestions
      } = response.data;

      if (shouldShowForm) {
        setShowProductForm(true);
        if (newExistingQA) {
          setExistingQA(newExistingQA);
        }
      } else {
        setShowProductForm(false);
      }

      // Aggiorna il contatore delle coppie Q&A se presente
      if (totalQuestions !== undefined && totalQuestions !== null) {
        if (newExistingQA && newExistingQA.length !== totalQuestions) {
          // Se existingQA è vuoto o diverso dal conteggio totale, aggiorniamo manualmente
          setExistingQA(newExistingQA || Array(totalQuestions).fill({}));
        } else if (!newExistingQA && existingQA.length !== totalQuestions) {
          // Creiamo un array fittizio della lunghezza corretta se manca newExistingQA
          setExistingQA(Array(totalQuestions).fill({}));
        }
      }

      if (newDownloadLink) {
        setDownloadLink(newDownloadLink);
      } else {
        const extractedLink = extractDownloadLink(responseMessage);
        if (extractedLink) setDownloadLink(extractedLink);
      }

      if (phase === 'final') {
        appendMessage(responseMessage, false, preview);
      } else {
        appendMessage(responseMessage, false);
      }

      // Assicuriamoci nuovamente che l'input sia visibile quando il loading è completato
      setTimeout(() => {
        smoothScrollToBottom();
        if (window.innerWidth <= 768) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }, 300);

    } catch (error) {
      console.error('Errore durante la chat:', error);
      if (error.response?.status === 401) {
        appendMessage('Errore di autenticazione. Prova a effettuare nuovamente il login.', false);
      } else {
        appendMessage('Si è verificato un errore. Riprova più tardi.', false);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (downloadLink) {
      const token = getAccessToken();
      if (!token) {
        appendMessage('Sessione scaduta. Effettua nuovamente il login prima di scaricare il file.', false);
        return;
      }

      try {
        // Log per debug
        console.log("Download avviato");
        console.log("Download link originale:", downloadLink);
        
        // Determina se downloadLink è già un URL completo o un percorso relativo
        const isFullUrl = downloadLink.startsWith('http://') || downloadLink.startsWith('https://');
        console.log("È un URL completo?", isFullUrl);
        
        // Aggiungiamo il token come query parameter per l'autenticazione
        const tokenParam = downloadLink.includes('?') ? `&token=${token}` : `?token=${token}`;
        
        // Costruisci l'URL completo corretto
        const downloadUrl = isFullUrl
          ? `${downloadLink}${tokenParam}`  // URL completo: usa direttamente
          : `${API_BASE_URL}${downloadLink}${tokenParam}`;  // Percorso relativo: aggiungi API_BASE_URL
        
        console.log("URL finale per il download:", downloadUrl);
        console.log("API_BASE_URL:", API_BASE_URL);
        
        console.log("Inizio richiesta axios per il download");
        const response = await axios.get(downloadUrl, {
          responseType: 'blob'  // Importante: richiedi il file come blob
        });
        console.log("Risposta ricevuta:", response.status, response.statusText);
        console.log("Tipo contenuto:", response.headers['content-type']);
        console.log("Dimensione dati:", response.data.size, "bytes");

        // Crea un oggetto URL dal blob ricevuto
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dataset.csv');
        document.body.appendChild(link);
        console.log("Link di download creato, esecuzione click");
        link.click();
        
        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        console.log("Download completato con successo");
        
        appendMessage('Download completato con successo!', false);
      } catch (error) {
        console.error('Errore durante il download del file:', error);
        console.error('Dettagli errore:', error.message);
        if (error.response) {
          console.error('Risposta errore:', error.response.status, error.response.statusText);
          console.error('Dati risposta:', error.response.data);
          appendMessage(`Errore durante il download del file: ${error.response.status} - ${error.response.statusText}`, false);
        } else if (error.request) {
          console.error('Richiesta senza risposta:', error.request);
          appendMessage('Errore di rete durante il download del file. Verifica la tua connessione e riprova.', false);
        } else {
          appendMessage('Si è verificato un errore imprevisto durante il download del file. Riprova più tardi.', false);
        }
      }
    } else {
      appendMessage('Nessun link di download disponibile. Completa prima il dataset.', false);
    }
  };

  const resetSession = async () => {
    try {
      // Primero hacemos la llamada API para resetear el servidor
      const token = localStorage.getItem('token');
      if (token) {
        await axios.post(
          `${API_BASE_URL}/chatbot/reset-session`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }
        );
      }

      // Solo después de que el servidor se haya reseteado con éxito, reseteamos el estado local
      setMessages([]);
      setInput('');
      setLoading(false);
      setDownloadLink(null);
      setPreview(null);
      setShowProductForm(false);
      setExistingQA([]);
      
      // Añade el mensaje de bienvenida
      appendMessage(<WelcomeMessage isDarkMode={isDarkMode} />, false, null, true);
      welcomeMessageAddedRef.current = true;

    } catch (error) {
      console.error('Errore durante il reset della sessione:', error);
      appendMessage('Si è verificato un errore durante il reset della sessione. Riprova più tardi.', false);
    }
  };

  const handlePurposeChange = (newPurpose) => {
    setSelectedPurpose(newPurpose);
    appendMessage(`Finalità cambiata a: ${PURPOSES.find(p => p.id === newPurpose).label}`, false);
  };

  // Funzione per preparare il download
  const prepareDownload = async () => {
    if (!existingQA || existingQA.length === 0) {
      appendMessage('Non ci sono domande e risposte da scaricare.', false);
      return;
    }

    try {
      setLoading(true);
      const token = getAccessToken();
      
      console.log("Inizio preparazione download");
      const response = await axios.get(`${API_BASE_URL}/chatbot/prepare-download`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log("Risposta prepare-download:", response.data);

      if (response.data.status === 'success') {
        // Usa download_link invece di downloadLink
        if (response.data.download_link) {
          console.log("Link di download ricevuto:", response.data.download_link);
          setDownloadLink(response.data.download_link);
          
          // Se il conteggio delle domande dal server è diverso, aggiorna lo stato
          if (response.data.totalQuestions !== existingQA.length) {
            console.log(`Aggiornamento conteggio QA: da ${existingQA.length} a ${response.data.totalQuestions}`);
            setExistingQA(response.data.existingQA || []);
          }
          
          // Avvia il download immediatamente
          setTimeout(() => handleDownload(), 500);
        } else {
          console.error("Nessun link di download nella risposta:", response.data);
          appendMessage('Errore nella preparazione del download: link mancante nella risposta.', false);
        }
      } else {
        console.error("Errore nella risposta prepare-download:", response.data.message);
        appendMessage(`Errore nella preparazione del download: ${response.data.message}`, false);
      }
    } catch (error) {
      console.error('Errore nella preparazione del download:', error);
      if (error.response) {
        console.error('Dettagli errore:', error.response.data);
        appendMessage(`Errore: ${error.response.data.message || error.message}`, false);
      } else {
        appendMessage(`Errore: ${error.message}`, false);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full w-full">
      <ChatbotHeader isDarkMode={isDarkMode} />
      
      <div className="p-0 md:p-6 h-[calc(100vh-4rem)] flex flex-col">
        <div className="max-w-7xl mx-auto h-full w-full flex flex-col">
          <div 
            className={`rounded-lg shadow-lg overflow-hidden flex-1 flex flex-col ${
              isDarkMode ? 'bg-gray-800' : 'bg-white'
            }`}
            ref={chatContainerRef}
          >
            <div className="flex h-full w-full">
              {/* Sidebar */}
              <div className={`w-64 flex-shrink-0 hidden md:block ${
                isDarkMode 
                  ? 'border-r border-gray-700 bg-gray-800' 
                  : 'border-r border-gray-200 bg-gray-50'
              }`}>
                <div className={`p-4 ${
                  isDarkMode ? 'border-b border-gray-700' : 'border-b border-gray-200'
                }`}>
                  <h2 className={`text-lg font-semibold ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    Assistente Dataset
                  </h2>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  }`}>
                    Crea il tuo dataset di fine-tuning
                  </p>
                </div>
                
                <div className="p-4">
                  <div className="mb-6">
                    <h3 className={`text-sm font-medium mb-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>
                      Scopo del Dataset
                    </h3>
                    <select
                      value={selectedPurpose}
                      onChange={(e) => handlePurposeChange(e.target.value)}
                      className={`w-full px-3 py-2 rounded-md border focus:ring-2 focus:ring-blue-500 ${
                        isDarkMode 
                          ? 'bg-gray-700 border-gray-600 text-white focus:border-blue-500' 
                          : 'bg-white border-gray-300 text-gray-900 focus:border-blue-500'
                      }`}
                    >
                      {PURPOSES.map((p) => (
                        <option key={p.id} value={p.id}>
                          {p.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <div className="mb-6">
                    <h3 className={`text-sm font-medium mb-2 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}>
                      Statistiche Dataset
                    </h3>
                    <div className={`p-3 rounded-md ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}>
                      <div className="flex items-center justify-between mb-2">
                        <span className={`text-sm ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-500'
                        }`}>
                          Coppie Q&A:
                        </span>
                        <span className={`font-medium ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>
                          {existingQA.length}
                        </span>
                      </div>
                      <div className={`w-full rounded-full h-2.5 ${
                        isDarkMode ? 'bg-gray-600' : 'bg-gray-200'
                      }`}>
                        <div 
                          className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-in-out" 
                          style={{ width: `${Math.min(100, (existingQA.length / 50) * 100)}%` }}
                        ></div>
                      </div>
                      <p className={`text-xs mt-1 ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        {existingQA.length >= 50 
                          ? "Dataset completo!" 
                          : `${existingQA.length}/50 coppie (${Math.round((existingQA.length / 50) * 100)}%)`
                        }
                      </p>
                    </div>
                  </div>
                  
                  <div className="space-y-3">
                    <button
                      onClick={prepareDownload}
                      disabled={existingQA.length === 0}
                      className={`w-full flex items-center justify-center px-4 py-2 rounded-md transition-colors duration-200 ${
                        existingQA.length === 0
                          ? isDarkMode 
                            ? 'bg-gray-700 text-gray-400 cursor-not-allowed' 
                            : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                          : 'bg-blue-600 text-white hover:bg-blue-700'
                      }`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                      </svg>
                      Scarica Dataset
                    </button>
                    
                    <button
                      onClick={resetSession}
                      className={`w-full flex items-center justify-center px-4 py-2 rounded-md transition-colors duration-200 ${
                        isDarkMode 
                          ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' 
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                      </svg>
                      Reset Conversazione
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Área principal del chat - Con ancho completo */}
              <div className="flex-1 flex flex-col overflow-hidden">
                <div className="h-full flex flex-col overflow-hidden">
                  {/* Área messaggi - Aggiunta altezza massima con overflow */}
                  <div 
                    id="chat-messages" 
                    className={`flex-1 overflow-y-auto max-h-[calc(100vh-14rem)] space-y-6 mb-4 rounded-lg p-4 md:p-6 w-full ${
                      isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}
                    ref={chatMessagesRef}
                  >
                    {messages.map((message, index) => (
                      <div key={index} className="w-full">
                        {message.isWelcomeMessage ? (
                          <WelcomeMessage isDarkMode={isDarkMode} />
                        ) : (
                          <ChatMessage 
                            message={message.content}
                            isUser={message.isUser}
                            isDarkMode={isDarkMode}
                          />
                        )}
                        {message.preview && (
                          <DatasetPreview data={message.preview} isDarkMode={isDarkMode} />
                        )}
                      </div>
                    ))}
                    {loading && (
                      <div className="flex justify-start">
                        <div className={`py-3 px-4 rounded-lg ${
                          isDarkMode ? 'bg-gray-600 text-white' : 'bg-gray-200 text-gray-800'
                        }`}>
                          <div className="flex items-center space-x-2">
                            <div className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce"></div>
                            <div className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
                            <div className="w-2 h-2 bg-indigo-500 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Formulario prodotti - modificato per garantire posizionamento stabile */}
                  {showProductForm && (
                    <div className={`mb-4 p-4 rounded-lg shadow-md ${
                      isDarkMode 
                        ? 'bg-gray-800 border border-gray-700' 
                        : 'bg-white border border-gray-200'
                    }`}>
                      <ProductServiceForm 
                        onSubmit={handleProductSubmit}
                        isDarkMode={isDarkMode}
                        existingQA={existingQA}
                      />
                    </div>
                  )}

                  {/* Area input - fissata in modo più robusto */}
                  <div 
                    className={`p-3 rounded-lg w-full z-10 sticky bottom-0 mt-auto ${
                      isDarkMode 
                        ? 'bg-gray-800 border-t border-gray-700' 
                        : 'bg-white border-t border-gray-200'
                    } shadow-lg`}
                  >
                    <div className="flex items-center space-x-4">
                      <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                        placeholder="Scrivi un messaggio..."
                        className={`flex-1 p-2 border rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 ${
                          isDarkMode 
                            ? 'bg-gray-700 border-gray-600 text-white focus:border-blue-500' 
                            : 'bg-white border-gray-300 text-gray-900 focus:border-blue-500'
                        }`}
                        disabled={loading}
                        ref={inputRef}
                      />
                      <button
                        onClick={sendMessage}
                        disabled={loading || !input.trim()}
                        className="px-3 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 inline-flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        <span>Invia</span>
                        <Send className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;