import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNotification } from '../../hooks/useNotification';
import BusinessInfoForm from './BusinessInfoForm';
import DatasetPreview from './DatasetPreview';
import DatasetEditor from './DatasetEditor';
import axiosInstance from '../../utils/axiosInstance';
import LoadingIndicator from './LoadingIndicator';

const DatasetGenerator = ({ isDarkMode }) => {
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [dataset, setDataset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [error, setError] = useState(null);
  const [generationProgress, setGenerationProgress] = useState({
    status: 'idle', // idle, generating, success, error
    message: ''
  });
  const [fixingRecordId, setFixingRecordId] = useState(null);

  const handleGenerateDataset = async (businessInfo) => {
    try {
      setLoading(true);
      setBusinessInfo(businessInfo);
      
      // Estrai num_qa_pairs dai dati
      const { num_qa_pairs, ...restBusinessInfo } = businessInfo;
      console.log("Valore di num_qa_pairs prima dell'invio:", num_qa_pairs, typeof num_qa_pairs);
      
      // Usa axiosInstance in modo coerente
      const response = await axiosInstance.post(`/dataset/generate?num_qa_pairs=${num_qa_pairs}`, restBusinessInfo);
      
      if (response.data.task_id) {
        // Polling dello stato del task
        const pollInterval = setInterval(async () => {
          try {
            const statusResponse = await axiosInstance.get(`/dataset/generate/${response.data.task_id}`);
            
            if (statusResponse.data.status === 'success') {
              clearInterval(pollInterval);
              setLoading(false);
              // Aggiorna sia il dataset che le statistiche
              console.log('Dataset ricevuto:', statusResponse.data.dataset);
              setDataset({
                qa_pairs: statusResponse.data.dataset.qa_pairs,
                statistics: statusResponse.data.dataset.statistics,
                business_info: statusResponse.data.dataset.business_info
              });
              setBusinessInfo(businessInfo);
              setGenerationProgress({
                status: 'success',
                message: 'Dataset generato con successo!'
              });
              showNotification('Dataset generato con successo', 'success');
            } else if (statusResponse.data.status === 'failed') {
              clearInterval(pollInterval);
              setLoading(false);
              setGenerationProgress({
                status: 'error',
                message: statusResponse.data.error || 'Errore nella generazione'
              });
              throw new Error(statusResponse.data.error);
            } else if (statusResponse.data.progress) {
              // Aggiorna il messaggio di progresso se disponibile
              setGenerationProgress({
                status: 'generating',
                message: statusResponse.data.progress
              });
            }
          } catch (error) {
            clearInterval(pollInterval);
            setLoading(false);
            throw error;
          }
        }, 2000);
        
        // Timeout dopo 5 minuti
        setTimeout(() => {
          clearInterval(pollInterval);
          if (loading) {
            setLoading(false);
            setError('Timeout nella generazione del dataset. Riprova più tardi.');
          }
        }, 300000);
        
      } else {
        throw new Error(response.data.message || 'Errore nella generazione del dataset');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.detail || 
                          error.message || 
                          'Errore nella generazione del dataset. Riprova più tardi.';
                          
      setError(errorMessage);
      setGenerationProgress({
        status: 'error',
        message: errorMessage
      });
      showNotification(errorMessage, 'error');
      setLoading(false);
    }
  };

  const handleUpdateQA = async (index, updatedQA) => {
    try {
      setLoading(true);
      console.log("Aggiornamento record con indice:", index, "e dati:", updatedQA);
      
      if (index === undefined || index === null) {
        throw new Error("Indice non valido per l'aggiornamento");
      }
      
      // Aggiorna il dataset locale
      setDataset(prevDataset => {
        if (!prevDataset || !prevDataset.qa_pairs) return prevDataset;
        
        const newQaPairs = [...prevDataset.qa_pairs];
        // Verifica che l'indice sia valido
        if (index >= 0 && index < newQaPairs.length) {
          newQaPairs[index] = {
            ...newQaPairs[index],
            ...updatedQA
          };
        } else {
          console.error("Indice fuori intervallo:", index);
        }
        
        return {
          ...prevDataset,
          qa_pairs: newQaPairs
        };
      });
      
      // Chiamata API opzionale - commenta se non funziona
      // try {
      //   await axiosInstance.put(`/dataset/${index}`, updatedQA);
      // } catch (apiError) {
      //   console.warn("Errore API, ma l'aggiornamento locale è riuscito:", apiError);
      // }
      
      showNotification('Record aggiornato con successo', 'success');
    } catch (error) {
      console.error("Errore durante l'aggiornamento:", error);
      showNotification(
        `Errore durante l'aggiornamento: ${error.message}`, 
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteQA = async (index) => {
    try {
      setLoading(true);
      console.log("Eliminazione record con indice:", index);
      
      if (index === undefined || index === null) {
        throw new Error("Indice non valido per l'eliminazione");
      }
      
      // Aggiorna il dataset locale rimuovendo l'elemento
      setDataset(prevDataset => {
        if (!prevDataset || !prevDataset.qa_pairs) return prevDataset;
        
        const newQaPairs = prevDataset.qa_pairs.filter((_, i) => i !== index);
        
        return {
          ...prevDataset,
          qa_pairs: newQaPairs
        };
      });
      
      // Chiamata API opzionale - commenta se non funziona
      // try {
      //   await axiosInstance.delete(`/dataset/${index}`);
      // } catch (apiError) {
      //   console.warn("Errore API, ma l'eliminazione locale è riuscita:", apiError);
      // }
      
      showNotification('Record eliminato con successo', 'success');
    } catch (error) {
      console.error("Errore durante l'eliminazione:", error);
      showNotification(
        `Errore durante l'eliminazione: ${error.message}`, 
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAIFixQA = async (index, domanda, risposta) => {
    try {
      setFixingRecordId(index);
      console.log("Correzione AI per indice:", index, "con dati:", { domanda, risposta });
      
      if (index === undefined || index === null) {
        throw new Error("Indice non valido per la correzione AI");
      }
      
      if (!risposta || !domanda) {
        throw new Error("Dati mancanti per la correzione AI");
      }
      
      // Ottieni le informazioni aziendali per fornire più contesto
      const businessInfoContext = dataset.business_info ? 
        `Nome attività: ${dataset.business_info.nome || ""}, 
         Tipo: ${dataset.business_info.tipo || ""}, 
         Finalità: ${dataset.business_info.finalita || ""}` : "";
      
      // Chiamata API per la correzione AI con prompt molto più specifico
      const response = await axiosInstance.post('/ai_correction', {
        text: risposta,
        context: `RUOLO: Sei un assistente clienti esperto per ${dataset.business_info?.nome || 'un\'attività commerciale'} (${dataset.business_info?.tipo || 'negozio'}).
        
        DOMANDA DEL CLIENTE: "${domanda}"
        
        CONTESTO AZIENDALE:
        ${businessInfoContext}
        ${dataset.business_info?.address ? `Indirizzo: ${dataset.business_info.address}` : ''}
        ${dataset.business_info?.contacts ? `Contatti: ${dataset.business_info.contacts}` : ''}
        ${dataset.business_info?.opening_hours ? `Orari: ${dataset.business_info.opening_hours}` : ''}

        ISTRUZIONI:
        1. Rispondi DIRETTAMENTE alla domanda specifica posta dal cliente
        2. Includi dettagli CONCRETI e SPECIFICI relativi all'attività
        3. Mantieni un tono professionale ma amichevole
        4. Evita frasi generiche come "siamo qui per aiutarti" o "non esitare a contattarci"
        5. La risposta deve essere INFORMATIVA e PRATICA
        6. Includi dati specifici come prezzi, procedure, tempistiche o requisiti quando rilevanti
        7. Limita la risposta a informazioni direttamente correlate alla domanda
        
        IMPORTANTE: Questa è UNA SINGOLA RISPOSTA a una domanda specifica, non una conversazione. 
        Fornisci tutte le informazioni necessarie in modo conciso ma completo.`,
        error_type: 'length'
      });
      
      if (response.data.corrected_text) {
        // Prepara i dati aggiornati
        const updatedQA = {
          risposta: response.data.corrected_text
        };
        
        // Aggiorna il dataset locale
        setDataset(prevDataset => {
          if (!prevDataset || !prevDataset.qa_pairs) return prevDataset;
          
          const newQaPairs = [...prevDataset.qa_pairs];
          // Verifica che l'indice sia valido
          if (index >= 0 && index < newQaPairs.length) {
            newQaPairs[index] = {
              ...newQaPairs[index],
              ...updatedQA
            };
          }
          
          return {
            ...prevDataset,
            qa_pairs: newQaPairs
          };
        });
        
        showNotification(
          `Testo ottimizzato: ${response.data.original_length} → ${response.data.new_length} caratteri`, 
          'success'
        );
      } else {
        throw new Error("Nessun testo corretto ricevuto dall'API");
      }
    } catch (error) {
      console.error("Errore durante la correzione AI:", error);
      showNotification(
        `Errore durante la correzione AI: ${error.message}`, 
        'error'
      );
    } finally {
      setFixingRecordId(null);
    }
  };

  const handleDownloadDataset = async () => {
    try {
      if (!dataset || !dataset.qa_pairs || dataset.qa_pairs.length === 0) {
        showNotification('Nessun dato disponibile per il download', 'error');
        return;
      }

      // Prima salviamo il dataset e otteniamo il nome del file
      const saveResponse = await axiosInstance.post('/dataset/save', dataset);
      
      if (!saveResponse.data.filename) {
        throw new Error('Nome file non ricevuto dal server');
      }

      // Ora possiamo scaricare il file criptato
      const response = await axiosInstance.get(`/chatbot/download/${saveResponse.data.filename}`, {
        responseType: 'blob'
      });

      // Crea un URL per il download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', saveResponse.data.filename.replace('.enc', '')); // Rimuove .enc dal nome del file
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      showNotification('Dataset scaricato con successo', 'success');
    } catch (error) {
      console.error('Errore durante il download del dataset:', error);
      showNotification(
        `Errore nel download del dataset: ${error.response?.data?.detail || error.message}`, 
        'error'
      );
    }
  };

  return (
    <div className={`h-full w-full ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
      <div className="max-w-7xl mx-auto h-full p-6">
        <div className={`rounded-lg shadow-lg overflow-hidden p-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <h1 className={`text-3xl font-bold mb-8 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Generatore Dataset Intelligente</h1>
          
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {error}
            </div>
          )}

          {loading && <LoadingIndicator message={generationProgress.message} isDarkMode={isDarkMode} />}

          {!dataset && !loading ? (
            <BusinessInfoForm 
              onSubmit={handleGenerateDataset}
              loading={loading}
              isDarkMode={isDarkMode}
            />
          ) : !loading && dataset?.qa_pairs ? (
            <div>
              <DatasetPreview 
                dataset={dataset.qa_pairs}
                statistics={dataset.statistics}
                businessInfo={dataset.business_info}
                isDarkMode={isDarkMode}
              />
              <DatasetEditor
                dataset={dataset.qa_pairs || []}
                onUpdateQA={handleUpdateQA}
                onDeleteQA={handleDeleteQA}
                onAIFixQA={handleAIFixQA}
                fixingRecordId={fixingRecordId}
                isDarkMode={isDarkMode}
              />
              <button
                onClick={handleDownloadDataset}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              >
                Scarica Dataset
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DatasetGenerator;