import React from 'react';
import { 
  DocumentTextIcon, 
  BookOpenIcon, 
  ChatBubbleLeftRightIcon, 
  CpuChipIcon 
} from '@heroicons/react/24/outline';

/**
 * Componente che mostra un riepilogo delle risorse dell'utente
 */
const ResourcesSummary = ({ data, isDarkMode = false }) => {
  const resources = [
    {
      name: 'Modelli AI',
      count: data.models_count,
      icon: CpuChipIcon,
      color: 'bg-indigo-100 text-indigo-600',
      description: 'Modelli fine-tuned'
    },
    {
      name: 'Dataset',
      count: data.datasets_count,
      icon: BookOpenIcon,
      color: 'bg-green-100 text-green-600',
      description: 'Dataset creati'
    },
    {
      name: 'Documenti',
      count: data.documents_count,
      icon: DocumentTextIcon,
      color: 'bg-yellow-100 text-yellow-600',
      description: 'Documenti RAG'
    },
    {
      name: 'Widget',
      count: data.widgets_count,
      icon: ChatBubbleLeftRightIcon,
      color: 'bg-blue-100 text-blue-600',
      description: 'Chatbot widget'
    }
  ];

  return (
    <div>
      <h2 className={`text-xl font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-4`}>Le Tue Risorse</h2>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {resources.map((resource) => (
          <div
            key={resource.name}
            className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} overflow-hidden shadow rounded-lg`}
          >
            <div className="p-5">
              <div className="flex items-center">
                <div className={`flex-shrink-0 rounded-md p-3 ${resource.color}`}>
                  <resource.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-500'} truncate`}>
                      {resource.name}
                    </dt>
                    <dd>
                      <div className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        {resource.count}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} px-5 py-3`}>
              <div className="text-sm">
                <span className={`${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {resource.description}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourcesSummary; 