import React, { useEffect } from 'react';
import TableCell from './TableCell';
import ActionMenu from './ActionMenu';

/**
 * Componente per visualizzare i dati del dataset in formato tabella
 * @param {Array} data - Array di oggetti con i dati da visualizzare
 * @param {boolean} isEditMode - Flag per indicare se la tabella è in modalità modifica
 * @param {boolean} isDarkMode - Flag per indicare se la UI è in modalità scura
 * @param {function} handleCellEdit - Funzione chiamata quando il contenuto di una cella viene modificato
 * @param {function} handleSaveEdit - Funzione per salvare le modifiche a una cella
 * @param {function} handleCancelEdit - Funzione per annullare le modifiche a una cella
 * @param {function} handleDeleteRecord - Funzione per eliminare un record
 * @param {function} handleAICorrection - Funzione per correggere un record con l'AI
 * @param {function} handleEditRecord - Funzione per entrare in modalità modifica per un record
 * @param {number} showActions - Indice della riga per cui mostrare il menu delle azioni
 * @param {function} handleShowActions - Funzione per mostrare il menu delle azioni
 * @param {function} handleCloseMenu - Funzione per chiudere il menu delle azioni
 * @param {string} menuPosition - Posizione del menu delle azioni ('top', 'bottom', 'left', 'right')
 * @param {number} AIFixLoadingIndex - Indice della riga per cui è in corso la correzione AI
 */
const DataTable = ({
  data,
  isEditMode,
  isDarkMode,
  handleCellEdit,
  handleSaveEdit,
  handleCancelEdit,
  handleDeleteRecord,
  handleAICorrection,
  handleEditRecord,
  showActions,
  handleShowActions,
  handleCloseMenu,
  menuPosition,
  AIFixLoadingIndex
}) => {
  // Log per debugging
  useEffect(() => {
    const dataLength = data?.length || 0;
    const dataIsArray = Array.isArray(data);
    const dataFirstRow = dataLength > 0 ? data[0] : null;
    const hasEditFlags = dataFirstRow && 
      (dataFirstRow.hasError !== undefined || 
       dataFirstRow.isDomandaEditing !== undefined || 
       dataFirstRow.isRispostaEditing !== undefined);
    
    console.log('DataTable - Informazioni dati:', {
      dataLength,
      isEditMode,
      showActions,
      dataIsArray,
      dataFirstRow,
      hasEditFlags,
      erroriPresenti: dataIsArray ? data.filter(item => item.hasError).length : 0,
      primiTreRecord: dataIsArray && dataLength > 0 ? data.slice(0, 3).map(item => ({
        domanda: item.domanda?.substring(0, 20) + (item.domanda?.length > 20 ? '...' : ''),
        risposta: item.risposta?.substring(0, 20) + (item.risposta?.length > 20 ? '...' : ''),
        hasError: item.hasError,
        hasDomandaValidation: !!item.domandaValidation,
        hasRispostaValidation: !!item.rispostaValidation,
        domandaValid: item.domandaValidation?.isValid,
        rispostaValid: item.rispostaValidation?.isValid
      })) : []
    });
  }, [data, isEditMode, showActions]);

  if (!data || data.length === 0) {
    return (
      <div className={`p-4 text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
        Nessun dato disponibile.
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className={`min-w-full table-auto divide-y ${
        isDarkMode ? 'divide-gray-700' : 'divide-gray-200'
      }`}>
        <thead className={isDarkMode ? 'bg-gray-700' : 'bg-gray-50'}>
          <tr>
            <th 
              scope="col" 
              className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                isDarkMode ? 'text-gray-300' : 'text-gray-500'
              }`}
            >
              #
            </th>
            <th 
              scope="col" 
              className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                isDarkMode ? 'text-gray-300' : 'text-gray-500'
              }`}
            >
              Domanda
            </th>
            <th 
              scope="col" 
              className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                isDarkMode ? 'text-gray-300' : 'text-gray-500'
              }`}
            >
              Risposta
            </th>
            <th 
              scope="col" 
              className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                isDarkMode ? 'text-gray-300' : 'text-gray-500'
              }`}
            >
              Stato
            </th>
            <th 
              scope="col" 
              className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
                isDarkMode ? 'text-gray-300' : 'text-gray-500'
              }`}
            >
              Azioni
            </th>
          </tr>
        </thead>
        <tbody className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
          {data.map((row, index) => (
            <tr 
              key={`row-${index}`}
              className={`${
                row.hasError 
                  ? isDarkMode 
                    ? 'bg-red-900/10' 
                    : 'bg-red-50'
                  : isDarkMode 
                    ? 'bg-gray-800' 
                    : 'bg-white'
              } transition-colors duration-200 hover:${
                isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
              }`}
            >
              <td className={`px-6 py-4 text-sm whitespace-nowrap ${
                isDarkMode ? 'text-gray-300' : 'text-gray-900'
              }`}>
                {row.originalIndex || index + 1}
              </td>
              <td className="w-5/12">
                <TableCell
                  content={row.domanda}
                  isEditing={isEditMode}
                  onChange={(idx, field, value) => {
                    console.log(`Modifica cella: index=${idx}, field=${field}, value=${value.substring(0, 20)}...`);
                    handleCellEdit(idx, field, value);
                  }}
                  isDarkMode={isDarkMode}
                  type="domanda"
                  index={index}
                  onSave={(idx, field, value) => {
                    console.log(`Salvataggio cella: index=${idx}, field=${field}, value=${value.substring(0, 20)}...`);
                    handleSaveEdit(idx, field, value);
                  }}
                  onCancel={(idx, field) => {
                    console.log(`Annullamento modifica cella: index=${idx}, field=${field}`);
                    handleCancelEdit(idx, field);
                  }}
                  row={row}
                />
              </td>
              <td className="w-5/12">
                <TableCell
                  content={row.risposta}
                  isEditing={isEditMode}
                  onChange={(idx, field, value) => {
                    console.log(`Modifica cella: index=${idx}, field=${field}, value=${value.substring(0, 20)}...`);
                    handleCellEdit(idx, field, value);
                  }}
                  isDarkMode={isDarkMode}
                  type="risposta"
                  index={index}
                  onSave={(idx, field, value) => {
                    console.log(`Salvataggio cella: index=${idx}, field=${field}, value=${value.substring(0, 20)}...`);
                    handleSaveEdit(idx, field, value);
                  }}
                  onCancel={(idx, field) => {
                    console.log(`Annullamento modifica cella: index=${idx}, field=${field}`);
                    handleCancelEdit(idx, field);
                  }}
                  row={row}
                />
              </td>
              <td className={`px-6 py-4 whitespace-nowrap text-sm ${
                row.hasError
                  ? 'text-red-500'
                  : isDarkMode ? 'text-green-400' : 'text-green-600'
              }`}>
                <div className="flex items-center">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    row.hasError
                      ? isDarkMode ? 'bg-red-900 text-red-300' : 'bg-red-100 text-red-800'
                      : isDarkMode ? 'bg-green-900 text-green-300' : 'bg-green-100 text-green-800'
                  }`}>
                    {row.hasError ? 'Errore' : 'Valido'}
                  </span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 relative">
                <div>
                  <button
                    onClick={() => handleShowActions(index, data.length)}
                    className={`inline-flex items-center justify-center p-2 rounded-full ${
                      isDarkMode 
                        ? 'hover:bg-gray-700 text-gray-400 hover:text-gray-300' 
                        : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
                    }`}
                    aria-label="Azioni"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                    </svg>
                  </button>
                  
                  {showActions === index && (
                    <ActionMenu
                      onEdit={() => {
                        console.log(`Richiesta modifica per riga ${index}`);
                        handleEditRecord(index);
                      }}
                      onDelete={() => {
                        console.log(`Richiesta eliminazione per riga ${index}`);
                        handleDeleteRecord(index);
                      }}
                      onAIFix={() => {
                        console.log(`Richiesta correzione AI per riga ${index}`);
                        handleAICorrection(index);
                      }}
                      onCancel={handleCloseMenu}
                      isDarkMode={isDarkMode}
                      position={menuPosition}
                      isFixNeeded={row.hasError}
                      isAIFixLoading={AIFixLoadingIndex === index}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable; 