import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  ChatBubbleLeftRightIcon,
  DocumentTextIcon,
  BeakerIcon,
  MagnifyingGlassIcon,
  ChartBarIcon,
  GlobeAltIcon,
  Cog6ToothIcon,
  SquaresPlusIcon,
  ArrowsPointingOutIcon,
  UserCircleIcon,
  AcademicCapIcon
} from '@heroicons/react/24/outline';

const menuItems = [
  { id: 'dashboard', path: '/dashboard', label: 'Dashboard', icon: HomeIcon },
  { id: 'chatbot', path: '/chatbot', label: 'Chatbot AI', icon: ChatBubbleLeftRightIcon },
  { id: 'dataset-generator', path: '/dataset-generator', label: 'Generatore Dataset', icon: DocumentTextIcon },
  { id: 'playground', path: '/playground', label: 'Model Playground', icon: BeakerIcon },
  { id: 'embeddings', path: '/embeddings', label: 'Embeddings', icon: ArrowsPointingOutIcon },
  { id: 'rag-analytics', path: '/rag-analytics', label: 'RAG Analytics', icon: ChartBarIcon },
  { id: 'rag-simple', path: '/rag-simple', label: 'RAG Search', icon: MagnifyingGlassIcon },
  { id: 'website-analyzer', path: '/website-analyzer', label: 'Dataset from Website', icon: GlobeAltIcon },
  { id: 'training', path: '/training', label: 'Agenti di Formazione', icon: AcademicCapIcon },
  { id: 'widgets', path: '/widgets', label: 'I miei Widget', icon: SquaresPlusIcon },
  { id: 'settings', path: '/settings', label: 'Impostazioni API', icon: Cog6ToothIcon },
  { id: 'user-profile', path: '/user-profile', label: 'Profilo Utente', icon: UserCircleIcon }
];

// Raggruppa i menu per categoria
const menuGroups = [
  {
    title: 'Principale',
    items: ['dashboard', 'chatbot', 'dataset-generator', 'playground', 'website-analyzer']
  },
  {
    title: 'Analisi & Ricerca',
    items: ['embeddings', 'rag-analytics', 'rag-simple']
  },
  {
    title: 'Formazione & Assistenza',
    items: ['training']
  },
  {
    title: 'Configurazione',
    items: ['widgets', 'settings', 'user-profile']
  }
];

const NavigationMenu = ({ isOpen, isDarkMode }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  
  if (!isOpen) return null;

  return (
    <div className={`h-full transition-colors duration-200 ${
      isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
    } border-r ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
      <div className="p-4">
        <div className="text-lg font-semibold mb-6">FineTuningMax</div>
        
        {menuGroups.map((group) => (
          <div key={group.title} className="mb-6">
            <div className={`text-xs font-semibold uppercase tracking-wider mb-2 ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              {group.title}
            </div>
            <ul>
              {group.items.map((itemId) => {
                const item = menuItems.find(m => m.id === itemId);
                if (!item) return null;
                
                const isActive = currentPath === item.path;
                const IconComponent = item.icon;
                
                return (
                  <li key={item.id} className="mb-1">
                    <Link
                      to={item.path}
                      className={`flex items-center px-3 py-2 rounded-md transition-colors duration-200 ${
                        isActive
                          ? isDarkMode
                            ? 'bg-gray-700 text-white'
                            : 'bg-blue-50 text-blue-700'
                          : isDarkMode
                            ? 'text-gray-300 hover:bg-gray-700 hover:text-white'
                            : 'text-gray-700 hover:bg-gray-100'
                      }`}
                    >
                      <IconComponent className="h-5 w-5 mr-3" />
                      <span>{item.label}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavigationMenu; 