import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TrainingProvider } from '../contexts/TrainingContext';
import AgentChatWithSidebar from '../components/training-agents/AgentChatWithSidebar';
import RagDocumentConnector from '../components/training-agents/RagDocumentConnector';
import { getSessionState } from '../services/agentService';

// Funzione di utilità per normalizzare i dati della sessione
const ensureValidSession = (session) => {
  if (!session) return null;
  
  return {
    ...session,
    messages: Array.isArray(session.messages) ? session.messages : [],
    agent_type: session.agent_type || 'coach',
    level: session.level || 'base',
    status: session.status || 'active'
  };
};

const AgentChatPage = ({ token, isDarkMode }) => {
  const { sessionId: sessionIdParam } = useParams();
  const [sessionId, setSessionId] = useState(null);
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('chat');
  const [dataLoaded, setDataLoaded] = useState(false);
  // Flag per evitare chiamate ripetute in caso di errori
  const [fetchAttempted, setFetchAttempted] = useState(false);
  
  const navigate = useNavigate();
  
  useEffect(() => {
    // Converti il parametro sessionId in un numero
    if (sessionIdParam) {
      const parsedId = parseInt(sessionIdParam, 10);
      if (isNaN(parsedId)) {
        setError('ID sessione non valido');
        setIsLoading(false);
      } else {
        setSessionId(parsedId);
        // Reset del flag dataLoaded quando cambia l'ID sessione
        setDataLoaded(false);
        // Reset del flag fetchAttempted quando cambia l'ID sessione
        setFetchAttempted(false);
      }
    } else {
      setError('ID sessione mancante');
      setIsLoading(false);
    }
  }, [sessionIdParam]);
  
  useEffect(() => {
    const fetchSessionDetails = async () => {
      // Evita tentativi multipli di fetch se abbiamo già dati o abbiamo già tentato
      if (!sessionId || dataLoaded || fetchAttempted) {
        return;
      }
      
      try {
        setIsLoading(true);
        // Segniamo che abbiamo tentato di recuperare i dati, anche in caso di errore
        setFetchAttempted(true);
        
        console.log(`Caricamento dettagli sessione ${sessionId}`);
        
        // Aggiungi un timeout di 10 secondi per evitare attese infinite
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 10000);
        
        try {
          const sessionData = await getSessionState(sessionId);
          console.log('Dettagli sessione caricati:', sessionData);
          setSession(ensureValidSession(sessionData));
          setError(null);
          setDataLoaded(true); // Imposta il flag che i dati sono stati caricati
        } catch (fetchError) {
          console.error('Errore nel tentativo di caricamento standard:', fetchError);
          
          // Se l'errore è 500, crea una sessione minima funzionante
          if (fetchError.response && fetchError.response.status === 500) {
            console.log("Creazione sessione di fallback...");
            const fallbackSession = {
              id: sessionId,
              status: 'active',
              agent_type: 'coach',
              level: 'base',
              messages: []
            };
            setSession(ensureValidSession(fallbackSession));
            setError("Alcuni dettagli della sessione non sono disponibili, ma puoi continuare la conversazione.");
            setDataLoaded(true); // Imposta il flag anche in caso di fallback
          } else {
            throw fetchError; // Rilancia per gestione errore nel catch esterno
          }
        } finally {
          clearTimeout(timeoutId);
        }
      } catch (err) {
        console.error('Errore nel caricamento della sessione:', err);
        setError(`Errore nel caricamento della sessione: ${err.message}`);
        setSession(null);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchSessionDetails();
  }, [sessionId, dataLoaded, fetchAttempted]); // Aggiungi fetchAttempted alle dipendenze
  
  const handleBackClick = () => {
    navigate('/training');
  };
  
  // Verifichiamo che sia la sessione che l'ID siano pronti prima di renderizzare l'interfaccia
  const isSessionReady = sessionId && session;
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="text-center py-8">
        <div className="bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 p-4 rounded-lg mb-4">
          {error}
        </div>
        <button
          onClick={handleBackClick}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Torna alla lista agenti
        </button>
      </div>
    );
  }
  
  return (
    <TrainingProvider>
      <div className="h-screen">
        {activeTab === 'chat' && isSessionReady ? (
          <AgentChatWithSidebar 
            sessionId={sessionId} 
            agentType={session?.agent_type || 'coach'} 
          />
        ) : activeTab === 'documents' && isSessionReady ? (
          <div className="container mx-auto px-4 py-6">
            <div className="bg-white dark:bg-gray-900 rounded-lg shadow-lg p-4 h-full">
              <div className="flex justify-between items-center mb-6">
                <button
                  onClick={handleBackClick}
                  className="text-blue-600 dark:text-blue-400 flex items-center"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                  </svg>
                  Torna alla lista
                </button>
                <div className="flex space-x-4">
                  <button
                    className="px-4 py-2 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
                    onClick={() => setActiveTab('chat')}
                  >
                    Chat
                  </button>
                  <button
                    className="px-4 py-2 rounded-md bg-blue-600 text-white"
                    onClick={() => setActiveTab('documents')}
                  >
                    Documenti
                  </button>
                </div>
              </div>
              <RagDocumentConnector 
                agentId={sessionId}
                agentType={session?.agent_type || 'coach'}
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-full text-gray-500">
            {error || 'Sessione non disponibile. Tornare alla lista agenti e riprovare.'}
          </div>
        )}
      </div>
    </TrainingProvider>
  );
};

export default AgentChatPage; 