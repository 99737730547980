import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  ArrowDownTrayIcon, 
  ArrowUpTrayIcon, 
  DocumentIcon, 
  ExclamationCircleIcon,
  CheckCircleIcon,
  CpuChipIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
  XMarkIcon,
  PencilSquareIcon,
  TrashIcon,
  SparklesIcon,
  EllipsisVerticalIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';

// Importa gli interceptor di Axios
import axios from './utils/axios-interceptors';

// Importa i componenti UI
import TableCell from './components/dashboard/TableCell.js';
import ActionMenu from './components/dashboard/ActionMenu.js';
import Pagination from './components/dashboard/Pagination.js';
import StatusBar from './components/dashboard/StatusBar.js';
import DataTable from './components/dashboard/DataTable.js';
import UploadSection from './components/dashboard/UploadSection.js';
import MessageLog from './components/dashboard/MessageLog.js';
import JsonPreview from './components/dashboard/JsonPreview.js';
import DatasetExpansionOptions from './components/dashboard/DatasetExpansionOptions.js';

// Importa gli hooks personalizzati
import useTableData from './hooks/useTableData.js';
import useFileOperations from './hooks/useFileOperations.js';
import useAICorrection from './hooks/useAICorrection.js';
import useFinetuning from './hooks/useFinetuning.js';

// Importa la configurazione dell'API
import { API_BASE_URL } from './config/api.js';

// Importa gli stili
import './index.css';

// Stili globali per la scrollbar personalizzata
const globalStyles = `
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(156, 163, 175, 0.5);
    border-radius: 3px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(156, 163, 175, 0.7);
  }
`;

/**
 * Componente principale Dashboard
 * Gestisce la visualizzazione e manipolazione dei dataset per il fine-tuning
 */
const Dashboard = ({ onLogout, isDarkMode }) => {
  // Ref per lo scroll automatico dei messaggi
  const messagesEndRef = useRef(null);
  
  // Stato per i messaggi di log
  const [messages, setMessages] = useState([]);
  
  // Stato per i messaggi di sistema
  const [systemMessage, setSystemMessage] = useState({
    text: '',
    type: 'info' // 'info', 'success', 'warning', 'error'
  });
  
  // Stato per mostrare il messaggio informativo durante il fine-tuning
  const [showInfoMessage, setShowInfoMessage] = useState(false);
  
  // Callback per aggiungere messaggi al log
  const appendMessage = useCallback((message, isUser = false) => {
    setMessages(prevMessages => [...prevMessages, { content: message, isUser }]);
  }, []);
  
  // Funzione per validare le celle
  const validateCell = useCallback((field, value) => {
    if (!value || !value.trim()) {
      return {
        isValid: false,
        message: `Il campo ${field === 'domanda' ? 'domanda' : 'risposta'} non può essere vuoto`
      };
    }
    
    switch (field) {
      case 'risposta':
        if (value.length > 500) {
          return { 
            isValid: false, 
            message: `La risposta supera il limite di 500 caratteri (attuale: ${value.length} caratteri)` 
          };
        }
        if (value.length < 10) {
          return { 
            isValid: false, 
            message: `La risposta è troppo breve. Minimo 10 caratteri (attuale: ${value.length} caratteri)` 
          };
        }
        break;
        
      case 'domanda':
        if (value.length > 150) {
          return { 
            isValid: false, 
            message: `La domanda supera il limite di 150 caratteri (attuale: ${value.length} caratteri)` 
          };
        }
        if (value.length < 5) {
          return { 
            isValid: false, 
            message: `La domanda è troppo breve. Minimo 5 caratteri (attuale: ${value.length} caratteri)` 
          };
        }
        break;
        
      default:
        return { isValid: false, message: "Campo non valido" };
    }
    
    return { 
      isValid: true, 
      message: `${field === 'domanda' ? 'Domanda' : 'Risposta'} valida (${value.length} caratteri)` 
    };
  }, []);
  
  // Hook per la gestione dei dati della tabella
  const tableData = useTableData(validateCell);
  
  // Hook per la gestione delle operazioni sui file
  const fileOperations = useFileOperations(appendMessage);
  
  // Hook per la gestione della correzione AI
  const aiCorrection = useAICorrection(appendMessage);
  
  // Hook per la gestione del fine-tuning
  const {
    isFineTuning,
    fineTuneStatus,
    startTime,
    elapsedTime,
    progress,
    setIsFineTuning,
    setFineTuneStatus,
    setStartTime,
    setElapsedTime,
    setProgress,
    formatElapsedTime,
    startFineTuning,
    pollRealJobId,
    monitorFineTuneStatus
  } = useFinetuning(appendMessage);
  
  // Effetto per lo scroll automatico dei messaggi
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);
  
  // Effetto per mostrare il messaggio informativo durante il fine-tuning
  useEffect(() => {
    if (isFineTuning) {
      const timer = setTimeout(() => {
        setShowInfoMessage(true);
      }, 30000); // Mostra il messaggio dopo 30 secondi
      
      return () => clearTimeout(timer);
    } else {
      setShowInfoMessage(false);
    }
  }, [isFineTuning]);
  
  // Listener per l'evento aiCorrectionComplete
  useEffect(() => {
    const handleAICorrectionComplete = (event) => {
      console.log('Evento aiCorrectionComplete ricevuto:', event.detail);
      
      // Forza un aggiornamento delle statistiche
      if (tableData && typeof tableData.updateStats === 'function') {
        console.log('Forzo aggiornamento statistiche da Dashboard in risposta a evento aiCorrectionComplete');
        tableData.updateStats();
        
        // Mostra una notifica all'utente
        if (event.detail.success) {
          setSystemMessage({
            text: `Correzione AI completata con successo per ${event.detail.fieldName === 'domanda' ? 'la domanda' : 'la risposta'}`,
            type: 'success'
          });
        }
      }
    };
    
    // Registra il listener per l'evento
    document.addEventListener('aiCorrectionComplete', handleAICorrectionComplete);
    
    // Pulizia al dismount
    return () => {
      document.removeEventListener('aiCorrectionComplete', handleAICorrectionComplete);
    };
  }, [tableData, setSystemMessage]);
  
  // Listener per l'evento datasetValidated
  useEffect(() => {
    const handleDatasetValidated = (event) => {
      console.log('Evento datasetValidated ricevuto:', event.detail);
      
      // Mostra una notifica all'utente
      setSystemMessage({
        text: `Dataset completamente valido! Ora puoi generare il file JSONL per il fine-tuning.`,
        type: 'success'
      });
      
      // Forza un re-render del componente StatusBar
      const statusBarElement = document.querySelector('.fixed.bottom-0');
      if (statusBarElement) {
        const tempClass = 'force-update-' + Date.now();
        statusBarElement.classList.add(tempClass);
        setTimeout(() => statusBarElement.classList.remove(tempClass), 50);
      }
    };
    
    // Registra il listener per l'evento
    document.addEventListener('datasetValidated', handleDatasetValidated);
    
    // Pulizia al dismount
    return () => {
      document.removeEventListener('datasetValidated', handleDatasetValidated);
    };
  }, [setSystemMessage]);
  
  // Gestore per il cambiamento del numero di varianti
  const handleNumVariantsChange = (e) => {
    const value = parseInt(e.target.value);
    fileOperations.setNumVariants(value >= 10 ? value : 10); // Imposta 10 come minimo
  };
  
  // Gestore per scaricare il template
  const handleDownloadTemplate = async () => {
    await fileOperations.handleDownloadTemplate('assistenza_clienti_primo_livello');
  };
  
  // Gestore per caricare e analizzare il file
  const handleFileUpload = async () => {
    try {
      const result = await fileOperations.handleFileUpload();
      if (result) {
        // Nel caso in cui result sia un task_id, monitorarlo per ottenere il risultato
        if (typeof result === 'string') {
          const taskResult = await fileOperations.monitorTaskStatus(result);
          handleTaskCompletion(taskResult);
        } else {
          // Se result è già un oggetto con i dati, elaborarlo direttamente
          handleTaskCompletion(result);
        }
      }
    } catch (error) {
      console.error('Errore durante il caricamento del file:', error);
      appendMessage(`Errore durante il caricamento: ${error.message}`, false);
    } finally {
      // Assicuriamoci che isProcessing venga reimpostato a false
      console.log('Fine handleFileUpload, reimposto isProcessing a false');
      fileOperations.setIsProcessing(false);
    }
  };
  
  // Gestore per la correzione AI di una riga
  const handleAICorrection = async (index) => {
    console.log('Richiesta correzione AI per riga:', index, {
      isEditMode: tableData.isEditMode,
      hasEditedData: !!tableData.editedData,
      editedDataType: typeof tableData.editedData,
      hasPreviewData: !!tableData.previewData,
      previewDataLength: tableData.previewData?.length,
      hasPaginatedData: !!tableData.paginatedData,
      paginatedDataLength: tableData.paginatedData?.length
    });
    
    // Imposta lo stato di caricamento
    tableData.setAIFixLoadingIndex?.(index);
    
    // Assicurati che la modalità di modifica sia attiva
    if (!tableData.isEditMode) {
      console.log('Attivazione modalità di modifica per la correzione AI');
      tableData.setIsEditMode(true);
      
      // Crea una copia profonda dei dati se editedData non esiste
      if (!tableData.editedData) {
        tableData.setEditedData([...tableData.previewData]);
        // Piccolo ritardo per assicurarsi che i dati siano aggiornati
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    }
    
    // Verifica che l'indice sia valido
    if (index < 0 || index >= tableData.paginatedData.length) {
      console.error('Indice non valido per la correzione AI:', {
        index,
        paginatedDataLength: tableData.paginatedData.length
      });
      appendMessage("Errore: indice non valido per la correzione AI", false);
      return;
    }
    
    // Ora possiamo procedere con la correzione AI
    await aiCorrection.handleAICorrection(
      index,
      tableData.editedData,
      tableData.paginatedData,
      tableData.currentPage,
      tableData.rowsPerPage,
      tableData.setEditedData,
      tableData.updateStats,
      validateCell,
      tableData.previewData,
      tableData.setIsEditMode
    );
  };
  
  // Gestore per procedere con la generazione JSONL
  const handleProceed = async () => {
    console.log('handleProceed chiamato', {
      isProcessing: fileOperations.isProcessing,
      hasErrors,
      stats: tableData.stats,
      previewDataLength: tableData.previewData?.length,
      editedDataLength: tableData.editedData?.length,
      isEditMode: tableData.isEditMode,
      jsonFilePath: fileOperations.jsonFilePath,
      useExpansion: fileOperations.useExpansion,
      numVariants: fileOperations.numVariants
    });
    
    // Se c'è già un'elaborazione in corso, non procedere
    if (fileOperations.isProcessing) {
      console.log('Operazione in corso, non posso procedere');
      return;
    }
    
    // Imposta esplicitamente isProcessing a true all'inizio
    fileOperations.setIsProcessing(true);
    
    try {
      console.log('Inizio generazione JSONL');
      
      // Determina quali dati utilizzare (editedData se in modalità modifica, altrimenti previewData)
      const dataToUse = tableData.isEditMode ? tableData.editedData : tableData.previewData;
      
      console.log(`Utilizzo ${tableData.isEditMode ? 'editedData' : 'previewData'} per la generazione JSONL`, {
        dataLength: dataToUse?.length,
        firstRecord: dataToUse?.[0],
        dataType: typeof dataToUse,
        isArray: Array.isArray(dataToUse)
      });
      
      // Passa i dati alla funzione handleGenerateJson
      const result = await fileOperations.handleGenerateJson(false, dataToUse);
      console.log('Risultato generazione JSONL:', result);
      
      if (!result) {
        console.error('Nessun risultato ricevuto da handleGenerateJson');
        return;
      }
      
      // Gestiamo sia il caso in cui riceviamo un task_id sia il caso in cui riceviamo direttamente il risultato
      if (typeof result === 'string') {
        // È un task_id, dobbiamo monitorare il task
        console.log('Task ID ricevuto:', result);
        const taskResult = await fileOperations.monitorTaskStatus(result);
        console.log('Risultato task completato:', taskResult);
        console.log('Struttura completa del risultato:', JSON.stringify(taskResult, null, 2));
        
        if (taskResult && taskResult.jsonl_path) {
          console.log('Aggiornamento jsonFilePath con:', taskResult.jsonl_path);
          fileOperations.setJsonFilePath(taskResult.jsonl_path);
          console.log('Aggiornamento jsonPreview con:', taskResult.preview);
          fileOperations.setJsonPreview(taskResult.preview || []);
          appendMessage(`File JSONL generato con successo: ${taskResult.jsonl_path}`, false);
          appendMessage('Ora puoi procedere con il fine-tuning del modello.', false);
        } else {
          console.error('Risultato task non contiene jsonl_path:', taskResult);
          appendMessage('Errore: il risultato non contiene il percorso del file JSONL', false);
        }
      } else {
        // È già il risultato finale
        console.log('Risultato diretto ricevuto:', result);
        console.log('Struttura completa del risultato diretto:', JSON.stringify(result, null, 2));
        
        if (result.jsonl_path) {
          console.log('Aggiornamento jsonFilePath con:', result.jsonl_path);
          fileOperations.setJsonFilePath(result.jsonl_path);
          console.log('Aggiornamento jsonPreview con:', result.preview);
          fileOperations.setJsonPreview(result.preview || []);
          appendMessage(`File JSONL generato con successo: ${result.jsonl_path}`, false);
          appendMessage('Ora puoi procedere con il fine-tuning del modello.', false);
        } else {
          console.error('Risultato diretto non contiene jsonl_path:', result);
          appendMessage('Errore: il risultato non contiene il percorso del file JSONL', false);
        }
      }
    } catch (error) {
      console.error('Errore in handleProceed:', error);
      appendMessage(`Errore durante la generazione JSONL: ${error.message}`, false);
    } finally {
      // Assicuriamoci che isProcessing venga reimpostato a false alla fine
      console.log('Fine handleProceed, reimposto isProcessing a false');
      fileOperations.setIsProcessing(false);
    }
  };
  
  // Gestore per procedere comunque (ignora gli errori)
  const handleProceedAnyway = async () => {
    console.log('handleProceedAnyway chiamato', {
      isProcessing: fileOperations.isProcessing,
      hasErrors,
      stats: tableData.stats,
      previewDataLength: tableData.previewData?.length,
      editedDataLength: tableData.editedData?.length,
      isEditMode: tableData.isEditMode
    });
    
    // Se c'è già un'elaborazione in corso, non procedere
    if (fileOperations.isProcessing) {
      console.log('Operazione in corso, non posso procedere');
      return;
    }
    
    // Imposta esplicitamente isProcessing a true all'inizio
    fileOperations.setIsProcessing(true);
    
    try {
      // Determina quali dati utilizzare (editedData se in modalità modifica, altrimenti previewData)
      const dataToUse = tableData.isEditMode && tableData.editedData ? 
        tableData.editedData : 
        tableData.previewData;
      
      console.log(`Utilizzo ${tableData.isEditMode ? 'editedData' : 'previewData'} per la generazione JSONL forzata`, {
        dataLength: dataToUse?.length,
        firstRecord: dataToUse?.[0]
      });
      
      // Passa i dati alla funzione handleGenerateJson con flag forza=true
      const result = await fileOperations.handleGenerateJson(true, dataToUse);
      console.log('Risultato generazione JSONL forzata:', result);
      
      if (!result) {
        console.error('Nessun risultato ricevuto da handleGenerateJson');
        return;
      }
      
      // Gestiamo sia il caso in cui riceviamo un task_id sia il caso in cui riceviamo direttamente il risultato
      if (typeof result === 'string') {
        // È un task_id, dobbiamo monitorare il task
        console.log('Task ID ricevuto:', result);
        const taskResult = await fileOperations.monitorTaskStatus(result);
        
        if (taskResult && taskResult.jsonl_path) {
          fileOperations.setJsonFilePath(taskResult.jsonl_path);
          fileOperations.setJsonPreview(taskResult.preview || []);
          appendMessage(`File JSONL generato con successo (forzato): ${taskResult.jsonl_path}`, false);
          appendMessage('Ora puoi procedere con il fine-tuning del modello.', false);
        }
      } else {
        // È già il risultato finale
        console.log('Risultato diretto ricevuto:', result);
        
        if (result.jsonl_path) {
          fileOperations.setJsonFilePath(result.jsonl_path);
          fileOperations.setJsonPreview(result.preview || []);
          appendMessage(`File JSONL generato con successo (forzato): ${result.jsonl_path}`, false);
          appendMessage('Ora puoi procedere con il fine-tuning del modello.', false);
        }
      }
    } catch (error) {
      console.error('Errore in handleProceedAnyway:', error);
      appendMessage(`Errore durante la generazione JSONL forzata: ${error.message}`, false);
    } finally {
      // Assicuriamoci che isProcessing venga reimpostato a false alla fine
      console.log('Fine handleProceedAnyway, reimposto isProcessing a false');
      fileOperations.setIsProcessing(false);
    }
  };
  
  // Gestore per inviare il file per il fine-tuning
  const handleSendForFinetuning = async (jsonFilePathParam) => {
    const temporaryJobId = await startFineTuning(jsonFilePathParam);
    if (temporaryJobId) {
      const jobId = await pollRealJobId(temporaryJobId);
      if (jobId) {
        await monitorFineTuneStatus(jobId);
      }
    }
  };
  
  // Gestore per la correzione dell'intero file
  const handleCorrectFile = async (filename) => {
    const taskId = await aiCorrection.handleCorrectFile(filename);
    if (taskId) {
      const result = await fileOperations.monitorTaskStatus(taskId);
      handleTaskCompletion(result);
    }
  };
  
  // Gestore per il completamento di un task
  const handleTaskCompletion = (result) => {
    try {
      if (!result) {
        console.log('Nessun risultato ricevuto in handleTaskCompletion');
        return;
      }
      
      // Debug per verificare la struttura dei dati ricevuti
      console.log('Risultato task completion ricevuto:', result);
      console.log('Tipo di risultato:', typeof result);
      console.log('Chiavi nel risultato:', Object.keys(result));
      
      // Aggiorna il percorso del file JSONL
      if (result.jsonl_path) {
        console.log('Aggiornamento jsonFilePath con:', result.jsonl_path);
        fileOperations.setJsonFilePath(result.jsonl_path);
      } else if (result.json_file_path) {
        console.log('Aggiornamento jsonFilePath con json_file_path:', result.json_file_path);
        fileOperations.setJsonFilePath(result.json_file_path);
      }
      
      // Aggiorna la preview JSONL
      if (result.preview) {
        console.log('Aggiornamento preview JSONL con:', result.preview);
        fileOperations.setJsonPreview(result.preview);
      } else if (result.json_preview) {
        console.log('Aggiornamento preview JSONL con json_preview:', result.json_preview);
        fileOperations.setJsonPreview(result.json_preview);
      }
      
      // Mostra informazioni sull'espansione del dataset
      if (result.records_processed && result.original_records) {
        const recordsExpanded = result.records_processed - result.original_records;
        const expansionMessage = `Dataset espanso da ${result.original_records} a ${result.records_processed} record (${recordsExpanded} nuovi record generati).`;
        appendMessage(expansionMessage, false);
        
        // Log per debug
        console.log('Informazioni espansione dataset:', {
          original: result.original_records,
          processed: result.records_processed,
          expanded: recordsExpanded
        });
      }
      
      // Ottieni i dati del dataset
      let datasetData = [];
      
      // Estrai i dati in base al formato della risposta
      if (result.dataset && result.dataset.length > 0) {
        // Caso semplice: i dati sono nel campo dataset
        datasetData = result.dataset;
        console.log('Dati trovati in result.dataset:', datasetData.length);
      } else if (result.preview) {
        // Caso per il formato originale con preview
        if (Array.isArray(result.preview)) {
          // Se preview è un array (come in upload_preview di singolo file)
          datasetData = result.preview;
          console.log('Dati trovati in result.preview (array):', datasetData.length);
        } else if (result.preview.data && Array.isArray(result.preview.data)) {
          // Se preview è un oggetto con un campo data (come in merge/upload)
          datasetData = result.preview.data;
          console.log('Dati trovati in result.preview.data:', datasetData.length);
        }
      } else if (result.results && result.results.length > 0) {
        // Caso per il formato originale di upload_preview: cerca i dati nei risultati
        for (const fileResult of result.results) {
          if (fileResult.preview) {
            if (Array.isArray(fileResult.preview) && fileResult.preview.length > 0) {
              datasetData = fileResult.preview;
              console.log('Dati trovati in result.results[].preview (array):', datasetData.length);
              break;
            } else if (fileResult.preview.data && Array.isArray(fileResult.preview.data)) {
              datasetData = fileResult.preview.data;
              console.log('Dati trovati in result.results[].preview.data:', datasetData.length);
              break;
            }
          }
        }
      }
      
      console.log('Totale dati trovati:', datasetData.length);
      if (datasetData.length > 0) {
        console.log('Esempio primo record:', datasetData[0]);
      }
      
      if (datasetData.length === 0) {
        appendMessage('Non sono stati trovati dati nel dataset', false);
        // Prova a mostrare la struttura completa per debug
        console.log('Struttura completa risposta:', JSON.stringify(result, null, 2));
        return;
      }
      
      // Verifica che i dati abbiano i campi domanda e risposta
      const hasDomandaRisposta = datasetData.every(item => 'domanda' in item && 'risposta' in item);
      if (!hasDomandaRisposta) {
        console.log('I dati non hanno i campi domanda e risposta richiesti:', datasetData[0]);
        appendMessage('I dati non hanno il formato atteso (domanda/risposta)', false);
        return;
      }
      
      // Aggiungi i dati di validazione a ogni record
      const dataWithValidation = datasetData.map((item, index) => {
        // Valida domanda
        const domandaValidation = validateCell('domanda', item.domanda);
        
        // Valida risposta
        const rispostaValidation = validateCell('risposta', item.risposta);
        
        // Determina se il record ha errori
        const hasError = !domandaValidation.isValid || !rispostaValidation.isValid;
        
        return {
          ...item,
          domandaValidation,
          rispostaValidation,
          hasError,
          originalIndex: index + 1,
          status: hasError ? 'invalid' : 'valid'
        };
      });
      
      // Inizializza i dati nella tabella
      tableData.initializeData(dataWithValidation);
      
      // Estrai le statistiche
      const stats = {
        total: dataWithValidation.length,
        valid: dataWithValidation.filter(item => item.status === 'valid').length,
        invalid: dataWithValidation.filter(item => item.status === 'invalid').length
      };
      
      // Se ci sono statistiche nella risposta, usale
      if (result.preview && result.preview.stats) {
        stats.valid = result.preview.stats.valid_records || stats.valid;
        stats.invalid = result.preview.stats.invalid_records || stats.invalid;
        stats.total = result.total_records || stats.total;
      }
      
      // Conta gli errori
      const errorCount = dataWithValidation.filter(item => item.hasError).length;
      
      // Visualizza il messaggio appropriato
      if (errorCount > 0) {
        appendMessage(`Analisi completata. Trovati ${errorCount} errori da correggere.`, false);
        
        // Aggiungi dettagli sugli errori
        const errorsByType = {
          domandaVuota: dataWithValidation.filter(item => !item.domanda || item.domanda.trim() === '').length,
          rispostaVuota: dataWithValidation.filter(item => !item.risposta || item.risposta.trim() === '').length,
          domandaLunga: dataWithValidation.filter(item => item.domanda && item.domanda.length > 150).length,
          rispostaLunga: dataWithValidation.filter(item => item.risposta && item.risposta.length > 500).length,
          domandaCorta: dataWithValidation.filter(item => item.domanda && item.domanda.length < 5 && item.domanda.length > 0).length,
          rispostaCorta: dataWithValidation.filter(item => item.risposta && item.risposta.length < 10 && item.risposta.length > 0).length
        };
        
        // Aggiungi messaggi per i tipi di errori trovati
        if (errorsByType.domandaVuota > 0) {
          appendMessage(`- ${errorsByType.domandaVuota} record con domanda vuota`, false);
        }
        if (errorsByType.rispostaVuota > 0) {
          appendMessage(`- ${errorsByType.rispostaVuota} record con risposta vuota`, false);
        }
        if (errorsByType.domandaLunga > 0) {
          appendMessage(`- ${errorsByType.domandaLunga} record con domanda troppo lunga (>150 caratteri)`, false);
        }
        if (errorsByType.rispostaLunga > 0) {
          appendMessage(`- ${errorsByType.rispostaLunga} record con risposta troppo lunga (>500 caratteri)`, false);
        }
        if (errorsByType.domandaCorta > 0) {
          appendMessage(`- ${errorsByType.domandaCorta} record con domanda troppo corta (<5 caratteri)`, false);
        }
        if (errorsByType.rispostaCorta > 0) {
          appendMessage(`- ${errorsByType.rispostaCorta} record con risposta troppo corta (<10 caratteri)`, false);
        }
      } else {
        appendMessage('Analisi completata. Nessun errore trovato! Puoi procedere con la generazione del file JSONL.', false);
      }
      
      // Aggiungi messaggi per qualità e suggerimenti se presenti
      if (result.quality_score) {
        appendMessage(`Qualità del dataset: ${Math.round(result.quality_score * 100)}%`, false);
      }
      
      if (result.improvement_suggestions && result.improvement_suggestions.length > 0) {
        appendMessage(`Suggerimenti di miglioramento: ${result.improvement_suggestions.join(', ')}`, false);
      }
      
      // Forza un re-render dei componenti che dipendono da jsonFilePath o jsonPreview
      if (fileOperations.jsonFilePath || fileOperations.jsonPreview) {
        console.log('Forzo re-render dei componenti UI con jsonFilePath:', fileOperations.jsonFilePath);
        
        // Breve timeout per assicurarsi che lo stato sia aggiornato
        setTimeout(() => {
          // Forza l'aggiornamento della StatusBar
          const statusBarElement = document.querySelector('.dashboard-status-bar');
          if (statusBarElement) {
            statusBarElement.classList.add('force-update-' + Date.now());
            setTimeout(() => statusBarElement.classList.remove('force-update-' + Date.now()), 50);
          }
          
          // Notifica che la generazione è completata
          if (fileOperations.jsonFilePath && !systemMessage.text.includes('JSONL')) {
            setSystemMessage({
              text: 'File JSONL generato con successo! Ora puoi procedere con il fine-tuning.',
              type: 'success'
            });
          }
        }, 100);
      }
    } catch (error) {
      console.error('Errore durante l\'elaborazione del risultato:', error);
      appendMessage(`Errore durante l'elaborazione: ${error.message}`, false);
    } finally {
      // Assicuriamoci che isProcessing venga reimpostato a false
      console.log('Fine handleTaskCompletion, reimposto isProcessing a false');
      fileOperations.setIsProcessing(false);
    }
  };
  
  // Calcola se ci sono errori nel dataset
  const hasErrors = tableData.stats.invalid > 0;
  
  // Effetto per notificare quando tutte le righe sono valide, senza uscire dalla modalità di modifica
  useEffect(() => {
    // Verifica se ci sono ancora errori nel dataset
    const currentHasErrors = tableData.stats.invalid > 0;
    
    // Forza un aggiornamento del componente StatusBar per mostrare il pulsante corretto
    const statusBarElement = document.querySelector('.dashboard-status-bar');
    if (statusBarElement) {
      const tempClass = 'force-update-' + Date.now();
      statusBarElement.classList.add(tempClass);
      setTimeout(() => statusBarElement.classList.remove(tempClass), 50);
    }
    
    // Forza un aggiornamento anche dell'intestazione della tabella
    const datasetPreviewElement = document.querySelector('.mb-4.flex.justify-between');
    if (datasetPreviewElement) {
      const tempClass = 'force-update-' + Date.now();
      datasetPreviewElement.classList.add(tempClass);
      setTimeout(() => datasetPreviewElement.classList.remove(tempClass), 50);
    }
    
    // Notifica l'utente quando tutte le righe sono valide, ma non esce dalla modalità di modifica
    if (tableData.stats.invalid === 0 && tableData.previewData.length > 0) {
      console.log('Dataset completamente valido, notifica all\'utente');
      
      // Forza un altro re-render del componente StatusBar
      if (statusBarElement) {
        const tempClass = 'force-update-' + Date.now();
        statusBarElement.classList.add(tempClass);
        setTimeout(() => statusBarElement.classList.remove(tempClass), 50);
      }
      
      // Mostra un messaggio di successo se non è già stato mostrato
      if (systemMessage.type !== 'success' || !systemMessage.text.includes('Dataset completamente valido')) {
        setSystemMessage({
          text: 'Dataset completamente valido! Ora puoi generare il file JSONL per il fine-tuning o continuare ad utilizzare Fix AI per ottimizzare le risposte.',
          type: 'success'
        });
      }
    }
  }, [tableData.stats, tableData.previewData.length, systemMessage, setSystemMessage]);
  
  // Formatta il tempo trascorso per il fine-tuning
  const elapsedTimeFormatted = formatElapsedTime(elapsedTime);
  
  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      {/* Aggiungi gli stili globali */}
      <style>{globalStyles}</style>
      
      <header className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">Dashboard FineTuningMax</h1>
        </div>
      </header>
      
      <main className="max-w-7xl mx-auto py-8 px-4 sm:px-6">
        {/* Sezione Upload */}
        <UploadSection
          selectedFiles={fileOperations.selectedFiles}
          handleFileChange={fileOperations.handleFileChange}
          handleFileUpload={handleFileUpload}
          handleDownloadTemplate={handleDownloadTemplate}
          isDarkMode={isDarkMode}
          useExpansion={fileOperations.useExpansion}
          setUseExpansion={fileOperations.setUseExpansion}
          numVariants={fileOperations.numVariants}
          handleNumVariantsChange={handleNumVariantsChange}
          templateFormat={fileOperations.templateFormat}
          setTemplateFormat={fileOperations.setTemplateFormat}
          isProcessing={fileOperations.isProcessing}
        />
        
        {/* Indicatore di Progresso per l'Espansione del Dataset - Spostato qui per maggiore visibilità */}
        {fileOperations.isProcessing && fileOperations.useExpansion && (
          <div className="mb-8 mt-8 animate-pulse">
            <div className={`p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-gray-800' : 'bg-white'} border-2 ${isDarkMode ? 'border-blue-700' : 'border-blue-300'}`}>
              <h2 className={`text-lg font-semibold mb-4 flex items-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                <SparklesIcon className={`h-6 w-6 mr-2 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'} animate-pulse`} />
                Espansione Dataset in Corso
              </h2>
              
              <div className="flex items-center mb-4">
                <div className="w-6 h-6 mr-3 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Generazione di {fileOperations.numVariants} varianti per ogni domanda/risposta...
                </p>
              </div>
              
              <div className="relative pt-1">
                <div className={`overflow-hidden h-6 mb-4 text-xs flex rounded-full ${
                  isDarkMode ? 'bg-blue-900' : 'bg-blue-200'
                }`}>
                  <div 
                    style={{ width: `${fileOperations.progress}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500 ease-in-out rounded-full relative overflow-hidden progress-bar-animated"
                  >
                    <div className="absolute inset-0 bg-white opacity-20 animate-pulse-fast"></div>
                    <span className="relative z-10 font-bold">{fileOperations.progress}%</span>
                  </div>
                </div>
                <div className="flex justify-between">
                  <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Elaborazione dataset
                  </span>
                  <span className={`text-sm font-semibold ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>
                    {fileOperations.progress}%
                  </span>
                </div>
              </div>
              
              <div className="mt-4 p-3 rounded-md flex items-start bg-opacity-50 bg-blue-100 dark:bg-blue-900 dark:bg-opacity-30">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  L'espansione del dataset può richiedere alcuni minuti, soprattutto per dataset più grandi. 
                  Il sistema sta generando varianti semanticamente simili per arricchire il tuo dataset.
                </p>
              </div>
            </div>
          </div>
        )}
        
        {/* Sezione Messaggi */}
        <div className="mb-8">
          <MessageLog
            messages={messages}
            messagesEndRef={messagesEndRef}
            isDarkMode={isDarkMode}
            isFineTuning={isFineTuning}
            fineTuneStatus={fineTuneStatus}
            progress={progress}
            elapsedTimeFormatted={elapsedTimeFormatted}
            showInfoMessage={showInfoMessage}
          />
        </div>
        
        {/* JSON Preview */}
        {fileOperations.jsonPreview && (
          <div className="mt-4">
            <JsonPreview 
              preview={fileOperations.jsonPreview} 
              isDarkMode={isDarkMode} 
              jsonFilePath={fileOperations.jsonFilePath}
            />
          </div>
        )}
        
        {/* Opzioni di Espansione Dataset - Spostato prima della tabella */}
        {tableData.previewData.length > 0 && tableData.stats.invalid === 0 && !fileOperations.jsonFilePath && !fileOperations.isProcessing && (
          <DatasetExpansionOptions
            useExpansion={fileOperations.useExpansion}
            setUseExpansion={fileOperations.setUseExpansion}
            numVariants={fileOperations.numVariants}
            handleNumVariantsChange={(e) => fileOperations.setNumVariants(parseInt(e.target.value) || 10)}
            isDarkMode={isDarkMode}
            handleProceed={handleProceed}
            isProcessing={fileOperations.isProcessing}
            hasErrors={hasErrors}
          />
        )}

        {/* Sezione Tabella Dati */}
        {tableData.previewData.length > 0 && (
          <div className="mb-32">
            <div className={`p-6 rounded-lg shadow-md mb-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
              <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Dataset Preview
              </h2>
              
              <div className="mb-4 flex justify-between items-center">
                <div className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Totale righe: {tableData.stats.total} | 
                  Valide: <span className="text-green-500">{tableData.stats.valid}</span> | 
                  Con errori: <span className="text-red-500">{tableData.stats.invalid}</span>
                </div>
              </div>
              
              <DataTable
                data={tableData.paginatedData}
                isEditMode={tableData.isEditMode}
                isDarkMode={isDarkMode}
                handleCellEdit={tableData.handleCellEdit}
                handleSaveEdit={tableData.handleSaveEdit}
                handleCancelEdit={tableData.handleCancelEdit}
                handleDeleteRecord={tableData.handleDeleteRecord}
                handleAICorrection={handleAICorrection}
                handleEditRecord={tableData.handleEditRecord}
                showActions={tableData.showActions}
                handleShowActions={tableData.handleShowActions}
                handleCloseMenu={tableData.handleCloseMenu}
                menuPosition={tableData.menuPosition}
                AIFixLoadingIndex={aiCorrection.AIFixLoadingIndex}
              />
              
              <Pagination
                currentPage={tableData.currentPage}
                totalPages={tableData.totalPages}
                onPageChange={tableData.handlePageChange}
                rowsPerPage={tableData.rowsPerPage}
                onRowsPerPageChange={tableData.handleRowsPerPageChange}
                isDarkMode={isDarkMode}
              />
            </div>
          </div>
        )}
        
        {/* Barra di Stato */}
        {tableData.previewData.length > 0 && (
          <StatusBar
            hasErrors={hasErrors}
            errorCount={tableData.stats.invalid}
            onShowErrorsOnly={tableData.handleShowErrorsOnly}
            onShowAll={tableData.handleShowAll}
            showErrorsOnly={tableData.showErrorsOnly}
            onProceed={handleProceed}
            onProceedAnyway={handleProceedAnyway}
            onSendForFinetuning={handleSendForFinetuning}
            jsonFilePath={fileOperations.jsonFilePath}
            isDarkMode={isDarkMode}
            isProcessing={fileOperations.isProcessing || isFineTuning}
          />
        )}

        {/* Logging per debug */}
        {console.log('StatusBar props:', {
          hasErrors,
          errorCount: tableData.stats.invalid,
          showErrorsOnly: tableData.showErrorsOnly,
          jsonFilePath: fileOperations.jsonFilePath,
          isProcessing: fileOperations.isProcessing || isFineTuning,
          isEditMode: tableData.isEditMode
        })}
      </main>
      
      {/* Aggiunge spazio per evitare che il contenuto venga nascosto dalla barra fissa */}
      <div className="pb-20" />
    </div>
  );
};

export default Dashboard;